import { useState } from 'react';
import { Flex, TooltipV2, defaultTheme } from '@compstak/ui-kit';
import { SuggestUpdateForm } from 'Components/SuggestUpdate';
import { LeaseComp } from 'types';
import { SuggestUpdateButton } from 'Components/SuggestUpdate/SuggestUpdateButton';
import styled from 'styled-components';
import IconAlert from 'ui/svg_icons/triangle_alert.svg';

type Props = {
	lease: LeaseComp;
};
export const LimitedDetailsLeaseBanner = ({ lease }: Props) => {
	const [formIsOpen, setFormIsOpen] = useState(false);

	return formIsOpen ? (
		<StyledSuggestUpdateForm
			formIsOpen={formIsOpen}
			setFormIsOpen={setFormIsOpen}
			selectedComps={[lease]}
			compType="lease"
			market={lease.market}
		/>
	) : (
		<BannerContainer>
			<Flex alignItems="center">
				<IconAlert height="1.125rem" />
				<MessageContainer>
					This Comp is missing a required field.
					<TooltipV2
						content={limitedDetailsTooltip}
						contentCSS={() => ({ zIndex: defaultTheme.zIndex.overlay })}
						side="bottom"
					>
						<LearnMoreButton>Learn More</LearnMoreButton>
					</TooltipV2>
				</MessageContainer>
			</Flex>
			<StyledSuggestUpdateButton onClick={() => setFormIsOpen(true)} />
		</BannerContainer>
	);
};

const limitedDetailsTooltip =
	'A full comp includes the Tenant Name, Transaction SqFt, an Execution OR Commencement Date, and at least one rent value.';

const BannerContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	// TODO: add colors to ui-kit
	background-color: #fff9eb;
	border-bottom: 1px solid #e5b02f;
	padding: 1.25rem 2.5rem;
	font-size: 0.875rem;
	gap: 1rem;
`;

const MessageContainer = styled.p`
	margin: 0 0 0 1rem;
`;
const LearnMoreButton = styled.span`
	text-decoration: underline;
	margin-left: 0.25rem;
	cursor: pointer;
`;

const StyledSuggestUpdateButton = styled(SuggestUpdateButton)`
	&:not(:disabled) {
		border: 1px solid ${({ theme }) => theme.colors.neutral.n100};
		:hover {
			border: none;
		}
	}
`;

const StyledSuggestUpdateForm = styled(SuggestUpdateForm)`
	background-color: #fff9eb;
	border-bottom: 1px solid #e5b02f;
`;
