import { userActions } from 'actions/user';
import { useReferenceDataQuery, useUserQuery } from 'api';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch } from 'react-redux';
import { useFilters } from 'reducers/filtersReducer';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import IconChevron from '../../ui/svg_icons/arrow_bottom_rounded.svg';
import { Graph } from './Graph/Graph';

type Props = {
	isOpen: boolean;
	toggleIsOpen: () => void;
	isDashboard?: boolean;
};

export const LeaseInsights = ({
	isOpen,
	toggleIsOpen,
	isDashboard = false,
}: Props) => {
	const [filters, setFilters] = useFilters(
		isDashboard ? 'exchange-dashboard' : 'main'
	);
	const { data: user } = useUserQuery();
	const { data: referenceData } = useReferenceDataQuery();

	const dispatch = useDispatch();

	const boundUserActions = useMemo(() => {
		return bindActionCreators(userActions, dispatch);
	}, [dispatch]);

	return (
		<>
			<Header>
				<Title>Insights</Title>
				<CollapseButton onClick={toggleIsOpen}>
					{isOpen ? 'Collapse' : 'Expand'}
					<StyledIconChevron
						width={12}
						height={6}
						style={{
							transform: isOpen ? 'rotate(180deg)' : undefined,
						}}
					/>
				</CollapseButton>
			</Header>
			{isOpen && (
				<div>
					<ErrorBoundary
						fallback={<h3>There was a problem with showing insights data</h3>}
					>
						<Graph
							key="graph"
							thresholdForPoints={100}
							// @ts-expect-error prop not defined
							market={getFiltersMarkets(filters)[0]}
							user={user}
							filters={filters}
							referenceData={referenceData}
							onFilterChange={setFilters}
							userActions={boundUserActions}
						/>
					</ErrorBoundary>
				</div>
			)}
		</>
	);
};

const Header = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: ${(p) => p.theme.colors.neutral.n20};
	line-height: 35px;
	height: 35px;
	padding: 0 1rem;
`;

const Title = styled.h3`
	font-size: 16px;
	font-weight: 500;
	text-transform: uppercase;
	color: ${(p) => p.theme.colors.gray.gray500};
`;

const CollapseButton = styled.button`
	all: unset;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	color: ${(p) => p.theme.colors.blue.blue400};
	font-weight: 500;
	font-size: 12px;
	text-transform: uppercase;
	font-family: ${(p) => p.theme.typography.fontFamily.gotham};
`;

const StyledIconChevron = styled(IconChevron)`
	fill: ${(p) => p.theme.colors.blue.blue400};
`;
