import {
	VictoryAxis,
	VictoryBar,
	VictoryChart,
	VictoryStack,
	VictoryVoronoiContainer,
	VictoryLabel,
	VictoryTooltip,
} from 'victory';
import { ChartBox, ChartOption } from '../ChartSelect';
import { useChartHistogramQuery } from 'api';
import { PORTFOLIO_CHART_COLORS } from '../../constants';
import {
	CHART_AXIS_TICK_STYLE,
	VICTORY_CONTAINER_STYLE_400,
	VICTORY_TOOLTIP_STYLE,
} from '../chartsConstants';
import { useNavigate } from 'react-router';
import { routes } from 'router';
import { FiltersState } from 'PortfolioAnalytics/PortfolioFiltersProvider';
import { ChartStateWrapper } from '../ChartStateWrapper';
import {
	Legend,
	LegendColor,
	LegendItem,
	LegendLabel,
	ChartAndLegendContainer,
} from 'PortfolioAnalytics/styles/PortfolioUI';
import { YEAR_RANGE } from './utils';
import { SpaceTypeName } from 'api';
import { useMemo } from 'react';
import { formatMoney, formatNumber } from 'format';

type LeaseExpirationsBySpaceTypeChartProps = {
	portfolioId: number;
	filters: FiltersState;
};

type TransformedValue = {
	expirationYear: number;
	yValue: number;
	actualValue: number;
	spaceTypeName?: SpaceTypeName;
};

type ChartData = [SpaceTypeName, TransformedValue[]][] | [];

const LEASE_EXPIRATION__BY_SPACE_TYPE_CHART_NAME = 'Upcoming Lease Expirations';
const MINIMUM_BAR_HEIGHT_PERCENTAGE = 2;

export const LeaseExpirationsBySpaceTypeChartV2 = (
	props: LeaseExpirationsBySpaceTypeChartProps
) => {
	return (
		<ChartBox<'lease'> chartName={LEASE_EXPIRATION__BY_SPACE_TYPE_CHART_NAME}>
			{(selectedChart) => (
				<LeaseExpirationsBySpaceTypeChartV2Content
					{...props}
					selectedChart={selectedChart}
				/>
			)}
		</ChartBox>
	);
};

type LeaseExpirationsBySpaceTypeChartV2ContentProps =
	LeaseExpirationsBySpaceTypeChartProps & {
		selectedChart: ChartOption<'lease'>;
	};

const LeaseExpirationsBySpaceTypeChartV2Content = ({
	portfolioId,
	filters,
	selectedChart,
}: LeaseExpirationsBySpaceTypeChartV2ContentProps) => {
	const { data, isFetching, isError } = useChartHistogramQuery(portfolioId, {
		filters,
		chartMetric:
			selectedChart.type === 'sqft' ? 'transactionSize' : selectedChart.type,
		chartDimension: 'expirationDate',
		chartGrouping: 'spaceType',
		chartLimit: 10,
	});

	const fiveYearData = data?.values.filter((value) =>
		YEAR_RANGE.includes(value.valueLabel)
	);

	const spaceTypes: SpaceTypeName[] = useMemo(() => {
		if (!fiveYearData || fiveYearData.length === 0) return [];
		return Array.from(
			new Set(
				fiveYearData.flatMap((item) =>
					item.values.map((v) => v.label as SpaceTypeName)
				)
			)
		).sort();
	}, [fiveYearData]);
	const maxValue = useMemo(() => {
		if (!fiveYearData) return -Infinity;
		return Math.max(
			...fiveYearData.flatMap((yearData) =>
				yearData.values.map((v) => v.value || 0)
			)
		);
	}, [fiveYearData]);
	const halfwayVerticalAxis = maxValue * 0.5;

	const chartData: ChartData = useMemo(() => {
		if (!fiveYearData) return [];
		return spaceTypes.map((spaceType) => {
			const minVisibleValue = (maxValue * MINIMUM_BAR_HEIGHT_PERCENTAGE) / 100;
			const values = fiveYearData.map((yearData) => {
				const matchingValue = yearData.values.find(
					(v) => v.label === spaceType
				);
				const actualValue = matchingValue?.value || 0;
				const transformedValue: TransformedValue = {
					expirationYear: yearData.valueLabel,
					//if actualValue is too small, bars won't be visible
					yValue: actualValue > 0 ? Math.max(actualValue, minVisibleValue) : 0,
					actualValue,
					spaceTypeName: spaceType,
				};
				return transformedValue;
			});
			return [spaceType, values];
		});
	}, [spaceTypes, fiveYearData, maxValue]);

	const navigate = useNavigate();

	return (
		<ChartStateWrapper
			isError={isError}
			isFetching={isFetching}
			noData={data?.values.length === 0}
			showNoDataMessage={false}
		>
			<ChartAndLegendContainer>
				<VictoryChart
					height={500}
					padding={{ left: 30, top: 75, right: 5, bottom: 40 }}
					containerComponent={
						<VictoryVoronoiContainer
							responsive={true}
							style={{
								...VICTORY_CONTAINER_STYLE_400,
								maxHeight: '500px',
							}}
							activateData={true}
							labels={({ datum }: { datum: TransformedValue }) => {
								if (!datum || !datum.actualValue) return '';
								const value = datum.actualValue;
								const formattedValue =
									selectedChart.type === 'leaseAmount'
										? formatMoney(value, { decimalPlaces: 2, shorten: true })
										: formatNumber(value, { shorten: true });
								return datum.yValue > 0
									? `${formattedValue}\nClick the bar to view\n${datum.spaceTypeName} leases\nexpiring in ${datum.expirationYear}`
									: `No ${datum.spaceTypeName} leases\nexpiring in ${datum.expirationYear}`;
							}}
							labelComponent={
								<VictoryTooltip
									centerOffset={{
										x: 0,
										y: ({ datum }) => {
											return datum?._y1 && datum?._y1 > halfwayVerticalAxis
												? 100
												: 0;
										},
									}}
									{...VICTORY_TOOLTIP_STYLE}
									flyoutWidth={150}
								/>
							}
						/>
					}
				>
					<VictoryAxis
						dependentAxis
						offsetX={30}
						tickFormat={(yAxisValue: number) =>
							selectedChart.type === 'leaseAmount'
								? formatMoney(yAxisValue, { decimalPlaces: 0, shorten: true })
								: formatNumber(yAxisValue, { shorten: true })
						}
						tickLabelComponent={
							<VictoryLabel renderInPortal style={CHART_AXIS_TICK_STYLE} />
						}
						style={{
							axis: { stroke: '#F0F0F0', strokeWidth: 2 },
							grid: { stroke: '#F0F0F0' },
							tickLabels: {
								padding: 5,
							},
						}}
					/>
					<VictoryAxis
						fixLabelOverlap
						tickFormat={(yAxisValue: number) => `${yAxisValue}`}
						tickLabelComponent={
							<VictoryLabel renderInPortal style={CHART_AXIS_TICK_STYLE} />
						}
						style={{
							axis: { stroke: '#F0F0F0', strokeWidth: 2 },
							axisLabel: { fontSize: 20, padding: 0, color: '#A3A3A3' },
						}}
						domain={{
							x: [
								YEAR_RANGE[0] - 0.99,
								YEAR_RANGE[YEAR_RANGE.length - 1] + 0.99,
							],
						}}
					/>
					<VictoryStack
						colorScale={PORTFOLIO_CHART_COLORS}
						style={{
							parent: {
								height: 300,
							},
						}}
					>
						{chartData.map(([spaceTypeName, spaceTypeGroup]) => {
							const spaceTypeGroupWithoutEmptyBars = spaceTypeGroup.filter(
								(d) => d.yValue && d.actualValue > 0
							);
							return (
								<VictoryBar
									key={spaceTypeName}
									barRatio={0.6}
									data={spaceTypeGroupWithoutEmptyBars}
									x="expirationYear"
									y="yValue"
									style={{
										data: {
											cursor: 'pointer',
											width: 24,
										},
									}}
									events={[
										{
											target: 'data',
											eventHandlers: {
												onClick: (e, clickedProps) => {
													const { expirationYear } = clickedProps.datum;
													navigate(
														routes.portfolioByIdView.toHref(
															{
																portfolioId: portfolioId,
																viewType: 'list',
															},
															{
																expirationYears: [expirationYear],
																spaceTypes: [spaceTypeName],
															}
														)
													);
												},
											},
										},
									]}
								/>
							);
						})}
					</VictoryStack>
				</VictoryChart>
				<Legend>
					{chartData.map(([spaceType], i) => (
						<LegendItem key={i}>
							<LegendColor color={PORTFOLIO_CHART_COLORS[i]} />
							<LegendLabel>{spaceType}</LegendLabel>
						</LegendItem>
					))}
				</Legend>
			</ChartAndLegendContainer>
		</ChartStateWrapper>
	);
};
