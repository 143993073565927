import {
	SimpleTableColumn,
	MQB,
	defaultTheme,
	Spinner,
} from '@compstak/ui-kit';
import { useMediaQuery } from 'react-responsive';
import { AnyComp, PropertyComp } from 'types';
import { useRenderCompField } from 'hooks/useFormatCompField';
import { getIsMonthlyMarket } from 'utils';
import { Checkbox as CheckboxOriginal, StopPropagation } from 'Components';
import { CheckedState } from '@radix-ui/react-checkbox';
import { ExportCompsButton } from 'Components/Export';
import { usePortfolioProperties } from './PortfolioPropertiesProvider';
import { NumberOfActiveLeasesCell } from './NumberOfActiveLeasesCell';

export const PORTFOLIO_PROPERTIES_COLUMNS: (SimpleTableColumn<PropertyComp> & {
	tabletWidth?: number;
})[] = [
	{
		id: 'notExpiredLeaseCount',
		header: 'Active Leases',
		width: 100,
		flex: 1,
	},
	{
		id: 'buildingAddress',
		header: 'Address',
		width: 180,
		tabletWidth: 100,
		flex: 1,
	},
	{
		id: 'city',
		header: 'City',
		width: 140,
		tabletWidth: 100,
		flex: 1,
	},
	{
		id: 'state',
		header: 'State',
		width: 140,
		tabletWidth: 100,
		flex: 1,
	},
	{
		id: 'marketDisplayName',
		header: 'Market',
		width: 120,
		tabletWidth: 80,
		flex: 1,
	},
	{
		id: 'submarket',
		header: 'Submarket',
		width: 140,
		tabletWidth: 90,
		flex: 1,
	},
	{
		id: 'buildingPropertyType',
		header: 'Property Type',
		width: 100,
		flex: 1,
	},
	{
		id: 'buildingPropertySubtype',
		header: 'Property Subtype',
		width: 140,
		flex: 1,
	},
	{
		id: 'buildingClass',
		header: 'Class',
		width: 60,
		flex: 1,
		hAlign: 'right',
	},
	{
		id: 'buildingYear',
		header: 'Year Built',
		width: 80,
		hAlign: 'right',
	},
	{
		id: 'buildingYearRenovated',
		header: 'Year Renovated',
		width: 110,
		hAlign: 'right',
	},
	{
		id: 'buildingFloorsCount',
		header: 'Floors',
		width: 60,
		hAlign: 'right',
	},
	{
		id: 'landlordName',
		header: 'Current Landlord',
		width: 180,
		tabletWidth: 120,
		hAlign: 'right',
	},
];

export const usePortfolioPropertiesColumns = () => {
	const { isFetchingAll, selectionState } = usePortfolioProperties();

	const isTablet = useMediaQuery({ maxWidth: MQB.T_834 });

	const renderCompField = useRenderCompField();

	const renderPortfolioPropertyCell = ({
		row,
		name,
	}: {
		row: PropertyComp;
		name: keyof PropertyComp;
	}) => {
		return renderCompField({
			name,
			row: row as AnyComp,
			isMonthly: getIsMonthlyMarket(row.marketId),
		});
	};

	const {
		getAreAllSelected,
		areSomeSelected,
		getIsRowSelected,
		toggleAllSelected,
		toggleRow,
	} = selectionState;

	const selectAllCheckboxState: CheckedState = getAreAllSelected()
		? true
		: areSomeSelected
			? 'indeterminate'
			: false;

	const columns: SimpleTableColumn<PropertyComp>[] = [
		{
			id: 'selection',
			header: () => {
				if (isFetchingAll) {
					return <Spinner size="s" isCentered />;
				}

				return (
					<CheckboxOriginal
						id="selectAll"
						name="selectAll"
						data-qa-id="select-all-checkbox"
						checked={selectAllCheckboxState}
						onCheckedChange={toggleAllSelected}
						bgColorUnchecked={defaultTheme.colors.white.white}
					/>
				);
			},
			body: ({ row }) => {
				return (
					<StopPropagation handler="onClick">
						<CheckboxOriginal
							name={`selectRow-${row.id}`}
							data-qa-id={`select-row-checkbox-${row.id}`}
							checked={getIsRowSelected(row.id)}
							onCheckedChange={() => toggleRow(row.id)}
							onClick={(e) => e.stopPropagation()}
						/>
					</StopPropagation>
				);
			},
			width: 50,
			hAlign: 'center',
		},
		{
			id: 'actions',
			header: () => '',
			body: ({ row }) => (
				<StopPropagation handler="onClick">
					<ExportCompsButton
						compType="property"
						compIds={[row.id]}
						buttonType="row"
					/>
				</StopPropagation>
			),
			width: 75,
		},
		...PORTFOLIO_PROPERTIES_COLUMNS.map((col) => {
			const column: SimpleTableColumn<PropertyComp> = {
				...col,
				body: ({ row }) => {
					if (col.id === 'notExpiredLeaseCount' && !!row.notExpiredLeaseCount) {
						return <NumberOfActiveLeasesCell property={row} />;
					}
					return renderPortfolioPropertyCell({
						row,
						name: col.id as keyof PropertyComp,
					});
				},
				tooltip: ({ row }) =>
					renderPortfolioPropertyCell({
						row,
						name: col.id as keyof PropertyComp,
					}),
				width: isTablet ? col.tabletWidth ?? col.width : col.width,
				hAlign: 'right',
			};

			return column;
		}),
	];

	return columns;
};
