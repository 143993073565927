import constate from 'constate';
import { LeaseComp } from 'types';
import { useSelectionState } from 'utils/useSelectionState';
import { getFiltersFromPortfolioLeasesFilters } from './utils';
import { useEffect, useMemo } from 'react';
import {
	PortfolioV2,
	SEARCH_INFINITE_PAGE_SIZE,
	SEARCH_MAX_LIMIT,
	useSearchFilterParams,
	useSearchLeasesInfiniteQuery,
	useSearchLeasesQuery,
} from 'api';
import { useInfiniteTableRows } from 'utils/useInfiniteTableRows';
import { PortfolioLeasesFilters } from './PortfolioLeasesFiltersProvider';
import { usePortfolioFilters } from 'PortfolioAnalytics/PortfolioFiltersProvider';
import { getFiltersFromPortfolioFilters } from 'PortfolioAnalytics/utils/getFiltersFromPortfolioFilters';
import { getFiltersFromPortfolioIdRouteSearchParams } from 'PortfolioAnalytics/utils/getFiltersFromPortfolioIdRouteSearchParams';
import { PortfolioByIdRouteSearchParams } from 'router';

type Props = {
	portfolio: PortfolioV2;
	includeFields: (keyof LeaseComp)[];
	portfolioLeasesFilters: PortfolioLeasesFilters;
	searchParams?: PortfolioByIdRouteSearchParams;
};

export const [PortfolioLeasesProvider, usePortfolioLeases] = constate(
	({
		portfolio,
		includeFields,
		portfolioLeasesFilters,
		searchParams,
	}: Props) => {
		const { filters: portfolioFilters } = usePortfolioFilters();

		const filters = useMemo(() => {
			const portfolioFiltersObject = getFiltersFromPortfolioFilters({
				...portfolioFilters,
				...searchParams,
				portfolio,
			});

			const portfolioAndLeasesFilters = getFiltersFromPortfolioLeasesFilters({
				leaseFilters: portfolioLeasesFilters,
				portfolioFilters: portfolioFiltersObject,
			});

			const filtersFromSearchParams =
				getFiltersFromPortfolioIdRouteSearchParams(searchParams ?? {});

			return { ...portfolioAndLeasesFilters, ...filtersFromSearchParams };
		}, [portfolioFilters, portfolioLeasesFilters, portfolio, searchParams]);

		const filterParams = useSearchFilterParams({ filters });

		const infiniteLeasesQuery = useSearchLeasesInfiniteQuery({
			...filterParams,
			properties: includeFields,
		});
		const noLimitLeasesQuery = useSearchLeasesQuery(
			{
				...filterParams,
				offset: 0,
				limit: SEARCH_MAX_LIMIT,
				properties: ['id', 'marketId'],
			},
			{ enabled: false }
		);

		const infiniteRows = useInfiniteTableRows({
			data: infiniteLeasesQuery.data,
			getRows: (page) => page.comps,
			isFetching: infiniteLeasesQuery.isFetchingNextPage,
			pageSize: SEARCH_INFINITE_PAGE_SIZE,
		});
		const rowsForSelection = noLimitLeasesQuery.data?.comps ?? infiniteRows;

		const _selectionState = useSelectionState(rowsForSelection);
		const selectionState = {
			..._selectionState,
			toggleAllSelected: () => {
				// fetch all leases if we haven't yet and there is more to load
				if (!noLimitLeasesQuery.data && infiniteLeasesQuery.hasNextPage) {
					noLimitLeasesQuery.refetch();
				} else {
					_selectionState.toggleAllSelected();
				}
			},
		};

		useEffect(() => {
			if (noLimitLeasesQuery.data?.comps) {
				selectionState.toggleAllSelected();
			}
		}, [noLimitLeasesQuery.data]);

		useEffect(() => {
			selectionState.resetSelection();
		}, [filters]);

		const isFetching =
			infiniteLeasesQuery.isFetching || noLimitLeasesQuery.isFetching;
		const isError = infiniteLeasesQuery.isError || noLimitLeasesQuery.isError;
		const isFetchingNextPage = infiniteLeasesQuery.isFetchingNextPage;
		const hasNextPage = infiniteLeasesQuery.hasNextPage;
		const fetchNextPage = infiniteLeasesQuery.fetchNextPage;

		return {
			data: infiniteRows,
			isFetchingNextPage,
			isError,
			isFetching,
			isFetchingAll: noLimitLeasesQuery.isFetching,
			hasNextPage,
			fetchNextPage,
			selectionState,
		};
	}
);
