import { UnlockButton } from 'Components/UnlockButton/UnlockButton';
import { SearchLayoutRouteParams } from 'Layouts/SearchLayout';
import { useIsSendToAnalyticsBtnAllowed } from 'hooks/chartBuilderHooks';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { FiltersType, useFilters } from 'reducers/filtersReducer';
import styled from 'styled-components';
import { CompType, PropertyComp } from 'types';
import { useAppSelector } from 'util/useAppSelector';
import { GoToCompTypeSection } from './GoToCompTypeSection';
import { SaveSearchButton } from './SaveSearchButton';
import { ExportCompsButton } from 'Components/Export';
import { useMemo } from 'react';
import { extractPropertyIdsFromComps, isPropertyComp } from 'utils/compHelpers';
import { SendToChartBuilderButton } from './SendToChartBuilderButton';
import { useIsMultifamily } from 'hooks';
import { AddToPortfolioButton } from 'Components';
import { Flex, MQB } from '@compstak/ui-kit';

type SearchToolbarButtonsProps = {
	compType: CompType;
	params: SearchLayoutRouteParams;
	filterContext?: FiltersType;
};

export const SearchToolbarButtons = ({
	compType,
	params,
	filterContext,
}: SearchToolbarButtonsProps) => {
	const flags = useFeatureFlags();

	const isSendToAnalyticsBtnAllowed = useIsSendToAnalyticsBtnAllowed(compType);
	const [filters] = useFilters(filterContext);
	const selection = useAppSelector((state) => state.selection.list);
	const ownedComps = useMemo(() => {
		return selection.filter((comp) => (isPropertyComp(comp) ? true : comp.own));
	}, [selection]);
	const isMufa = useIsMultifamily({ markets: getFiltersMarkets(filters) });

	const portfolioPropertyIds = useMemo(() => {
		return extractPropertyIdsFromComps({ comps: selection, compType });
	}, [selection]);

	if (params.view !== 'list') {
		return null;
	}

	return (
		<Root>
			<LeftButtons>
				{compType !== 'property' && (
					<UnlockButton
						compType={compType}
						markets={getFiltersMarkets(filters)}
					/>
				)}
				<ExportCompsButton
					buttonType="table"
					compIds={ownedComps.map(({ id }) => id)}
					compType={compType}
					getComps={() => ownedComps}
					isMufa={isMufa}
				/>
				{compType === 'property' && (
					<GoToCompTypeSection
						properties={selection as PropertyComp[]}
						filters={filters}
					/>
				)}
			</LeftButtons>
			<VerticalSeparator />
			<Flex gap="0.5rem">
				<StyledAddToPortfolioButton
					marketIds={selection.map(({ marketId }) => marketId)}
					propertyIds={portfolioPropertyIds}
					variant="primary3"
					disabled={selection.length === 0}
				/>

				{flags.SavedSearchImprovements && (
					<SaveSearchButton compType={compType} />
				)}
				{isSendToAnalyticsBtnAllowed && (
					<SendToChartBuilderButton
						compType={compType}
						count={selection.length}
						filters={filters}
					/>
				)}
			</Flex>
		</Root>
	);
};

const Root = styled.div`
	display: flex;
	align-items: center;
`;

const LeftButtons = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;
`;

const VerticalSeparator = styled.span`
	width: 1px;
	height: 32px;
	background-color: ${({ theme }) => theme.colors.neutral.n50};
	margin: 0 1rem;
`;

const StyledAddToPortfolioButton = styled(AddToPortfolioButton)`
	height: 100%;
	min-width: unset;

	svg {
		width: 1.5rem;
		height: 1.5rem;
	}

	@media (max-width: ${MQB.T_834}) {
		padding: 0.5rem;
	}
`;
