import {
	AngleArrow,
	Button,
	Close,
	Excel,
	Flex,
	Pdf,
	Spinner,
} from '@compstak/ui-kit';
import * as Popover from '@radix-ui/react-popover';
import { defaultDropdownContentCss } from 'Components/UI';
import styled from 'styled-components';
import { useCompExport } from './useCompExport';
import { CompType, PropertyComp } from 'types';
import { useState } from 'react';
import { PropertyLease, PropertySale } from 'api';
import { getExportPopupTitle } from './getExportPopupTitle';
import { AnnualMonthlySelector } from './AnnualMonthlySelector';
import { MarketToCompsExportTable } from './MarketToCompsExportTable/MarketToCompsExportTable';
import { useMarketToCompIds } from './useMarketToCompIds';

type Props<T extends CompType> = {
	compIds: number[];
	/** only relevant to Per Market Export policy */
	getComps?: () => (T extends 'lease'
		? PropertyLease
		: T extends 'sale'
			? PropertySale
			: PropertyComp)[];
	type: T;
	closePopup: () => void;
	side?: Popover.PopoverContentProps['side'];
	isMufa?: boolean;
};

export const CompExportPopup = <T extends CompType>({
	compIds,
	getComps,
	type,
	closePopup,
	side,
	isMufa,
}: Props<T>) => {
	const [selectedCompIds, setSelectedCompIds] = useState(compIds);
	const [isMonthly, setIsMonthly] = useState(false);
	const [isAnnualMonthlySelectorOn, setIsAnnualMonthlySelectorOn] =
		useState(false);

	const {
		isFetching,
		isLoadingExportExcel,
		onClickExportExcel,
		onClickExportPdf,
		onClickCopyIds,
		shouldShowIdsButton,
		data,
	} = useCompExport({
		compIds,
		type,
		closePopup,
		isMufa,
	});

	const isPerMarketTableOn =
		!isAnnualMonthlySelectorOn &&
		!!data?.perMarket &&
		compIds.length > 1 &&
		!!getComps;

	const marketToCompIds = useMarketToCompIds(
		{ getComps },
		{ enabled: isPerMarketTableOn }
	);

	const title = isAnnualMonthlySelectorOn
		? 'Excel Export'
		: isPerMarketTableOn
			? 'Export your comps'
			: getExportPopupTitle(data);

	return (
		<>
			<Popover.Portal>
				<Content
					side={side}
					onCloseAutoFocus={(e) => {
						e.preventDefault();
					}}
				>
					<Popover.Arrow />
					{isFetching ? (
						<SpinnerContainer>
							<Spinner size="l" />
						</SpinnerContainer>
					) : (
						<Root>
							<Header>
								{isAnnualMonthlySelectorOn && (
									<div onClick={() => setIsAnnualMonthlySelectorOn(false)}>
										<StyledAngleArrow rotate={90} />
									</div>
								)}
								<Title>{title}</Title>
								<Close
									onClick={closePopup}
									data-qa-id="popup-content-close-btn"
									isInheritedColor
								/>
							</Header>
							{isAnnualMonthlySelectorOn && (
								<AnnualMonthlySelector
									isMonthly={isMonthly}
									setIsMonthly={setIsMonthly}
									isLoadingExportExcel={isLoadingExportExcel}
									onClickExportExcel={() =>
										onClickExportExcel({
											monthly: isMonthly,
											compIds: selectedCompIds,
										})
									}
								/>
							)}
							{isPerMarketTableOn && (
								<MarketToCompsExportTable
									marketToCompIds={marketToCompIds}
									perMarketPolicy={data.perMarket!}
									setSelectedCompIds={setSelectedCompIds}
								/>
							)}
							{!isAnnualMonthlySelectorOn && (
								<Flex gap="0.5rem">
									<StyledButton
										icon={<Excel isInheritedColor />}
										onClick={() => setIsAnnualMonthlySelectorOn(true)}
										variant="primary"
										data-qa-id="popup-export-excel-btn"
										disabled={!selectedCompIds.length}
									>
										Excel
									</StyledButton>
									{type !== 'property' && (
										<StyledButton
											icon={<Pdf isInheritedColor />}
											onClick={() =>
												onClickExportPdf({ compIds: selectedCompIds })
											}
											data-qa-id="popup-export-pdf-btn"
											disabled={!selectedCompIds.length}
										>
											Pdf
										</StyledButton>
									)}
									{shouldShowIdsButton && (
										<StyledButton
											onClick={() =>
												onClickCopyIds({ compIds: selectedCompIds })
											}
											data-qa-id="popup-export-ids-btn"
										>
											Ids
										</StyledButton>
									)}
								</Flex>
							)}
						</Root>
					)}
				</Content>
			</Popover.Portal>
		</>
	);
};

const Content = styled(Popover.Content)`
	${defaultDropdownContentCss};
	background-color: ${(p) => p.theme.colors.gray.gray700};
	// TODO: z-index is identical to modal overlay @see packages/apps/src/Components/Modals/common/UI.tsx until the AP-16395 is finished
	// should be theme.zIndex.dropdown
	z-index: ${({ theme }) => theme.zIndex.overlay + theme.zIndex.modal};
`;

const SpinnerContainer = styled.div`
	height: 92px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const StyledButton = styled(Button)`
	flex: 1;
`;

export const Root = styled.div`
	padding: 1rem;
	min-width: 260px;
	color: ${(p) => p.theme.colors.white.white};
	font-family: ${(p) => p.theme.typography.fontFamily.gotham};
	border-radius: 3px;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
`;

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 0.5rem;
`;

export const Title = styled.div`
	display: flex;
	gap: 4px;
	align-items: center;
	font-size: 0.75rem;
	text-transform: uppercase;
`;

const StyledAngleArrow = styled(AngleArrow)`
	cursor: pointer;
	path {
		stroke: ${(p) => p.theme.colors.neutral.n0};
	}
`;
