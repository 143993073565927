import styled from 'styled-components';
import { colors } from '@compstak/ui-kit';
import IconMap from 'ui/svg_icons/map.svg';

type Props = {
	message?: React.ReactNode;
	className?: string;
};
export const MapNotAvailable = ({ message, className }: Props) => {
	return (
		<Container className={className}>
			<IconContainer>
				<IconMap />
			</IconContainer>
			<MessageHeader>Map not available</MessageHeader>
			{message && <Message>{message}</Message>}
		</Container>
	);
};

const Container = styled.div`
	width: 100%;
	height: 100%;
	padding: 1em;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	gap: 1em;
	color: ${colors.neutral.n400};
	border: 1px solid ${colors.neutral.n40};
	background-color: ${colors.white.white};
`;

const MessageHeader = styled.div`
	font-size: 1.15em;
	font-weight: 500;
`;
const Message = styled.div`
	font-size: 0.875em;
`;

const IconContainer = styled.div`
	svg {
		width: 3em;
		height: 3em;
		path {
			fill: ${colors.gray.n100};
		}
	}
`;
