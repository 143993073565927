import { Button } from '@compstak/ui-kit';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { exportPortfolio } from 'PortfolioAnalytics/Export/exportPortfolio';
import { PageTitleRowIconButton } from 'PortfolioAnalytics/styles/PortfolioUI';

import { useState } from 'react';
import styled from 'styled-components';

import IconExportBlue from 'ui/svg_icons/export_blue.svg';
import { useBenchmarkTableConfig } from './BenchmarkingTable/useBenchmarkTableConfig';

type Props = {
	elementToExport: HTMLElement | null;
};

export const BenchmarkingDropdown = ({ elementToExport }: Props) => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const { handleExportCSV, isFetchingPortfolios } = useBenchmarkTableConfig();

	return (
		<DropdownMenu.Root open={isMenuOpen} onOpenChange={setIsMenuOpen}>
			<DropdownMenu.Trigger asChild>
				<PageTitleRowIconButton variant="ghost" icon={<IconExportBlue />} />
			</DropdownMenu.Trigger>
			<DropdownMenu.Portal>
				<DropdownContent>
					<span>Select Export Option</span>
					<DropdownMenu.Item asChild>
						<Button
							variant="secondary"
							onClick={() =>
								exportPortfolio({
									filename: 'Portfolio Comparison',
									element: elementToExport,
								})
							}
						>
							Save Image
						</Button>
					</DropdownMenu.Item>
					<DropdownMenu.Item asChild>
						<Button
							isLoading={isFetchingPortfolios}
							variant="secondary"
							onClick={handleExportCSV}
						>
							Copy to Excel
						</Button>
					</DropdownMenu.Item>
				</DropdownContent>
			</DropdownMenu.Portal>
		</DropdownMenu.Root>
	);
};

const DropdownContent = styled(DropdownMenu.Content)`
	background-color: ${(p) => p.theme.colors.gray.gray700};
	color: ${(p) => p.theme.colors.white.white};

	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 0.5rem;
	padding: 0.5rem;

	margin-right: 1rem;
`;
