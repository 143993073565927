import React from 'react';

import {
	CompFilterCategory,
	FilterCategory,
	LocationFilterCategory,
} from './FilterCategories';
import { BuildingFilterCategoryCommonFilters } from './FilterCategories/BuildingFilterCategoryCommonFilters';
import { useFilterFieldContext } from 'Components/Filters/Fields/FilterFieldContextProvider';
import { FilterSpacer } from 'Components/Filters/Base/Filter/FilterSpacer';
import { BuyerFilter } from 'Components/Filters/Fields/BuyerFilter/BuyerFilter';
import { SellerFilter } from 'Components/Filters/Fields/SellerFilter/SellerFilter';
import { CommonlyUsedSaleFilters } from './FilterCategories/CommonlyUsedSaleFilters';
import { TransactionSizeFilter } from 'Components/Filters/Fields/TransactionSizeFilter/TransactionSizeFilter';
import { YearRenovatedFilter } from 'Components/Filters/Fields/YearRenovatedFilter/YearRenovatedFilter';
import { MarketStartingRentFilter } from 'Components/Filters/Fields/MarketStartingRentFilter/MarketStartingRentFilter';
import { AverageTransactionSizeFilter } from 'Components/Filters/Fields/AverageTransactionSizeFilter/AverageTransactionSizeFilter';
import { PortfolioFilter } from 'Components/Filters/Fields/PortfolioFilter/PortfolioFilter';
import { SalePricePsfFilter } from 'Components/Filters/Fields/SalePricePsfFilter/SalePricePsfFilter';
import { SalePriceFilter } from 'Components/Filters/Fields/SalePriceFilter/SalePriceFilter';
import { SaleDateFilter } from 'Components/Filters/Fields/SaleDateFilter/SaleDateFilter';
import { InterestTypeFilter } from 'Components/Filters/Fields/InterestTypeFilter/InterestTypeFilter';
import { PropertyMarketEffectiveRentFilter } from 'Components/Filters/Fields/PropertyMarketEffectiveRentFilter/PropertyMarketEffectiveRentFilter';
import { DataSourceFilter } from 'Components/Filters/Fields/DataSourceFilter/DataSourceFilter';
import { withErrorBoundaryDefault } from 'Components/ErrorBoundary';
import { PricePerAcreFilter } from 'Components/Filters/Fields/PricePerAcreFilter/PricePerAcreFilter';
import { PricePerUnitFilter } from 'Components/Filters/Fields/PricePerUnitFilter/PricePerUnitFilter';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

export const SaleFilterFields = () => {
	const { context } = useFilterFieldContext();

	return (
		<>
			{context !== 'analytics' && <CommonlyUsedSaleFilters />}

			<LocationFilterCategory />
			<PropertyFiltersCategory />
			<TransactionFiltersCategory />
			<BuyerSellerFiltersCategory />
			<CompFilterCategory />
		</>
	);
};

const PropertyFiltersCategory = withErrorBoundaryDefault(
	function PropertyFiltersCategoryUnsafe() {
		return (
			<FilterCategory category="Property">
				<BuildingFilterCategoryCommonFilters />

				<YearRenovatedFilter />

				<FilterSpacer />

				<MarketStartingRentFilter />
				<PropertyMarketEffectiveRentFilter />
				<AverageTransactionSizeFilter />
			</FilterCategory>
		);
	}
);

const TransactionFiltersCategory = withErrorBoundaryDefault(
	function TransactionFiltersCategoryUnsafe() {
		const { ffAppsTotalUnitsAcresBuildingsPricePerAcre } = useFeatureFlags();
		return (
			<FilterCategory category="Transaction">
				<PortfolioFilter />
				<SalePriceFilter />
				<SalePricePsfFilter />
				{ffAppsTotalUnitsAcresBuildingsPricePerAcre && (
					<>
						<PricePerUnitFilter />
						<PricePerAcreFilter />
					</>
				)}
				<SaleDateFilter />
				<TransactionSizeFilter />
				<InterestTypeFilter />
				<DataSourceFilter />
			</FilterCategory>
		);
	}
);

const BuyerSellerFiltersCategory = withErrorBoundaryDefault(
	function BuyerSellerFiltersCategoryUnsafe() {
		return (
			<FilterCategory category="Buyer & Seller">
				<BuyerFilter />
				<SellerFilter />
			</FilterCategory>
		);
	}
);
