import {
	VictoryPie,
	VictoryTooltip,
	VictoryContainer,
	VictoryPieProps,
} from 'victory';
import {
	Legend,
	LegendColor,
	LegendItem,
	LegendLabel,
	ChartAndLegendContainer,
} from 'PortfolioAnalytics/styles/PortfolioUI';
import {
	CHART_HEIGHT,
	VICTORY_CONTAINER_STYLE_400,
	VICTORY_TOOLTIP_STYLE,
} from './chartsConstants';
import { PORTFOLIO_CHART_COLORS } from '../constants';
import { useTheme } from 'styled-components';

export const PortfolioPieChart = ({
	data,
	events,
	dataType = 'lease',
}: {
	data: VictoryPieProps['data'];
	events: VictoryPieProps['events'];
	dataType?: 'property' | 'lease';
}) => {
	const theme = useTheme();
	if (!data) return null;

	const dataTypeLabel = dataType === 'property' ? 'properties' : 'leases';

	return (
		<ChartAndLegendContainer>
			<VictoryPie
				labelComponent={
					<VictoryTooltip
						{...VICTORY_TOOLTIP_STYLE}
						text={(d) => {
							const name =
								d?.datum?.xName && d?.datum?.xName.length > 20
									? d?.datum?.xName.substring(0, 20) + '...'
									: d?.datum?.xName;
							return `(${d?.datum?.label}) \n Click to view \n ${dataTypeLabel} for \n ${name}`;
						}}
						centerOffset={{
							x: (d) => {
								const { endAngle, startAngle } = d?.datum;
								const pieRight = endAngle && endAngle < 180;
								const pieLeft = endAngle && endAngle > 180;
								const largerThanHalf = endAngle - startAngle > 180;
								return largerThanHalf ? 0 : pieRight ? -75 : pieLeft ? 75 : 0;
							},
							y: -20,
						}}
					/>
				}
				containerComponent={
					<VictoryContainer
						responsive={true}
						style={VICTORY_CONTAINER_STYLE_400}
					/>
				}
				colorScale={PORTFOLIO_CHART_COLORS}
				data={data}
				height={CHART_HEIGHT}
				style={{
					parent: {
						height: CHART_HEIGHT,
					},
					data: {
						cursor: 'pointer',
						stroke: theme.colors.white.white,
						strokeWidth: 2,
					},
				}}
				animate={{
					duration: 500,
				}}
				events={events}
			/>
			<Legend>
				{data.map((dataPoint, i) =>
					dataPoint.x && dataPoint.label && dataPoint.y ? (
						<LegendItem key={i}>
							<LegendColor color={PORTFOLIO_CHART_COLORS[i]} />
							<LegendLabel>
								{dataPoint.x} - {dataPoint.label}
							</LegendLabel>
						</LegendItem>
					) : null
				)}
			</Legend>{' '}
		</ChartAndLegendContainer>
	);
};
