import { GoogleMapSwitcher } from '@compstak/maps';
import { MQB } from '@compstak/ui-kit';
import { useMediaQuery } from 'react-responsive';
import { GeoPoint } from 'types';

export function ResponsiveMapSwitcher({ geoPoint }: { geoPoint: GeoPoint }) {
	const mapIsSmall = useMediaQuery({ maxWidth: MQB.D_1280 });
	return (
		<GoogleMapSwitcher
			lat={geoPoint.lat}
			lon={geoPoint.lon}
			zoom={16}
			width={mapIsSmall ? 342 : 456}
			height={mapIsSmall ? 240 : 320}
			googleStreetViewId={'BuildingClassSurveyStreetViewId'}
		/>
	);
}
