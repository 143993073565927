import { SearchTable } from 'Components';
import { routes } from 'router';
import { ExportCompsButton } from 'Components/Export';
import { usePortfolioLeases } from 'PortfolioAnalytics/LeaseActivity/PortfolioLeasesProvider';

export const PortfolioTableLeases = () => {
	const {
		data: rows,
		isFetching,
		hasNextPage,
		fetchNextPage,
		selectionState: {
			areSomeSelected,
			getAreAllSelected,
			getIsRowSelected,
			toggleAllSelected,
			toggleRow,
		},
	} = usePortfolioLeases();

	return (
		<SearchTable
			rows={rows}
			getRowId={(row) => row.id}
			noData={!isFetching && rows.length === 0}
			columnPreferencesType="lease"
			getIsRowSelected={(row) => getIsRowSelected(row.id)}
			areAllSelected={getAreAllSelected()}
			areSomeSelected={areSomeSelected}
			toggleRowSelection={(row) => toggleRow(row.id)}
			toggleAllRowsSelection={toggleAllSelected}
			onLoadMore={() => {
				if (hasNextPage && !isFetching) {
					fetchNextPage();
				}
			}}
			renderActionButton={(row) => (
				<ExportCompsButton
					compType="lease"
					compIds={[row.id]}
					buttonType="wide-row"
				/>
			)}
			getBodyCellProps={(row) => ({
				to: routes.leaseById.toHref({ id: row.id }),
			})}
		/>
	);
};
