import { MARKER_COLOR_MAP } from '@compstak/maps';
import { pick } from 'lodash';

export const BENCHMARKING_PORTFOLIOS_LIMIT = 5;
export const BENCHMARKING_COLOR_MAP = pick(
	MARKER_COLOR_MAP,
	'blue',
	'green',
	'purple',
	'grayTeal',
	'raspberry'
);
export const BENCHMARKING_COLORS = Object.values(BENCHMARKING_COLOR_MAP);
export const BENCHMARKING_SELECT_PORTFOLIOS_MESSAGE = `Select up to ${BENCHMARKING_PORTFOLIOS_LIMIT} portfolios from the left`;
