import styled from 'styled-components';
import IconAlertInCircle from 'ui/svg_icons/alert_in_circle.svg';
import { colors } from '@compstak/ui-kit';

const { gray700, n100 } = colors.gray;

export const ChartError = () => {
	return (
		<Root>
			<IconAlertInCircle />
			<Title>Unable to load chart</Title>
			<Message>An error occurred while loading the chart.</Message>
			<Message>Please refresh the page to try again.</Message>
		</Root>
	);
};

const Root = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 0.5rem;
	padding: 5rem 0;
	font-family: ${(p) => p.theme.typography.fontFamily.openSans};
`;

const Title = styled.div`
	font-size: 1.25em;
	color: ${gray700};
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
`;

const Message = styled.div`
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	text-align: center;
	color: ${n100};
`;
