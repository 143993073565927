import { DATE_FORMATS } from 'constants/dateFormats';
import dayjs from 'dayjs';
import {
	TrendData,
	TrendFilters,
	TrendMetricFilter,
	Bubbles,
} from 'PortfolioAnalytics/portfolioAnalyticsTypes';
import { InsightDataPoint } from 'Pages/Analytics/analytics';
import { FiltersState } from '../../PortfolioFiltersProvider';
import { InsightsParams, PortfolioV2 } from 'api';
import { FULL_DATA_THRESHOLD } from 'Components/Graphs/constants';
import { filtersToServerJSON } from 'models/filters/util';
import { getFiltersFromPortfolioFilters } from 'PortfolioAnalytics/utils/getFiltersFromPortfolioFilters';

type PortfolioTrendFetchProps = {
	portfolio: PortfolioV2;
	trendFilters: TrendFilters;
	filters: FiltersState;
};

export const getDateForTimeSpan = (years: number) =>
	dayjs().subtract(years, 'year').format(DATE_FORMATS['YYYY-MM-DD']);

export const getTrendQueryParams = ({
	portfolio,
	trendFilters,
	filters,
}: PortfolioTrendFetchProps): {
	portfolioQueryParams: InsightsParams;
} => {
	const portfolioQueryFilter: InsightsParams['filter'] = [
		...filtersToServerJSON(
			getFiltersFromPortfolioFilters({ ...filters, portfolio })
		),
		{ property: 'outlier', value: false },
	];

	return {
		portfolioQueryParams: {
			properties: [
				trendFilters.metric,
				'executionDate',
				'transactionSize',
				'id',
			],
			fullDataThreshold: FULL_DATA_THRESHOLD,
			quartileThreshold: 10e9,
			trendMonths: trendFilters.movAverage,
			filter: portfolioQueryFilter,
		},
	};
};

export const formatDate = (
	date: Date,
	shouldFormatDateAsYear: boolean,
	shortenYear?: boolean
) =>
	dayjs(date).format(
		shouldFormatDateAsYear ? (shortenYear ? "'YY" : 'YYYY') : "MMM 'YY"
	);

export const getTrendlineData = (arr: TrendData[], timeSpanDate: string) =>
	arr
		.filter(({ date }) => date >= timeSpanDate)
		.map(({ date, value }) => ({ x: new Date(date), y: value }));

export const getScatterData = (
	arr: InsightDataPoint[],
	metricFilter: TrendMetricFilter
) => {
	const metricToUse = metricFilter === 'ti' ? 'workValue' : metricFilter;
	const bubbles: Bubbles[] = [];
	arr.map((point) => {
		if (!point[metricToUse]) return;
		const newPoint = {
			x: new Date(point.executionDate),
			y: point[metricToUse] || 0,
			z: point.transactionSize,
		};
		bubbles.push(newPoint);
	});
	return bubbles;
};
