import { useQuery, useQueryClient } from '@tanstack/react-query';
import { SortField } from 'PortfolioAnalytics/PortfolioSidebar/types';
import { SortDirection } from 'api';
import { useCallback } from 'react';

const SORT_STATE = 'SORT_STATE';
const SORT_DIRECTION = 'SORT_DIRECTION';

export const usePortfolioSortControl = () => {
	const queryClient = useQueryClient();

	const { data: sortField = 'dateCreated' } = useQuery<SortField>({
		queryKey: [SORT_STATE],
		queryFn: () => 'dateCreated',
		staleTime: Infinity,
	});

	const setSortField = useCallback(
		(field: SortField) => queryClient.setQueryData([SORT_STATE], field),
		[queryClient]
	);

	const { data: sortDirection = 'desc' } = useQuery<SortDirection>({
		queryKey: [SORT_DIRECTION],
		queryFn: () => 'desc',
		staleTime: Infinity,
	});

	const setSortDirection = (direction: SortDirection) => {
		queryClient.setQueryData([SORT_DIRECTION], direction);
	};

	return {
		sortField,
		setSortField,
		sortDirection,
		setSortDirection,
	};
};
