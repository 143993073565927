import { PortfolioV2 } from 'api';
import styled from 'styled-components';
import { BENCHMARKING_COLORS } from 'PortfolioAnalytics/Benchmarking/constants';
import { PortfolioColorCircle } from 'PortfolioAnalytics/UI';
import { MapLegendContainer } from 'maps';
import { TooltipV2 } from '@compstak/ui-kit';

type Props = {
	portfolios: PortfolioV2[];
};

export const PortfoliosMapLegend = ({ portfolios }: Props) => {
	return (
		<PortfoliosMapLegendContainer>
			{portfolios.map((portfolio, index) => (
				<PortfolioMapLegendItem
					key={portfolio.portfolio.id}
					portfolio={portfolio}
					index={index}
				/>
			))}
		</PortfoliosMapLegendContainer>
	);
};

type PortfolioMapLegendProps = {
	portfolio: PortfolioV2;
	index: number;
};

const PortfolioMapLegendItem = ({
	portfolio,
	index,
}: PortfolioMapLegendProps) => {
	return (
		<MapLegendItem>
			<PortfolioColorCircle
				color={BENCHMARKING_COLORS[index]}
				diameter="0.75rem"
			/>
			<TooltipV2 content={portfolio.portfolio.name}>
				<span>{portfolio.portfolio.name}</span>
			</TooltipV2>
		</MapLegendItem>
	);
};

const PortfoliosMapLegendContainer = styled(MapLegendContainer)`
	display: flex;
	flex-direction: column;
`;

const MapLegendItem = styled.span`
	padding: 0.5em;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;
