import { BetaChip } from 'Components/UI';
import dayjs from 'dayjs';
import { useIsExchange } from 'hooks';
import { FiltersObject } from 'models/filters/types';
import { useMemo } from 'react';
import styled from 'styled-components';
import IconAmazonLogo from 'ui/svg_icons/amazon_logo.svg';
import IconFloatingRateLoans from 'ui/svg_icons/floating_rate_loans.svg';
import IconLifeScienceLab from 'ui/svg_icons/life_sceince__lab.svg';
import IconMedical from 'ui/svg_icons/medical.svg';
import IconOffice from 'ui/svg_icons/office.svg';
import IconRefrigeratedColdStorage from 'ui/svg_icons/refrigerated__cold_storage.svg';
import IconSalesDollarSign from 'ui/svg_icons/sales_dollar_sign.svg';
import IconWarehouseDistribution from 'ui/svg_icons/warehouse__distribution.svg';
import { RecommendedSearch } from './useRecommendedSearches';

const activeLeasesFilterValue: FiltersObject['expirationDate'] = {
	min: new Date(),
	max: null,
};

export const useLeaseRecommendedSearches = (): RecommendedSearch[] => {
	const isExchange = useIsExchange();

	return useMemo(() => {
		const reconmmendedSearches: RecommendedSearch[] = [
			{
				title: 'Cold Storage Leases',
				filtersText: 'National • Active Leases',
				filters: {
					spaceSubtypeId: [12],
					expirationDate: activeLeasesFilterValue,
				},
				icon: IconRefrigeratedColdStorage,
				compType: 'lease',
			},
			{
				title: 'Expiring Office Leases',
				tooltip: 'Expiring Office Leases',
				filtersText: 'National • Class A • Expiring within 2 years',
				filters: {
					buildingClassId: [1],
					spaceTypeId: [1],
					expirationDate: {
						min: new Date(),
						max: dayjs().add(2, 'year').toDate(),
					},
				},
				icon: IconOffice,
				compType: 'lease',
			},
			{
				title: 'New Warehouse / Distribution',
				tooltip: 'New Warehouse / Distribution',
				filtersText: 'National • Class A • Built within 5 years',
				filters: {
					spaceSubtypeId: [1, 2],
					buildingClassId: [1],
					expirationDate: activeLeasesFilterValue,
					buildingYearBuilt: {
						min: dayjs().subtract(5, 'year').get('year'),
						max: null,
					},
				},
				icon: IconWarehouseDistribution,
				compType: 'lease',
			},
			{
				title: 'Life Science Leases',
				tooltip: 'Life Science Leases',
				filtersText: 'National • Active Leases',
				filters: {
					spaceSubtypeId: [8],
					expirationDate: activeLeasesFilterValue,
				},
				icon: IconLifeScienceLab,
				compType: 'lease',
			},
			{
				title: 'Recent Medical Office Leases',
				tooltip: 'Recent Medical Office Leases',
				filtersText: 'National • Executed within past 2 years',
				filters: {
					spaceSubtypeId: [10],
					spaceTypeId: [1],
					executionDate: {
						min: dayjs().subtract(2, 'year').toDate(),
						max: null,
					},
				},
				icon: IconMedical,
				compType: 'lease',
			},
			{
				title: (
					<LoansTitle>
						<div>Maturing Floating Rate Loans</div>
						{isExchange && <BetaChip>Beta</BetaChip>}
					</LoansTitle>
				),
				tooltip: 'Maturing Floating Rate Loans',
				filtersText:
					'National  • Maturing within 1 year • Loan over $10mm • over 100K SqFt',
				filters: {
					buildingSize: {
						min: 100000,
						max: null,
					},
					loanAmount: {
						min: 10000000,
						max: null,
					},
					loanInterestType: 'Floating',
					loanMaturityDate: {
						min: new Date(),
						max: dayjs().add(1, 'year').toDate(),
					},
				},
				icon: IconFloatingRateLoans,
				compType: 'property',
			},
			{
				title: 'Recent Sales Over $20mm',
				tooltip: 'Recent Sales Over $20mm',
				filtersText: 'National • Over $20mm • Transaction date within 1 year',
				filters: {
					totalSalePrice: {
						min: 20000000,
						max: null,
					},
					saleDate: {
						min: dayjs().subtract(1, 'year').toDate(),
						max: null,
					},
				},
				icon: IconSalesDollarSign,
				compType: 'sale',
			},
			{
				title: 'Amazon Leases',
				tooltip: 'Amazon Leases',
				filtersText: 'National • Active Leases',
				filters: {
					tenantNames: ['Amazon.com'],
					expirationDate: activeLeasesFilterValue,
				},
				icon: IconAmazonLogo,
				compType: 'lease',
			},
		];

		return reconmmendedSearches;
	}, [isExchange]);
};

const LoansTitle = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;
	flex-wrap: wrap;
`;
