import { typography, MQB } from '@compstak/ui-kit';
import styled from 'styled-components';

export const Page = styled.div`
	position: absolute;
	padding: 0 80px 80px;
	inset: 0;
	background-color: white;
	font-family: ${typography.fontFamily.gotham};
	overflow-y: auto;

	@media (max-width: ${MQB.D_1536}) {
		padding: 0 1.5rem 80px;
	}
`;
Page.displayName = 'Page';

export const Content = styled.div`
	max-width: 1680px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;

	@media (max-width: ${MQB.D_1536}) {
		max-width: 1010px;
	}
`;

export const GreenHeader = styled.h2`
	color: ${(props) => props.theme.colors.green.green500};
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	width: fit-content;
	margin: 64px auto 48px;
`;
GreenHeader.displayName = 'GreenHeader';

export const Footer = styled.footer`
	display: flex;
	flex-direction: column;
	gap: 8px;
	align-items: center;
	margin-top: 80px;
	color: ${(props) => props.theme.colors.gray.gray500};
`;

export const Bi = styled.b`
	/* Bold & Italic */
	font-style: italic;
`;

export const CreditsContent = styled.div`
	display: flex;
	align-items: flex-start;
	gap: 1.5rem;
	width: 100%;

	@media (max-width: ${MQB.D_1536}) {
		flex-wrap: wrap;
	}
`;

export const CreditsWrapper = styled.section`
	width: 100%;
	margin-bottom: 3rem;
`;

export const CreditsAwardsCards = styled.div`
	flex-shrink: 0;

	@media (max-width: ${MQB.D_1536}) {
		flex-shrink: 1;
	}
`;
