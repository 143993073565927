import { TooltipV2 } from '@compstak/ui-kit';
import { CSSProperties } from 'react';
import { Link } from 'router';
import styled from 'styled-components';
import { useColumnsContext } from './Columns';
import { useHoverContext } from './HoverProvider';
import { useOptionsContext } from './OptionsProvider';
import { baseCellCss, cellBorders, LoadingIndicator, TextOverflow } from './UI';
import { getDefaultCellBackgroundColor, isLoadingRow } from './utils';

type Props = {
	rowIndex: number;
	columnIndex: number;
	style: CSSProperties;
};

export const BodyCell = ({ rowIndex, columnIndex, style }: Props) => {
	const { getIsRowSelected, getBodyCellProps, rows, getRowId, getCellBgColor } =
		useOptionsContext();
	const [hoveredIndex, setHoveredIndex] = useHoverContext();
	const { columns } = useColumnsContext();

	const row = rows[rowIndex];
	const column = columns[columnIndex];

	const isLoading = isLoadingRow(row);
	const isSelected = getIsRowSelected(row, rowIndex);
	const isHovered = hoveredIndex === rowIndex;

	const content = isLoading ? (
		<LoadingIndicator />
	) : (
		<TextOverflow>
			{column.body?.({
				row,
			})}
		</TextOverflow>
	);

	const props = getBodyCellProps?.(row, rowIndex);

	const getBgColorFn = getCellBgColor
		? getCellBgColor(row, rowIndex)
		: getDefaultCellBackgroundColor;

	return (
		<TooltipV2
			delayDuration={500}
			content={
				column.cellTooltip?.({ row, value: content, config: column }) ??
				'Click to view details'
			}
		>
			<StyledCell
				{...props}
				className="body-cell"
				data-rowindex={rowIndex}
				data-colindex={columnIndex}
				data-colid={column.id}
				data-comp-id={getRowId(row)}
				style={{
					...props?.style,
					...style,
					backgroundColor: getBgColorFn(isLoading, isHovered, isSelected),
					pointerEvents: isLoading ? 'none' : undefined,
				}}
				onMouseEnter={() => {
					setHoveredIndex(rowIndex);
				}}
				onMouseLeave={() => {
					setHoveredIndex(null);
				}}
				to={props?.to ?? ''}
				state={props?.state}
			>
				{content}
			</StyledCell>
		</TooltipV2>
	);
};

const StyledCell = styled(Link)`
	${baseCellCss}
	${cellBorders}
	cursor: pointer;
`;
