import {
	COMP_WITH_LIMITED_DETAILS_ROW_BG,
	HOVERED_COMP_WITH_LIMITED_DETAILS_ROW_BG,
	HOVERED_ROW_BG,
	LOADING_ROW_TYPE,
	SELECTED_COMP_WITH_LIMITED_DETAILS_ROW_BG,
	SELECTED_ROW_BG,
} from './constants';
import { LoadingRow } from './types';

const isObject = (v: unknown): v is Record<string, unknown> =>
	v != null && typeof v === 'object';

export const isLoadingRow = (row: unknown): row is LoadingRow =>
	isObject(row) && row.type === LOADING_ROW_TYPE;

export const getDefaultCellBackgroundColor = (
	isLoading: boolean,
	isHovered: boolean,
	isSelected: boolean
) => {
	if (isLoading) return undefined;
	if (isHovered) return HOVERED_ROW_BG;
	if (isSelected) return SELECTED_ROW_BG;
};

export const getCompWithLimitedDetailsCellBgColor = (
	isLoading: boolean,
	isHovered: boolean,
	isSelected: boolean
) => {
	if (isLoading) return undefined;
	if (isHovered) return HOVERED_COMP_WITH_LIMITED_DETAILS_ROW_BG;
	if (isSelected) return SELECTED_COMP_WITH_LIMITED_DETAILS_ROW_BG;
	return COMP_WITH_LIMITED_DETAILS_ROW_BG;
};
