import { LeaseComp } from 'types';

export function isEstimated(lease: LeaseComp, name: keyof LeaseComp) {
	return !!(
		(name === 'executionDate' && lease['estimatedExecutionDate']) ||
		(name === 'commencementDate' && lease['estimatedCommencementDate']) ||
		(name === 'expirationDate' && lease['estimatedExpirationDate']) ||
		(name === 'leaseTerm' && lease['estimatedLeaseTerm'])
	);
}
