import { useEditableCompstakEstimates } from './useEditableCompstakEstimates';
import { HideableLabel } from '../HideableLabel';
import { OverviewKeyValueTableInput, OverviewKeyValueTableLabel } from './UI';
import { CompstakEstimate } from 'Pages/PropertyPageV3/hooks/useCompstakEstimatesData';
import { compstakEstimatesLabels } from 'Pages/PropertyPageV3/CompstakEstimates';
import {
	AttributesTableField,
	AttributesTableHeader,
	AttributesTableSectionsContainer,
} from 'Pages/PropertyPageV3/LoansTab/UI';

type Props = ReturnType<typeof useEditableCompstakEstimates>;

export const EditableCompstakEstimates = ({
	editableCompstakEstimates,
	setEditableCompstakEstimates,
}: Props) => {
	if (!editableCompstakEstimates.showCompstakEstimates) return null;

	return (
		<div>
			<AttributesTableHeader>CompStak Rent Estimates</AttributesTableHeader>
			<AttributesTableSectionsContainer>
				{compstakEstimatesAttributes.map((attribute) => {
					const isVisible = editableCompstakEstimates.visibleFields[attribute];
					return (
						<AttributesTableField key={attribute}>
							<HideableLabel
								isVisible={isVisible}
								onToggle={() => {
									setEditableCompstakEstimates((s) => ({
										...s,
										visibleFields: {
											...s.visibleFields,
											[attribute]: !s.visibleFields[attribute],
										},
									}));
								}}
							>
								<OverviewKeyValueTableLabel>
									{`${compstakEstimatesLabels[attribute]}`}
								</OverviewKeyValueTableLabel>
							</HideableLabel>
							<OverviewKeyValueTableInput
								isVisible={isVisible}
								value={editableCompstakEstimates[attribute]}
								onChange={(e) => {
									setEditableCompstakEstimates((s) => ({
										...s,
										[attribute]: e.target.value,
									}));
								}}
							/>
						</AttributesTableField>
					);
				})}
			</AttributesTableSectionsContainer>
		</div>
	);
};

export const compstakEstimatesAttributes: CompstakEstimate[] = [
	'startingRent',
	'effectiveRent',
];
