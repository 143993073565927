import { Button } from '@compstak/ui-kit';
import { Link } from 'router';
import styled, { css } from 'styled-components';
import ArrowRightIcon from 'ui/svg_icons/arrow_right.svg';

const compPopupActionCSS = css`
	font-size: 12px;
	letter-spacing: 1.25px;
	padding: 12px 15px;
	text-align: left;
	font-family: ${(p) => p.theme.typography.fontFamily.gotham};
	font-weight: 300;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	text-transform: uppercase;
	border: 0;
	color: #ffffff;
	margin: 8px 0;
	background-color: #67708e;
	transition: background-color 0.2s ease-out;
	cursor: pointer;
	&.blue {
		background-color: ${(p) => p.theme.colors.blue.blue400};
		&:hover {
			background-color: #67aadd;
		}
	}
	&.single-child {
		justify-content: center;
	}
	&:hover {
		background-color: ${(p) => p.theme.colors.blue.blue400};
		color: ${(p) => p.theme.colors.white.white};
	}
`;

export const CompPopupActionLink = styled(Link)`
	${compPopupActionCSS}
`;

export const StyledArrowRightIcon = styled(ArrowRightIcon)`
	width: 9px;
	height: 15px;
	fill: ${({ theme }) => theme.colors.white.white};
`;

export const PopupHeader = styled.div`
	background-color: #222530;
	padding: 15px;
	padding-top: 10px;
	display: flex;
	align-items: flex-start;
`;

export const CompPopupRoot = styled.div<{ width?: string }>`
	width: ${({ width }) => width ?? '300px'};
`;

export const CompPopupRootLoading = styled.div`
	width: fit-content;
	padding: 8px;
`;

export const CompPopupActions = styled.div`
	padding: 0 8px;
`;

export const CompPopupActionsV2 = styled(CompPopupActions)`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	margin-bottom: 0.5rem;

	button,
	a {
		width: 100%;
	}

	a:hover {
		color: unset;
	}
`;

export const CompPopupSubtitle = styled.div<{ isDarkGrey?: boolean }>`
	color: #c4c4c4;
	font-size: 14px;
	font-weight: 400;
	font-family: ${(p) => p.theme.typography.fontFamily.gotham};
	line-height: 1.6;
	word-break: break-word;
	${(p) =>
		p.isDarkGrey &&
		css`
			font-size: 12px;
			color: #777777;
		`}
`;

export const PopupActionButton = styled(Button)`
	justify-content: space-between;
`;
