import { QueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from 'api/constants';
import factory from './factory';

export const exportComps = ({
	compIds,
	monthly,
	useMultifamily,
	queryClient,
}: {
	compIds: number[];
	monthly: boolean;
	useMultifamily: boolean;
	queryClient: QueryClient;
}) => {
	return factory
		.post('/api/properties/export', { compIds, monthly: monthly })
		.then(function (data) {
			queryClient.invalidateQueries([
				QUERY_KEYS.CHECK_PROPERTIES_EXPORT_STATUS,
			]);

			// @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
			if (data.exportId) {
				window.location.href =
					'/api' +
					(useMultifamily ? '/mufa' : '') +
					'/properties/' +
					// @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
					data.exportId +
					'/actions/export?canIncludeMarket=true';
			}
			// @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
			data.exportType = 'xls';
			return data;
		});
};
