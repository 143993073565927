import { reset } from 'actions/filters';
import { focusTypeahead } from 'actions/search';
import { deselectAll } from 'actions/selection';
import { PropertyByMarketIdAndBuildingAddressAndCityParams } from 'api';
import { FiltersTypesKeys } from 'models/filters/types';
import { clearCurrentSavedSearch } from 'Pages/SavedSearches/actions';
import propertyService from 'services/property';
import { AppDispatch } from 'store';
import { ReduxPromiseAction } from 'types/redux-promise-middleware';
import { PropertyPopup } from './searchMapReducer';

export const FOCUS_PROPERTY = 'FOCUS_PROPERTY' as const;
export const REMOVE_PROPERTY = 'REMOVE_PROPERTY' as const;
export const FOCUS_FILTER_SECTION = 'FOCUS_FILTER_SECTION' as const;

export const PINS_LIMIT = 200000;

export function focusProperty(
	propertyIdOrObject:
		| number
		| PropertyByMarketIdAndBuildingAddressAndCityParams,
	propertyPopup?: PropertyPopup
) {
	return {
		type: FOCUS_PROPERTY,
		meta: {
			propertyPopup,
		},
		payload: {
			promise: propertyService.load(propertyIdOrObject),
		},
	};
}

export function removeProperty() {
	return {
		type: REMOVE_PROPERTY,
	};
}

export function focusFilterSection(filterSectionId: FiltersTypesKeys | null) {
	return {
		type: FOCUS_FILTER_SECTION,
		filterSectionId,
	};
}

type ResetMapArgs = {
	resetCurrentSavedSearch?: boolean;
	resetFilters?: boolean;
	resetSelection?: boolean;
};

export const resetSearch =
	({
		resetCurrentSavedSearch = true,
		resetFilters = false,
		resetSelection = true,
	}: ResetMapArgs) =>
	(dispatch: AppDispatch) => {
		dispatch(focusTypeahead());
		dispatch(removeProperty());
		resetFilters && dispatch(reset('main'));
		resetCurrentSavedSearch && dispatch(clearCurrentSavedSearch());
		resetSelection && dispatch(deselectAll());
	};

export type MapSearchAction =
	| ReduxPromiseAction<ReturnType<typeof focusProperty>>
	| ReturnType<typeof removeProperty>
	| ReturnType<typeof focusFilterSection>;
