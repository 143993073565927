import React from 'react';
import { FilterCategory, LocationFilterCategory } from './FilterCategories';
import { useFilterFieldContext } from 'Components/Filters/Fields/FilterFieldContextProvider';
import { useIsExchange, useIsMultifamily } from 'hooks';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { FilterSpacer } from 'Components/Filters/Base/Filter/FilterSpacer';
import { BuildingFilterCategoryCommonFilters } from './FilterCategories/BuildingFilterCategoryCommonFilters';
import { useIsShowTreppUI } from 'utils';
import { CommonlyUsedPropertyFilters } from './FilterCategories/CommonlyUsedPropertyFilters';
import { YearRenovatedFilter } from 'Components/Filters/Fields/YearRenovatedFilter/YearRenovatedFilter';
import { LoanSourceFilter } from 'Components/Filters/Fields/LoanFilters/LoanSourceFilter/LoanSourceFilter';
import { LenderFilter } from 'Components/Filters/Fields/LoanFilters/LenderFilter/LenderFilter';
import { LoanAmountFilter } from 'Components/Filters/Fields/LoanFilters/LoanAmountFilter/LoanAmountFilter';
import { LoanInterestTypeFilter } from 'Components/Filters/Fields/LoanFilters/LoanInterestTypeFilter/LoanInterestTypeFilter';
import { LandlordFilter } from 'Components/Filters/Fields/LandlordFilter/LandlordFilter';
import { CurrentLoanRateFilter } from 'Components/Filters/Fields/LoanFilters/CurrentLoanRateFilter/CurrentLoanRateFilter';
import { LoanOriginationDateFilter } from 'Components/Filters/Fields/LoanFilters/LoanOriginationDateFilter/LoanOriginationDateFilter';
import { LoanMaturityDateFilter } from 'Components/Filters/Fields/LoanFilters/LoanMaturityDateFilter/LoanMaturityDateFilter';
import { LoanToValueFilter } from 'Components/Filters/Fields/LoanFilters/LoanToValueFilter/LoanToValueFilter';
import { RealPageFilterInfo } from './RealPageFilterInfo';
import { LastSalePriceFilter } from 'Components/Filters/Fields/LastSalePriceFilter/LastSalePriceFilter';
import { MarketStartingRentFilter } from 'Components/Filters/Fields/MarketStartingRentFilter/MarketStartingRentFilter';
import { BuildingSqFtExpiringInTwelveMonthsFilter } from 'Components/Filters/Fields/BuildingSqFtExpiringInTwelveMonthsFilter/BuildingSqFtExpiringInTwelveMonthsFilter';
import { AverageTransactionSizeFilter } from 'Components/Filters/Fields/AverageTransactionSizeFilter/AverageTransactionSizeFilter';
import { PropertyStatusFilter } from 'Components/Filters/Fields/PropertyStatusFilter/PropertyStatusFilter';
import { TotalUnitsFilter } from 'Components/Filters/Fields/TotalUnitsFilter/TotalUnitsFilter';
import { CurrentOccupancyRateFilter } from 'Components/Filters/Fields/CurrentOccupancyRateFilter/CurrentOccupancyRateFilter';
import { AssetClassFilter } from 'Components/Filters/Fields/AssetClassFilter/AssetClassFilter';
import { ManagementCompanyFilter } from 'Components/Filters/Fields/ManagementCompanyFilter/ManagementCompanyFilter';
import { StartingRentPerSqfFilter } from 'Components/Filters/Fields/StartingRentPerSqfFilter/StartingRentPerSqfFilter';
import { EffectiveRentPerSqfFilter } from 'Components/Filters/Fields/EffectiveRentPerSqfFilter/EffectiveRentPerSqfFilter';
import { OccupancyFilter } from 'Components/Filters/Fields/OccupancyFilter/OccupancyFilter';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

export const PropertyFilterFields = () => {
	const { context, filters } = useFilterFieldContext();
	const { AdditionOfOccupancy: AdditionOfOccupancyFF } = useFeatureFlags();

	const isMultifamily = useIsMultifamily({
		markets: getFiltersMarkets(filters),
	});

	const isEnterprise = !useIsExchange();

	return (
		<>
			{context !== 'analytics' && <CommonlyUsedPropertyFilters />}

			<LocationFilterCategory />

			<FilterCategory category="Property">
				<BuildingFilterCategoryCommonFilters />
				<YearRenovatedFilter />
				<LastSalePriceFilter />
				<LandlordFilter />
				{AdditionOfOccupancyFF && <OccupancyFilter />}

				<FilterSpacer />

				<BuildingSqFtExpiringInTwelveMonthsFilter />
				<AverageTransactionSizeFilter />
				<MarketStartingRentFilter />
			</FilterCategory>

			{useIsShowTreppUI({
				markets: getFiltersMarkets(filters),
			}) && (
				<FilterCategory category="Loan Info">
					<LoanSourceFilter />
					<LenderFilter />
					<LoanAmountFilter />
					<LoanInterestTypeFilter />
					<CurrentLoanRateFilter />
					<LoanOriginationDateFilter />
					<LoanMaturityDateFilter />
					<LoanToValueFilter />
				</FilterCategory>
			)}

			{isMultifamily && (
				<FilterCategory category="Multifamily">
					<PropertyStatusFilter />
					<TotalUnitsFilter />

					<FilterSpacer />

					<CurrentOccupancyRateFilter />
					<StartingRentPerSqfFilter />
					<EffectiveRentPerSqfFilter />
					<FilterSpacer />
					<AssetClassFilter />
					<ManagementCompanyFilter />
				</FilterCategory>
			)}

			{isEnterprise &&
				!isMultifamily &&
				getFiltersMarkets(filters).length > 1 && (
					<>
						<FilterSpacer />
						<RealPageFilterInfo />
					</>
				)}
		</>
	);
};
