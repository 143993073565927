import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Button } from '@compstak/ui-kit';
import { PortfolioV2 } from 'api';
import styled from 'styled-components';
import IconVerticalDots from 'ui/svg_icons/vertical-dots.svg';
import { PortfolioOptionsMenu } from './PortfolioOptionsMenu';

type Props = {
	portfolio: PortfolioV2;
};

export const PortfolioOptionsButton = ({ portfolio }: Props) => {
	return (
		<>
			<DropdownMenu.Root>
				<DropdownMenu.Trigger asChild>
					<OptionsButton variant={'ghost'} fitContent={true}>
						<IconVerticalDots />
					</OptionsButton>
				</DropdownMenu.Trigger>
				<PortfolioOptionsMenu portfolio={portfolio} />
			</DropdownMenu.Root>
		</>
	);
};

const OptionsButton = styled(Button)`
	display: flex;
	align-items: center;
	&:hover {
		background-color: ${(p) => p.theme.colors.gray.gray200};
	}
`;
