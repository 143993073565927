import { API, APIClientNotOkResponseError } from '@compstak/common';
import {
	QueryClient,
	useMutation,
	useQueryClient,
	UseMutationOptions,
} from '@tanstack/react-query';
import {
	getPortfolioWithPreferencesPayload,
	getPortfolioWithPreferencesUrl,
} from 'api/portfolio/preferences/utils';
import { trackSegmentAddPropertyToPortfolio } from 'middleware/tracking/segment';
import { invalidatePortfolioQueries } from 'PortfolioAnalytics/invalidatePortfolioQueries';
import { WithPreferencesParams } from '../preferences/types';

export const useUpdatePortfolioMutation = (
	options?: UseMutationOptions<
		void,
		APIClientNotOkResponseError,
		UpdatePortfolioParams,
		QueryClient
	>
) => {
	const queryClient = useQueryClient();
	const { onSuccess: onSuccessFromOptions, ...restOptions } = options || {};
	return useMutation({
		mutationFn: async (params: UpdatePortfolioParams) => {
			const { description, ...otherBody } = params.body;
			const body = {
				...otherBody,
				...(!!description && { description }),
			};

			const res = await API.put<void>(
				getPortfolioWithPreferencesUrl(
					`/pa/v2/api/portfolios/${params.id}`,
					body
				),
				getPortfolioWithPreferencesPayload(body)
			);
			return res.data;
		},
		onSuccess: (data, variables) => {
			const { id: portfolioId } = variables.body;

			trackSegmentAddPropertyToPortfolio(portfolioId);

			invalidatePortfolioQueries(queryClient);

			onSuccessFromOptions?.(data, variables, queryClient);
		},
		...restOptions,
	});
};

export type UpdatePortfolioParams = {
	id: number;
	body: WithPreferencesParams<{
		id: number;
		name: string;
		description: string | undefined;
		newPropertyIds?: number[];
		removedPropertyIds?: number[];
	}>;
};
