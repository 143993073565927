import { uniq } from 'lodash';
import {
	Comp,
	CompType,
	LeaseComp,
	PropertyComp,
	PropertyLoanData,
	SalesComp,
} from 'types';

export const isLeaseComp = (comp?: Comp): comp is LeaseComp => {
	return !!comp && 'startingRent' in comp;
};

export const isSaleComp = (comp?: Comp): comp is SalesComp => {
	return !!comp && 'isPortfolio' in comp;
};

export const isPropertyComp = (comp?: Comp): comp is PropertyComp => {
	return !!comp && !('own' in comp);
};

export const isPortfolioSaleComp = (
	comp?: Comp
): comp is SalesComp & { isPortfolio: true } => {
	return isSaleComp(comp) && comp.isPortfolio;
};

export const isLoanPropertyComp = (
	comp?: Comp
): comp is PropertyComp & { loan: PropertyLoanData } => {
	return isPropertyComp(comp) && 'loan' in comp && comp.loan != null;
};

const LOAN_FIELDS: (keyof PropertyLoanData)[] = [
	'currentLoanRate',
	'loanAmount',
	'loanAppraisedValue',
	'loanCapRate',
	'loanId',
	'loanInterestType',
	'loanMasterServicer',
	'loanMaturityDate',
	'loanOccupancy',
	'loanOriginator',
	'loanOriginationDate',
	'loanSource',
	'loanTerm',
	'loanToValue',
];

export const isLoanField = (key: string): key is keyof PropertyLoanData => {
	return LOAN_FIELDS.includes(key as keyof PropertyLoanData);
};

export const extractPropertyIdsFromComps = ({
	comps,
	compType,
}: {
	comps: Comp[];
	compType: CompType;
}) => {
	if (compType === 'lease') {
		return uniq(comps.map((comp) => (comp as LeaseComp).propertyId));
	}

	if (compType === 'sale') {
		return uniq(
			comps.flatMap((comp) =>
				(comp as SalesComp).portfolio.map((p) => p.propertyId)
			)
		);
	}

	return comps.map((s) => s.id);
};
