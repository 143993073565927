import {
	Data,
	InfoBoxTypeB,
	KeyValueTable,
	Pending,
	RowFlexContainer,
	TwoColumnList,
	TwoColumnListDataItem,
	useBoolean,
	WidgetContainer,
	WidgetTitle,
	Value,
} from '@compstak/ui-kit';
import { memo, useMemo } from 'react';
import transitScreenLogo from '../../images/transitscreen.png';

import { TransitScreenMap } from './TransitScreenMap';
import {
	HR,
	LeftColumn,
	MobilityScoreButton,
	MoreInfo,
	MoreInfoColumn,
	MoreInfoHeading,
	Row,
} from './UI';
import styled from 'styled-components';
// TODO: Remove TransitScreenDto from PPV2
import { TransitScreenDto } from 'api';
import { transformToKeyValueTableData } from 'utils';
import { MufaPropertyComp, PropertyComp } from 'types';
import { TabContentTitle } from 'Components/UI';
export interface TransitScreenProps {
	data: TransitScreenDto | undefined;
	propertyData: PropertyComp | MufaPropertyComp | undefined;
	isLoading: boolean;
	isError: boolean;
	width?: string;
	// TODO: Remove this prop, after releasing PPV3
	isPropertyPageV3?: boolean;
}

// The feature is suspended, ticket: https://compstak.atlassian.net/browse/AP-18679
export const IS_TRANSIT_TAB_DISABLED = true;

export const TransitScreen = memo<TransitScreenProps>((props) => {
	const { data, isLoading, propertyData } = props;
	const { score, scoreBreakdown, modes } = data || {};
	const [isMoreInfoShown, , , toggleShowMoreInfo] = useBoolean();

	const ScoreBreakdownData = useMemo<TwoColumnListDataItem[]>(
		() => [
			['Score Breakdown', 'Mass Transit: ' + scoreBreakdown?.masstransit],
			['', 'Ridehailing: ' + scoreBreakdown?.ridehailing],
			['', 'Bikeshare: ' + scoreBreakdown?.bikeshare],
			['', 'Carshare: ' + scoreBreakdown?.carshare],
		],
		[scoreBreakdown]
	);

	const keyValueTableData: Data[] = useMemo(
		() => transformToKeyValueTableData(modes),
		[modes]
	);

	return (
		<WidgetContainer>
			<RowFlexContainer>
				{props.isPropertyPageV3 ? (
					<TabContentTitle>TransitScreen</TabContentTitle>
				) : (
					<WidgetTitle>TransitScreen</WidgetTitle>
				)}
			</RowFlexContainer>
			<Row>
				{(() => {
					if (isLoading) return <Pending times={4} />;

					return (
						<>
							<LeftColumn>
								<InfoBoxTypeB primary={score} secondary="MobilityScore" />
								<HR />
								<TwoColumnList data={ScoreBreakdownData} />
							</LeftColumn>
							<RowFlexContainer
								style={{ width: '100%', flexDirection: 'column' }}
							>
								<TransitScreenMap subjectProperty={propertyData} />
								<TransitScreenKeyValueTable
									data={keyValueTableData}
									columns={3}
								/>
							</RowFlexContainer>
						</>
					);
				})()}
			</Row>

			<RowFlexContainer
				justifyContent="space-between"
				style={{ marginTop: '1rem' }}
			>
				<div>
					<span>Learn more about </span>
					<MobilityScoreButton
						onClick={toggleShowMoreInfo}
						data-qa-id="btn-mobility-score"
					>
						MobilityScore
					</MobilityScoreButton>
				</div>
				<img src={transitScreenLogo} alt="TransitScreen logo" height="36" />
			</RowFlexContainer>
			{isMoreInfoShown && (
				<MoreInfo>
					<MoreInfoHeading>MobilityScore</MoreInfoHeading>
					<RowFlexContainer alignItems="stretch" gap="1.5rem">
						<MoreInfoColumn>
							TransitScreen’s MobilityScore rates how easy it is to get around,
							assessing all available transportation choices including public
							transit, carsharing services, bikesharing services, and hailed
							ridesharing services.
						</MoreInfoColumn>
						<MoreInfoColumn>
							For each mobility choice, MobilityScores account for how long it
							will take to start moving on it – for example, the time it takes
							to leave a building, walk to a subway station, and wait for a
							train. MobilityScores range from 0 (no mobility choices) to 100
							(excellent mobility choices).
						</MoreInfoColumn>
					</RowFlexContainer>
				</MoreInfo>
			)}
		</WidgetContainer>
	);
});

TransitScreen.displayName = 'TransitScreen';

const TransitScreenKeyValueTable = styled(KeyValueTable)`
	& ${Value} {
		margin-left: 16px;
	}
`;
