import { Button, Flex } from '@compstak/ui-kit';
import { CSSProperties, FC, ReactNode } from 'react';
import { isValidTotpCodeInputValue } from 'util/mfAuthUtil';
import { BUTTON_SIZE } from './constants';
import { MfaPageContainerFlex, TotpCodeInputWrapper } from './styles';
import { HasCancelProp } from './types';
import { splitCodeIntoFours } from './utils';

export function TotpCodeInput({
	digits,
	setDigits,
	style,
	onBlur,
	onEnterKeyDown,
	error,
}: {
	digits: string;
	setDigits: (digits: string) => void;
	style?: CSSProperties;
	onBlur?: NoArgCallback;
	onEnterKeyDown?: NoArgCallback;
	error?: boolean;
}) {
	return (
		<TotpCodeInputWrapper error={error}>
			<input
				style={style}
				data-qa-id="6-digits-input"
				maxLength={6}
				required
				placeholder="6-digit code"
				type="text"
				value={digits}
				onBlur={onBlur}
				onKeyDown={(ev) => {
					if (ev.key === 'Enter') {
						onEnterKeyDown?.();
					}
				}}
				onChange={(ev) => {
					const newVal = ev.target.value;
					if (isValidTotpCodeInputValue(newVal)) {
						setDigits(newVal);
					}
				}}
			/>
		</TotpCodeInputWrapper>
	);
}

export const MfaPageContainer: FC<{ children: ReactNode }> = ({ children }) => {
	return <MfaPageContainerFlex>{children}</MfaPageContainerFlex>;
};

export function TotpCode32CharDisplay({ code }: { code: string }) {
	const fourCharParts = splitCodeIntoFours(code);
	if (code?.length !== 32 || fourCharParts?.length !== 8) {
		return <p>Error - bad code!</p>;
	}
	return (
		<Flex justifyContent="flex-start" gap="4px">
			{fourCharParts.map((part, idx) => (
				<b key={idx}>{part}</b>
			))}
		</Flex>
	);
}

export const BtnsArea: FC<{ children: ReactNode }> = ({ children }) => {
	return (
		<Flex justifyContent="flex-end">
			<Flex style={{ width: 'fit-content', gap: '16px' }}>{children}</Flex>
		</Flex>
	);
};

export function PrimaryBtn({
	onClick,
	disabled,
	isLoading,
	text,
}: {
	onClick: NoArgCallback;
	disabled?: boolean;
	isLoading?: boolean;
	text?: string;
}) {
	return (
		<Button
			dataTestId="continue-btn"
			onClick={onClick}
			isLoading={isLoading}
			disabled={disabled}
			style={{ width: '96px' }}
			size={BUTTON_SIZE}
		>
			{text || 'Continue'}
		</Button>
	);
}

export function CancelBtn({
	cancel,
	disabled,
}: { disabled?: boolean } & HasCancelProp) {
	return (
		<Button
			variant="secondary"
			dataTestId="cancel-btn"
			disabled={disabled}
			onClick={cancel}
			size={BUTTON_SIZE}
		>
			Cancel
		</Button>
	);
}
