import {
	AlertCircle,
	Button,
	Flex,
	MQB,
	Spinner,
	SuccessCircle,
} from '@compstak/ui-kit';
import { showUploadForm } from '../actions';
import { useShowUploadSuccessModal } from 'Components/Modals/UploadSuccessModal/UploadSuccessModal';
import {
	COMP_FILE_TOO_LARGE_ERROR_REASON,
	MAX_COMP_UPLOAD_FILE_SIZE_MB,
	uploadCompFile,
} from '../utils/uploadComp';
import { useState } from 'react';
import styled from 'styled-components';
import { ErrorWithReason } from 'types/errorTypes';
import { useAppDispatch } from 'util/useAppDispatch';
import { openFilesPicker } from 'utils/filesPickerUtils';
import { useQueryClient } from '@tanstack/react-query';
import { invalidateUserQuery } from 'hooks/userHooks';

let firstGoodSubmissionOfSession = true;

const DEFAULT_UPLOAD_FAILURE_ERROR = 'Failed to upload file.';

enum FileUploadStatus {
	ready = 'ready',
	uploading = 'uploading',
	succeeded = 'succeeded',
	failed = 'failed',
}

type FileItem = {
	data: File;
	key: string;
	status: FileUploadStatus;
	error?: string;
};

export function DragAndDropComp() {
	const [files, setFiles] = useState([] as FileItem[]);
	const dispatch = useAppDispatch();
	const showUploadSuccessModal = useShowUploadSuccessModal();

	const updateFileItem = (fileKey: string, changes: Partial<FileItem>) => {
		setFiles((prevFiles) =>
			prevFiles.map((prevFile) =>
				prevFile.key === fileKey
					? {
							...prevFile,
							// Errors are cleared on any changes, unless explicitly set
							error: undefined,
							...changes,
						}
					: prevFile
			)
		);
	};

	const addNewFiles = (newFiles: File[]) => {
		const ts = new Date().valueOf();
		setFiles((prevFiles) => [
			...prevFiles,
			...Array.from(newFiles).map((file) => ({
				data: file,
				key: `${ts}-${file.name}`,
				status: FileUploadStatus.ready,
			})),
		]);
	};

	const browseFiles = () => {
		openFilesPicker(addNewFiles);
	};

	const removeFileByKey = (fileKey: string) => {
		setFiles((prevFiles) =>
			prevFiles.filter((prevFile) => prevFile.key !== fileKey)
		);
	};
	const queryClient = useQueryClient();

	return (
		<DropWrapper>
			<DropContainer
				onDrop={(newFiles) => {
					addNewFiles(newFiles);
				}}
			>
				{files.length === 0 ? (
					<>
						<DropUploadHintArea>
							<UploadTitle>Drag & Drop files here</UploadTitle>
							<div>
								Drop files in ANY format (e.g. Excel, JPEG, PNG, PDF, etc).
							</div>
						</DropUploadHintArea>

						<Button size="l" onClick={browseFiles}>
							<UploadButtonContent>Browse files</UploadButtonContent>
						</Button>
					</>
				) : (
					<>
						<FileUl>
							{files.map((file) => (
								<FileLi key={file.key}>
									<FileNameSpan>{file.data.name}</FileNameSpan>
									{(() => {
										switch (file.status) {
											case FileUploadStatus.ready:
												return (
													<Button
														variant="ghost"
														onClick={() => removeFileByKey(file.key)}
													>
														REMOVE
													</Button>
												);
											case FileUploadStatus.uploading:
												return (
													<SpinnerContainer>
														<Spinner size="s" />
													</SpinnerContainer>
												);
											case FileUploadStatus.succeeded:
												return <SuccessCircleSC />;
											case FileUploadStatus.failed:
												return (
													<AlertCircleSC
														data-tooltip={file.error}
														data-tooltipdelay="0"
													/>
												);
											default:
												return <></>;
										}
									})()}
								</FileLi>
							))}
						</FileUl>
						<Flex justifyContent="center">
							<Button
								variant="success"
								size="l"
								disabled={
									// At least one file must be ready to upload to enable the button.
									!files.some((file) => file.status === FileUploadStatus.ready)
								}
								isLoading={files.some(
									(file) => file.status === FileUploadStatus.uploading
								)}
								onClick={() => {
									files
										.filter((file) => file.status === FileUploadStatus.ready)
										.forEach((file) => {
											updateFileItem(file.key, {
												status: FileUploadStatus.uploading,
											});
											try {
												uploadCompFile({
													file: file.data,
													addEventListeners(req) {
														req.addEventListener('loadend', () => {
															const success =
																req.status >= 200 && req.status < 400;
															if (success && firstGoodSubmissionOfSession) {
																firstGoodSubmissionOfSession = false;
																const compsAwarded: number =
																	typeof req.response?.comps?.awarded ===
																	'number'
																		? req.response.comps.awarded
																		: 0;
																invalidateUserQuery(queryClient);
																try {
																	showUploadSuccessModal({ compsAwarded });
																} catch (err) {
																	console.error(err);
																}
															}
															updateFileItem(
																file.key,
																success
																	? {
																			status: FileUploadStatus.succeeded,
																		}
																	: {
																			status: FileUploadStatus.failed,
																			error: DEFAULT_UPLOAD_FAILURE_ERROR,
																		}
															);
														});
													},
												});
											} catch (err: unknown) {
												const changes = {
													status: FileUploadStatus.failed,
													error: DEFAULT_UPLOAD_FAILURE_ERROR,
												};
												if (
													(err as ErrorWithReason).cause?.reason ===
													COMP_FILE_TOO_LARGE_ERROR_REASON
												) {
													changes.error = `Files over ${MAX_COMP_UPLOAD_FILE_SIZE_MB}MB cannot be accepted.`;
												}
												updateFileItem(file.key, changes);
											}
										});
								}}
							>
								CONFIRM UPLOAD
							</Button>
						</Flex>
						<Flex justifyContent="center" gap="8px">
							<Button
								variant="ghost"
								disabled={files.some(
									(file) => file.status === FileUploadStatus.uploading
								)}
								onClick={() => {
									setFiles([]);
								}}
							>
								CLEAR UPLOAD
							</Button>
							<Button variant="ghost" onClick={browseFiles}>
								ADD MORE FILES
							</Button>
						</Flex>
					</>
				)}
			</DropContainer>
			<OpenCompsFormButton onClick={() => dispatch(showUploadForm())}>
				ENTER COMP DETAILS MANUALLY
			</OpenCompsFormButton>
		</DropWrapper>
	);
}

function DropContainer({
	children,
	onDrop,
}: {
	children: React.ReactNode;
	onDrop: (files: File[]) => void;
}) {
	const [draggedOver, setDraggedOver] = useState(false);
	return (
		<DropContainerSC
			draggedOver={draggedOver}
			onDragOver={(ev) => {
				ev.preventDefault();
				setDraggedOver(true);
			}}
			onDragLeave={() => setDraggedOver(false)}
			onDrop={(ev) => {
				ev.preventDefault();
				setDraggedOver(false);
				const droppedFiles = Array.from(ev.dataTransfer.files);
				if (droppedFiles.length > 0) {
					onDrop(droppedFiles);
				}
			}}
		>
			{children}
		</DropContainerSC>
	);
}

const DropWrapper = styled.div`
	width: 100%;
	max-width: 800px;

	@media (max-width: ${MQB.D_1536}) {
		max-width: none;
	}
`;

const DropContainerSC = styled.div<{ draggedOver: boolean }>`
	background-color: ${(props) => props.theme.colors.gray.gray900};
	width: 100%;
	height: 380px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 1.5rem;
	padding: 40px 24px;
	border-radius: 4px;
	border: 2px dashed ${(props) => props.theme.colors.gray.gray300};
	text-align: center;

	@media (max-width: ${MQB.T_834}) {
		min-width: 320px;
		flex-shrink: 1;
	}

	${(props) =>
		props.draggedOver &&
		`
		border: 2px dashed ${props.theme.colors.blue.blue400};
	`}
`;
DropContainerSC.displayName = 'DropContainerSC';

const UploadTitle = styled.div`
	font-size: 1.25rem;
	text-transform: capitalize;
	font-weight: 500;
`;

UploadTitle.displayName = 'UploadTitle';

const DropUploadHintArea = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.5rem;
`;

DropUploadHintArea.displayName = 'DropDownUploadHintArea';

const UploadButtonContent = styled.span`
	width: 100%;
	display: inline-flex;
	min-width: 150px;
	justify-content: center;
`;

UploadButtonContent.displayName = 'UploadButtonContent';

const OrLineBreak = styled.div`
	color: ${(props) => props.theme.colors.gray.gray300};
	user-select: none;
`;
OrLineBreak.displayName = 'OrLineBreak';

const OpenCompsFormButton = styled(Button)`
	padding: 16px 8px;
	font-weight: 400;
	height: 40px;
	width: 100%;
	margin-top: 16px;
`;
OpenCompsFormButton.displayName = 'OpenCompsFormButton';
OpenCompsFormButton.defaultProps = { variant: 'primary2' };

const FileUl = styled.ul`
	list-style: none;
	padding: 0;
	margin: 0;
	overflow-y: auto;
	flex-grow: 1;
	flex-basis: 1px;
	width: 100%;
`;

const FileLi = styled.li`
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	gap: 8px;
	height: 32px;
`;

const FileNameSpan = styled.span`
	max-width: 480px;
	max-height: 32px;
	margin-left: 8px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const SpinnerContainer = styled.div`
	width: 24px;
`;

const AlertCircleSC = styled(AlertCircle)`
	cursor: default;
	margin-right: 8px;
	svg {
		color: red;
	}
`;

const SuccessCircleSC = styled(SuccessCircle)`
	cursor: default;
	margin-right: 8px;
	svg {
		color: green;
	}
`;
