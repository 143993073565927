import { Link, routes } from 'router';
import { ComparedPortfolio } from './useBenchmarkTableConfig';
import { PortfolioColorCircle } from 'PortfolioAnalytics/UI';
import styled from 'styled-components';

export const PortfolioHeader = ({
	portfolio,
}: {
	portfolio: ComparedPortfolio;
}) => {
	return (
		<div>
			<PortfolioColorCircle
				color={portfolio.markerColor ?? ''}
				diameter="1rem"
			/>
			<StyledLink
				to={routes.portfolioByIdView.toHref({
					portfolioId: portfolio.id,
				})}
				target="_blank"
				className="underline"
			>
				{portfolio.portfolioName}
			</StyledLink>
		</div>
	);
};

const StyledLink = styled(Link)`
	color: ${(p) => p.theme.colors.gray.n100};
	vertical-align: baseline;
`;
