import { TooltipV2 } from '@compstak/ui-kit';
import { ReactNode } from 'react';
import { TransitionStatus } from 'react-transition-group';
import styled, { css } from 'styled-components';

type StatsItemProps = {
	children: ReactNode;
	isActive?: boolean;
	label: string;
	transitionStatus?: TransitionStatus;
	isCompsWithLimitedDetailsIncluded: boolean;
};

export const StatsItem = ({
	children,
	isActive,
	label,
	transitionStatus,
	isCompsWithLimitedDetailsIncluded,
}: StatsItemProps) => {
	return (
		<TooltipV2
			content={
				isCompsWithLimitedDetailsIncluded ? compsWithLimitedDetailsTooltip : ''
			}
			side="bottom"
		>
			<Container
				isActive={isActive}
				shouldDisplayCursor={isCompsWithLimitedDetailsIncluded}
			>
				<Label transitionStatus={transitionStatus}>{label}</Label>
				<Value transitionStatus={transitionStatus}>
					{children}
					{isCompsWithLimitedDetailsIncluded && '*'}
				</Value>
			</Container>
		</TooltipV2>
	);
};

const compsWithLimitedDetailsTooltip =
	'*Comps with limited details are included';

const Container = styled.div<{
	isActive?: boolean;
	shouldDisplayCursor: boolean;
}>`
	width: 60px;
	display: inline-block;
	vertical-align: top;
	border-right: 1px solid ${({ theme }) => `${theme.colors.gray.gray300}`};
	transition: border 0.3s ease-in-out;
	text-align: center;
	${({ shouldDisplayCursor }) =>
		shouldDisplayCursor &&
		css`
			cursor: pointer;
		`}

	&:last-child {
		border-right: none;
	}

	@media (max-width: 1024px) {
		display: ${({ isActive }) => (isActive ? 'inline-block' : 'none')};
		cursor: pointer;
		border: none;
	}

	@media (max-width: 1440px) {
		width: 50px;
	}
`;

const Label = styled.div<{ transitionStatus?: TransitionStatus }>`
	transition: color 0.3s ease-in-out;
	text-transform: uppercase;
	font-weight: 500;
	font-size: 10px;
	letter-spacing: 1px;
	line-height: 10px;
	color: ${({ theme, transitionStatus }) =>
		transitionStatus === 'entering'
			? `${theme.colors.white.white}`
			: `${theme.colors.blue.blue500}`};
`;

const Value = styled.div<{ transitionStatus?: TransitionStatus }>`
	transition: color 0.3s ease-in-out;
	font-weight: 400;
	font-size: 14px;
	padding-top: 2px;
	line-height: 14px;
	color: ${({ theme, transitionStatus }) =>
		transitionStatus === 'entering'
			? `${theme.colors.white.white}`
			: `${theme.colors.gray.gray500}`};
	letter-spacing: 0.2px;
`;
