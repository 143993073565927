import { TableCellHAlign } from '@compstak/ui-kit';
import styled from 'styled-components';

export const GrayPostfix = styled.span`
	color: ${(p) => p.theme.colors.neutral.n50};
`;

const FLEX_JUSTIFY_MAP = {
	left: 'flex-start',
	center: 'center',
	right: 'flex-end',
} as const;

export const Gapper = styled.div<{
	hAlign?: TableCellHAlign;
}>`
	display: flex;
	gap: 0.25rem;
	justify-content: ${({ hAlign = 'left' }) => FLEX_JUSTIFY_MAP[hAlign]};
`;
