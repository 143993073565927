import React from 'react';
import { FilterCategory } from './FilterCategory';
import { AddressFilter } from 'Components/Filters/Fields/AddressFilter/AddressFilter';
import { CityFilter } from 'Components/Filters/Fields/CityFilter/CityFilter';
import { ZipFilter } from 'Components/Filters/Fields/ZipFilter/ZipFilter';
import { CountyAPNFilter } from 'Components/Filters/Fields/CountyAPNFilter/CountyAPNFilter';
import { withErrorBoundaryDefault } from 'Components/ErrorBoundary';

export const LocationFilterCategory = withErrorBoundaryDefault(
	function LocationFilterCategoryUnsafe() {
		return (
			<FilterCategory category="Location">
				<AddressFilter />
				<CityFilter />
				<ZipFilter />
				<CountyAPNFilter />
			</FilterCategory>
		);
	}
);
