import { formatDate } from 'format';
import styled from 'styled-components';

type OccupancyCellProps = {
	occupancyPercentage: number;
	occupancyDate?: string;
};

export const OccupancyInfo = ({
	occupancyPercentage,
	occupancyDate,
}: OccupancyCellProps) => {
	return (
		<>
			{occupancyPercentage.toFixed(2)}%{' '}
			{occupancyDate && (
				<OccupanceDate>
					(as of {formatDate(new Date(occupancyDate))})
				</OccupanceDate>
			)}
		</>
	);
};

const OccupanceDate = styled.span`
	font-size: 0.75rem;
	color: ${({ theme }) => theme.colors.gray.gray300};
`;
