import { UseQueryOptions, useQueries, useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from 'api/constants';
import { getQueryStringFromPortfolioFilters } from 'util/getQueryStringsFromPortfolioFilter';
import { API } from '@compstak/common';
import {
	ChartPropertiesHistogramResponse,
	ChartPropertiesHistogramParams,
} from './types';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

export type ChartPropertiesHistogramResponseWithPortfolioId =
	ChartPropertiesHistogramResponse & {
		portfolioId: number;
	};

const getChartHistogram = async (
	id: number,
	queryParams: string
): Promise<ChartPropertiesHistogramResponseWithPortfolioId> => {
	const response = await API.get<ChartPropertiesHistogramResponse>(
		`/pa/v2/api/portfolios/${id}/properties/chart/histogram${queryParams}`
	);

	return { ...response.data, portfolioId: id };
};

export const useChartPropertiesHistogramQueries = (
	ids: number[],
	params: ChartPropertiesHistogramParams,
	options?: UseQueryOptions<ChartPropertiesHistogramResponseWithPortfolioId>
) => {
	const { filters, ...extraParams } = params;
	const { portfolioExcludeSubleasesFF } = useFeatureFlags();
	const queryParams = getQueryStringFromPortfolioFilters(filters, {
		...extraParams,
		excludeSubleases: portfolioExcludeSubleasesFF,
	});

	return useQueries({
		queries: ids.map((id) => {
			return {
				queryKey: [QUERY_KEYS.CHART_HISTOGRAM_PROPERTIES, id, params],
				queryFn: () => getChartHistogram(id, queryParams),
				...options,
			};
		}),
	});
};

export const useChartPropertiesHistogramQuery = (
	id: number,
	params: ChartPropertiesHistogramParams,
	options?: UseQueryOptions<ChartPropertiesHistogramResponseWithPortfolioId>
) => {
	const { filters, ...extraParams } = params;
	const { portfolioExcludeSubleasesFF } = useFeatureFlags();
	const queryParams = getQueryStringFromPortfolioFilters(filters, {
		...extraParams,
		excludeSubleases: portfolioExcludeSubleasesFF,
	});

	return useQuery({
		queryKey: [QUERY_KEYS.CHART_HISTOGRAM_PROPERTIES, id, params],
		queryFn: () => getChartHistogram(id, queryParams),
		...options,
	});
};
