import {
	OurCalculationsModal,
	OurCalculationsModalContext,
} from 'Components/Modals/OurCalculationsModal/OurCalculationsModal';
import { useModal } from 'providers/ModalProvider';

type CalculationOverviewLinkProps = {
	className?: string;
	context: OurCalculationsModalContext;
};

export const CalculationOverviewLink = ({
	className,
	context,
}: CalculationOverviewLinkProps) => {
	const { openModal } = useModal();

	return (
		<a
			data-qa-id="calculation-overview-modal"
			className={`underline ${className}`}
			onClick={() =>
				openModal({
					modalContent: <OurCalculationsModal context={context} />,
					modalProps: { contentProps: { view: 'medium' } },
				})
			}
		>
			Calculation Overview
		</a>
	);
};
