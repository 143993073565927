import { removeFeedback } from 'Singletons/Feedback/actions';

import { CompType } from 'types/comp';
import { User } from '@compstak/common';

import * as leaseExportService from 'services/leaseExport';
import * as saleExportService from 'services/saleExport';
import * as propertyExportService from 'services/propertyExport';

export const PREPARE_LEASES = 'PREPARE_LEASES' as const;
export const EXPORT_CUSTOM_LEASES = 'EXPORT_CUSTOM_LEASES' as const;
export const HIDE_CUSTOM_EXPORT_FIELD = 'HIDE_CUSTOM_EXPORT_FIELD' as const;
export const SHOW_CUSTOM_EXPORT_FIELD = 'SHOW_CUSTOM_EXPORT_FIELD' as const;
export const UPDATE_CUSTOM_EXPORT_VALUE = 'UPDATE_CUSTOM_EXPORT_VALUE' as const;
export const UPDATE_CUSTOM_EXPORT_METADATA =
	'UPDATE_CUSTOM_EXPORT_METADATA' as const;
export const RESTORE_EXPORT = 'RESTORE_EXPORT' as const;
export const CLEAR_CUSTOM_EXPORT = 'CLEAR_CUSTOM_EXPORT' as const;

import marketService from 'services/markets';
import leaseService from 'services/lease';
import salesService from 'services/sale';
import propertyService from 'services/property';
import pluralizeCompType from 'util/pluralizeCompType';
import { QueryClient } from '@tanstack/react-query';
import { ExportState } from 'reducers/export';

const compService = {
	lease: leaseService,
	sale: salesService,
	property: propertyService,
};

const exportServices = {
	lease: leaseExportService,
	sale: saleExportService,
	property: propertyExportService,
};

type CustomExportAction = {
	type: typeof PREPARE_LEASES;
	meta: {
		leaseIds: Array<number>;
		preparedBy: string;
		compType: CompType;
	};
	payload: {
		promise: Promise<any>;
	};
};

export function prepareCustomExport({
	compIds,
	user,
	compType,
}: {
	compIds: number[];
	user: User;
	compType: CompType;
}): CustomExportAction {
	const preparedBy = `${user.firstName} ${user.lastName}<br />${
		user.email
	}<br />${user.phone ? user.phone : ''}`;
	const compsPromise = compService[compType].loadMany(compIds);

	const marketPromise = compsPromise.then((comps) => {
		return marketService.load(comps[0].marketId);
	});

	const promise = Promise.all([compsPromise, marketPromise]).then(
		([comps, marketResponse]) => {
			return {
				comps,
				market: marketResponse,
			};
		}
	);

	return {
		type: PREPARE_LEASES,
		meta: {
			leaseIds: compIds,
			preparedBy: preparedBy,
			compType: compType,
		},
		payload: {
			promise,
		},
	};
}

type UpdateCustomExportAction = {
	type: typeof UPDATE_CUSTOM_EXPORT_VALUE;
	payload: {
		leaseId: number;
		name: string;
		value: string;
		section: string;
	};
};

export function updateCustomExportValue(
	name: string,
	value: string,
	leaseId: number,
	// @ts-expect-error TS7006: Parameter 'section' implicitly...
	section
): UpdateCustomExportAction {
	return {
		type: UPDATE_CUSTOM_EXPORT_VALUE,
		payload: {
			leaseId: leaseId,
			name: name,
			value: value,
			section: section,
		},
	};
}

type UpdateCustomExportMetaAction = {
	type: typeof UPDATE_CUSTOM_EXPORT_METADATA;
	payload: {
		name: string;
		value: string;
	};
};

export function updateCustomExportMetaData(
	name: string,
	value: string
): UpdateCustomExportMetaAction {
	return {
		type: UPDATE_CUSTOM_EXPORT_METADATA,
		payload: {
			name: name,
			value: value,
		},
	};
}

type HideCustomExportAction = {
	type: typeof HIDE_CUSTOM_EXPORT_FIELD;
	payload: { name: string };
};

export function hideCustomExportField(name: string): HideCustomExportAction {
	return {
		type: HIDE_CUSTOM_EXPORT_FIELD,
		payload: {
			name: name,
		},
	};
}

type ShowCustomExportAction = {
	type: typeof SHOW_CUSTOM_EXPORT_FIELD;
	payload: {
		name: string;
	};
};

export function showCustomExportField(name: string): ShowCustomExportAction {
	return {
		type: SHOW_CUSTOM_EXPORT_FIELD,
		payload: {
			name: name,
		},
	};
}

function restoreExport(exportData: ExportState['exportData']) {
	return {
		type: RESTORE_EXPORT,
		payload: exportData,
	};
}

export function exportCustomData(
	data: ExportState['exportData'],
	showLineItemComps: boolean,
	showIndividualComps: boolean,
	compType: CompType,
	queryClient: QueryClient
) {
	const json = Object.assign(
		{},
		{
			showLineItemComps: showLineItemComps,
			showIndividualComps: showIndividualComps,
		},
		data
	);

	const leaseIds = [
		// @ts-expect-error TS7006: Parameter 'lease' implicitly h...
		...new Set(json.leases.map((lease) => parseInt(lease.id, 10))),
	];

	// @ts-expect-error TS7006: Parameter 'alertId' implicitly...
	const restore = (alertId) => (dispatch) => {
		dispatch(restoreExport(data));
		dispatch(removeFeedback(alertId));
	};
	const compTypePlural = pluralizeCompType(compType, false);

	return {
		type: EXPORT_CUSTOM_LEASES,
		meta: {
			feedback: {
				pending: 'Exporting ' + json.title,
				rejected: {
					duration: 0,
					// @ts-expect-error TS7006: Parameter 'xhr' implicitly has...
					message: (xhr) => {
						try {
							return JSON.parse(xhr.response).error.replace(
								'assertion failed: ',
								''
							);
						} catch (e) {
							return 'There was an unknown error trying to export.';
						}
					},
					buttons: [
						{
							text: 'Reopen Editor',
							link: {
								href: `/export/${compTypePlural}/${leaseIds.join(',')}`,
								'data-modal': true,
							},
							action: restore,
						},
						{
							text: 'Dismiss',
							// @ts-expect-error TS7006: Parameter 'alertId' implicitly...
							action: (alertId) => removeFeedback(alertId),
						},
					],
				},
				fulfilled: {
					duration: 0,
					message: `Export of ${json.title} complete.`,
					buttons: [
						{
							text: 'Make Changes',
							link: {
								href: `/export/${compTypePlural}/${leaseIds.join(',')}`,
								'data-modal': true,
							},
							action: restore,
						},
						{
							text: 'Dismiss',
							// @ts-expect-error TS7006: Parameter 'alertId' implicitly...
							action: (alertId) => removeFeedback(alertId),
						},
					],
				},
			},
		},
		payload: {
			promise: exportServices[compType]

				.exportComps({
					// @ts-expect-error TS2322: Type 'exportType' is not assign...
					exportType: 'pdf',
					compIds: leaseIds,
					customData: json,
					queryClient,
				})
				.then(function (response) {
					return response;
				}),
		},
	};
}

export function clearCustomData() {
	return {
		type: CLEAR_CUSTOM_EXPORT,
	};
}

export type ExportAction =
	| UpdateCustomExportAction
	| UpdateCustomExportMetaAction
	| ShowCustomExportAction
	| HideCustomExportAction
	| ReturnType<typeof clearCustomData>
	| ReturnType<typeof restoreExport>;

export const exportActions = {
	clearCustomData,
	exportCustomData,
	hideCustomExportField,
	prepareCustomExport,
	showCustomExportField,
	updateCustomExportMetaData,
	updateCustomExportValue,
};

export type ExportActions = typeof exportActions;
