import { MufaPropertyLevelFilters } from '../mufaProperty';
import { PropertyFilters } from '../property';
import {
	apnFilterStub,
	filterDateIntervalStub,
	filterNumberIntervalStub,
	marketFilterStub,
	multiPolygonFilterStub,
	polygonFilterStub,
	radiusFilterStub,
	submarketsFilterStub,
} from './filterStubs';

export const mufaPropertyLevelFiltersStub: {
	[K in keyof MufaPropertyLevelFilters]: NonNullable<
		MufaPropertyLevelFilters[K]
	>;
} = {
	askingRpsf: filterNumberIntervalStub,
	assetClassMarket: [],
	effectiveRpsf: filterNumberIntervalStub,
	managementCompany: '',
	occupancy: filterNumberIntervalStub,
	propertyStatus: [],
	totalUnits: filterNumberIntervalStub,
};

export const propertyFiltersStub: {
	[K in keyof PropertyFilters]: NonNullable<PropertyFilters[K]>;
} = {
	portfolioId: 1,
	polygon: polygonFilterStub,
	multiPolygon: multiPolygonFilterStub,
	radius: radiusFilterStub,
	address: [],
	buildingClassId: [],
	buildingName: '',
	buildingSize: filterNumberIntervalStub,
	buildingPropertyTypeId: [],
	buildingPropertySubtype: [],
	buildingYearBuilt: filterNumberIntervalStub,
	buildingYearRenovated: filterNumberIntervalStub,
	isPortfolio: 'true',
	market: marketFilterStub,
	markets: [marketFilterStub],
	buildingFloorsCount: filterNumberIntervalStub,
	propertySqFtExpiringInTwelveMonths: filterNumberIntervalStub,
	owns: 'own',
	totalSalePrice: filterNumberIntervalStub,
	lastSalePrice: filterNumberIntervalStub,
	saleDate: filterDateIntervalStub,
	salePricePsf: filterNumberIntervalStub,
	submarkets: submarketsFilterStub,
	opportunityZoneId: [],
	transactionSize: filterNumberIntervalStub,
	partial: 'true',
	dataSource: 'CompStak',
	propertyMarketStartingRent: filterNumberIntervalStub,
	propertyMarketEffectiveRent: filterNumberIntervalStub,
	propertyAverageTransactionSize: filterNumberIntervalStub,
	city: [],
	zip: [],
	landlordNames: [],
	sortField: 'buildingSize',
	sortDirection: 'desc',
	propertyId: [],
	loanSource: [],
	loanOriginators: [],
	loanAmount: filterNumberIntervalStub,
	loanInterestType: 'Fixed',
	currentLoanRate: filterNumberIntervalStub,
	loanOriginationDate: filterDateIntervalStub,
	loanMaturityDate: filterDateIntervalStub,
	loanToValue: filterNumberIntervalStub,
	fips: [],
	apn: apnFilterStub,
	occupancyPercentage: filterNumberIntervalStub,

	...mufaPropertyLevelFiltersStub,
};
