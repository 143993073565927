import {
	NotificationType,
	copyToClipboard,
	useNotifications,
} from '@compstak/ui-kit';
import { useUserQuery } from 'api';
import { routes, useNavigate } from 'router';
import { useModal } from 'providers/ModalProvider';
import { ExportTrialLimitExceededModal } from './ExportLimitExceededModal';
import { CompType } from 'types';
import { useCheckLeasesExportStatusQuery } from 'api/checkExportStatus/useCheckLeasesExportStatusQuery';
import { useCheckSalesExportStatusQuery } from 'api/checkExportStatus/useCheckSalesExportStatusQuery';
import { useCheckPropertiesExportStatusQuery } from 'api/checkExportStatus/useCheckPropertiesExportStatusQuery';
import { useExportLeasesMutation } from 'api/export/useExportLeasesMutation';
import { useExportSalesMutation } from 'api/export/useExportSalesMutation';
import { useExportPropertiesMutation } from 'api/export/useExportPropertiesMutation';

type Props<T extends CompType> = {
	compIds: number[];
	type: T;
	closePopup: () => void;
	isMufa?: boolean;
};

const EXCEL_EXPORT_NOTIFICATION_ID = 'EXCEL_EXPORT_NOTIFICATION_ID';

export const useCompExport = <T extends CompType>({
	compIds,
	type,
	closePopup,
	isMufa,
}: Props<T>) => {
	const { data: user, isFetching: isFetchingUser } = useUserQuery();
	const isAdmin = user?.userType === 'admin';
	const isTrial = Boolean(user?.enterpriseTrialData);
	const navigate = useNavigate();
	const { openModal } = useModal();

	const exportLeasesResult = useCheckLeasesExportStatusQuery(
		{
			compIds,
			format: 'xls',
		},
		{
			enabled: type === 'lease',
		}
	);

	const exportSalesResult = useCheckSalesExportStatusQuery(
		{
			compIds,
			format: 'xls',
		},
		{
			enabled: type === 'sale',
		}
	);

	const exportPropertiesResult = useCheckPropertiesExportStatusQuery(
		{
			compIds,
			format: 'xls',
		},
		{
			enabled: type === 'property',
		}
	);

	const { data: exportStatus, isFetching: isFetchingExportStatus } =
		type === 'lease'
			? exportLeasesResult
			: type === 'property'
				? exportPropertiesResult
				: exportSalesResult;

	const exportLeasesMutation = useExportLeasesMutation();
	const exportSalesMutation = useExportSalesMutation();
	const exportPropertiesMutation = useExportPropertiesMutation();

	const { mutate: exportExcel, isLoading: isLoadingExportExcel } =
		type === 'lease'
			? exportLeasesMutation
			: type === 'property'
				? exportPropertiesMutation
				: exportSalesMutation;

	const { addNotification, deleteNotification } = useNotifications();

	const onClickExportExcel = ({
		monthly,
		compIds,
		withFinancial = true,
	}: {
		monthly: boolean;
		compIds: number[];
		/** @default true */
		withFinancial?: boolean;
	}) => {
		addNotification({
			id: EXCEL_EXPORT_NOTIFICATION_ID,
			icon: 'info',
			description: 'Exporting Comps...',
			type: NotificationType.BLUE,
			duration: 2000,
		});

		exportExcel(
			{
				compIds,
				format: 'xls',
				monthly,
				withFinancial,
			},
			{
				onSuccess: ({ exportId }) => {
					deleteNotification(EXCEL_EXPORT_NOTIFICATION_ID);
					addNotification({
						id: EXCEL_EXPORT_NOTIFICATION_ID,
						description: 'Comps are downloading.',
						type: NotificationType.BLUE,
						duration: 2000,
					});
					closePopup();

					const propertyLink = `/api${isMufa ? '/mufa' : ''}/properties/${exportId}/actions/export?canIncludeMarket=true`;
					const saleLink = `/api/salesComps/export/${exportId}?canIncludeMarket=true`;
					const leaseLink = `/api/comps/actions/export/${exportId}?canIncludeMarket=true`;

					window.location.href =
						type === 'sale'
							? saleLink
							: type === 'property'
								? propertyLink
								: leaseLink;
				},
				onError: () => {
					if (isTrial) {
						openModal({ modalContent: <ExportTrialLimitExceededModal /> });
					} else {
						addNotification({
							icon: 'info',
							description:
								'We`re sorry, but you`ve exceeded your monthly export limit.',
						});
					}
					closePopup();
				},
			}
		);
	};

	const onClickExportPdf = ({ compIds }: { compIds: number[] }) => {
		closePopup();
		navigate(
			type === 'lease'
				? routes.exportPdfLeases.toHref({ compIds })
				: routes.exportPdfSales.toHref({ compIds })
		);
	};

	const onClickCopyIds = ({ compIds }: { compIds: number[] }) => {
		copyToClipboard(compIds.join(','));
		closePopup();
		addNotification({
			id: 'excelCopyIds',
			description: 'IDs copied to Clipboard',
			icon: 'info',
			type: NotificationType.BLUE,
			duration: 2000,
		});
	};

	return {
		onClickCopyIds,
		onClickExportPdf,
		onClickExportExcel,
		isLoadingExportExcel,
		data: exportStatus,
		isFetching: isFetchingExportStatus || isFetchingUser,
		shouldShowIdsButton: isAdmin,
	};
};
