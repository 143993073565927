import { useEffect } from 'react';
import {
	SEARCH_BY_VIEW_ROUTES,
	SearchRouteSearchParams,
	replace,
} from 'router';
import {
	filtersFromServerJSON,
	filtersToQueryString,
} from 'models/filters/util';
import { CompType } from 'types';
import { useMarkets } from 'hooks/useMarkets';
import { Spinner } from '@compstak/ui-kit';
import { mergeFilters } from 'models/filters/util/mergeFilters';
import { RouteComponentProps } from 'router/migration/types';
import { usePropertyByIdQuery, useUserQuery } from 'api';
import { useSavedSearchesQuery } from 'api/savedSearches/useSavedSearchesQuery';
import { useViewSavedSearchMutation } from 'api/savedSearches/useViewSavedSearchMutation';
import { useSavedSearchContext } from 'Components/SavedSearches/SavedSearchProvider';
import dayjs from 'dayjs';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';

type SearchRedirectProps = RouteComponentProps<{}, SearchRouteSearchParams> & {
	route: { compType: CompType };
};

const DEFAULT_VIEW = 'list';

export const SearchRedirectV2 = ({
	route: { compType },
	location,
}: SearchRedirectProps) => {
	const savedSearchId = location.query.savedSearchId;
	const dateCreatedMin = location.query.dateCreatedMin;
	const dateCreatedMax = location.query.dateCreatedMax;
	const propertyId = location.query.propertyId;

	const { data: savedSearches, isFetching: isSavedSearchFetching } =
		useSavedSearchesQuery();
	const { data: user } = useUserQuery();
	const markets = useMarkets();

	const { mutate: viewSavedSearch } = useViewSavedSearchMutation();

	const { setSavedSearch } = useSavedSearchContext();

	const { data: propertyData, isFetching: isPropertyFetching } =
		usePropertyByIdQuery(
			{ id: propertyId },
			{
				enabled: !!propertyId,
			}
		);

	useEffect(() => {
		if (isSavedSearchFetching || isPropertyFetching) return;

		const savedSearch = savedSearches?.find(({ id }) => id === savedSearchId);

		const isShowingNewComps = !!(dateCreatedMin || dateCreatedMax);

		if (savedSearch) {
			setSavedSearch(savedSearch);

			let filters = filtersFromServerJSON(
				compType,
				markets,
				savedSearch.filters
			);

			filters = mergeFilters(filters, {
				...(isShowingNewComps
					? {
							sortDirection: 'desc',
							sortField: 'dateCreated',
						}
					: {
							sortDirection: savedSearch.sortAsc ? 'asc' : 'desc',
							sortField: savedSearch.sortProperty,
						}),
				...(isShowingNewComps
					? {
							dateCreated: {
								min: dateCreatedMin ? dayjs(dateCreatedMin).toDate() : null,
								max: dateCreatedMax ? dayjs(dateCreatedMax).toDate() : null,
							},
						}
					: {}),
				...(propertyData
					? { address: [propertyData.buildingAddressAndCity] }
					: {}),
				markets: getFiltersMarkets(filters),
			});
			const queryString = filtersToQueryString(filters);

			viewSavedSearch({ id: savedSearch.id });

			const compTypeToRedirectTo =
				isShowingNewComps && compType === 'property' ? 'lease' : compType;

			replace(
				`${SEARCH_BY_VIEW_ROUTES[compTypeToRedirectTo].toHref({ view: DEFAULT_VIEW })}?${queryString}`
			);

			return;
		}

		replace(
			`${SEARCH_BY_VIEW_ROUTES[compType].toHref({ view: DEFAULT_VIEW })}${location.search}`
		);
	}, [
		savedSearchId,
		dateCreatedMin,
		dateCreatedMax,
		savedSearches,
		user,
		location.pathname,
		location.search,
		compType,
		markets,
		viewSavedSearch,
		setSavedSearch,
		propertyData,
		propertyId,
		isPropertyFetching,
		isSavedSearchFetching,
	]);

	return <Spinner size="xl" isCentered />;
};
