import {
	Combobox,
	ComboboxItem,
	ComboboxPopover,
	ComboboxProvider,
} from '@ariakit/react';
import { withErrorBoundaryDefault } from 'Components/ErrorBoundary';
import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { inputCss } from './Ui';
import { StrFormOption } from './UploadFormCommon';

export const SelectOrInputText = withErrorBoundaryDefault(
	SelectOrInputTextUnsafe
);
SelectOrInputText.displayName = 'SelectOrInputText';

function SelectOrInputTextUnsafe({
	options,
	name,
	value,
	onChange,
	onSearchBlur,
	placeholder = '',
}: {
	value: string;
	name: string;
	options: StrFormOption[];
	onChange: (value: string) => void;
	onSearchBlur?: (value: string) => void;
	placeholder?: string;
}) {
	const [search, setSearch] = useState('');
	const [isOpen, setIsOpen] = useState(false);

	const filteredOptions = useMemo(() => {
		return options.filter((option) => {
			return option.label.toLowerCase().includes(search.toLowerCase());
		});
	}, [search, options]);

	return (
		<ComboboxProvider
			value={value}
			setValue={onChange}
			open={isOpen}
			setOpen={(open) => {
				setIsOpen(open);
				if (!open) {
					setSearch('');
				}
			}}
			virtualFocus
		>
			{isOpen ? (
				<Input
					name={name}
					value={search}
					onChange={(e) => {
						e.preventDefault();
						setSearch(e.target.value);
					}}
					onBlur={(e) => {
						if (onSearchBlur) {
							onSearchBlur(e.target.value);
							return;
						}

						const matchedOption = options.find(
							(option) => option.value === e.target.value
						);

						if (matchedOption) {
							onChange(matchedOption.value);
						}
					}}
					placeholder={value ?? placeholder}
					autoFocus
				/>
			) : (
				<SelectedValue
					onClick={() => setIsOpen(true)}
					onFocus={() => setIsOpen(true)}
					placeholder={placeholder}
					defaultValue={value}
				/>
			)}
			<Dropdown sameWidth>
				{filteredOptions.map((option) => {
					return (
						<Item key={option.value} value={option.value}>
							{option.label}
						</Item>
					);
				})}
				{filteredOptions.length === 0 && (
					<NoOptions>No options found.</NoOptions>
				)}
			</Dropdown>
		</ComboboxProvider>
	);
}

const Input = styled(Combobox)`
	${inputCss}
`;

const SelectedValue = styled.input`
	${inputCss};
`;

const Dropdown = styled(ComboboxPopover)`
	display: block;
	position: absolute;
	background-color: white;
	border: 1px solid #ccc;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
	z-index: 1;
	width: 100%;
	max-height: 160px;
	overflow-y: auto;
	list-style: none;
	padding: 4px;
`;

const Item = styled(ComboboxItem)`
	padding: 0.5rem;
	cursor: pointer;

	&:hover {
		background-color: ${(p) => p.theme.colors.neutral.n20};
	}

	&[data-active-item] {
		background-color: ${(p) => p.theme.colors.neutral.n20};
	}
`;

const NoOptions = styled.div`
	padding: 0.5rem;
`;
