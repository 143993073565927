import { API } from '@compstak/common';
import {
	useInfiniteQuery,
	UseInfiniteQueryOptions,
} from '@tanstack/react-query';
import { QUERY_KEYS } from '../constants';
import {
	TopBuyersResponse,
	UseTopBuyersParams,
	UseTopBuyersPayload,
} from './useTopBuyersQuery';
import { filtersToServerJSON } from 'models/filters/util';

export const TOP_BUYERS_DEFAULT_PAGE_SIZE = 25;

export const useTopBuyersInfiniteQuery = (
	params: UseTopBuyersParams,
	options?: UseInfiniteQueryOptions<
		TopBuyersResponse,
		unknown,
		TopBuyersResponse
	>
) => {
	const { filters, limit = TOP_BUYERS_DEFAULT_PAGE_SIZE } = params;
	const filter = filtersToServerJSON(filters);

	return useInfiniteQuery({
		queryKey: [QUERY_KEYS.TOP_BUYERS_INFINITE, filter, limit],
		queryFn: async ({ pageParam = 0, signal }) => {
			const response = await API.post<TopBuyersResponse, UseTopBuyersPayload>(
				'/api/salesComps/topBuyers',
				{
					filter,
					offset: pageParam * limit,
					limit,
				},
				{ signal }
			);

			return response.data;
		},
		getNextPageParam: (lastPage, pages) => {
			const hasMore = lastPage.buyers?.length === limit;
			if (!hasMore) return;
			return pages.length;
		},
		...options,
	});
};
