import { Flex, defaultTheme } from '@compstak/ui-kit';
import { useLeaseHistory } from 'api/leaseHistory/useLeaseHistoryQuery';
import { formatDate } from 'format';
import { useMemo } from 'react';
import { routes } from 'router';
import styled from 'styled-components';
import { RenewalHistoryItem, RenewalHistoryProps } from './RenewalHistoryItem';

type LeaseHistoryProps = {
	leaseId: number;
};

export const LeaseHistory = ({ leaseId }: LeaseHistoryProps) => {
	const { data } = useLeaseHistory({
		leaseId: leaseId,
	});

	const { renewalHistory, relocationHistory, renewalSuccessor } = data ?? {};

	const historyItems = useMemo(() => {
		const parseDateStr = (dateString: string) =>
			`Signed on ${formatDate(dateString, 'MMM, YYYY')}`;
		return [
			relocationHistory && {
				title: 'Relocation from',
				link: routes.propertyById.toHref({
					propertyId: relocationHistory.propertyId,
				}),
				text: relocationHistory.address,
			},
			renewalHistory && {
				title: 'Renewal of Prior Lease',
				link: routes.leaseById.toHref({ id: renewalHistory.leaseId }),
				text: parseDateStr(renewalHistory.transactionDate),
			},
			renewalSuccessor && {
				title: 'Renewal of this Lease',
				link: routes.leaseById.toHref({ id: renewalSuccessor.leaseId }),
				text: parseDateStr(renewalSuccessor.transactionDate),
			},
		].filter((item): item is RenewalHistoryProps => Boolean(item));
	}, [relocationHistory, renewalHistory, renewalSuccessor]);

	if (historyItems.length === 0) {
		return null;
	}

	return (
		<Wrapper>
			<LabelStyled>Lease History</LabelStyled>
			<Flex direction="column" gap="6px" alignItems="flex-end">
				{historyItems.map(({ link, text, title }, index) => (
					<RenewalHistoryItem
						key={index}
						link={link}
						text={text}
						title={title}
					/>
				))}
			</Flex>
		</Wrapper>
	);
};

const Wrapper = styled.li`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	gap: 20px;
	padding: 0.31rem 0 0.375rem;
	border-bottom: 1px solid ${defaultTheme.colors.gray.gray300};
`;

const LabelStyled = styled.div`
	display: inline-block;
	vertical-align: top;
	width: 40%;
	color: ${defaultTheme.colors.neutral.n85};
	white-space: nowrap;
	font-weight: 400;
`;
