import { ColumnPreference } from '@compstak/common';
import { LOAN_SECTION, MUFA_SECTION, SearchTable } from 'Components';
import { ColumnConfig } from 'Components/SearchTable/columnConfigs';
import { TableSection } from 'Components/SearchTable/types';
import { routes } from 'router';
import { ExportCompsButton } from 'Components/Export';
import { usePortfolioProperties } from 'PortfolioAnalytics/Properties/PortfolioPropertiesProvider';

export const PortfolioTable = () => {
	const {
		data: rows,
		isFetching,
		hasNextPage,
		fetchNextPage,
		selectionState: {
			areSomeSelected,
			getAreAllSelected,
			getIsRowSelected,
			toggleAllSelected,
			toggleRow,
		},
	} = usePortfolioProperties();

	return (
		<SearchTable
			rows={rows}
			getRowId={(row) => row.id}
			noData={!isFetching && rows.length === 0}
			columnPreferencesType="property"
			getIsRowSelected={(row) => getIsRowSelected(row.id)}
			areAllSelected={getAreAllSelected()}
			areSomeSelected={areSomeSelected}
			toggleRowSelection={(row) => toggleRow(row.id)}
			toggleAllRowsSelection={toggleAllSelected}
			onLoadMore={() => {
				if (hasNextPage && !isFetching) {
					fetchNextPage();
				}
			}}
			renderActionButton={(row) => (
				<ExportCompsButton
					compType="property"
					compIds={[row.id]}
					buttonType="wide-row"
				/>
			)}
			getBodyCellProps={(row) => ({
				to: routes.propertyById.toHref({ propertyId: row.id }),
			})}
			filterColumnSections={(sections: TableSection[]) => {
				return sections.filter(
					(section) => !['loanInfo', 'mufa'].includes(section.id)
				);
			}}
			filterTableColumns={(columns: ColumnPreference[]) => {
				return columns.filter(
					(column) =>
						![...MUFA_SECTION.ids, ...LOAN_SECTION.ids].includes(
							column.compAttributeId as keyof ColumnConfig
						)
				);
			}}
		/>
	);
};
