import { Button } from '@compstak/ui-kit';
import styled from 'styled-components';

type Props = {
	onClick: () => void;
	className?: string;
};

export const SuggestUpdateButton = ({ onClick, className }: Props) => {
	return (
		<SuggestUpdateButtonContainer
			variant={'secondary2'}
			onClick={onClick}
			className={className}
		>
			Suggest an update
		</SuggestUpdateButtonContainer>
	);
};

export const SuggestUpdateButtonContainer = styled(Button)`
	width: 15.625rem;
	font-size: 0.625rem;
	font-weight: 400;
	&:not(:disabled) {
		&:hover {
			background-color: ${({ theme }) => theme.colors.blue.blue500};
			color: ${({ theme }) => theme.colors.white.white};
		}
	}
`;
