import { Styled } from 'PortfolioAnalytics/Charts/PortfolioTrend/Chart/styled';
import { colors } from '@compstak/ui-kit';
import { formatPercent } from 'format';
import { VictoryTooltipProps } from 'victory';

interface ActivePoint {
	name: string;
	y: number;
	style?: {
		data?: {
			fill?: string;
		};
	};
}

interface TooltipProps extends VictoryTooltipProps {
	activePoints?: ActivePoint[];
}

const isValidActivePoint = (point: unknown): point is ActivePoint => {
	if (!point || typeof point !== 'object') return false;
	return (
		'name' in point &&
		typeof point.name === 'string' &&
		'y' in point &&
		typeof point.y === 'number' &&
		!isNaN(point.y)
	);
};

export const TenantIndustryTooltip = (props: TooltipProps) => {
	const { x = 0, y = 0, width = 220, height = 20, activePoints = [] } = props;
	const CUTOFF_INDUSTRY_NAME_LENGTH = 30;
	const Y_OFFSET_ADJUSTMENT = 40;
	const offsetY = Math.max(y - Y_OFFSET_ADJUSTMENT, 0);
	const offsetX = Math.max(x - width, 0);
	const tooltipHeight = height * activePoints.length;
	if (!activePoints?.length) {
		return null;
	}
	return (
		<g transform={`translate(${offsetX}, ${offsetY})`}>
			<foreignObject width={width} height={tooltipHeight}>
				<Styled.CustomTooltipContainer>
					<Styled.CustomTooltipInfoBox height={tooltipHeight}>
						{activePoints.map((activePoint, index) => {
							if (!isValidActivePoint(activePoint)) return null;
							const { name, y: value } = activePoint;
							const fill = activePoint.style?.data?.fill ?? colors.gray.gray300;
							const shortenedIndustryName = `${name.slice(0, CUTOFF_INDUSTRY_NAME_LENGTH)}${name.length > CUTOFF_INDUSTRY_NAME_LENGTH ? '...' : ''}`;
							const formattedText = `${shortenedIndustryName} - ${formatPercent(value / 100)}`;
							const colorIndicatorTopPosition = 10 + height * index;
							return (
								<Styled.CustomTooltipInfoBoxData key={index}>
									<div>
										<Styled.CustomTooltipInfoBoxIndicatorLayer
											color={fill}
											top={colorIndicatorTopPosition}
											zIndex={2}
										/>
										<Styled.CustomTooltipInfoBoxIndicatorLayer
											color={colors.white.white}
											top={colorIndicatorTopPosition}
											zIndex={1}
										/>
									</div>
									<div>
										<span style={{ fontSize: 8 }}>{formattedText}</span>
									</div>
								</Styled.CustomTooltipInfoBoxData>
							);
						})}
					</Styled.CustomTooltipInfoBox>
				</Styled.CustomTooltipContainer>
			</foreignObject>
		</g>
	);
};
