import { UseQueryOptions, useQueries, useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from 'api/constants';
import { getQueryStringFromPortfolioFilters } from 'util/getQueryStringsFromPortfolioFilter';
import { API } from '@compstak/common';
import { FiltersState } from 'PortfolioAnalytics/PortfolioFiltersProvider';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

const getPortfolioPerformance = async (
	portfolioId: number,
	queryParams: string
): Promise<PortfolioPerformanceResponse> => {
	const response = await API.get<PortfolioPerformanceResponse>(
		`/pa/v2/api/portfolios/${portfolioId}/chart/performanceMatrix${queryParams}`
	);
	return response.data;
};

export const usePortfolioPerformanceQueries = (
	portfolioIds: number[],
	filters: FiltersState,
	options?: UseQueryOptions<PortfolioPerformanceResponse>
) => {
	const { portfolioExcludeSubleasesFF } = useFeatureFlags();
	const queryParams = getQueryStringFromPortfolioFilters(filters, {
		excludeSubleases: portfolioExcludeSubleasesFF,
	});

	return useQueries({
		queries: portfolioIds.map((portfolioId) => {
			return {
				queryKey: [QUERY_KEYS.PORTFOLIO_PERFORMANCE, portfolioId, queryParams],
				queryFn: () => getPortfolioPerformance(portfolioId, queryParams),
				...options,
			};
		}),
	});
};

type PortfolioPerformanceResponse = {
	values: PerformanceData[];
	portfolioId: number;
};
export type PerformanceData = {
	propertyId: number;
	risk: number;
	performance: number;
};

export const usePortfolioPerformanceQuery = (
	portfolioId: number,
	filters: FiltersState,
	options?: UseQueryOptions<PortfolioPerformanceResponse>
) => {
	const { portfolioExcludeSubleasesFF } = useFeatureFlags();
	const queryParams = getQueryStringFromPortfolioFilters(filters, {
		excludeSubleases: portfolioExcludeSubleasesFF,
	});

	return useQuery({
		queryKey: [QUERY_KEYS.PORTFOLIO_PERFORMANCE, portfolioId, queryParams],
		queryFn: () => getPortfolioPerformance(portfolioId, queryParams),
		...options,
	});
};
