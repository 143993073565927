import { usePropertyLeasesQuery } from 'api';
import { routes } from 'router';

export const useShouldShowMarketStats = () => {
	const params = routes.propertyById.useParams();

	const { data: leaseData, isLoading: isLoadingLeases } =
		usePropertyLeasesQuery({
			propertyId: params.propertyId,
		});

	return {
		data: !leaseData ? undefined : leaseData.comps.length > 0,
		isLoading: isLoadingLeases,
	};
};
