import { SortDirection } from 'api';
import { LeaseComp, PropertyComp, SalesComp } from 'types';
import { LeaseFilters } from './lease';
import { SaleFilters } from './sales';
import { PropertyFilters } from './property';
import { MufaPropertyFilters, MufaPropertyLevelFilters } from './mufaProperty';
import { Market } from '@compstak/common';
import { MergeTypes } from 'types/utils';

export type FilterNumberInterval = {
	min: number | null;
	max: number | null;
	allowFallback?: boolean;
};
export type FilterStringInterval = {
	min: string | null;
	max: string | null;
	allowFallback?: boolean;
};
export type FilterDateInterval = {
	min: Date | null;
	max: Date | null;
	allowFallback?: boolean;
};
export type FilterBooleanString = 'true' | 'false';
export type FilterMyComps = 'own' | 'dont-own' | 'team';
export type FilterHidden = 'both' | 'hidden';
export type Point = {
	lat: number;
	lng: number;
};
export type PolygonFilter = Point[];
export type MultiPolygonFilter = Point[][];
export type RadiusFilter = {
	center: Point;
	distance: number;
	buildingAddressAndCity?: string;
};

type SubmarketFilterItem = {
	id: number;
	name: string;
};
export type SubmarketsFilter = SubmarketFilterItem[];
export type LoanSourceFilter = Array<'Trepp' | 'Public'>;
export type LoanInterestTypeFilter = 'Fixed' | 'Floating';
export type InterestTypeFilter = 'Partial' | 'Full';
export type APNFilter = {
	fips: string;
	apn: string;
	area?: MultiPolygonFilter;
};

export type CompIdFilter = {
	value: number[];
	exclude?: boolean;
};

export type DataSourceFilter = 'CompStak' | 'Public Record';

export const leasesFilters: LeaseFilters = {
	portfolioId: null,
	polygon: null,
	multiPolygon: null,
	radius: null,
	address: null,
	buildingClassId: null,
	buildingFloorsCount: null,
	buildingName: null,
	buildingPropertyTypeId: null,
	buildingPropertySubtype: null,
	buildingSize: null,
	buildingYearBuilt: null,
	buildingYearRenovated: null,
	dateCreated: null,
	dateCreatedTimestamp: null,
	effectiveRent: null,
	executionDate: null,
	expirationDate: null,
	floors: null,
	landlordNames: null,
	landlordRealtyCompanies: null,
	lastAttributeUpdate: null,
	leaseTerm: null,
	leaseTypeId: null,
	// @ts-expect-error TS2322: Type 'null' is not assignable ...
	market: null,
	// @ts-expect-error TS2322: Type 'null' is not assignable ...
	markets: null,
	owns: null,
	spaceSubtypeId: null,
	spaceTypeId: null,
	startingRent: null,
	sublease: null,
	submarkets: null,
	opportunityZoneId: null,
	tenantNames: null,
	tenantRealtyCompanies: null,
	transactionSize: null,
	transactionTypeId: null,
	tenantIndustry: null,
	insideviewRevenue: null,
	insideviewEmployees: null,
	insideviewOwnershipId: null,
	insideviewTickers: null,
	insideviewStatusId: null,
	partial: null,
	hidden: null,
	city: null,
	zip: null,
	sortField: 'executionQuarter' as keyof LeaseComp,
	sortDirection: 'desc' as SortDirection,
	propertyId: null,
	fips: null,
	apn: null,
	compId: null,
	recencyDate: null,
	hasFullOrLimitedDetails: null,
	hasLimitedDetails: null,
};

export const salesFilters: SaleFilters = {
	portfolioId: null,
	polygon: null,
	multiPolygon: null,
	radius: null,
	address: null,
	buildingClassId: null,
	buildingFloorsCount: null,
	buildingName: null,
	buildingSize: null,
	buildingPropertyTypeId: null,
	buildingPropertySubtype: null,
	buildingYearBuilt: null,
	buildingYearRenovated: null,
	dateCreated: null,
	dateCreatedTimestamp: null,
	propertySqFtExpiringInTwelveMonths: null,
	buyers: null,
	sellers: null,
	lastAttributeUpdate: null,
	interestType: null,
	isPortfolio: null,
	// @ts-expect-error TS2322: Type 'null' is not assignable ...
	market: null,
	// @ts-expect-error TS2322: Type 'null' is not assignable ...
	markets: null,
	owns: null,
	totalSalePrice: null,
	lastSalePrice: null,
	saleDate: null,
	salePricePsf: null,
	pricePerUnit: null,
	pricePerAcre: null,
	submarkets: null,
	opportunityZoneId: null,
	transactionSize: null,
	partial: null,
	hidden: null,
	dataSource: null,
	propertyMarketStartingRent: null,
	propertyMarketEffectiveRent: null,
	propertyAverageTransactionSize: null,
	city: null,
	zip: null,
	sortField: 'dataSource' as keyof SalesComp,
	sortDirection: 'asc' as SortDirection,
	propertyId: null,
	fips: null,
	apn: null,
};

// filters unique to mufa properties only
export const mufaLevelFilters: MufaPropertyLevelFilters = {
	propertyStatus: null,
	totalUnits: null,
	occupancy: null,
	askingRpsf: null,
	effectiveRpsf: null,
	assetClassMarket: null,
	managementCompany: null,
};

export const propertiesFilters: PropertyFilters = {
	portfolioId: null,
	polygon: null,
	multiPolygon: null,
	radius: null,
	address: null,
	buildingClassId: null,
	buildingName: null,
	buildingSize: null,
	buildingPropertyTypeId: null,
	buildingPropertySubtype: null,
	buildingYearBuilt: null,
	buildingYearRenovated: null,
	isPortfolio: null,
	// @ts-expect-error TS2322: Type 'null' is not assignable ...
	market: null,
	// @ts-expect-error TS2322: Type 'null' is not assignable ...
	markets: null,
	buildingFloorsCount: null,
	propertySqFtExpiringInTwelveMonths: null,
	owns: null,
	totalSalePrice: null,
	lastSalePrice: null,
	saleDate: null,
	salePricePsf: null,
	submarkets: null,
	opportunityZoneId: null,
	transactionSize: null,
	partial: null,
	dataSource: null,
	propertyMarketStartingRent: null,
	propertyMarketEffectiveRent: null,
	propertyAverageTransactionSize: null,
	city: null,
	zip: null,
	landlordNames: null,
	sortField: 'buildingSize' as keyof PropertyComp,
	sortDirection: 'desc' as SortDirection,
	propertyId: null,
	fips: null,
	apn: null,
	occupancyPercentage: null,

	// Trepp loan filters
	loanSource: null,
	loanOriginators: null,
	loanAmount: null,
	loanInterestType: null,
	currentLoanRate: null,
	loanOriginationDate: null,
	loanMaturityDate: null,
	loanToValue: null,

	// MuFa fields only (initialized in order not to conditionally create & use `mufaFilters` explicitly)
	...mufaLevelFilters,
};

// Relevant ticket: https://compstak.atlassian.net/browse/AP-8167
export const mufaFilters: MufaPropertyFilters = {
	...propertiesFilters,
	// MuFa endpoints expect id, not propertyId
	id: propertiesFilters.propertyId,
};

export type FiltersObject = MergeTypes<
	MergeTypes<MergeTypes<LeaseFilters, SaleFilters>, PropertyFilters>,
	MufaPropertyFilters
>;

// all possible filter keys
export type FiltersTypesKeys = keyof FiltersObject;

export type FiltersValues = FiltersObject[FiltersTypesKeys];

export const allFilters: FiltersObject = Object.assign(
	{},
	leasesFilters,
	salesFilters,
	propertiesFilters,
	mufaFilters
);

// filters keys which values = a specific type

export type MarketFiltersKeys = FiltersKeysByValueType<Market>;

export type MarketsFiltersKeys = FiltersKeysByValueType<Market[]>;

export type SubmarketsFilterKeys = Exclude<
	FiltersKeysByValueType<SubmarketsFilter>,
	'markets'
>;

export type NumberIntervalFiltersKeys =
	FiltersKeysByValueType<FilterNumberInterval>;

export type DateIntervalFiltersKeys =
	FiltersKeysByValueType<FilterDateInterval>;

export type StringIntervalFiltersKeys =
	FiltersKeysByValueType<FilterStringInterval>;

export type IntervalFiltersKeys =
	| NumberIntervalFiltersKeys
	| DateIntervalFiltersKeys
	| StringIntervalFiltersKeys;

export type RadiusFiltersKeys = FiltersKeysByValueType<RadiusFilter>;

export type PolygonFiltersKeys = FiltersKeysByValueType<PolygonFilter>;

export type MultiPolygonFiltersKeys =
	FiltersKeysByValueType<MultiPolygonFilter>;

export type NumbersFiltersKeys = FiltersKeysByValueType<number[]>;

export type StringsFiltersKeys = FiltersKeysByValueType<string[]>;

export type NumberFiltersKeys = FiltersKeysByValueType<number>;

export type StringFiltersKeys = FiltersKeysByValueType<string>;

export type BooleanFiltersKeys = FiltersKeysByValueType<boolean>;

export type BooleanStringFiltersKeys =
	FiltersKeysByValueType<FilterBooleanString>;

export type DateTimestampFiltersKeys = 'dateCreatedTimestamp';

export type HiddenFiltersKeys = FiltersKeysByValueType<FilterHidden>;

export type MapFiltersKeys =
	| RadiusFiltersKeys
	| PolygonFiltersKeys
	| SubmarketsFilterKeys
	| Extract<FiltersTypesKeys, 'opportunityZoneId'>;

type FiltersKeysByValueType<T> = {
	[K in FiltersTypesKeys]: NonNullable<FiltersObject[K]> extends T ? K : never;
}[FiltersTypesKeys];
