import { memo } from 'react';
import { TooltipV2 } from '@compstak/ui-kit';
import { mapValues } from 'lodash';
import { nullValueText } from '@compstak/common';
import { getRentPeriodPostfix, formatRentWithPsfPostfix } from 'format';
import { PropertyTypeName } from 'api';
import { useLeaseContext } from '../LeasesTab/LeaseProvider';
import { AuxText } from './PropertyLeaseSnapshot';
import IconThreeDot from 'ui/svg_icons/three-dot.svg';
import styled from 'styled-components';
import { BlueBoxLabel, BlueBoxValue } from '../UI';
import { HelpTooltip } from '../HelpTooltip';
import { IconLockGrey } from 'ui/UI';

type Props = {
	isMonthlyMarket: boolean;
	propertyType?: PropertyTypeName;
	spaceTypeRents: {
		spaceType: string;
		weightedAvgCurrentRent?: number | null;
	}[];
};
export const AverageInPlaceRents = memo(
	({ isMonthlyMarket, propertyType, spaceTypeRents }: Props) => {
		const { leasesGroupedBySpaceType } = useLeaseContext();

		const formattedSpaceTypeRents: Record<string, any> = {};
		spaceTypeRents?.forEach(({ spaceType, weightedAvgCurrentRent }) => {
			formattedSpaceTypeRents[spaceType] =
				weightedAvgCurrentRent === null
					? weightedAvgCurrentRent
					: formatRentWithPsfPostfix(isMonthlyMarket, weightedAvgCurrentRent);
		});

		const leasesBySpaceTypesLengths = mapValues(
			leasesGroupedBySpaceType,
			(leasesBySpaceType) => leasesBySpaceType?.length ?? 0
		);
		const sortedLeasesBySpaceTypesKeys = Object.entries(
			leasesBySpaceTypesLengths
		)
			.sort((l1, l2) => l2[1] - l1[1])
			.map((l) => l[0]);

		const formattedSpaceTypeKeysLength = Object.keys(
			formattedSpaceTypeRents
		).length;

		const secondarySpaceTypesKeys = propertyType
			? sortedLeasesBySpaceTypesKeys.filter((key) => key !== propertyType)
			: sortedLeasesBySpaceTypesKeys;

		const shouldShowMore = formattedSpaceTypeKeysLength > RENT_DISPLAY_NUMBER;

		return (
			<div>
				<BlueBoxLabel>
					Average In-Place Rent {getRentPeriodPostfix(isMonthlyMarket)}{' '}
					<HelpTooltip text="This average is calculated by weighting the rent of each lease by its square footage, providing a more accurate reflection of rental values. Additionally, rents are broken out by space type (Office, Industrial, Flex R&D, Retail, Other, Land) for more detail." />
				</BlueBoxLabel>
				<div>
					{formattedSpaceTypeKeysLength > 0 ? (
						<RentsList>
							{propertyType &&
								formattedSpaceTypeRents[propertyType] !== undefined && (
									<div>
										•{' '}
										{formattedSpaceTypeRents[propertyType] ?? (
											<StyledIconLockGrey />
										)}{' '}
										<AuxText>({propertyType})</AuxText>
									</div>
								)}
							{secondarySpaceTypesKeys.map((key) =>
								formattedSpaceTypeRents[key] !== undefined ? (
									<div key={key}>
										• {formattedSpaceTypeRents[key] ?? <StyledIconLockGrey />}{' '}
										<AuxText>({key})</AuxText>
									</div>
								) : (
									<></>
								)
							)}
						</RentsList>
					) : (
						<BlueBoxValue>{nullValueText}</BlueBoxValue>
					)}
					{shouldShowMore && (
						<TooltipV2
							side="top"
							content={
								<ul style={{ listStyle: 'inside' }}>
									{propertyType &&
										formattedSpaceTypeRents[propertyType] !== undefined && (
											<li>
												{propertyType} -{' '}
												{formattedSpaceTypeRents[propertyType] ?? (
													<StyledIconLockGrey />
												)}
											</li>
										)}
									{secondarySpaceTypesKeys.map((key) =>
										formattedSpaceTypeRents[key] !== undefined ? (
											<li key={key}>
												{key} -{' '}
												{formattedSpaceTypeRents[key] ?? <StyledIconLockGrey />}
											</li>
										) : (
											<></>
										)
									)}
								</ul>
							}
						>
							<ShowMoreContainer>
								<IconThreeDot />
								SHOW MORE
							</ShowMoreContainer>
						</TooltipV2>
					)}
				</div>
			</div>
		);
	}
);

AverageInPlaceRents.displayName = 'AverageInPlaceRents';

const RENT_DISPLAY_NUMBER = 4;

const RentsList = styled.div`
	padding-left: 0.5rem;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 0.5rem;
	div {
		color: ${(p) => p.theme.colors.gray.gray700};
		font-size: 0.875rem;
		font-weight: 500;
		white-space: nowrap;
	}
`;

const ShowMoreContainer = styled.div`
	width: fit-content;
	display: flex;
	align-items: center;
	margin-top: 0.5rem;

	color: ${(p) => p.theme.colors.blue.blue500};
	font-weight: 400;
	font-size: 0.75rem;

	svg {
		path {
			fill: ${(p) => p.theme.colors.blue.blue500};
		}
		width: 10px;
		height: 10px;
		transform: rotate(90deg);
		margin-right: 4px;
	}

	&:hover {
		cursor: pointer;
	}
`;

const StyledIconLockGrey = styled(IconLockGrey)`
	display: inline-block;
	margin: 0 1rem;
`;
