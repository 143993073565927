import { UseQueryOptions, useQueries, useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from 'api/constants';
import { getQueryStringFromPortfolioFilters } from 'util/getQueryStringsFromPortfolioFilter';
import { API } from '@compstak/common';
import { ChartTopParams, ChartTopResponse } from './types';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

export type ChartTopResponseWithPortfolioId = ChartTopResponse & {
	portfolioId: number;
};

const chartTopQueryFn = async (
	id: number,
	params: ChartTopParams,
	extraQueryParams?: Record<string | number, any>
): Promise<ChartTopResponseWithPortfolioId> => {
	const { filters, ...extraParams } = params;
	const queryParams = getQueryStringFromPortfolioFilters(filters, {
		...extraParams,
		...extraQueryParams,
	});

	const response = await API.get<ChartTopResponse>(
		`/pa/v2/api/portfolios/${id}/chart/top${queryParams}`
	);

	return { portfolioId: id, ...response.data };
};

export const useChartTopQueries = (
	ids: number[],
	params: ChartTopParams,
	options?: UseQueryOptions<ChartTopResponse>
) => {
	const { portfolioExcludeSubleasesFF } = useFeatureFlags();

	return useQueries({
		queries: ids.map((id) => {
			return {
				queryKey: [QUERY_KEYS.CHART_TOP, id, params],
				queryFn: () =>
					chartTopQueryFn(id, params, {
						excludeSubleases: portfolioExcludeSubleasesFF,
					}),
				...options,
			};
		}),
	});
};

export const useChartTopQuery = (
	id: number,
	params: ChartTopParams,
	options?: UseQueryOptions<ChartTopResponse>
) => {
	const { portfolioExcludeSubleasesFF } = useFeatureFlags();

	return useQuery({
		queryKey: [QUERY_KEYS.CHART_TOP, id, params],
		queryFn: () =>
			chartTopQueryFn(id, params, {
				excludeSubleases: portfolioExcludeSubleasesFF,
			}),
		...options,
	});
};
