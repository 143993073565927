import { ChartBox } from 'PortfolioAnalytics/Charts/ChartSelect';
import { UseQueryResult } from '@tanstack/react-query';
import { ChartLegendV2, LegendData } from '../ChartLegend';
import { TenantIndustryTooltip } from './TenantIndustryTooltip';

import {
	VictoryAxis,
	VictoryBar,
	VictoryChart,
	VictoryGroup,
	VictoryLabelProps,
	VictoryStack,
	VictoryVoronoiContainer,
	VictoryTooltip,
} from 'victory';

import {
	BENCHMARKING_COLORS,
	BENCHMARKING_SELECT_PORTFOLIOS_MESSAGE,
} from '../../constants';
import { VICTORY_CONTAINER_STYLE_800 } from 'PortfolioAnalytics/Charts/chartsConstants';
import { usePortfoliosByIdsQueriesV2 } from 'api/portfolio/portfolioById/usePortfoliosByIdsQueriesV2';
import {
	useChartTopQueries,
	ChartTopResponseWithPortfolioId,
} from 'api/portfolio/charts/leases/useChartTopQueries';
import { useMemo } from 'react';
import { ChartOption } from 'PortfolioAnalytics/Charts/ChartSelect';
import { usePortfolioFilters } from 'PortfolioAnalytics/PortfolioFiltersProvider';
import { usePortfoliosSelection } from 'PortfolioAnalytics/hooks/usePortfoliosSelection';
import { ChartStateWrapper } from 'PortfolioAnalytics/Charts/ChartStateWrapper';
import { colors } from '@compstak/ui-kit';
import { portfolioNameById } from '../../utils';

const CHART_NAME = 'Tenant Industry Distribution';
const CUTOFF_BAR_LENGTH_TO_DISPLAY_VALUE = 11;
const CUTOFF_BAR_LENGTH_TO_DISPLAY_NAME = 36;
const CUTOFF_NAME_LENGTH = 12;

export const BenchmarkingTenantIndustryChartV2 = () => {
	return (
		<ChartBox chartName={CHART_NAME}>
			{(selectedChart) => (
				<BenchmarkingTenantIndustryChartContentV2
					selectedChart={selectedChart}
				/>
			)}
		</ChartBox>
	);
};

const getGradientColor = (baseColor: string, index: number) => {
	switch (index) {
		case 0:
			return baseColor;
		case 1:
			return `${baseColor}cc`;
		case 2:
			return `${baseColor}99`;
		case 3:
			return `${baseColor}66`;
		case 4:
			return `${baseColor}33`;
		default:
			return baseColor;
	}
};

const industryNameStyle = {
	fill: colors.white.white,
	textShadow:
		'0px 3px 5px rgba(24, 25, 26, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31)',
	fontSize: '0.7em',
	fontWeight: 300,
	fontFamily: 'Gotham, sans-serif',
};

const valueStyle = {
	fill: colors.white.white,
	fontSize: '0.7em',
	fontWeight: 500,
	fontFamily: 'Gotham, sans-serif',
};

const CustomLabel: React.FC<VictoryLabelProps> = (props) => {
	const { x, y, datum } = props;
	const name = datum?.name || '';
	const value = datum?.y || 0;
	const industryNameXOffset = value === 100 ? -48 : -40;
	const shortenedName =
		value > CUTOFF_BAR_LENGTH_TO_DISPLAY_NAME
			? `${name.slice(0, CUTOFF_NAME_LENGTH)}${name.length > CUTOFF_NAME_LENGTH ? '...' : ''}`
			: '';
	const valueString =
		value > CUTOFF_BAR_LENGTH_TO_DISPLAY_VALUE ? `${value.toFixed(1)}%` : '';

	return (
		<g>
			<text
				x={x}
				y={y}
				dy={5}
				dx={industryNameXOffset}
				textAnchor="end"
				style={industryNameStyle}
			>
				{shortenedName}
			</text>
			<text x={x} y={y} dy={5} dx={-3} textAnchor="end" style={valueStyle}>
				{valueString}
			</text>
		</g>
	);
};

const BenchmarkingTenantIndustryChartContentV2 = ({
	selectedChart,
}: {
	selectedChart: ChartOption<'lease'>;
}) => {
	const { filters } = usePortfolioFilters();
	const { selectedPortfolioIds } = usePortfoliosSelection();
	const portfoliosByIdParams = selectedPortfolioIds.map((portfolioId) => ({
		id: portfolioId,
		filters,
	}));
	const portfoliosResponse = usePortfoliosByIdsQueriesV2(portfoliosByIdParams);
	const isFetchingPortfolios = portfoliosResponse.some(
		(portfolioResponse) => portfolioResponse.isFetching
	);
	const isErrorPortfolios = portfoliosResponse.some(
		(portfolioResponse) => portfolioResponse.isError
	);

	const tenantIndustryResponse = useChartTopQueries(selectedPortfolioIds, {
		filters,
		chartGrouping: 'tenantIndustry',
		chartMetric:
			selectedChart.type === 'sqft' ? 'transactionSize' : selectedChart.type,
		chartLimit: 5,
	});

	const isFetchingTenantIndustry = tenantIndustryResponse.some(
		(tenantIndustryData) => tenantIndustryData.isFetching
	);

	const isErrorTenantIndustry = tenantIndustryResponse.some(
		(tenantIndustryData) => tenantIndustryData.isError
	);

	const isFetching = isFetchingPortfolios || isFetchingTenantIndustry;
	const isError = isErrorPortfolios || isErrorTenantIndustry;

	const tenantIndustryData = tenantIndustryResponse
		.filter(
			(
				portfolioData
			): portfolioData is UseQueryResult<ChartTopResponseWithPortfolioId> & {
				data: ChartTopResponseWithPortfolioId;
			} => portfolioData?.data !== null && portfolioData?.data !== undefined
		)
		.map((portfolio) => portfolio.data);

	const chartData = tenantIndustryData.map((portfolioData) => {
		return portfolioData.values.map((value) => {
			return {
				x: value.label,
				y: value.value,
				portfolioId: portfolioData.portfolioId,
			};
		});
	});

	const { chartDataWithColor, totalValues } = useMemo(() => {
		const totalValues: Record<number, number> = {};
		return {
			chartDataWithColor: chartData
				.map((portfolio, portfolioIndex) => {
					const baseColor = BENCHMARKING_COLORS[portfolioIndex];
					let totalValue = 0;
					return portfolio.map((industry, industryIndex) => {
						totalValue += industry.y;
						totalValues[industry.portfolioId] = totalValue;
						const industryColor = getGradientColor(baseColor, industryIndex);
						return { ...industry, color: industryColor };
					});
				})
				.filter((portfolio) => portfolio && portfolio.length > 0),
			totalValues,
		};
	}, [chartData]);

	const chartHeight = chartDataWithColor.length * 30 + 80;

	const allPortfoliosEmpty = useMemo(
		() => selectedPortfolioIds.length > 0 && chartDataWithColor.length === 0,
		[selectedPortfolioIds, chartDataWithColor]
	);

	const noData =
		!isFetching && (selectedPortfolioIds.length === 0 || allPortfoliosEmpty);

	const legendData: LegendData[] = useMemo(() => {
		return selectedPortfolioIds.map((_portfolioId, index) => {
			const portfolioData = tenantIndustryData[index];
			return {
				hasData: Boolean(
					portfolioData?.values && portfolioData.values.length > 0
				),
				name: portfolioNameById(portfoliosResponse, index),
			};
		});
	}, [tenantIndustryData, selectedPortfolioIds, portfoliosResponse]);

	return (
		<ChartStateWrapper
			isError={isError}
			isFetching={isFetching}
			noData={noData}
			showNoDataMessage={!allPortfoliosEmpty}
			noDataMessage={BENCHMARKING_SELECT_PORTFOLIOS_MESSAGE}
		>
			<VictoryChart
				domainPadding={5}
				height={chartHeight}
				containerComponent={
					<VictoryVoronoiContainer
						id="benchmarking-tenant-industry-chart-voronoi-container"
						constrainToVisibleArea={false}
						style={{ height: '100%', ...VICTORY_CONTAINER_STYLE_800 }}
						voronoiDimension="x"
						labels={() => ' '}
						labelComponent={
							<VictoryTooltip
								flyoutComponent={
									<TenantIndustryTooltip width={220} height={20} />
								}
							/>
						}
					/>
				}
			>
				<VictoryAxis
					style={{
						tickLabels: { fontSize: 12 },
						axis: { stroke: 'transparent' },
					}}
					tickFormat={() => ''}
				/>
				<VictoryGroup horizontal>
					{chartDataWithColor
						.slice()
						.reverse()
						.map((portfolio, index) => (
							<VictoryStack key={index}>
								{portfolio.map((industry, industryIndex) => {
									return (
										<VictoryBar
											barWidth={30}
											data={[
												{
													x: `${index + 1}`,
													y:
														totalValues[industry.portfolioId] > 0
															? (100 * industry.y) /
																totalValues[industry.portfolioId]
															: 0,
													name: industry.x,
												},
											]}
											key={industryIndex}
											labels={() => ''}
											labelComponent={<CustomLabel />}
											style={{
												data: { fill: `${industry.color}` },
											}}
										/>
									);
								})}
							</VictoryStack>
						))}
				</VictoryGroup>
			</VictoryChart>
			<ChartLegendV2
				portfoliosLegendData={legendData}
				colors={BENCHMARKING_COLORS}
			/>
		</ChartStateWrapper>
	);
};
