import { PortfolioPropertiesRemovalModal } from 'PortfolioAnalytics/modals/PortfolioPropertiesRemovalModal';
import { PortfolioV2 } from 'api';
import { PopupActionButton, StyledArrowRightIcon } from 'maps';
import { useModal } from 'providers/ModalProvider';
import styled from 'styled-components';
import { PropertyComp } from 'types';

type Props = {
	property: PropertyComp;
	portfolio: PortfolioV2;
};

export const RemovePropertyPopupButton = ({ property, portfolio }: Props) => {
	const { openModal, closeModal } = useModal();

	return (
		<PopupActionButton
			variant="secondary"
			onClick={() =>
				openModal({
					modalContent: (
						<PortfolioPropertiesRemovalModal
							portfolio={portfolio}
							onClose={closeModal}
							removedPropertyIds={[property.id]}
						/>
					),
				})
			}
		>
			<RemoveIcon>
				<span>&mdash;</span>
			</RemoveIcon>
			<span>Remove From Portfolio</span>
			<StyledArrowRightIcon />
		</PopupActionButton>
	);
};

const RemoveIcon = styled.span`
	min-width: 1rem;
`;
