import { Button as OriginalButton } from '@compstak/ui-kit';
import styled from 'styled-components';

export const MapAnalyticsMenuPositionContainer = styled.div`
	position: absolute;
	left: 1.25rem;
	bottom: 70px;
	z-index: 3;
`;

export const MapAnalyticsMenuContainer = styled.div`
	font-family: ${(p) => p.theme.typography.fontFamily.gotham};
	width: 250px;
	max-height: calc(100vh - 220px);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-color: #ffffff;
	box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.1);
`;

export const MapAnalyticsForm = styled.form`
	background-color: #f4f6f9;
	border-radius: 3px;
	border: 1px solid #e6e6e6;
	max-height: calc(100vh - 320px);
	padding: 1rem;
	position: relative;
	overflow: hidden;
	overflow-y: auto;
	width: 250px;
`;

export const MapAnalyticsTitleInput = styled.input`
	width: 190px;
	padding: 14px 10px;
	font-size: 16px;
	margin: 10px 0;
`;

export const MapAnalyticsMenuHeader = styled.div`
	padding: 1rem;
	padding-bottom: 0.75rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const MapAnalyticsInfo = styled.div`
	padding: 1rem;
	padding-top: 0;
	padding-bottom: 1.2rem;
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	transition: height 0.2s ease-in-out;

	&.export {
		padding: 20px 20px 0 20px;
	}

	.actions {
		display: flex;
		font-size: 12px;
		color: #464b5e;
		button:first-of-type {
			margin-right: 20px;
		}
	}

	.market-name {
		font-size: 10px;
		text-transform: uppercase;
		color: #464b5e;
		margin-top: 2px;
		padding: 0 0 20px;
	}
`;

export const MapAnalyticsInfoDescription = styled.div`
	color: ${(p) => p.theme.colors.gray.gray700};
	font-size: 12px;
	font-weight: 300;
	margin: 0 0 1rem;
`;

export const MapAnalyticsInfoTitle = styled.h6`
	font-size: 16px;
	font-weight: 300;
	color: #50576d;
	margin-bottom: 0.75rem;
`;

export const MapAnalyticsMenuContent = styled.div`
	padding: 1rem;
	overflow-y: auto;
	max-height: 500px;
`;

export const FilterFocusButton = styled.button`
	all: unset;
	align-items: center;
	color: ${(p) => p.theme.colors.gray.gray700};
	cursor: pointer;
	display: flex;
	font-family: ${(p) => p.theme.typography.fontFamily.gotham};
	font-size: 12px;
	font-style: normal;
	font-weight: normal;
	letter-spacing: 1px;
	line-height: 14px;
	text-decoration-line: underline;
	text-decoration: underline;
`;

export const Button = styled(OriginalButton)`
	height: 40px;
	font-size: 10px;
	padding: 0 15px;
	box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.1);
	margin-right: 10px;
`;

export const TopRightContainer = styled.div`
	position: absolute;
	top: 70px;
	right: 20px;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	z-index: 10;
	font-family: ${({ theme }) => theme.typography.fontFamily.gotham};
`;

export const OverridedTopRightContainer = styled(TopRightContainer)`
	top: 0;
`;
