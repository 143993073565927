import React, { FC, useEffect, useRef, useState } from 'react';

import { useBoolean } from '@compstak/ui-kit';

import { Modal } from 'Components/Modals/common/UI';
import AnalyticsDataToShapes from './Components/AnalyticsDataToShapes';
import Content from './Components/Content';
import { NewDataSetModal } from './Components/Modals/NewDataSetModal';
import { InfoBanner } from './Components/Banners/InfoBanner';
import { DataProviderInfoModal } from './Components/Modals/DataProviderInfoModal';
import { FiltersChangedModal } from './Components/Modals/FiltersChangedModal';
import Sidebar from './Components/Sidebar';

import { usePrevious } from 'util/hooks';
import { Chart, DataSetType, Shape } from 'Pages/Analytics/analytics';
import { MarketsState, PermissionsState, useUser } from 'Pages/Login/reducers';

import layoutStyles from 'ui/styles/layout.less';
import { FeedbackActions } from 'Singletons/Feedback/actions';
import { LoginActions } from 'Pages/Login/actions';
import { isPermittedDataset } from 'Pages/Analytics/Builder/chartBuilderHelpers';
import { useInsightsData } from 'Components/Graphs/ShapeDataLoader';
import { useHistoryState } from 'router/HistoryProvider';
import { Options } from 'highcharts';

type AnalyticsLayoutProps = {
	chartDraft: Chart;
	feedbackActions: FeedbackActions;
	shapes: Shape[];
	permissions: PermissionsState;
	markets: MarketsState;
	options: { chartDraft: Chart };
	userActions: Pick<LoginActions, 'loadUser'>;
};

const AnalyticsLayout: FC<AnalyticsLayoutProps> = (props) => {
	const {
		chartDraft,
		shapes,
		feedbackActions,
		permissions,
		userActions,
		options,
	} = props;
	const { preferences } = useUser();
	const [exportRequest, setExportRequest] = useState<Options | null>(null);
	const [copyRequest, setCopyRequest] = useState(null);
	const [
		isDataProviderModalOpen,
		openDataProviderModal,
		closeDataProviderModal,
	] = useBoolean(false);
	const [
		isFiltersChangedModalOpen,
		openFiltersChangedModalOpen,
		closeFiltersChangedModalOpen,
	] = useBoolean(false);
	const [isDataInfoBannerOpen, openDataInfoBanner, closeDataInfoBanner] =
		useBoolean(false);
	const lastMufaDataSetsCountRef = useRef(0);
	const prevDataSetTypes =
		usePrevious(options?.chartDraft?.dataSets?.map((i) => i.type)) || [];

	const mufaDataSetsCount =
		chartDraft?.dataSets?.filter((ds) => ds?.type === DataSetType.MUFA)
			?.length ?? 0;

	const insightsData = useInsightsData({ chartDraft, shapes });

	useEffect(() => {
		feedbackActions.clearFeedback();
	}, []);

	useEffect(() => {
		const newDataSetTypes = options.chartDraft.dataSets.map((i) => i.type);
		if (
			newDataSetTypes.length === prevDataSetTypes.length &&
			newDataSetTypes.some(
				(val, index) =>
					prevDataSetTypes[index] && val !== prevDataSetTypes[index]
			)
		) {
			openFiltersChangedModalOpen();
		}
	}, [options]);

	useEffect(() => {
		if (mufaDataSetsCount > lastMufaDataSetsCountRef.current) {
			openDataProviderModal();
			openDataInfoBanner();
		}
		if (mufaDataSetsCount !== lastMufaDataSetsCountRef.current) {
			lastMufaDataSetsCountRef.current = mufaDataSetsCount;
		}
	}, [mufaDataSetsCount, openDataProviderModal, openDataInfoBanner]);

	const onExportRequest = (options: Options) => setExportRequest(options);

	// @ts-expect-error TS7006: Parameter 'options' implicitly...
	const copyToExcel = (options) => setCopyRequest(options || {});

	const { goBack } = useHistoryState();

	const hasData =
		chartDraft &&
		chartDraft.dataSets.filter((ds) => isPermittedDataset(ds, permissions))
			.length > 0;

	if (!hasData) {
		return (
			<Modal
				onClose={goBack}
				contentProps={{
					view: 'medium',
				}}
			>
				<NewDataSetModal chartDraft={chartDraft} />
			</Modal>
		);
	}

	const needToShowInfoModal =
		isDataProviderModalOpen &&
		// @ts-expect-error common types should be updated
		!preferences.mufaChartbuilderMessageWindowHiddenAt;

	const needToShowFiltersChangedModal =
		isFiltersChangedModalOpen &&
		// @ts-expect-error common types should be updated
		!preferences.mufaChartbuilderFilterLossMessageHiddenAt;

	const needToShowDataInfoBanner =
		isDataInfoBannerOpen &&
		// @ts-expect-error common types should be updated
		!preferences.mufaChartbuilderMessageBannerHiddenAt;

	return (
		<div className={layoutStyles.contentWithSidebar}>
			<div className={layoutStyles.sidebar}>
				<Sidebar {...props} insightsData={insightsData} />
			</div>
			{needToShowInfoModal && (
				<DataProviderInfoModal
					onClose={closeDataProviderModal}
					updateUser={userActions.loadUser}
				/>
			)}
			{needToShowFiltersChangedModal && (
				<FiltersChangedModal
					onClose={closeFiltersChangedModalOpen}
					updateUser={userActions.loadUser}
				/>
			)}
			{needToShowDataInfoBanner && (
				<InfoBanner
					description="Property Level and Multifamily level filters are available for RealPage Multifamily Data."
					onClose={closeDataInfoBanner}
					updateUser={userActions.loadUser}
				/>
			)}
			<Content
				{...props}
				copyRequest={copyRequest}
				exportRequest={exportRequest}
				market={props.markets[1]}
				compType="lease" // TODO: Probably, it's a stub, need to investigate and replace
				export={onExportRequest}
				copyToExcel={copyToExcel}
				insightsData={insightsData}
			/>
		</div>
	);
};

export default AnalyticsDataToShapes(AnalyticsLayout);
