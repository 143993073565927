import { PerformanceDistributionChartContent } from 'PortfolioAnalytics/Charts/PortfolioPerformanceDistributionContent';
import { Spacer } from 'PortfolioAnalytics/UI';
import { usePortfoliosSelection } from 'PortfolioAnalytics/hooks/usePortfoliosSelection';
import { ChartBox } from 'PortfolioAnalytics/Charts/ChartSelect';

export const BenchmarkingPerformanceDistributionChart = () => {
	const selectedPortfolioIdsForBenchmarking =
		usePortfoliosSelection().selectedPortfolioIds;
	return (
		<ChartBox
			chartName="Portfolio Performance Distribution"
			displaySelector={false}
			chartToolTip={BENCHMARKING_PERFORMANCE_TOOLTIP}
		>
			<PerformanceDistributionChartContent
				portfolioIds={selectedPortfolioIdsForBenchmarking}
			/>
		</ChartBox>
	);
};

const BENCHMARKING_PERFORMANCE_TOOLTIP = (
	<Spacer>
		The four bars represent the count of properties in each of the four
		quadrants in the Property Performance Matrix to show the distribution of
		performance / risk on the portfolio level. To learn more about the
		calculations, review the Property Performance Matrix Tooltip.
	</Spacer>
);
