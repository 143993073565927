import { formatRent } from './formatRent';
import { renderRentPeriodPostfix } from './renderRentPeriodPostfix';
import { getRentPeriodPostfix } from './getRentPeriodPostfix';

export const formatRentWithPostfix = (value: number, isMonthly?: boolean) => {
	return (
		<>
			{formatRent(value, isMonthly)} {renderRentPeriodPostfix(isMonthly)}
		</>
	);
};

export const formatRentWithPostfixAsString = (
	value: number,
	isMonthly?: boolean
) => {
	return `${formatRent(value, isMonthly)} ${getRentPeriodPostfix(isMonthly)}`;
};
