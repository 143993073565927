import { API } from '@compstak/common';
import { UseQueryOptions } from '@tanstack/react-query';
import { QUERY_KEYS } from 'api/constants';
import { useQueryHook } from 'api/utils';
import { mapUrlToGateway } from 'utils/gatewayUtil';
import {
	RelocationHistory,
	RenewalPredecessor,
	RenewalSuccessor,
	relocationHistoryValidator,
	renewalPredecessorValidator,
	renewalSuccessorValidator,
} from './types/leaseHistoryTypes';

const fetchRelocationHistory = async (leaseId: number) => {
	const uncheckedResponse = await API.get(
		mapUrlToGateway(`/lease-service/leases/${leaseId}/relocations/predecessor`)
	).then((res) => res.data);
	const checkedResponse = relocationHistoryValidator.parse(uncheckedResponse);
	return checkedResponse;
};

const fetchRenewalHistory = async (leaseId: number) => {
	const uncheckedResponse = await API.get(
		mapUrlToGateway(`/lease-service/leases/${leaseId}/renewals/predecessor`)
	).then((res) => res.data);
	const checkedResponse = renewalPredecessorValidator.parse(uncheckedResponse);
	return checkedResponse;
};

const fetchRenewalSuccessor = async (leaseId: number) => {
	const uncheckedResponse = await API.get(
		mapUrlToGateway(`/lease-service/leases/${leaseId}/renewals/successor`)
	).then((res) => res.data);
	const checkedResponse = renewalSuccessorValidator.parse(uncheckedResponse);
	return checkedResponse;
};

type LeaseHistoryResponse = {
	relocationHistory: RelocationHistory['predecessor'] | undefined;
	renewalHistory: RenewalPredecessor['predecessor'] | undefined;
	renewalSuccessor: RenewalSuccessor['successor'] | undefined;
};

type UseLeaseHistoryProps = {
	leaseId: number;
	options?: UseQueryOptions<LeaseHistoryResponse>;
};

export const useLeaseHistory = ({ leaseId, options }: UseLeaseHistoryProps) => {
	return useQueryHook({
		...options,
		queryKey: [QUERY_KEYS.LEASE_HISTORY, leaseId],
		queryFn: async () => {
			const responses = await Promise.allSettled([
				fetchRelocationHistory(leaseId),
				fetchRenewalHistory(leaseId),
				fetchRenewalSuccessor(leaseId),
			]);

			// TODO: Throw if any of the responses are rejected because of an unexpected error.

			const [relocationHistory, renewalHistory, renewalSuccessor] =
				responses.map((response) => {
					if (response.status === 'rejected') {
						return null;
					}

					return response.value;
				}) as [
					RelocationHistory | null,
					RenewalPredecessor | null,
					RenewalSuccessor | null,
				];

			return {
				relocationHistory: relocationHistory?.predecessor,
				renewalHistory: renewalHistory?.predecessor,
				renewalSuccessor: renewalSuccessor?.successor,
			};
		},
	});
};
