import { useMemo } from 'react';
import dayjs from 'dayjs';
import { TooltipV2 } from '@compstak/ui-kit';
import { useNavigate } from 'router';
import { SortDirection, useSearchLeasesQuery, useSearchSalesQuery } from 'api';
import { useNewCompsQuery } from 'api/newComps/useNewCompsQuery';
import { SavedSearch } from 'api/savedSearches/useSavedSearchesQuery';
import {
	filtersFromServerJSON,
	filtersToServerJSON,
} from 'models/filters/util';
import { useMarkets } from 'hooks/useMarkets';
import { PropertyFilters } from 'models/filters/property';
import { SavedSearchType } from 'api/savedSearches/types';
import abbreviateNumber from 'ui/util/abbreviateNumber';
import { mergeFilters } from 'models/filters/util/mergeFilters';
import styled from 'styled-components';
import { SEARCH_ROUTES } from 'router';
import { tooltipCss } from 'utils';

type Props = {
	savedSearch: SavedSearch;
	dateCreatedMin: string;
};

export const SavedSearchNewCount = ({ savedSearch, dateCreatedMin }: Props) => {
	const markets = useMarkets();

	const savedSearchFilters = useMemo(() => {
		return filtersFromServerJSON(
			savedSearch.searchType,
			markets,
			savedSearch.filters
		);
	}, [savedSearch.searchType, savedSearch.filters, markets]);

	const params = useMemo(() => {
		return {
			filter: filtersToServerJSON(
				mergeFilters(savedSearchFilters, {
					dateCreated: {
						min: dayjs(dateCreatedMin).toDate(),
						max: null,
					},
				})
			),
			offset: 0,
			limit: 1,
			order: 'asc' as SortDirection,
			sort: 'dateCreated',
		};
	}, [dateCreatedMin, savedSearchFilters]);

	const { data: leaseData } = useSearchLeasesQuery(params, {
		enabled: savedSearch.searchType === SavedSearchType.LEASES,
	});

	const { data: saleData } = useSearchSalesQuery({
		params,
		enabled: savedSearch.searchType === SavedSearchType.SALES,
	});

	const { data: propertiesNewCompsData } = useNewCompsQuery(
		{
			newFrom: dayjs(dateCreatedMin).toDate(),
			filters: savedSearchFilters as PropertyFilters,
		},
		{ enabled: savedSearch.searchType === SavedSearchType.PROPERTIES }
	);

	if (savedSearch.searchType === SavedSearchType.PROPERTIES) {
		return propertiesNewCompsData ? (
			<>
				{propertiesNewCompsData.leases.total > 0 && (
					<NewCount
						savedSearchId={savedSearch.id}
						dateCreatedMin={dateCreatedMin}
						searchType={SavedSearchType.LEASES}
						newCompsCount={`${abbreviateNumber(propertiesNewCompsData.leases.total)}${
							propertiesNewCompsData.possiblyHasMore ? '+' : ''
						} New Lease${propertiesNewCompsData.leases.total > 1 ? 's' : ''}`}
						tooltipContent={
							"Shows the number of new lease comps added since your last visit, based on the 'Last Viewed' date. Due to differences in filters for properties, leases, and sales, the total may not include all relevant comps."
						}
					/>
				)}
				{propertiesNewCompsData.sales.total > 0 && (
					<NewCount
						savedSearchId={savedSearch.id}
						dateCreatedMin={dateCreatedMin}
						searchType={SavedSearchType.SALES}
						newCompsCount={`${abbreviateNumber(propertiesNewCompsData.sales.total)}${
							propertiesNewCompsData?.possiblyHasMore ? '+' : ''
						} New Sale${propertiesNewCompsData.sales.total > 1 ? 's' : ''}`}
						tooltipContent={
							"Shows the number of new sale comps added since your last visit, based on the 'Last Viewed' date. Due to differences in filters for properties, leases, and sales, the total may not include all relevant comps."
						}
					/>
				)}
			</>
		) : null;
	}

	const newCompsCount =
		savedSearch.searchType === SavedSearchType.SALES
			? saleData?.totalCount
			: leaseData?.totalCount;

	return newCompsCount ? (
		<NewCount
			savedSearchId={savedSearch.id}
			dateCreatedMin={dateCreatedMin}
			searchType={savedSearch.searchType}
			newCompsCount={`${abbreviateNumber(newCompsCount)} New`}
			tooltipContent={`Shows the number of ${savedSearch.searchType} comps added since your last visit to this Saved Search. The count is based on the 'Last Viewed' date.`}
		/>
	) : null;
};

type NewCountProps = {
	tooltipContent: string;
	searchType: SavedSearchType;
	savedSearchId: string;
	dateCreatedMin: string;
	newCompsCount: string;
};

const NewCount = ({
	tooltipContent,
	searchType,
	savedSearchId,
	dateCreatedMin,
	newCompsCount,
}: NewCountProps) => {
	const navigate = useNavigate();
	return (
		<TooltipV2 content={tooltipContent} contentCSS={tooltipCss}>
			<Count
				onClick={(e) => {
					e.stopPropagation();
					navigate(
						SEARCH_ROUTES[searchType].toHref(undefined, {
							savedSearchId,
							dateCreatedMin,
						})
					);
				}}
			>
				{newCompsCount}
			</Count>
		</TooltipV2>
	);
};

const Count = styled.div`
	// TODO: add color to ui-kit
	border: 1px solid #1864b2;
	background-color: #f4f9ff;
	color: #1864b2;
	border-radius: 8px;
	padding: 0.25rem 0.5rem;
	font-size: 0.75rem;
	font-weight: 500;
	width: fit-content;
`;
