import {
	useChartPropertiesTopQuery,
	BUILDING_CLASS_NAME_TO_ID,
	BuildingClassId,
} from 'api';
import { formatPercent } from 'format';
import { PortfolioPieChart } from 'PortfolioAnalytics/Charts/PortfolioPieChart';
import { ChartStateWrapper } from '../ChartStateWrapper';
import { FiltersState } from 'PortfolioAnalytics/PortfolioFiltersProvider';
import { ChartBox, ChartOption } from 'PortfolioAnalytics/Charts/ChartSelect';
import { useNavigate } from 'react-router';
import { routes } from 'router';

type BuildingClassByPropertySqftChartProps = {
	portfolioId: number;
	filters: FiltersState;
};

type BuildingClassByPropertySqftChartV2ContentProps =
	BuildingClassByPropertySqftChartProps & {
		selectedChart: ChartOption<'property'>;
	};

const BUILDING_CLASS_BY_PROPERTY_SQFT_CHART_NAME = 'Building Class';

export const BuildingClassByPropertySqftChartV2 = (
	props: BuildingClassByPropertySqftChartProps
) => {
	return (
		<ChartBox
			chartName={BUILDING_CLASS_BY_PROPERTY_SQFT_CHART_NAME}
			chartDataType="property"
		>
			{(selectedChart) => (
				<BuildingClassByPropertySqftChartV2Content
					{...props}
					selectedChart={selectedChart}
				/>
			)}
		</ChartBox>
	);
};

const BuildingClassByPropertySqftChartV2Content = ({
	portfolioId,
	filters,
	selectedChart,
}: BuildingClassByPropertySqftChartV2ContentProps) => {
	const { data, isFetching, isError } = useChartPropertiesTopQuery(
		portfolioId,
		{
			filters,
			chartMetric:
				selectedChart.type === 'totalBuildingSize'
					? 'buildingSize'
					: selectedChart.type,
			chartGrouping: 'buildingClass',
			chartLimit: 10,
		}
	);

	const total = data?.total;

	const chartData =
		!total || total === 0 || !data?.values
			? []
			: data?.values
					.map((item) => ({
						x: item.label,
						y: item.value,
						label: formatPercent(item.value / total, 1),
						buildingClassId:
							BUILDING_CLASS_NAME_TO_ID[
								item.label as keyof typeof BUILDING_CLASS_NAME_TO_ID
							],
					}))
					.sort((a, b) => a.x.localeCompare(b.x));

	const noData = chartData.length === 0;

	const navigate = useNavigate();

	return (
		<ChartStateWrapper
			isError={isError}
			isFetching={isFetching}
			noData={noData}
			showNoDataMessage={false}
		>
			<PortfolioPieChart
				data={chartData}
				events={[
					{
						target: 'data',
						eventHandlers: {
							onClick: (_, clickedProps) => {
								const buildingClassId = clickedProps.datum
									.buildingClassId as BuildingClassId;
								navigate(
									routes.portfolioByIdView.toHref(
										{ portfolioId: portfolioId, viewType: 'list' },
										{ buildingClassIds: [buildingClassId] }
									)
								);
							},
						},
					},
				]}
				dataType="property"
			/>
		</ChartStateWrapper>
	);
};
