import { ChartBox, ChartOption } from 'PortfolioAnalytics/Charts/ChartSelect';
import { CHART_NAME } from './BenchmarkingLeaseExpirationChartV2';
import { ChartLegendV1 } from '../ChartLegend';
import { useMemo } from 'react';
import { usePortfoliosByIdsQueries } from 'api/portfolio/portfolioById/usePortfoliosByIdsQueries';
import { usePortfolioFilters } from 'PortfolioAnalytics/PortfolioFiltersProvider';
import { usePortfoliosSelection } from 'PortfolioAnalytics/hooks/usePortfoliosSelection';
import {
	BENCHMARKING_COLORS,
	BENCHMARKING_SELECT_PORTFOLIOS_MESSAGE,
} from 'PortfolioAnalytics/Benchmarking/constants';
import {
	VictoryAxis,
	VictoryBar,
	VictoryChart,
	VictoryGroup,
	VictoryTooltip,
	VictoryVoronoiContainer,
	VictoryLabel,
} from 'victory';
import { LeaseExpirationTooltip } from './LeaseExpirationTooltip';
import { Portfolio } from 'api';
import { ChartStateWrapper } from 'PortfolioAnalytics/Charts/ChartStateWrapper';
import {
	YEAR_RANGE,
	filterValidYears,
	getSelectedChartValue,
} from 'PortfolioAnalytics/Charts/LeaseExpirationBySpaceType/utils';
import { formatNumber } from 'format';
import {
	CHART_AXIS_TICK_STYLE,
	VICTORY_CONTAINER_STYLE_800,
} from 'PortfolioAnalytics/Charts/chartsConstants';

export const BenchmarkingLeaseExpirationChartV1 = () => {
	return (
		<ChartBox chartName={CHART_NAME}>
			{(selectedChart) => (
				<BenchmarkingLeaseExpirationChartV1Content
					selectedChart={selectedChart}
				/>
			)}
		</ChartBox>
	);
};

export const BenchmarkingLeaseExpirationChartV1Content = ({
	selectedChart,
}: {
	selectedChart: ChartOption<'lease'>;
}) => {
	const { selectedPortfolioIds } = usePortfoliosSelection();
	const { filters } = usePortfolioFilters();

	const selectedPortfoliosResults = usePortfoliosByIdsQueries(
		selectedPortfolioIds.map((id) => ({ id, filters }))
	);

	const selectedPortfoliosResultsWithColors = useMemo(() => {
		return selectedPortfoliosResults
			.map((result, index) => ({
				...result,
				color: BENCHMARKING_COLORS[index],
			}))
			.reverse()
			.filter(
				(result) =>
					result.data?.leaseExpirationsBySpaceTypesByYears &&
					result.data?.leaseExpirationsBySpaceTypesByYears.length > 0
			);
	}, [selectedPortfoliosResults]);

	const isFetching = selectedPortfoliosResults.some(
		(result) => result.isFetching
	);

	const isError = selectedPortfoliosResults.some((result) => result.isError);

	const isLeaseExpirationsEmpty = useMemo(
		() =>
			selectedPortfolioIds.length > 0 &&
			selectedPortfoliosResults.every(
				(result) =>
					result.data?.leaseExpirationsBySpaceTypesByYears?.length === 0
			),
		[selectedPortfoliosResults, selectedPortfolioIds]
	);

	const noData =
		!isFetching &&
		(selectedPortfolioIds.length === 0 || isLeaseExpirationsEmpty);

	return (
		<ChartStateWrapper
			isError={isError}
			isFetching={isFetching}
			noData={noData}
			showNoDataMessage={!isLeaseExpirationsEmpty}
			noDataMessage={BENCHMARKING_SELECT_PORTFOLIOS_MESSAGE}
		>
			<VictoryChart
				domainPadding={20}
				containerComponent={
					<VictoryVoronoiContainer
						voronoiDimension="x"
						style={VICTORY_CONTAINER_STYLE_800}
						labels={() => ' '}
						labelComponent={
							<VictoryTooltip
								flyoutComponent={
									<LeaseExpirationTooltip
										width={128}
										height={20}
										selectedChartType={selectedChart.type}
									/>
								}
							/>
						}
					/>
				}
			>
				<VictoryAxis
					dependentAxis
					tickLabelComponent={
						<VictoryLabel
							style={{ ...CHART_AXIS_TICK_STYLE, fontSize: '10px' }}
						/>
					}
					tickCount={6}
					tickFormat={(d: number) =>
						formatNumber(d, { minDecimals: 0, maxDecimals: 2, shorten: true })
					}
					style={{
						axis: { stroke: '#F0F0F0', strokeWidth: 2 },
						grid: { stroke: '#F0F0F0' },
					}}
				/>
				<VictoryAxis
					tickLabelComponent={
						<VictoryLabel
							style={{ ...CHART_AXIS_TICK_STYLE, fontSize: '10px' }}
						/>
					}
					style={{
						axis: { stroke: 'transparent' },
					}}
					// For some reason, if tickValues are not converted to strings, the values will be overlapping
					tickValues={YEAR_RANGE.map((year) => year.toString())}
				/>
				<VictoryGroup offset={6} horizontal>
					{selectedPortfoliosResultsWithColors.map((result, idx) => {
						const filteredPortfolioLeaseExpirations =
							result.data?.leaseExpirationsBySpaceTypesByYears.filter(
								(leaseExpiration) => filterValidYears(leaseExpiration)
							);
						const portfolioExpirationYearToValue =
							filteredPortfolioLeaseExpirations?.reduce<Record<number, number>>(
								(acc, leaseExpiration) => {
									const expirationYear = leaseExpiration.expirationYear;
									if (!acc[expirationYear]) {
										acc[expirationYear] = 0;
									}
									acc[expirationYear] += getSelectedChartValue(
										selectedChart.type,
										leaseExpiration
									);
									return acc;
								},
								{}
							);
						const data = Object.typedEntries(
							portfolioExpirationYearToValue ?? {}
						).map(([expirationYear, value]) => ({
							x: expirationYear,
							y: value,
						}));

						return (
							<VictoryBar
								barWidth={6}
								key={idx}
								data={data}
								style={{ data: { fill: `${result.color}` } }}
							/>
						);
					})}
				</VictoryGroup>
			</VictoryChart>
			<ChartLegendV1
				portfolios={
					selectedPortfoliosResults
						.map((result) => result?.data)
						.filter((portfolio) => portfolio) as Portfolio[]
				}
				colors={BENCHMARKING_COLORS}
				metric="leaseExpirationsBySpaceTypesByYears"
			/>
		</ChartStateWrapper>
	);
};
