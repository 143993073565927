import { PortfolioLeaseExpirationsBySpaceTypesByYears } from 'api';
import { ChartTypeLease } from '../ChartSelect';

export const YEAR_RANGE = new Array(5)
	.fill(new Date())
	.map((date: Date, i) => date.getFullYear() + i);

export const getSelectedChartValue = (
	chartType: ChartTypeLease,
	value: PortfolioLeaseExpirationsBySpaceTypesByYears
) => {
	switch (chartType) {
		case 'leaseAmount':
			return value.expiringLeaseAmount;
		case 'leaseCount':
			return value.expiringLeaseCount;
		default:
			return value.totalSqFt;
	}
};

export const filterValidYears = (
	leaseExpiration: PortfolioLeaseExpirationsBySpaceTypesByYears
) => {
	return YEAR_RANGE.includes(leaseExpiration.expirationYear);
};
