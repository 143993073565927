import React, { useMemo } from 'react';
import { useChartTopQuery } from 'api';
import { formatPercent } from 'format';
import { ChartBox, ChartOption } from '../ChartSelect';
import { PortfolioPieChart } from '../PortfolioPieChart';
import { useNavigate } from 'react-router';
import { routes } from 'router';
import { ChartStateWrapper } from '../ChartStateWrapper';
import { FiltersState } from 'PortfolioAnalytics/PortfolioFiltersProvider';

const TOP_INDUSTRIES_COUNT = 5;
const TENANT_INDUSTRY_CHART_NAME = `Top ${TOP_INDUSTRIES_COUNT} Tenant Industry Exposure`;

type TenantIndustryExposureChartProps = {
	portfolioId: number;
	filters: FiltersState;
};

export const TenantIndustryExposureChartV2 = (
	props: TenantIndustryExposureChartProps
) => {
	return (
		<ChartBox<'lease'> chartName={TENANT_INDUSTRY_CHART_NAME}>
			{(selectedChart) => (
				<TenantIndustryExposureChartV2Content
					{...props}
					selectedChart={selectedChart}
				/>
			)}
		</ChartBox>
	);
};

type TenantExposureChartContentProps = TenantIndustryExposureChartProps & {
	selectedChart: ChartOption<'lease'>;
};

const TenantIndustryExposureChartV2Content = ({
	portfolioId,
	filters,
	selectedChart,
}: TenantExposureChartContentProps) => {
	const { data, isFetching, isError } = useChartTopQuery(portfolioId, {
		filters,
		chartMetric:
			selectedChart.type === 'sqft' ? 'transactionSize' : selectedChart.type,
		chartGrouping: 'tenantIndustry',
		chartLimit: 30,
	});

	const chartData = useMemo(() => {
		if (!data?.values) {
			return [];
		}

		const totalValue = data.values.reduce((acc, { value }) => acc + value, 0);

		const topFiveIndustriesChartData = data.values
			.slice(0, TOP_INDUSTRIES_COUNT)
			.map(({ label, value }) => ({
				x: label,
				y: value,
				label: formatPercent(value / totalValue, 1),
				tenantIndustries: [label],
			}));

		const otherIndustriesData = data.values.slice(
			TOP_INDUSTRIES_COUNT,
			data.values.length
		);
		const otherIndustriesValue = otherIndustriesData.reduce(
			(acc, { value }) => acc + value,
			0
		);

		const otherIndustriesChartData = {
			x: 'Other industries',
			y: otherIndustriesValue,
			label: formatPercent(otherIndustriesValue / totalValue, 1),
			tenantIndustries: otherIndustriesData.map(({ label }) => label),
		};

		return [...topFiveIndustriesChartData, otherIndustriesChartData];
	}, [data]);

	const navigate = useNavigate();

	return (
		<ChartStateWrapper
			isError={isError}
			isFetching={isFetching}
			noData={data?.values.length === 0}
			showNoDataMessage={false}
		>
			<PortfolioPieChart
				data={chartData}
				events={[
					{
						target: 'data',
						eventHandlers: {
							onClick: (_, clickedProps) => {
								const tenantIndustries = clickedProps.datum.tenantIndustries;
								navigate(
									routes.portfolioByIdView.toHref(
										{ portfolioId: portfolioId, viewType: 'list' },
										{ tenantIndustry: tenantIndustries }
									)
								);
							},
						},
					},
				]}
			/>
		</ChartStateWrapper>
	);
};
