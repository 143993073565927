import { RadioButton } from 'Components/RadioButton/RadioButton';
import {
	Criterion,
	INITIAL_CRITERION_DURATION,
	PortfolioLeasesFilters,
} from '../PortfolioLeasesFiltersProvider';
import { CriterionDurationSelect } from './CriterionDurationSelect';
import { PropsWithChildren } from 'react';
import { Flex, TooltipV2 } from '@compstak/ui-kit';
import { LEASE_ACTIVITY_TOOLTIPS } from 'constants/tooltips';
import IconQuestionMark from 'ui/svg_icons/question_mark_outline.svg';

type Props = PropsWithChildren<{
	criterion: Criterion;
	filters: PortfolioLeasesFilters;
	setFilters: React.Dispatch<React.SetStateAction<PortfolioLeasesFilters>>;
}>;

export const CriterionRadioButton = ({
	children,
	criterion,
	filters,
	setFilters,
}: Props) => {
	return (
		<Flex gap="0.5rem">
			<Flex gap="0.125rem">
				<RadioButton
					checked={filters.filterBy?.criterion === criterion}
					onChange={() =>
						setFilters((prevFilters) => ({
							...prevFilters,
							filterBy: {
								criterion,
								duration:
									prevFilters.filterBy?.duration ?? INITIAL_CRITERION_DURATION,
							},
						}))
					}
				>
					{children}
				</RadioButton>
				<TooltipV2 content={LEASE_ACTIVITY_TOOLTIPS[criterion]}>
					<span>
						<IconQuestionMark width="1rem" />
					</span>
				</TooltipV2>
			</Flex>
			{filters.filterBy?.criterion === criterion &&
				criterion !== 'allLeases' && (
					<CriterionDurationSelect
						value={filters.filterBy.duration}
						onChange={(value) =>
							setFilters((prevFilters) => ({
								...prevFilters,
								filterBy: { criterion, duration: value },
							}))
						}
					/>
				)}
		</Flex>
	);
};
