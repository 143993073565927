import { nullValueText } from '@compstak/common';
import {
	SimpleTableColumn,
	renderTableValue,
	TooltipV2,
	defaultTheme,
	Pending,
} from '@compstak/ui-kit';
import { formatInteger, formatMoney, formatPercent } from 'format';
import { useMarkets } from 'hooks/useMarkets';

import {
	Link,
	PortfolioByIdRouteParams,
	PortfolioByIdRouteSearchParams,
	routes,
} from 'router';
import styled from 'styled-components';
import { formatCsv } from 'format';

import { TitleWithTooltip } from './TitleWithTooltip';
import { PortfolioTabEnum } from 'PortfolioAnalytics/Dashboard/Dashboard';
import { ReactNode, useCallback, useMemo } from 'react';
import { usePortfoliosByIdsQueriesV2 } from 'api/portfolio/portfolioById/usePortfoliosByIdsQueriesV2';
import { usePortfolioFilters } from 'PortfolioAnalytics/PortfolioFiltersProvider';
import { PortfolioV2, SpaceTypeName } from 'api';
import { omit, startCase } from 'lodash';
import { UseQueryResult } from '@tanstack/react-query';
import { usePortfoliosSelection } from 'PortfolioAnalytics/hooks/usePortfoliosSelection';

import { BENCHMARKING_COLOR_MAP } from '../constants';
import { PortfolioDeleteButton } from './PortfolioDeleteButton';
import { PortfolioHeader } from './PortfolioHeader';
import { useToast } from 'providers/ToastProvider';

export type ComparedPortfolio = {
	id: number;
	portfolioName: string;
	markets: React.ReactNode;
	propertiesInPortfolio: React.ReactNode;
	officeSpaceType: React.ReactNode;
	retailSpaceType: React.ReactNode;
	industrialSpaceType: React.ReactNode;
	landSpaceType: React.ReactNode;
	flexRnDSpaceType: React.ReactNode;
	otherSpaceType: React.ReactNode;
	activeLeasedSqFt: React.ReactNode;
	activeLeaseCount: React.ReactNode;
	avgFreeRent: React.ReactNode;
	avgLeaseTerm: React.ReactNode;
	avgCurrentRent: React.ReactNode;
	avgNetEffectiveRent: React.ReactNode;
	avgTIImprovement: React.ReactNode;
	markerColor: (typeof BENCHMARKING_COLOR_MAP)[keyof typeof BENCHMARKING_COLOR_MAP];
};

const TABLE_ITEMS: {
	attribute: keyof ComparedPortfolio;
	header: React.ReactNode;
}[] = [
	{ attribute: 'markets', header: 'Markets' },
	{ attribute: 'propertiesInPortfolio', header: 'Properties in Portfolio' },
	{ attribute: 'officeSpaceType', header: 'Office • Space Type' },
	{ attribute: 'industrialSpaceType', header: 'Industrial • Space Type' },
	{ attribute: 'retailSpaceType', header: 'Retail • Space Type' },
	{ attribute: 'flexRnDSpaceType', header: 'Flex/R&D • Space Type' },
	{ attribute: 'landSpaceType', header: 'Land • Space Type' },
	{ attribute: 'otherSpaceType', header: 'Other • Space Type' },
	{
		attribute: 'activeLeasedSqFt',
		header: (
			<TitleWithTooltip title="Active Leased SqFt" tooltipContent="totalSqFt" />
		),
	},
	{
		attribute: 'activeLeaseCount',
		header: (
			<TitleWithTooltip
				title="Total Active Leases"
				tooltipContent="activeLeaseCount"
			/>
		),
	},
	{
		attribute: 'avgCurrentRent',
		header: (
			<TitleWithTooltip
				title="Avg. Current Rent"
				tooltipContent="avgCurrentRent"
			/>
		),
	},
	{
		attribute: 'avgNetEffectiveRent',
		header: (
			<TitleWithTooltip
				title="Avg. Net Effective Rent"
				tooltipContent="avgNetEffectiveRent"
			/>
		),
	},
	{
		attribute: 'avgFreeRent',
		header: (
			<TitleWithTooltip title="Avg. Free Rent" tooltipContent="avgFreeRent" />
		),
	},
	{
		attribute: 'avgLeaseTerm',
		header: (
			<TitleWithTooltip title="Avg. Lease Term" tooltipContent="avgLeaseTerm" />
		),
	},
	{
		attribute: 'avgTIImprovement',
		header: (
			<TitleWithTooltip
				title="Avg. T.I. Allowance"
				tooltipContent="avgTIImprovement"
			/>
		),
	},
];

export const useBenchmarkTableConfig = () => {
	const { selectedPortfolioIds } = usePortfoliosSelection();
	const { filters } = usePortfolioFilters();
	const results = usePortfoliosByIdsQueriesV2(
		selectedPortfolioIds.map((id) => ({ id, filters }))
	);
	const isFetchingPortfolios = useMemo(
		() => results.some(({ isFetching }) => isFetching),
		[results]
	);
	const allMarkets = useMarkets();

	const generateData = useCallback(
		(
			data: PortfolioV2,
			isExcelData: boolean,
			index: number
		): ComparedPortfolio => {
			const portfolioMarketDisplayNames = data.markets.map(
				(market) => allMarkets[market.marketId].displayName
			);
			return {
				id: data.portfolio.id,
				portfolioName: data.portfolio.name,
				markets: isExcelData
					? portfolioMarketDisplayNames.length > 0
						? formatCsv(portfolioMarketDisplayNames)
						: nullValueText
					: renderTableValue(portfolioMarketDisplayNames, (v) =>
							renderMarkets(v)
						),
				propertiesInPortfolio: isExcelData
					? data.metrics.propertyCount
					: renderTableValue(data.metrics.propertyCount || null, (v) =>
							renderPortfolioByIdLink({
								routeParams: { portfolioId: data.portfolio.id },
								searchParams: { tab: PortfolioTabEnum.PROPERTIES, ...filters },
								text: v,
							})
						),
				activeLeaseCount: isExcelData
					? data.metrics.activeLeaseCount
					: renderTableValue(data.metrics.activeLeaseCount || null, (v) =>
							renderPortfolioByIdLink({
								routeParams: { portfolioId: data.portfolio.id },
								searchParams: {
									tab: PortfolioTabEnum.LEASE_ACTIVITY,
									...filters,
								},
								text: v,
							})
						),
				officeSpaceType: renderSpaceTypeDistribution({
					data,
					spaceTypeName: 'Office',
				}),
				retailSpaceType: renderSpaceTypeDistribution({
					data,
					spaceTypeName: 'Retail',
				}),
				industrialSpaceType: renderSpaceTypeDistribution({
					data,
					spaceTypeName: 'Industrial',
				}),
				landSpaceType: renderSpaceTypeDistribution({
					data,
					spaceTypeName: 'Land',
				}),
				flexRnDSpaceType: renderSpaceTypeDistribution({
					data,
					spaceTypeName: 'Flex/R&D',
				}),
				otherSpaceType: renderSpaceTypeDistribution({
					data,
					spaceTypeName: 'Other',
				}),
				activeLeasedSqFt: renderTableValue(
					data.metrics.totalSqFt || null,
					(v) => formatInteger(v) + ' SqFt'
				),
				avgFreeRent: renderTableValue(
					data.metrics.avgFreeRent,
					(v) => formatInteger(v) + ` mo`
				),
				avgLeaseTerm: renderTableValue(
					data.metrics.avgLeaseTerm,
					(v) => formatInteger(v) + ` mo`
				),
				avgCurrentRent: renderTableValue(data.metrics.avgCurrentRent, (v) =>
					formatMoney(v)
				),
				avgNetEffectiveRent: renderTableValue(
					data.metrics.avgNetEffectiveRent,
					(v) => formatMoney(v)
				),
				avgTIImprovement: renderTableValue(data.metrics.avgTIImprovement, (v) =>
					formatMoney(v)
				),
				markerColor: Object.values(BENCHMARKING_COLOR_MAP)[index],
			};
		},
		[filters, allMarkets]
	);

	const filteredResults = useMemo(() => {
		return results.filter(
			(
				result
			): result is UseQueryResult<PortfolioV2, unknown> & {
				data: PortfolioV2;
			} => !!result.data
		);
	}, [results]);

	const comparedPortfolios: ComparedPortfolio[] = useMemo(
		() =>
			filteredResults.map(({ data }, index) =>
				generateData(data, false, index)
			),
		[filteredResults, generateData]
	);

	const transposeData = useCallback(() => {
		const headers = Object.typedKeys(comparedPortfolios[0])
			.filter((key) => key !== 'markerColor')
			.map((key) => startCase(key));

		const rows = [
			headers.join('\t'),
			...filteredResults.map(({ data: portfolio }, index) =>
				Object.typedEntries(generateData(portfolio, true, index))
					.filter(([key]) => key !== 'markerColor')
					.map(([, value]) => value)
					.join('\t')
			),
		];

		return rows.join('\n');
	}, [comparedPortfolios, filteredResults, generateData]);

	const { addToast } = useToast();
	const handleExportCSV = useCallback(() => {
		const csv = transposeData();

		navigator.clipboard.writeText(csv);

		addToast({
			title: 'Copied to clipboard',
		});
	}, [transposeData]);

	const transposedColumns: SimpleTableColumn<
		(typeof transposedRows)[number]
	>[] = [
		{
			id: 'attribute',
			width: 200,
			header: '',
			body: ({ row }) => row.header,
			flex: 1,
		},

		...comparedPortfolios.flatMap<
			SimpleTableColumn<(typeof transposedRows)[number]>
		>((portfolio, rowIndex) => {
			return [
				{
					id: TABLE_ITEMS[rowIndex].attribute,
					width: 160,
					header: () => {
						if (isFetchingPortfolios) {
							return (
								<PendingContainer>
									<Pending bgColor={defaultTheme.colors.gray.gray100} />
								</PendingContainer>
							);
						}

						return (
							<PortfolioHeader portfolio={portfolio as ComparedPortfolio} />
						);
					},
					body: ({ row }: { row: (typeof transposedRows)[number] }) => {
						if (isFetchingPortfolios)
							return (
								<PendingContainer>
									<Pending bgColor={defaultTheme.colors.gray.gray100} />
								</PendingContainer>
							);

						return row.values[rowIndex];
					},
					flex: 2,
				},
				{
					id: `deletePortfolio_${portfolio.id}`,
					width: 64,
					header: () => {
						if (isFetchingPortfolios)
							return (
								<PendingContainer>
									<Pending bgColor={defaultTheme.colors.gray.gray100} />
								</PendingContainer>
							);

						return <PortfolioDeleteButton portfolio={portfolio} />;
					},
					body: () => '',
					flex: 0,
				},
			];
		}),
	];
	const transposedRows = TABLE_ITEMS.map((c) => ({
		attribute: c.attribute,
		header: c.header,
		values: comparedPortfolios.map((row) => {
			return row[c.attribute];
		}),
	}));

	return {
		transposedRows,
		transposedColumns,
		handleExportCSV,
		isFetchingPortfolios,
	};
};

const StyledSpan = styled.span`
	color: ${(p) => p.theme.colors.blue.blue500};
`;

const PendingContainer = styled.div`
	width: 10rem;
	height: 1.5rem;
`;

const renderMarkets = (markets: string[]) => {
	if (markets.length === 0) return nullValueText;

	return markets.length > 2 ? (
		<TooltipV2 content={formatCsv(markets)}>
			<StyledSpan>{markets.length} Markets</StyledSpan>
		</TooltipV2>
	) : (
		formatCsv(markets)
	);
};

const renderPortfolioByIdLink = ({
	routeParams,
	searchParams,
	text,
}: {
	routeParams: PortfolioByIdRouteParams;
	searchParams: PortfolioByIdRouteSearchParams;
	text: ReactNode;
}) => (
	<Link
		to={routes.portfolioByIdView.toHref(
			routeParams,
			omit(searchParams, 'markets')
		)}
		target="_blank"
		className="underline"
	>
		{text}
	</Link>
);

const renderSpaceTypeDistribution = ({
	data,
	spaceTypeName,
}: {
	data: PortfolioV2;
	spaceTypeName: SpaceTypeName;
}) => {
	const totalActiveLeaseCount = data.metrics.activeLeaseCount;
	const effectiveSpaceTypeName: SpaceTypeName[] =
		spaceTypeName === 'Other' ? [spaceTypeName, 'Unknown'] : [spaceTypeName];
	const spaceTypeActiveLeasesCount = data.metrics.spaceTypes
		.filter(({ spaceType }) => effectiveSpaceTypeName.includes(spaceType))
		.map(({ activeLeaseCount }) => activeLeaseCount)
		.reduce((acc, i) => {
			acc += i;
			return acc;
		}, 0);

	const spaceTypeDistribution =
		spaceTypeActiveLeasesCount && totalActiveLeaseCount
			? spaceTypeActiveLeasesCount / totalActiveLeaseCount
			: null;

	return renderTableValue(spaceTypeDistribution, (v) => formatPercent(v, 2));
};
