import { ChartBox } from './ChartSelect';
import { Spacer } from 'PortfolioAnalytics/UI';
import {
	PerformanceDistributionChartContent,
	PERFORMANCE_DIST_CHART_TYPE,
} from './PortfolioPerformanceDistributionContent';

type PropertyPerformanceChartProps = {
	portfolioId: number;
};

export const PortfolioPerformanceDistributionChart = ({
	portfolioId,
}: PropertyPerformanceChartProps) => {
	return (
		<ChartBox
			chartName="Portfolio Performance Distribution"
			displaySelector={false}
			chartToolTip={PORTFOLIO_PERFORMANCE_DISTRIBUTION_TOOLTIP}
		>
			<PerformanceDistributionChartContent
				portfolioIds={[portfolioId]}
				chartType={PERFORMANCE_DIST_CHART_TYPE.singlePortfolio}
			/>
		</ChartBox>
	);
};

const PORTFOLIO_PERFORMANCE_DISTRIBUTION_TOOLTIP = (
	<Spacer>
		The four bars represent the count of properties in each of the four
		quadrants in the Property Performance Matrix to show the distribution of
		performance / risk on the portfolio level. To learn more about the
		calculations, review the Property Performance Matrix Tooltip.
	</Spacer>
);
