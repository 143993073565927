import { SearchTable, SearchTableProps } from 'Components/SearchTable';
import { useInfiniteTableRows } from 'utils/useInfiniteTableRows';
import {
	LeaseAndSaleAveragesResponse,
	SEARCH_INFINITE_PAGE_SIZE,
	useSearchFilterParams,
	useSearchLeasesInfiniteQuery,
	useSearchTableAverages,
} from 'api';
import { useLeasesSalesAveragesFormatting } from 'hooks/useLeasesSalesAveragesFormatting';
import { useSearchTableSelection } from 'hooks/useSearchTableSelection';
import { filtersToQueryString } from 'models/filters/util';
import { mergeFilters } from 'models/filters/util/mergeFilters';
import { useFilters } from 'reducers/filtersReducer';
import { routes } from 'router/routes';
import { LeaseComp } from 'types';
import { ExportCompsButton } from 'Components/Export/ExportCompsButton';
import { UnlockCompButton } from 'Components/Unlock/UnlockCompButton';

type Props = Partial<SearchTableProps<LeaseComp>>;

export const LeasesSearchTable = (props: Props) => {
	const [filters, setFilters] = useFilters('exchange-dashboard');

	const params = useSearchFilterParams({ filters });

	const {
		data: leasesData,
		isFetching,
		fetchNextPage,
		hasNextPage,
	} = useSearchLeasesInfiniteQuery(params);

	const totalCount = leasesData?.pages[0].totalCount ?? 0;
	const searchAverages = leasesData?.pages[0].averages;

	const rows = useInfiniteTableRows({
		data: leasesData,
		getRows: (page) => page.comps,
		isFetching,
		pageSize: SEARCH_INFINITE_PAGE_SIZE,
	});

	const { data: averages } = useSearchTableAverages({
		compType: 'lease',
		searchAverages,
		filters,
	});

	const averagesFormatting = useLeasesSalesAveragesFormatting({
		averages: averages as LeaseAndSaleAveragesResponse,
		filters,
	});

	const selectionProps = useSearchTableSelection({
		compType: 'lease',
		totalCount,
		filters,
	});

	return (
		<SearchTable
			{...props}
			{...selectionProps}
			sortName={filters.sortField}
			sortDirection={filters.sortDirection}
			onSort={(name, dir) => {
				setFilters(
					mergeFilters(filters, {
						// @ts-expect-error filters
						sortField: name,
						sortDirection: dir,
					})
				);
			}}
			rows={rows}
			averages={averagesFormatting}
			getRowId={(row) => row.id}
			noData={!isFetching && totalCount === 0}
			onLoadMore={() => {
				if (hasNextPage && !isFetching) {
					fetchNextPage();
				}
			}}
			columnPreferencesType="lease"
			renderActionButton={(row) =>
				row.own ? (
					<ExportCompsButton
						compType="lease"
						compIds={[row.id]}
						buttonType="wide-row"
					/>
				) : (
					<UnlockCompButton type="lease" comp={row} buttonType="wide-row" />
				)
			}
			getBodyCellProps={(row, rowIndex) => ({
				to: routes.leaseById.toHref({ id: row.id }),
				state: {
					index: rowIndex,
					queryString: filtersToQueryString(filters),
				},
			})}
		/>
	);
};
