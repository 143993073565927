import { Map, MapProps } from '@compstak/maps';
import { FeatureFlags } from 'api/featureFlags/types';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { MapNotAvailable } from '../MapNotAvailable/MapNotAvailable';
import { MAP_NOT_AVAILABLE_MESSAGE } from './constants';

type MapWrapperProps = MapProps & {
	mapDomain: FeatureFlags['disableMapsFF'][number];
};

export const MapWrapper = ({ mapDomain, ...props }: MapWrapperProps) => {
	const { disableMapsFF } = useFeatureFlags();
	const isDisabled = disableMapsFF.includes(mapDomain);

	if (isDisabled) {
		return <MapNotAvailable message={MAP_NOT_AVAILABLE_MESSAGE} />;
	}

	return <Map {...props} />;
};
