import { z } from 'zod';

const relocationHistoryValidator = z.object({
	leaseId: z.number(),
	predecessor: z.object({
		address: z.string(),
		propertyId: z.number(),
	}),
});

type RelocationHistory = z.infer<typeof relocationHistoryValidator>;

const renewalBaseResponseValidator = z.object({
	leaseId: z.number(),
});

const innerValidator = z.object({
	transactionDate: z.string(),
	leaseId: z.number(),
});

const renewalPredecessorValidator = renewalBaseResponseValidator.extend({
	predecessor: innerValidator,
});

type RenewalPredecessor = z.infer<typeof renewalPredecessorValidator>;

const renewalSuccessorValidator = renewalBaseResponseValidator.extend({
	successor: innerValidator,
});

type RenewalSuccessor = z.infer<typeof renewalSuccessorValidator>;

export {
	relocationHistoryValidator,
	renewalPredecessorValidator,
	renewalSuccessorValidator,
};

export type { RelocationHistory, RenewalPredecessor, RenewalSuccessor };
