import { omit } from 'lodash';
import { VictoryLabel, VictoryLabelProps } from 'victory';
import {
	CUSTOM_AXIS_LABEL_PROPS,
	CUSTOM_AXIS_LABEL_STYLE,
} from './chartsConstants';

export const ChartAxisLabel = (
	_props: VictoryLabelProps & { fontSize?: number }
) => {
	// omitting props which we really don't want to override via passed props (which are mostly default ones)
	const props = omit(
		_props,
		Object.typedKeys(CUSTOM_AXIS_LABEL_PROPS),
		'style'
	);
	const { fontSize } = { ...CUSTOM_AXIS_LABEL_STYLE, ..._props };
	return (
		<VictoryLabel
			backgroundComponent={<rect rx="3" />}
			{...CUSTOM_AXIS_LABEL_PROPS}
			style={{ ...CUSTOM_AXIS_LABEL_STYLE, fontSize }}
			{...props}
		/>
	);
};
