import { UseQueryOptions, useQueries, useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from 'api/constants';
import { getQueryStringFromPortfolioFilters } from 'util/getQueryStringsFromPortfolioFilter';
import { API } from '@compstak/common';
import { ChartHistogramParams, ChartHistogramResponse } from './types';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

type ChartLeasesHistogramParams = ChartHistogramParams & {
	chartDimension: 'expirationDate' | 'buildingSize';
};

type ChartHistogramResponseWithPortfolioId = ChartHistogramResponse & {
	portfolioId: number;
};

const getChartHistogram = async (
	id: number,
	queryParams: string
): Promise<ChartHistogramResponseWithPortfolioId> => {
	const response = await API.get<ChartHistogramResponse>(
		`/pa/v2/api/portfolios/${id}/chart/histogram${queryParams}`
	);

	return { ...response.data, portfolioId: id };
};

export const useChartHistogramQueries = (
	ids: number[],
	params: ChartLeasesHistogramParams,
	options?: UseQueryOptions<ChartHistogramResponseWithPortfolioId>
) => {
	const { filters, ...extraParams } = params;
	const { portfolioExcludeSubleasesFF } = useFeatureFlags();
	const queryParams = getQueryStringFromPortfolioFilters(filters, {
		...extraParams,
		excludeSubleases: portfolioExcludeSubleasesFF,
	});

	return useQueries({
		queries: ids.map((id) => {
			return {
				queryKey: [QUERY_KEYS.CHART_HISTOGRAM, id, params],
				queryFn: () => getChartHistogram(id, queryParams),
				...options,
			};
		}),
	});
};

export const useChartHistogramQuery = (
	id: number,
	params: ChartLeasesHistogramParams,
	options?: UseQueryOptions<ChartHistogramResponseWithPortfolioId>
) => {
	const { filters, ...extraParams } = params;
	const { portfolioExcludeSubleasesFF } = useFeatureFlags();
	const queryParams = getQueryStringFromPortfolioFilters(filters, {
		...extraParams,
		excludeSubleases: portfolioExcludeSubleasesFF,
	});

	return useQuery({
		queryKey: [QUERY_KEYS.CHART_HISTOGRAM, id, params],
		queryFn: () => getChartHistogram(id, queryParams),
		...options,
	});
};
