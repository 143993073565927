import { ChartBox } from './ChartSelect';
import { colors, MQB } from '@compstak/ui-kit';
import {
	VictoryChart,
	VictoryScatter,
	VictoryAxis,
	VictoryLabel,
	VictoryZoomContainer,
	VictoryLine,
	VictoryLabelProps,
} from 'victory';
import { StyledLink, Spacer, StyledList } from 'PortfolioAnalytics/UI';
import { styled } from 'styled-components';
import { usePortfolioPerformanceQuery } from 'api/portfolio/charts/usePortfolioPerformanceQuery.ts';
import { usePortfolioByIdQueryV2 } from 'api/portfolio/portfolioById/usePortfolioByIdQueryV2';
import { FiltersState } from 'PortfolioAnalytics/PortfolioFiltersProvider';
import {
	PerformanceChartPopup,
	DataPoint,
} from './PortfolioPerformanceChartPopup';
import { useState, useRef, useCallback, useMemo, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { PropertyPerformanceMatrixZoomControls } from './PropertyPerformanceMatrixZoomControls';
import {
	handleZoom,
	handleResetZoom,
	isChartZoomDomain,
} from './propertyPerformanceMatrixZoomUtils';
import { ChartStateWrapper } from './ChartStateWrapper';
import { CHART_AXIS_TICK_STYLE } from './chartsConstants';

const { blue500 } = colors.blue;
const { white } = colors.white;
const { gray700, gray200 } = colors.gray;
const { n30, n100, n300 } = colors.neutral;

type PropertyPerformanceChartProps = {
	portfolioId: number;
	filters: FiltersState;
};

type ChartZoomDomain = {
	x: [number, number];
	y: [number, number];
};

const DOMAIN_BUFFER_Y = 1.1;
const DOMAIN_BUFFER_X = 2;
const TICK_MULTIPLIERS = [1, 0.75, 0.5, 0.25];
const xTickValues = [
	...TICK_MULTIPLIERS.map((multiplier) => 100 * multiplier),
	0,
];
const minX = Math.min(...xTickValues);
const maxX = Math.max(...xTickValues);

export const PropertyPerformanceMatrixChart = ({
	portfolioId,
	filters,
}: PropertyPerformanceChartProps) => {
	return (
		<ChartBox
			chartName="Property Performance Matrix"
			displaySelector={false}
			chartToolTip={PORTFOLIO_PERFORMANCE_TOOLTIP}
			optionalTooltipProps={{ style: { maxWidth: 480 } }}
		>
			<PropertyPerformanceMatrixChartContent
				portfolioId={portfolioId}
				filters={filters}
			/>
		</ChartBox>
	);
};

export const PropertyPerformanceMatrixChartContent = ({
	portfolioId,
	filters,
}: PropertyPerformanceChartProps) => {
	const [activeDataPoint, setActiveDataPoint] = useState<DataPoint | null>(
		null
	);
	const scaleRef = useRef<{
		x: ((value: number) => number) | null;
		y: ((value: number) => number) | null;
	}>({ x: null, y: null });

	const {
		data,
		isFetching: isFetchingPortfolioPerformance,
		isError: isErrorPortfolioPerformance,
	} = usePortfolioPerformanceQuery(portfolioId, filters);
	const {
		data: portfolioV2,
		isFetching: isFetchingPortfolioById,
		isError: isErrorPortfolioById,
	} = usePortfolioByIdQueryV2({
		id: portfolioId,
	});
	const isFetching = isFetchingPortfolioPerformance || isFetchingPortfolioById;
	const isError = isErrorPortfolioPerformance || isErrorPortfolioById;
	const dataToUse = useMemo(() => data?.values || [], [data?.values]);
	const maxPerformance = useMemo(
		() => 100 * Math.max(...dataToUse.map((d) => Math.abs(d.performance))),
		[dataToUse]
	);
	const maxYTick = Math.ceil(maxPerformance / 2) * 2;
	const minYTick = -maxYTick;

	const yTickValues = useMemo(
		() => [
			...TICK_MULTIPLIERS.map((multiplier) => multiplier * minYTick),
			0,
			...TICK_MULTIPLIERS.slice()
				.reverse()
				.map((multiplier) => multiplier * maxYTick),
		],
		[minYTick, maxYTick]
	);

	const LowerXLimit = minX - DOMAIN_BUFFER_X;
	const UpperXLimit = maxX + DOMAIN_BUFFER_X;
	const LowerYLimit = minYTick * DOMAIN_BUFFER_Y;
	const UpperYLimit = maxYTick * DOMAIN_BUFFER_Y;

	const initialZoomDomain: ChartZoomDomain = useMemo(
		() => ({
			x: [LowerXLimit, UpperXLimit],
			y: [LowerYLimit, UpperYLimit],
		}),
		[LowerXLimit, UpperXLimit, LowerYLimit, UpperYLimit]
	);

	const [currentZoomDomain, setCurrentZoomDomain] = useState(initialZoomDomain);

	useEffect(() => {
		handleResetZoom(initialZoomDomain, setCurrentZoomDomain);
	}, [initialZoomDomain]);

	const transformedData = useMemo(
		() =>
			dataToUse.map((d) => ({
				performance: 100 * d.performance,
				risk: 100 * d.risk,
				reversedRisk: 100 * (1 - d.risk),
				size: 4.5,
				propertyId: d.propertyId,
			})),
		[dataToUse]
	);
	const is1280 = useMediaQuery({ minWidth: MQB.D_1280 });
	const isBelowDesktopScreenSize = !is1280;
	const is1470 = useMediaQuery({ minWidth: '1470px' });
	const is1366 = useMediaQuery({ minWidth: '1366px' });
	const isNarrowDesktopScreenSize = is1366 && !is1470;

	const chartStyles = {
		innerContainerwidth: '95%',
		chartWidth: '95%',
		chartHeight: '90%',
		chartPadding: { left: 65, top: 10, right: 0, bottom: 50 },
	};
	const handleDataPointClick = useCallback(
		(datum: DataPoint) => {
			activeDataPoint ? setActiveDataPoint(null) : setActiveDataPoint(datum);
		},
		[activeDataPoint]
	);

	const handleDataPointMouseOver = useCallback((datum: DataPoint) => {
		setActiveDataPoint(datum);
	}, []);

	const getPixelPosition = useCallback(
		(datum: DataPoint) => {
			const scales = scaleRef.current;
			if (
				!scales.x ||
				!scales.y ||
				typeof scales.x !== 'function' ||
				typeof scales.y !== 'function'
			) {
				return { x: 0, y: 0 };
			}

			const x = scales.x(datum.reversedRisk);
			const y = scales.y(datum.performance);

			if (isNaN(x) || isNaN(y)) {
				console.error('Invalid scale calculation results');
				return { x: 0, y: 0 };
			}
			const scaledX = x;
			const scaledY = isNarrowDesktopScreenSize
				? datum.performance > 80
					? y - 40
					: datum.performance > -20
						? y - 10
						: y - 20
				: datum.performance > 80
					? y - 20
					: datum.performance > 20
						? y
						: y + 20;
			return {
				x: scaledX,
				y: scaledY,
			};
		},
		[scaleRef, isNarrowDesktopScreenSize]
	);

	return (
		<ChartStateWrapper
			isError={isError}
			isFetching={isFetching}
			noData={transformedData.length === 0 || !data}
		>
			<MatrixChartRoot>
				<ZoomInstructions>
					Tip: Use the mouse wheel to zoom into the chart. Click and drag to
					pan.
				</ZoomInstructions>
				<PerformanceMatrixOuterContainer>
					<PerformanceMatrixInnerContainer
						innerContainerwidth={chartStyles.innerContainerwidth}
					>
						<LabelRow>
							<QuadrantLabel isNarrowDesktop={isNarrowDesktopScreenSize}>
								High Performer, High Exp. Risk
							</QuadrantLabel>
							<QuadrantLabel isNarrowDesktop={isNarrowDesktopScreenSize}>
								High Performer, Low Exp. Risk
							</QuadrantLabel>
						</LabelRow>
						<VictoryChart
							containerComponent={
								<VictoryZoomContainer
									onZoomDomainChange={(newZoomDomain: ChartZoomDomain) => {
										if (isChartZoomDomain(newZoomDomain)) {
											setCurrentZoomDomain(newZoomDomain);
										}
										setActiveDataPoint(null);
									}}
									zoomDomain={currentZoomDomain}
									minimumZoom={{ x: UpperXLimit / 4, y: UpperYLimit / 2 }}
									maximumZoom={{ x: LowerXLimit, y: LowerYLimit }}
									allowPan={true}
									constrainToVisibleArea={true}
									style={{
										border: 'none',
										padding: 0,
										width: chartStyles.chartWidth,
										height: chartStyles.chartHeight,
										cursor: 'move',
									}}
								/>
							}
							domainPadding={{ x: 20, y: 20 }}
							padding={chartStyles.chartPadding}
						>
							{/* X Axis */}
							<VictoryAxis
								tickValues={xTickValues}
								tickFormat={(tick: number) => `${100 - tick}%`}
								offsetY={43}
								tickLabelComponent={
									<VictoryLabel
										renderInPortal
										style={{ ...CHART_AXIS_TICK_STYLE, fontSize: 11 }}
									/>
								}
								style={{
									axis: { stroke: 'none' },
									tickLabels: {
										fontSize: 11,
										padding: 5,
										color: gray700,
									},
									grid: {
										stroke: n30,
										strokeWidth: 1,
										strokeDasharray: '4,4',
									},
								}}
							/>
							<XAxisTitle x={100} y={297} />
							{/* Y axis */}
							<YAxisTitle x={20} y={210} angle={-90} />
							<VictoryAxis
								dependentAxis
								tickValues={yTickValues}
								offsetX={70}
								tickLabelComponent={
									<VictoryLabel
										renderInPortal
										style={{ ...CHART_AXIS_TICK_STYLE, fontSize: 11 }}
									/>
								}
								tickFormat={(d: string) => `${d}%`}
								style={{
									axis: { stroke: 'none' },
									grid: {
										stroke: n30,
										strokeWidth: 1,
										strokeDasharray: '4,4',
									},
								}}
							/>
							{/* Vertical Divider */}
							<VictoryLine
								data={[
									{ x: 50, y: DOMAIN_BUFFER_Y * minYTick },
									{ x: 50, y: DOMAIN_BUFFER_Y * maxYTick },
								]}
								style={{
									data: { stroke: 'black', strokeWidth: 1 },
								}}
								x={() => 50}
							/>

							{/* Horizontal Divider */}
							<VictoryLine
								data={[
									{ x: -DOMAIN_BUFFER_X, y: 0 },
									{ x: maxX + DOMAIN_BUFFER_X, y: 0 },
								]}
								style={{
									data: { stroke: 'black', strokeWidth: 1 },
								}}
								y={() => 0}
							/>

							{/* Scatter Points */}
							<VictoryScatter
								data={transformedData}
								x="reversedRisk"
								y="performance"
								labels={({ datum }) => datum.y}
								style={{
									data: {
										fill: blue500,
										cursor: 'pointer',
									},
								}}
								events={[
									{
										target: 'data',
										eventHandlers: {
											onClick: (event, clickedProps) => {
												if (clickedProps.scale) {
													scaleRef.current = clickedProps.scale;
												}
												handleDataPointClick(clickedProps.datum);
											},
											onMouseOver: (event, hoveredProps) => {
												if (hoveredProps.scale) {
													scaleRef.current = hoveredProps.scale;
												}
												handleDataPointMouseOver(hoveredProps.datum);
											},
										},
									},
								]}
							/>
						</VictoryChart>
						<LabelRow>
							<QuadrantLabel isNarrowDesktop={isNarrowDesktopScreenSize}>
								Low Performer, High Exp. Risk
							</QuadrantLabel>
							<QuadrantLabel isNarrowDesktop={isNarrowDesktopScreenSize}>
								Low Performer, Low Exp. Risk
							</QuadrantLabel>
						</LabelRow>
						{activeDataPoint && portfolioV2 && !isBelowDesktopScreenSize && (
							<PerformanceChartPopup
								closePopup={() => {
									setActiveDataPoint(null);
								}}
								portfolio={portfolioV2}
								dataPoint={activeDataPoint}
								left={`${getPixelPosition(activeDataPoint).x}px`}
								top={`${getPixelPosition(activeDataPoint).y}px`}
							/>
						)}
					</PerformanceMatrixInnerContainer>
					<PropertyPerformanceMatrixZoomControls
						handleZoom={({ zoomOut }) =>
							handleZoom({
								zoomOut,
								initialZoomDomain,
								setZoomDomain: setCurrentZoomDomain,
							})
						}
						handleResetZoom={() =>
							handleResetZoom(initialZoomDomain, setCurrentZoomDomain)
						}
					/>
				</PerformanceMatrixOuterContainer>
				<PropertyPerformanceLegend>
					<PropertyPerformanceLegendColor />
					Property
				</PropertyPerformanceLegend>
			</MatrixChartRoot>
		</ChartStateWrapper>
	);
};

const MatrixChartRoot = styled.div`
	display: flex;
	flex-direction: column;
	padding-top: 1rem;
	font-size: 0.75rem;
	font-style: normal;
	font-weight: 400;
`;

const ZoomInstructions = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 3px 3px 3px 0px;
	background: ${gray200};
	place-self: flex-start;
	padding: 0.5em;
	color: ${n300};
	letter-spacing: 0.3px;
	margin-bottom: 1.2em;
`;

const PerformanceMatrixOuterContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 0.75em;
	width: 100%;
	max-width: 600px;
	justify-content: space-between;
	margin: auto;
`;

const PerformanceMatrixInnerContainer = styled.div<{
	innerContainerwidth: string;
}>`
	position: relative;
	width: ${({ innerContainerwidth }) => `${innerContainerwidth}`};
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
	justify-content: space-between;
	align-items: center;
	border-radius: 3px;
	border: 1px solid ${n100};
	min-height: 100%;
	margin: 0 auto;
`;

const LabelRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 82%;
	margin-left: 16%;
	margin-right: 2%;
`;

const QuadrantLabel = styled.div<{ isNarrowDesktop: boolean }>`
	border-radius: 2px;
	font-size: ${({ isNarrowDesktop: is1470 }) => (is1470 ? '0.6rem' : '0.7rem')};
	border: 1px solid ${n100};
	background: ${n100};
	height: 22px;
	padding: 5px 7px;
	color: ${white};
	text-align: center;
	line-height: 0.7rem;
	letter-spacing: 0.3px;
`;

const PropertyPerformanceLegend = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	column-gap: 0.4rem;
	height: 25px;
	margin-top: 0.4rem;
	width: 100%;
	text-align: center;
	color: ${({ theme }) => theme.colors.gray.gray700};
`;

const PropertyPerformanceLegendColor = styled.div`
	background-color: ${blue500};
	border-radius: 50%;
	height: 0.5rem;
	width: 0.5rem;
`;

const PORTFOLIO_PERFORMANCE_TOOLTIP = (
	<>
		<Spacer>
			The Property Performance Matrix plots the performance and risk of each
			property in the portfolio. Click on any property in the chart for more
			information.
		</Spacer>
		<Spacer>
			Performance represents the property's rent compared with CompStak's
			Estimated Starting Rent. It is based on the dominant space type of the
			property, and excludes subleases.
		</Spacer>
		<Spacer>
			Performance: (AvgInPlaceRent - EstStartingRent) / EstStartingRent
		</Spacer>
		<Spacer>
			<StyledList>
				<li>
					Avg. In Place Rent: calculated by weighting the rent of each lease by
					its square footage, providing a more accurate reflection of rental
					values.
				</li>
				<li>
					CompStak Estimated Starting Rent: an estimated rental price reflecting
					the current market value of this property. This figure indicates what
					the property might lease for today, taking into account historical
					data and lease conditions. Read{' '}
					<StyledLink
						href="https://compstak.com/blog/market-rents-on-compstak-whitepaper"
						target="_blank"
						rel="noreferrer"
					>
						this white paper
					</StyledLink>{' '}
					to learn more.
				</li>
			</StyledList>
		</Spacer>
		<Spacer>
			Risk: The percentage of lease SqFt expiring in the next 24 months.
		</Spacer>
		<Spacer>
			Only properties with a dominant space type of Office or Industrial are
			included.
		</Spacer>
	</>
);

const YAxisTitle = (props: VictoryLabelProps) => {
	const { x = 20, y = 210, angle = -90 } = props;
	const text = 'Avg. Rent vs. CompStak Est. Starting Rent';
	return (
		<g transform={`rotate(${angle}, ${x}, ${y})`}>
			<rect
				x={x - 72}
				y={y - 22}
				width={280}
				height={20}
				fill={n30}
				rx={2} // Rounded corners
			/>
			<text
				x={x - 60}
				y={y - 8}
				style={{
					fontSize: 12,
					fill: n300,
					letterSpacing: '0.3px',
				}}
			>
				{text}
			</text>
		</g>
	);
};

const XAxisTitle = (props: VictoryLabelProps) => {
	const { x = 100, y = 297 } = props;
	const text = '% of Lease SqFt expiring within 24 months';
	return (
		<g>
			<rect x={x - 10} y={y - 15} width={290} height={20} fill={n30} rx={2} />
			<text
				x={x}
				y={y - 2}
				style={{
					fontSize: 12,
					fill: n300,
					letterSpacing: '0.3px',
				}}
			>
				{text}
			</text>
		</g>
	);
};
