import { Spinner } from '@compstak/ui-kit';
import { usePortfolioByIdQuery } from 'api';
import {
	DashboardCardLoadingContainer,
	PortfolioOverviewSectionHeader,
} from 'PortfolioAnalytics/Dashboard/UI';
import { LeaseExpirationsBySpaceTypeChart } from './LeaseExpirationBySpaceType/LeaseExpirationsBySpaceTypeChart';
import { BuildingClassChart } from './BuildingClassChart';
import { BuildingClassByPropertySqftChart } from './BuildingClassByPropertySqftChart/BuildingClassByPropertySqftChart';
import { PropertyTypeByPropertySqftChart } from './PropertyType/PropertyTypeByPropertySqftChart';
import { PropertyTypeByPropertySqftChartV2 } from './PropertyType/PropertyTypeByPropertySqftChartV2';
import { PropertyCountByPropertySqftChart } from './PropertyCountByPropertySqftChart';
import { PropertyTypeChart } from './PropertyType/PropertyTypeChart';
import { TenantExposureChart } from './TenantExposure/TenantExposureChart';
import { TenantIndustryExposureChart } from './TenantIndustryExposure/TenantIndustryExposureChart';
import { PortfolioPerformanceDistributionChart } from './PortfolioPerformanceDistributionChart';
import { PropertyPerformanceMatrixChart } from './PropertyPerformanceMatrixChart';
import { usePortfolioFilters } from '../PortfolioFiltersProvider';
import { ChartsContainerV2, ChartsContainer_6_5 } from '../styles/PortfolioUI';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import styled from 'styled-components';
import { TenantExposureChartV2 } from './TenantExposure/TenantExposureChartV2';
import { LeaseExpirationsBySpaceTypeChartV2 } from './LeaseExpirationBySpaceType/LeaseExpirationsBySpaceTypeChartV2';
import { TenantIndustryExposureChartV2 } from './TenantIndustryExposure/TenantIndustryExposureChartV2';
import { BuildingClassByPropertySqftChartV2 } from './BuildingClassByPropertySqftChart/BuildingClassByPropertySqftChartV2';

type OverviewTabChartsProps = {
	portfolioId: number;
};

export const OverviewTabCharts = ({ portfolioId }: OverviewTabChartsProps) => {
	const {
		portfolioRevampPhase6_5,
		portfolioChartsV2EndpointFF,
		portfolioRevampPropertyCountChartFF,
	} = useFeatureFlags();

	const { filters } = usePortfolioFilters();

	const { data: portfolio, isFetching: isFetchingPortfolio } =
		usePortfolioByIdQuery(
			{
				id: portfolioId,
				filters,
			},
			{
				enabled: !portfolioChartsV2EndpointFF,
			}
		);

	if (
		!portfolioChartsV2EndpointFF &&
		(portfolio == null || isFetchingPortfolio)
	) {
		return (
			<DashboardCardLoadingContainer>
				<Spinner />
			</DashboardCardLoadingContainer>
		);
	}

	return (
		<>
			{portfolioRevampPhase6_5 && (
				<FlexContainer>
					<FlexContainer>
						<FlexContainer>
							<PortfolioOverviewSectionHeader>
								Risk Analysis
							</PortfolioOverviewSectionHeader>
							<ChartsContainer_6_5 wideStyle={true}>
								<PropertyPerformanceMatrixChart
									portfolioId={portfolioId}
									filters={filters}
								/>
								<PortfolioPerformanceDistributionChart
									portfolioId={portfolioId}
								/>
							</ChartsContainer_6_5>
						</FlexContainer>
						<FlexContainer>
							<ChartsContainer_6_5>
								{portfolioChartsV2EndpointFF ? (
									<>
										<LeaseExpirationsBySpaceTypeChartV2
											portfolioId={portfolioId}
											filters={filters}
										/>
										<TenantExposureChartV2
											portfolioId={portfolioId}
											filters={filters}
										/>
										<TenantIndustryExposureChartV2
											portfolioId={portfolioId}
											filters={filters}
										/>
									</>
								) : (
									<>
										<LeaseExpirationsBySpaceTypeChart portfolio={portfolio!} />
										<TenantExposureChart portfolio={portfolio!} />
										<TenantIndustryExposureChart portfolio={portfolio!} />
									</>
								)}
							</ChartsContainer_6_5>
						</FlexContainer>
					</FlexContainer>
					<FlexContainer>
						<PortfolioOverviewSectionHeader>
							Portfolio Breakdown
						</PortfolioOverviewSectionHeader>
						<ChartsContainer_6_5>
							{portfolioChartsV2EndpointFF ? (
								<PropertyTypeByPropertySqftChartV2
									portfolioId={portfolioId}
									filters={filters}
								/>
							) : (
								<PropertyTypeByPropertySqftChart portfolio={portfolio!} />
							)}
							{portfolioChartsV2EndpointFF ? (
								<BuildingClassByPropertySqftChartV2
									filters={filters}
									portfolioId={portfolioId}
								/>
							) : (
								<BuildingClassByPropertySqftChart portfolio={portfolio!} />
							)}

							{portfolioRevampPropertyCountChartFF && (
								<PropertyCountByPropertySqftChart
									portfolioId={portfolioId}
									filters={filters}
								/>
							)}
						</ChartsContainer_6_5>
					</FlexContainer>
				</FlexContainer>
			)}
			{!portfolioRevampPhase6_5 && (
				<ChartsContainerV2>
					<TenantExposureChart portfolio={portfolio!} />
					<TenantIndustryExposureChart portfolio={portfolio!} />
					<LeaseExpirationsBySpaceTypeChart portfolio={portfolio!} />
					<PropertyTypeChart portfolio={portfolio!} />
					<BuildingClassChart portfolio={portfolio!} />
				</ChartsContainerV2>
			)}
		</>
	);
};

const FlexContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;
