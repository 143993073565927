import { PortfolioFormFieldsV2, usePortfolioForm } from '../PortfolioForm';
import { useCreatePortfolioMutation } from 'api';
import {
	ModalButton,
	ModalButtons,
	ModalTitle,
} from 'Components/Modals/common/UI';
import { SuccessView } from './SuccessView';
import { useToast } from 'providers/ToastProvider';

export const CreateAPortfolioDialog = ({
	propertyIds,
	closeDialog,
	closeCreateAPortfolioDialog,
}: {
	propertyIds: number[];
	closeDialog: NoArgCallback;
	closeCreateAPortfolioDialog: NoArgCallback;
}) => {
	const { addToast } = useToast();

	const onError = () => {
		closeDialog();

		addToast({
			title: 'Adding properties to portfolio was unsuccessful',
			type: 'error',
		});
	};

	const {
		mutateAsync: createPortfolio,
		isLoading: isLoadingCreatePortfolio,
		data: createPortfolioData,
	} = useCreatePortfolioMutation({ onError });

	const portfolioForm = usePortfolioForm({});

	const isSubmitDisabled =
		portfolioForm.isFormLoading ||
		!portfolioForm.isValid ||
		isLoadingCreatePortfolio;

	const onSubmit = async () => {
		createPortfolio({
			...portfolioForm.state,
			propertyIds,
		});
	};
	return (
		<div>
			{createPortfolioData ? (
				<SuccessView
					portfolioId={createPortfolioData.id}
					closeDialog={closeDialog}
				/>
			) : (
				<>
					<ModalTitle>Create a New Portfolio</ModalTitle>
					<form
						onSubmit={(e) => {
							e.preventDefault();
							onSubmit();
						}}
					>
						<PortfolioFormFieldsV2 {...portfolioForm} action="create" />
						<ModalButtons>
							<ModalButton
								variant="secondary"
								type="button"
								onClick={closeCreateAPortfolioDialog}
							>
								Cancel
							</ModalButton>
							<ModalButton
								type="submit"
								variant="primary"
								disabled={isSubmitDisabled}
								isLoading={isLoadingCreatePortfolio}
							>
								Create Portfolio
							</ModalButton>
						</ModalButtons>
					</form>
				</>
			)}
		</div>
	);
};
