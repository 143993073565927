import React from 'react';

import Menu from 'Singletons/Menu';

import styles from '../styles/details.less';
import { LeaseComp } from 'types';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { LimitedDetailsLeaseBanner } from './LimitedDetailsLeaseBanner';
import { useIsExchange } from 'hooks';

type CompPartialMenuState = any;

class CompPartialMenu extends React.Component<{}, CompPartialMenuState> {
	constructor(props: {}) {
		super(props);
		this.state = {
			style: {
				display: '',
			},
		};
	}

	hidePartialCompMessage() {
		// Hacky way to hide popup imediatly because we are going to another page.
		this.setState({
			style: { display: 'none' },
		});
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'menuActions' does not exist on type 'Rea... Remove this comment to see the full error message
		this.props.menuActions.hideMenu();
	}

	render() {
		return (
			<div style={this.state.style} className={styles.leasePartialMenuContent}>
				<p>
					This comp is missing some key details but is visible because you
					submitted it.
				</p>
				<p>
					Read about how we credit submitted comps{' '}
					<a
						onClick={this.hidePartialCompMessage.bind(this)}
						href="/upload?section=about"
					>
						here
					</a>
					.
				</p>
			</div>
		);
	}
}

type CompPartialProps = {
	verifyButton: React.ReactNode;
	lease: LeaseComp;
};

const CompPartial = ({ verifyButton, lease, ...props }: CompPartialProps) => {
	const flags = useFeatureFlags();
	const isExchange = useIsExchange();

	const showPartialCompMessage = (
		event: React.MouseEvent<HTMLAnchorElement>
	) => {
		event.preventDefault();
		// @ts-expect-error Property 'menuActions' does not exist on type '{ comp: LeaseComp; }'.
		props.menuActions.showMenu(
			'lease-partial-menu',
			event.target,
			'below',
			props
		);
	};

	if (
		flags['AppsPromotionIterations3.5'] &&
		lease.hasLimitedDetails &&
		!isExchange
	) {
		return <LimitedDetailsLeaseBanner lease={lease} />;
	}

	return (
		<div className={styles.partialMessage}>
			<div className={styles.leftSide}>
				<span className={styles.warningIcon}>!</span>
				You submitted this comp but we still need some more details.{' '}
				<a
					href="#"
					onClick={(e) => {
						e.preventDefault();
						// @ts-expect-error Property 'menuActions' does not exist on type '{ comp: LeaseComp; }'.
						props.menuActions.showMenu(showPartialCompMessage);
					}}
				>
					Learn More
				</a>
			</div>
			<div className={styles.rightSide}>{verifyButton}</div>
		</div>
	);
};

export default CompPartial;

Menu.addComponent('lease-partial-menu', CompPartialMenu);
