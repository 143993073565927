import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {
	PortfolioDropdownMenuContentV2,
	PortfolioDropdownMenuItemV2,
} from '../UI';
import { useModal } from 'providers/ModalProvider';
import { PortfolioV2 } from 'api';
import { PortfolioModal } from 'PortfolioAnalytics/modals/PortfolioModal';
import { DeletePortfolioDialog } from '../modals/DeletePortfolioDialog';
import { usePortfolioPreferencesQuery } from 'api/portfolio/preferences/usePortfolioPreferencesQuery';
import { PortfolioNotificationFrequency } from 'api/portfolio/preferences/types';
import { Spinner } from '@compstak/ui-kit';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

type Props = {
	portfolio: PortfolioV2;
};

export const PortfolioOptionsMenu = ({ portfolio }: Props) => {
	const { openModal } = useModal();

	const { portfolioRevampAlertsAndNotificationsFF } = useFeatureFlags();

	const {
		data: portfolioPreferences,
		isFetching: isFetchingPortfolioPreferences,
	} = usePortfolioPreferencesQuery(
		{ portfolioId: portfolio.portfolio.id },
		{ enabled: portfolioRevampAlertsAndNotificationsFF }
	);

	const openUpdateDialog = () =>
		openModal({
			modalContent: (
				<PortfolioModal
					title="Edit Portfolio"
					action="edit"
					editedPortfolio={portfolio}
					initialFormState={{
						name: portfolio.portfolio.name,
						description: portfolio.portfolio.description ?? '',
						notificationFrequency:
							portfolioPreferences?.notificationFrequency ??
							PortfolioNotificationFrequency.Never,
					}}
				/>
			),
		});

	const openCopyDialog = () =>
		openModal({
			modalContent: (
				<PortfolioModal
					action="copy"
					title="Duplicate Portfolio"
					editedPortfolio={portfolio}
					initialFormState={{
						name: `${portfolio.portfolio.name} copy`,
						description: portfolio.portfolio.description ?? '',
						notificationFrequency:
							portfolioPreferences?.notificationFrequency ??
							PortfolioNotificationFrequency.Never,
					}}
				/>
			),
		});

	const openDeleteDialog = () =>
		openModal({
			modalContent: (
				<DeletePortfolioDialog portfolioId={portfolio.portfolio.id} />
			),
		});

	return (
		<DropdownMenu.Portal>
			<PortfolioDropdownMenuContentV2 side="right" align="start">
				{isFetchingPortfolioPreferences ? (
					<Spinner isCentered />
				) : (
					<>
						<PortfolioDropdownMenuItemV2 onClick={openUpdateDialog}>
							Edit Name, Description & Notifications
						</PortfolioDropdownMenuItemV2>
						<PortfolioDropdownMenuItemV2 onClick={openCopyDialog}>
							Duplicate Portfolio
						</PortfolioDropdownMenuItemV2>
						<PortfolioDropdownMenuItemV2 onClick={openDeleteDialog}>
							Delete Portfolio
						</PortfolioDropdownMenuItemV2>
					</>
				)}
			</PortfolioDropdownMenuContentV2>
		</DropdownMenu.Portal>
	);
};
