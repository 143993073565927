import { ModalParagraph, ModalTitle } from 'Components/Modals/common/UI';
import styled from 'styled-components';

type Props = {
	promotionTitle: string;
	promotionMarkets: string[];
};

export const PromotionMarketsDialog = ({
	promotionMarkets,
	promotionTitle,
}: Props) => {
	return (
		<div>
			<ModalTitle>Markets in this Promotion</ModalTitle>
			<ModalParagraph>
				The following markets are accepted as part of the promotion,{' '}
				<strong>{promotionTitle}</strong>.
			</ModalParagraph>
			<MarketsList>
				{promotionMarkets.map((m) => (
					<li key={m}>{m}</li>
				))}
			</MarketsList>
		</div>
	);
};

const MarketsList = styled.ol`
	max-height: 400px;
	overflow-y: auto;
	list-style-type: decimal;
	padding-left: 40px;
`;
