import { DataSet, Chart } from 'Pages/Analytics/analytics';
import { FiltersObject } from 'models/filters/types';
import { useEffect, useMemo } from 'react';
import { useSelectionState } from 'utils/useSelectionState';

import { useUnderlyingDataColumns } from './useUnderlyingDataColumns';
import { SEARCH_INFINITE_PAGE_SIZE, useSearchFilterParams } from 'api';
import {
	useSearchUnderlyingLeasesInfiniteQuery,
	UnderlyingLeasesInfiniteParams,
} from 'api/underlyingLeases/useUnderlyingLeasesInfiniteQuery';
import { UnderlyingLeasesCompAttributes } from 'api/underlyingLeases/types';
import { useInfiniteTableRows } from 'utils/useInfiniteTableRows';
import {
	VirtualTable,
	SimpleTableBodyCellRoot,
	SimpleTableHeaderCellRoot,
	colors,
} from '@compstak/ui-kit';
import { useNavigate, routes } from 'router';
import styled from 'styled-components';
import {
	useUnderlyingDataTableContext,
	COMP_VISIBILITY_OPTIONS,
} from '../UnderlyingDataTableProvider';
import { calculateMinDateBasedOnTimespan } from 'Components/Graphs/configGenerators/axes';
import { useUpdateChartExcludedCompsMutation } from './useUpdateExcludedCompIds';
import { FULL_DATA_THRESHOLD } from 'Components/Graphs/constants.ts';
import { pickPropertiesForInsightData } from 'Components/Graphs/util';

export const UnderlyingLeasesDataTable = ({
	dataSet,
	chart,
}: {
	dataSet: DataSet;
	chart: Chart;
}) => {
	const { timespan } = chart;

	const navigate = useNavigate();
	const {
		setSelectedCompIds,
		compVisibilitySelectedOption,
		excludedCompIdsForDataset,
		sortField,
		setSortField,
		sortDirection,
		setSortDirection,
		setUnderlyingDataCount,
	} = useUnderlyingDataTableContext();

	const dataSetHasExcludedComps = excludedCompIdsForDataset?.length > 0;

	const filtersWithCompIdAndSortingAndTimespan: FiltersObject = useMemo(() => {
		const minExecutionDateBasedOnTimespan =
			calculateMinDateBasedOnTimespan(timespan);
		const executionDateFilterBasedOnTimespan = {
			min: minExecutionDateBasedOnTimespan.toDate(),
			max: null,
		};
		const filtersWithUpdatedSortingAndTimespan = {
			...dataSet.filters,
			sortField,
			sortDirection,
			executionDate: executionDateFilterBasedOnTimespan,
		};
		if (!dataSetHasExcludedComps) {
			return filtersWithUpdatedSortingAndTimespan;
		}
		if (
			compVisibilitySelectedOption ===
			COMP_VISIBILITY_OPTIONS.showOnlyExcludedComps
		) {
			return {
				...filtersWithUpdatedSortingAndTimespan,
				compId: { value: excludedCompIdsForDataset, exclude: false },
			};
		}
		if (
			compVisibilitySelectedOption === COMP_VISIBILITY_OPTIONS.hideExcludedComps
		) {
			return {
				...filtersWithUpdatedSortingAndTimespan,
				compId: { value: excludedCompIdsForDataset, exclude: true },
			};
		}
		return filtersWithUpdatedSortingAndTimespan;
	}, [
		compVisibilitySelectedOption,
		excludedCompIdsForDataset,
		dataSet.filters,
		sortField,
		sortDirection,
	]);

	const propertiesForUnderlyingDateRequest = pickPropertiesForInsightData({
		y: dataSet.series,
	});

	const updateChartExcludedCompsMutation = useUpdateChartExcludedCompsMutation({
		chart,
		dataSet,
		filters: filtersWithCompIdAndSortingAndTimespan,
		properties: propertiesForUnderlyingDateRequest,
	});

	useEffect(() => {
		updateChartExcludedCompsMutation.mutate();
	}, [excludedCompIdsForDataset]);

	const params = useSearchFilterParams({
		filters: filtersWithCompIdAndSortingAndTimespan,
	});

	const underlyingDataParams: UnderlyingLeasesInfiniteParams = {
		...params,
		trendMonths: chart.trendMonths || 3,
		fullDataThreshold: FULL_DATA_THRESHOLD,
		properties: propertiesForUnderlyingDateRequest,
	};

	//When the user is viewing only excluded comps or only included comps, we still need the total count of underlying data.
	const excludedCompIdsFilter = underlyingDataParams.filter.find(
		(filter) => filter.property === 'id'
	);

	const underlyingDataParamsWithoutExcludedCompIdsFilter: UnderlyingLeasesInfiniteParams =
		{
			...underlyingDataParams,
			filter: underlyingDataParams.filter.filter(
				(filter) => filter !== excludedCompIdsFilter
			),
		};
	const { data: underlyingLeasesDatawithAllComps } =
		useSearchUnderlyingLeasesInfiniteQuery(
			underlyingDataParamsWithoutExcludedCompIdsFilter
		);
	useEffect(() => {
		underlyingLeasesDatawithAllComps &&
			setUnderlyingDataCount(
				underlyingLeasesDatawithAllComps?.pages[0].totalCount
			);
	}, [underlyingLeasesDatawithAllComps]);

	const {
		data: underlyingLeasesData,
		isFetching,
		fetchNextPage,
		hasNextPage,
	} = useSearchUnderlyingLeasesInfiniteQuery(underlyingDataParams);

	const rows = useInfiniteTableRows({
		data: underlyingLeasesData,
		getRows: (page) => page.comps,
		isFetching,
		pageSize: SEARCH_INFINITE_PAGE_SIZE,
	});
	const rowsToDisplayInTable = useMemo(() => {
		return compVisibilitySelectedOption ===
			COMP_VISIBILITY_OPTIONS.showOnlyExcludedComps && !dataSetHasExcludedComps
			? []
			: rows;
	}, [compVisibilitySelectedOption, rows, excludedCompIdsForDataset]);

	const rowSelectionMethods = useSelectionState(rows);

	useEffect(() => {
		setSelectedCompIds(rowSelectionMethods.selection);
	}, [rowSelectionMethods.selection]);

	useEffect(() => {
		rowSelectionMethods.resetSelection();
	}, [excludedCompIdsForDataset]);

	const underlyingColumns = useUnderlyingDataColumns({
		rowSelectionMethods,
		filters: filtersWithCompIdAndSortingAndTimespan,
		onSort: (columnId: keyof UnderlyingLeasesCompAttributes) => {
			const isSame = sortField === columnId;
			const updatedSortDirection = isSame
				? sortDirection === 'desc'
					? 'asc'
					: 'desc'
				: 'desc';
			setSortField(columnId);
			setSortDirection(updatedSortDirection);
		},
	});

	return (
		<Wrapper cellLeftPadding="0">
			<VirtualTable
				id="chartbuilder-underlying-data"
				rows={rowsToDisplayInTable}
				columns={underlyingColumns}
				maxBodyHeight={600}
				bodyRowHeight={48}
				isBodyHoverable
				getBodyCellProps={({ row, columnIndex }) => ({
					onClick: () => {
						if (columnIndex === 0) {
							return;
						}
						navigate(routes.leaseById.toHref({ id: row.id }));
					},
					style:
						excludedCompIdsForDataset &&
						excludedCompIdsForDataset.includes(row.id)
							? { color: colors.neutral.n70, fontStyle: 'italic' }
							: {},
				})}
				onLoadMore={() => {
					if (hasNextPage && !isFetching) {
						fetchNextPage();
					}
				}}
				isLoadingMore={isFetching}
			/>
		</Wrapper>
	);
};

const Wrapper = styled.div<{ cellLeftPadding: string }>`
	${SimpleTableBodyCellRoot} {
		div {
			padding-right: 2px;
		}
	}
	${SimpleTableBodyCellRoot}, ${SimpleTableHeaderCellRoot} {
		padding-left: ${({ cellLeftPadding }) => cellLeftPadding};
	}
`;
