import {
	CompSectionedMapping,
	FormattedComp,
	formatCompAttribute,
	formatValue,
} from 'util/comp-format/src/format';
import CompSection from './CompSection';
import { LeaseComp, PropertyComp } from 'types';
import { usePropertyByIdQuery } from 'api';
import { Flex, Spinner } from '@compstak/ui-kit';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useMemo } from 'react';
import { list } from 'util/comp-format/attributes';
import {
	getDiffBetweenPropertyAndLease,
	transformLeaseToPropertyAttribute,
} from './CompSections.utils';
import styled from 'styled-components';

type CompSectionsProps = {
	sections: string[];
	comp: LeaseComp;
	sectionedAttrs: CompSectionedMapping;
};

export const CompSections = ({
	sections,
	comp,
	sectionedAttrs,
}: CompSectionsProps) => {
	const { updatedLeaseDetailView: updatedLeaseDetailViewFF } =
		useFeatureFlags();
	const {
		data: property,
		isLoading: isPropertyLoading,
		error: propertyError,
	} = usePropertyByIdQuery(
		{ id: comp.propertyId },
		{ enabled: updatedLeaseDetailViewFF, refetchOnWindowFocus: false }
	);

	// In case if given property attributes are not equal to comp attributes
	// we need to add those attributes to Space section
	// and delete those attributes from Building section
	const reOrderedAttributes = useMemo(() => {
		if (!property || propertyError) {
			return sectionedAttrs;
		}

		const { spaceAttributes, buildingAttributes } =
			getDiffBetweenPropertyAndLease({
				comp,
				property,
			});

		const additionalSpaceAttributes = spaceAttributes
			.map(({ name }) => {
				return sectionedAttrs.Building.find((attr) => attr.name === name);
			})
			.filter(Boolean) as FormattedComp[];

		const attributesSettings = list('lease');
		const buildingAttributesWithoutLeaseAttributes =
			sectionedAttrs.Building.filter(
				(item) => !buildingAttributes.find(({ name }) => name === item.name)
			);
		const additionalBuildingAttributes = attributesSettings
			.filter((attributeSetting) => {
				return buildingAttributes.find(
					(buildingAttribute) =>
						buildingAttribute.name === attributeSetting.name
				);
			})
			.map((attributeSettings) => {
				const compAttribute = formatCompAttribute({
					comp,
					compType: 'lease',
					attributeSettings,
				});
				const propertyAttributeName = transformLeaseToPropertyAttribute(
					compAttribute.name
				) as keyof PropertyComp;
				const updatedPropertyValue = property[propertyAttributeName];
				const formattedValue = formatValue(
					compAttribute.name as keyof PropertyComp,
					updatedPropertyValue,
					undefined,
					'lease'
				);

				return {
					...compAttribute,
					value: formattedValue,
				};
			});

		const spaceSectionAttributes = [
			...sectionedAttrs.Space,
			...additionalSpaceAttributes,
		];
		const buildingSectionAttributes = [
			...buildingAttributesWithoutLeaseAttributes,
			...additionalBuildingAttributes,
		];

		return {
			...sectionedAttrs,
			Space: spaceSectionAttributes,
			Building: buildingSectionAttributes,
		};
	}, [sectionedAttrs, property, comp, propertyError]);

	if (isPropertyLoading && updatedLeaseDetailViewFF) {
		return (
			<Flex justifyContent="center">
				<Spinner />
			</Flex>
		);
	}

	return (
		<SectionsContainer>
			{sections.map((sectionName) => {
				return (
					<CompSection
						key={sectionName}
						section={sectionName}
						comp={comp}
						sectionedAttrs={reOrderedAttributes}
					/>
				);
			})}
		</SectionsContainer>
	);
};

const SectionsContainer = styled.div`
	column-width: 290px;
	column-gap: 25px;
`;
