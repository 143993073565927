import { useUnlockConditions } from 'api';

type Props = {
	comps: { id: number; cost: number }[];
};

export const useUnlockButtonLongText = ({ comps }: Props) => {
	const { freeCompsCount, totalCost, isUserFetching } = useUnlockConditions({
		comps,
	});

	let insideParens = '0';

	if (isUserFetching) {
		return '';
	}

	if (freeCompsCount > 0 && totalCost === 0) {
		insideParens = 'Free';
	} else if (totalCost === 1) {
		insideParens = `${totalCost} Credits`;
	} else {
		insideParens = `${totalCost} Credits`;
	}

	return `Unlock (${insideParens})`;
};
