import { Market } from '@compstak/common';
import { FormSelect, Spinner } from '@compstak/ui-kit';
import { useHasMufaChartbuilderAccess } from 'hooks/chartBuilderHooks';
import { FiltersObject } from 'models/filters/types';
import { mufaLevelFilters } from 'models/filters/types';
import { DataSet, DataSetType } from 'Pages/Analytics/analytics';
import { addDataSets } from 'Pages/Analytics/Builder/actions';
import {
	attributeToDataSetType,
	dataSetTypeToSeriesMap,
	DEFAULT_CHART,
	MAX_DATASETS_ALLOWED,
} from 'Pages/Analytics/Builder/chartBuilderConstants';
import {
	getCompTypeFromDataSetType,
	getDataSetTypesFromUrlSearchParam,
} from 'Pages/Analytics/Builder/chartBuilderHelpers';
import { NoAnalyticsAccessModal } from 'Components/Modals/NoAnalyticsAccessModal/NoAnalyticsAccessModal';
import { DRAFTS_PROJECT_NAME } from 'Pages/Analytics/config';
import { saveChartDraftAndNewDataSets } from 'Pages/Analytics/Repository/actions';
import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { AnalyticsImportSearchParams, routes } from 'router';
import { CompType } from 'types';
import { useAppDispatch } from 'util/useAppDispatch';
import {
	getAttrOptionsForCreateDSForm,
	mapMarketsToSelectOptionsAndSort,
} from './CreateNewDataSetV2/utils';
import { isSet } from 'models/filters/util/getSetFilters';
import { setFilters } from 'models/filters/util/setFilters';
import { filtersFromQueryString } from 'models/filters/util/urls';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { RouteComponentProps } from 'router/migration/types';
import { useMarkets } from 'hooks/useMarkets';
import {
	Modal,
	ModalButton,
	ModalButtons,
	ModalInputLabel,
	ModalParagraph,
	ModalTitle,
} from 'Components/Modals/common/UI';
import { ChartBuilderMarketFormSelect } from 'Pages/Analytics/Components/Modals/ChartBuilderMarketFormSelect';
import { useHistoryState } from 'router/HistoryProvider';
import { getExpirationaAndExecutionDateFilterWarning } from '../analyticsModalHelpers';
import { useFilters } from 'reducers/filtersReducer';
import { useAppSelector } from 'util/useAppSelector';
import { useNavigate } from 'react-router';
import { AttributeToPlotType } from 'Pages/Analytics/Builder/chartBuilderConstants';
import { useMarketsHaveFeature } from 'api';

enum ModalType {
	CHART_IN_PROGRESS = 'CHART_IN_PROGRESS',
	DATASET_LIMIT = 'DATASET_LIMIT',
	TITLE = 'TITLE',
	CHART_SAVED = 'CHART_SAVED',
	NO_PERMISSION = 'NO_PERMISSION',
}

type ImportDataSetProps = RouteComponentProps<
	unknown,
	AnalyticsImportSearchParams
>;

type AttributeToPlotState = AttributeToPlotType | '';

const ImportDataSetBase = ({ location }: ImportDataSetProps) => {
	const {
		series,
		tab,
		'dataset-types': dataSetTypes,
		propertyId,
		title: _title,
	} = location.query;

	const markets = useMarkets();
	const [filters] = useFilters();
	const { chartDraft, error, loading } = useAppSelector(
		(store) => store.chartBuilder
	);
	const selection = useAppSelector((store) => store.selection);

	const { data: _hasAnalyticsAccess } = useMarketsHaveFeature({
		feature: 'analytics',
		marketIds: getFiltersMarkets(filters).map((market) => market.id),
	});

	const hasAnalyticsAccess = _hasAnalyticsAccess && !error;

	const [modalType, setModalType] = useState(() => {
		if (!hasAnalyticsAccess) {
			return ModalType.NO_PERMISSION;
		}

		if (!chartDraft || chartDraft.dataSets.length === 0) {
			return ModalType.TITLE;
		}

		const currentDatasetsAmount = chartDraft.dataSets?.length ?? 0;
		const expectedAmount = currentDatasetsAmount + 1;
		const exceedsDatasetLimit = expectedAmount > MAX_DATASETS_ALLOWED;

		return exceedsDatasetLimit
			? ModalType.DATASET_LIMIT
			: ModalType.CHART_IN_PROGRESS;
	});

	useLayoutEffect(() => {
		if (!hasAnalyticsAccess) {
			setModalType(ModalType.NO_PERMISSION);
		}
	}, [hasAnalyticsAccess]);

	const shouldAddToCurrentChart = useRef(true);
	const [title, setTitle] = useState(_title ?? '');
	const [marketName, setMarketName] = useState('');
	const [attributeToPlot, setAttributeToPlot] =
		useState<AttributeToPlotState>('');

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const urlMarkets: Market[] = useMemo(() => {
		try {
			const { market: queryMarket, markets: queryMarkets } =
				filtersFromQueryString({ compType: tab, markets, queryString: series });
			if (!queryMarkets) return [queryMarket];

			return queryMarkets;
		} catch (e) {
			console.error('something went wrong parsing the url params');
			return [];
		}
	}, [tab, markets, series]);

	const redirectToChartBuilder = () => {
		navigate(routes.analytics.path);
	};

	const getDataSetTypes = () => {
		return getDataSetTypesFromUrlSearchParam(dataSetTypes);
	};

	const getSelected = () => {
		const selected = selection.list;
		if (!Array.isArray(selected)) {
			console.error('Selected should be an array!?', selected);
			return [];
		}
		return selected;
	};

	const getFiltersFromUrlSearchParams = (dataSetType: DataSetType) => {
		const propertyIdSearchParam = `${
			dataSetType === DataSetType.MUFA ? 'id' : 'propertyId'
		}=${propertyId}`;
		const queryString = [
			series,
			...(propertyId ? [propertyIdSearchParam] : []),
		].join('&');

		let filters = filtersFromQueryString({
			compType:
				dataSetType === DataSetType.MUFA
					? 'mufa'
					: getCompTypeFromDataSetType(dataSetType),
			markets,
			queryString,
			extraOptions: {
				ignoreErrors: true,
			},
		});
		const currentMarkets = getFiltersMarkets(filters);
		// @ts-expect-error TS2345: Argument of type 'null' is not...
		filters = setFilters(filters, 'markets', null);

		if (currentMarkets.length > 1) {
			filters = setFilters(filters, 'market', markets[marketName]);
		} else if (currentMarkets.length === 1) {
			filters = setFilters(filters, 'market', currentMarkets[0]);
		}
		// Added PropertyId because if user is on lease tab,
		// their id will be id of Lease, not propertyId
		// so now if user is on lease tab it will get a propertyId as value
		const selectedIds = [
			// @ts-expect-error what
			...new Set(getSelected().map(({ id, propertyId }) => propertyId ?? id)),
		];

		if (selectedIds.length < 1) {
			return filters;
		}
		if (dataSetType === DataSetType.MUFA) {
			return setFilters(filters, 'id', selectedIds);
		}
		return setFilters(filters, 'propertyId', selectedIds);
	};

	const getNewDataSetsFromUrlSearchParams = () => {
		const dataSetTypes = getDataSetTypes();
		const dataSets: DataSet[] = dataSetTypes.map((dst, idx) => {
			console.assert(
				idx !== 1 || dst === DataSetType.MUFA,
				'Second dataset type should always be MUFA?!'
			);
			const dataSet: DataSet = {
				name: idx === 1 ? `${title} (Multifamily)` : title,
				series: dataSetTypeToSeriesMap[dst],
				filters: getFiltersFromUrlSearchParams(dst),
				isVisible: true,
				type: dst,
			};
			return dataSet;
		});
		return dataSets;
	};

	const getDataSets = () => {
		if (tab === 'property' && attributeToPlot) {
			const dataSetType = attributeToDataSetType[attributeToPlot];
			const dataSet: DataSet = {
				name: title,
				type: dataSetType,
				filters: getFiltersFromUrlSearchParams(dataSetType),
				isVisible: true,
				series: attributeToPlot,
			};
			return [dataSet];
		} else {
			return getNewDataSetsFromUrlSearchParams();
		}
	};

	const addToCurrentChart = () => {
		dispatch(addDataSets(chartDraft ?? DEFAULT_CHART, getDataSets(), markets));
		redirectToChartBuilder();
	};

	const createNewChartAndSaveCurrent = () => {
		dispatch(saveChartDraftAndNewDataSets(getDataSets()));
		redirectToChartBuilder();
	};

	const chartInProgressActions = {
		onAddToCurrent: () => {
			setModalType(ModalType.TITLE);
			shouldAddToCurrentChart.current = true;
		},
		onCreateNew: () => {
			setModalType(ModalType.CHART_SAVED);
			shouldAddToCurrentChart.current = false;
		},
	};

	const dataSetLimitActions = {
		onReviewCurrent: () => {
			redirectToChartBuilder();
		},
		onCreateNew: () => {
			setModalType(ModalType.TITLE);
			shouldAddToCurrentChart.current = false;
		},
	};

	const titleActions = {
		onSubmit: () => {
			if (shouldAddToCurrentChart.current) {
				addToCurrentChart();
			} else {
				createNewChartAndSaveCurrent();
			}
		},
	};

	const chartSavedActions = {
		onGoBack: () => {
			setModalType(ModalType.CHART_IN_PROGRESS);
		},
		onOk: () => {
			setModalType(ModalType.TITLE);
		},
	};

	if (loading) {
		return <Spinner isCentered size="xl" />;
	}

	if (urlMarkets.length === 0) {
		return (
			<div>
				The URL is malformed. Please try again, starting from the beginning.
			</div>
		);
	}

	switch (modalType) {
		case ModalType.CHART_IN_PROGRESS:
			return (
				<ChartInProgressModal
					onAddToCurrent={chartInProgressActions.onAddToCurrent}
					onCreateNew={chartInProgressActions.onCreateNew}
				/>
			);
		case ModalType.DATASET_LIMIT:
			return (
				<DataSetLimitModal
					onReviewCurrent={dataSetLimitActions.onReviewCurrent}
					onCreateNew={dataSetLimitActions.onCreateNew}
				/>
			);
		case ModalType.TITLE: {
			return (
				<TitleModal
					title={title}
					marketName={marketName}
					onChangeTitle={setTitle}
					onChangeMarketName={setMarketName}
					attributeToPlot={attributeToPlot}
					onChangeAttributeToPlot={setAttributeToPlot}
					onSubmit={titleActions.onSubmit}
					compType={tab}
					urlMarkets={urlMarkets}
					filters={filters}
					getFiltersFromUrlSearchParams={getFiltersFromUrlSearchParams}
				/>
			);
		}
		case ModalType.CHART_SAVED:
			return (
				<ChartSavedModal
					onGoBack={chartSavedActions.onGoBack}
					onOk={chartSavedActions.onOk}
				/>
			);
		case ModalType.NO_PERMISSION:
			return <NoPermissionModal />;
		default:
			return <div />;
	}
};

export const ImportDataSetLayout = (props: ImportDataSetProps) => {
	const { goBack } = useHistoryState();

	return (
		<Modal onClose={goBack}>
			<ImportDataSetBase {...props} />
		</Modal>
	);
};

const ChartInProgressModal = ({
	onAddToCurrent,
	onCreateNew,
}: {
	onAddToCurrent: () => void;
	onCreateNew: () => void;
}) => {
	return (
		<>
			<ModalTitle>Chart In Progress</ModalTitle>
			<ModalParagraph>
				You currently have an Analytics Chart in progress.
			</ModalParagraph>
			<ModalButtons>
				<ModalButton
					dataTestId="add-to-current-chart-btn"
					variant="secondary"
					onClick={onAddToCurrent}
				>
					Add To Current Chart
				</ModalButton>
				<ModalButton
					dataTestId="create-new-chart-btn"
					variant="primary"
					onClick={onCreateNew}
				>
					Create New Chart
				</ModalButton>
			</ModalButtons>
		</>
	);
};

const ChartSavedModal = ({
	onGoBack,
	onOk,
}: {
	onGoBack: () => void;
	onOk: () => void;
}) => {
	return (
		<>
			<ModalTitle>Current Chart Saved</ModalTitle>
			<ModalParagraph>
				The chart you previously created will be saved under{' '}
				{DRAFTS_PROJECT_NAME} in My Projects.
			</ModalParagraph>
			<ModalButtons>
				<ModalButton
					dataTestId="chart-saved-modal-go-back-btn"
					variant="secondary"
					onClick={onGoBack}
				>
					Go Back
				</ModalButton>
				<ModalButton
					dataTestId="chart-saved-modal-ok-btn"
					variant="primary"
					onClick={onOk}
				>
					OK
				</ModalButton>
			</ModalButtons>
		</>
	);
};

const DataSetLimitModal = ({
	onReviewCurrent,
	onCreateNew,
}: {
	onReviewCurrent: () => void;
	onCreateNew: () => void;
}) => {
	return (
		<>
			<ModalTitle>Data Set Limit</ModalTitle>
			<ModalParagraph>
				You currently have an Analytics chart in progress. Sending this data to
				this chart will exceed the {MAX_DATASETS_ALLOWED} data sets limit.
				<br />
				<br />
				Would you like to add selected data set to the current chart, discard
				your current chart, or save the current chart to start a new chart?
			</ModalParagraph>
			<ModalButtons>
				<ModalButton
					dataTestId="dataset-limit-modal-review-current-btn"
					onClick={onReviewCurrent}
					variant="secondary"
				>
					Review Current Chart
				</ModalButton>
				<ModalButton
					dataTestId="dataset-limit-modal-create-new-btn"
					variant="primary"
					onClick={onCreateNew}
				>
					Create New Chart
				</ModalButton>
			</ModalButtons>
		</>
	);
};

interface TitleModalProps {
	title: string;
	marketName: string;
	onChangeTitle: (title: string) => void;
	onChangeMarketName: (marketName: string) => void;
	attributeToPlot: AttributeToPlotType | string;
	onChangeAttributeToPlot: (attributeToPlot: AttributeToPlotState) => void;
	compType: CompType;
	onSubmit: () => void;
	urlMarkets: Market[];
	filters: FiltersObject;
	getFiltersFromUrlSearchParams: (dataSetType: DataSetType) => FiltersObject;
}

const TitleModal = ({
	title,
	marketName,
	onChangeTitle,
	onChangeMarketName,
	attributeToPlot,
	onChangeAttributeToPlot,
	compType,
	onSubmit,
	urlMarkets,
	filters,
	getFiltersFromUrlSearchParams,
}: TitleModalProps) => {
	const leaseFiltersFromUrlSearchParams = getFiltersFromUrlSearchParams(
		DataSetType.COMMERCIAL
	);
	const hasMufaChartBuilderAccess = useHasMufaChartbuilderAccess();
	const { salesCompsAnalytics2 } = useFeatureFlags();
	const showMultiMarket = urlMarkets.length > 1;

	const incompatibleFiltersToDataSetType = useMemo(() => {
		if (
			compType === 'property' &&
			// @ts-expect-error TS7053: Element implicitly has an 'any...
			attributeToDataSetType[attributeToPlot] === DataSetType.COMMERCIAL
		) {
			return Object.keys(mufaLevelFilters).some((filter) =>
				// @ts-expect-error TS2345: Argument of type 'string' is n...
				isSet(filters[filter], filter)
			);
		}
		return false;
	}, [attributeToPlot, compType, filters]);

	const attributeToPlotItems = getAttrOptionsForCreateDSForm(
		hasMufaChartBuilderAccess,
		salesCompsAnalytics2
	);

	let canSubmit = title.length > 0;
	if (compType === 'property') {
		canSubmit = canSubmit && attributeToPlot.length > 0;
	}
	if (showMultiMarket) {
		canSubmit = canSubmit && !!marketName;
	}

	const incompatibleFiltersWarningMessage =
		getExpirationaAndExecutionDateFilterWarning(
			leaseFiltersFromUrlSearchParams
		);
	return (
		<>
			<form
				onSubmit={(e) => {
					e.preventDefault();
					onSubmit();
				}}
			>
				<ModalTitle>Title Data Set</ModalTitle>
				<ModalInputLabel>
					Title Your Data Set
					<input
						type="text"
						data-qa-id="set-dataset-title"
						placeholder="Enter name here"
						value={title}
						onChange={(ev) => {
							onChangeTitle(ev.target.value);
						}}
					></input>
				</ModalInputLabel>
				{compType === 'lease' && incompatibleFiltersWarningMessage && (
					<ModalParagraph>{incompatibleFiltersWarningMessage}</ModalParagraph>
				)}
				{showMultiMarket && (
					<ChartBuilderMarketFormSelect
						items={mapMarketsToSelectOptionsAndSort(urlMarkets)}
						value={marketName}
						onChange={({ selectedItem }) => {
							onChangeMarketName(selectedItem?.value.toString() ?? '');
						}}
					/>
				)}
				{compType === 'property' && (
					<div>
						<ModalInputLabel>
							Attribute to Plot
							<FormSelect
								data-qa-id="select-attribute-to-plot"
								items={attributeToPlotItems}
								onChange={({ selectedItem }) => {
									onChangeAttributeToPlot(
										selectedItem?.value == null
											? ''
											: (selectedItem.value as AttributeToPlotType)
									);
								}}
								value={attributeToPlot}
								placeholder="Select from Dropdown"
							/>
						</ModalInputLabel>
						{incompatibleFiltersToDataSetType && (
							<ModalParagraph>
								RealPage Multifamily Filters will only be applied to RealPage
								Attributes
							</ModalParagraph>
						)}
					</div>
				)}
				<ModalButtons>
					<ModalButton
						type="submit"
						dataTestId="title-modal-done-btn"
						disabled={!canSubmit}
					>
						Done
					</ModalButton>
				</ModalButtons>
			</form>
		</>
	);
};

const NoPermissionModal = () => {
	const { goBack } = useHistoryState();

	return (
		<Modal onClose={goBack}>
			<NoAnalyticsAccessModal onClose={goBack} />
		</Modal>
	);
};
