import { API } from '@compstak/common';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from 'api/constants';
import { SPECIAL_USER_TAGS } from 'constants/userTagConstants';
import { useUser } from 'Pages/Login/reducers';
import { mapUrlToGateway } from 'utils/gatewayUtil';
import { z } from 'zod';
import {
	Promotion,
	PromotionState,
} from '../../Pages/Promotions/promotionTypes';
import { promotionValidator } from './../../Pages/Promotions/promotionTypes';

const HIDE_PROMOTIONS_TAG = SPECIAL_USER_TAGS.hidePromotions;

export function useUserCanSeePromotions() {
	const userTags: string[] = useUser()?.tags ?? [];
	const shouldHideBasedOnTag: boolean =
		!!HIDE_PROMOTIONS_TAG &&
		userTags.some((tag) => tag.trim().toLowerCase() === HIDE_PROMOTIONS_TAG);
	return !shouldHideBasedOnTag;
}

export function useExchangeTeamMemberCanSeePromotions() {
	// TODO: Check user permissions and redirect if permissions are missing.
	// Note: Even if a regular user would go the promo preview url,
	// the fetch for the preview promo would fail.
	return true;
}

const retry2times = (failureCount: number) => failureCount < 2;

export function useGetPromotion(promoId: number) {
	return useQuery({
		queryKey: [QUERY_KEYS.PROMOTION, promoId],
		queryFn: async (): Promise<Promotion> => {
			const response = await API.get(
				mapUrlToGateway(`/user-service/admin/promotions/${promoId}`)
			);
			if (response.status >= 400) {
				throw Error(`Failed to fetch promotion with id ${promoId}`);
			}
			const promo = promotionValidator.parse(response.data);
			const parsedPromo = parseValidatedPromo(promo);
			return parsedPromo;
		},
		retry: retry2times,
	});
}

export function useGetPublicPromotions() {
	return useQuery({
		queryKey: [QUERY_KEYS.PROMOTIONS],
		queryFn: async (): Promise<Promotion[]> => {
			const response = await API.get(
				mapUrlToGateway('/user-service/user/promotions')
			);
			if (response.status >= 400) {
				throw Error('Failed to fetch promotions');
			}
			const { promotions: validatedPromos } = z
				.object({ promotions: z.array(promotionValidator) })
				.parse(response.data);

			const mappedPromos = validatedPromos
				.map(parseValidatedPromo)
				.sort((a, b) => {
					const dateA = new Date(a.marketingStartDate).valueOf();
					const dateB = new Date(b.marketingStartDate).valueOf();
					if (dateA === dateB) {
						return a.id - b.id;
					}
					return dateA - dateB;
				});
			return mappedPromos;
		},
		retry: retry2times,
	});
}

export function useLaunchPromoMutation() {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: async (promoId: number) => {
			const res = await API.post(
				mapUrlToGateway(`/user-service/admin/promotions/${promoId}/launch`)
			);
			if (res.status >= 400) {
				const err = new Error(`Failed to launch promotion with id ${promoId}`);
				// @ts-expect-error TS2339: Property 'data' does not exist...
				err.data = res.data;
				throw err;
			}
			await queryClient.invalidateQueries([QUERY_KEYS.PROMOTIONS]);
			await queryClient.invalidateQueries([QUERY_KEYS.PROMOTION, promoId]);
		},
	});
}

function parseValidatedPromo(
	validatedPromo: z.infer<typeof promotionValidator>
) {
	return {
		...validatedPromo,
		state: validatedPromo.state as PromotionState,
	};
}
