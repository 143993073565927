import { ButtonProps, Download, TooltipV2 } from '@compstak/ui-kit';
import * as Popover from '@radix-ui/react-popover';
import { useState } from 'react';
import { CompExportPopup } from './CompExportPopup';
import pluralizeCompType from 'util/pluralizeCompType';
import { CompType, PropertyComp } from 'types';
import { formatInteger } from 'format';
import { useMediaQuery } from 'react-responsive';
import { ACTION_COLUMN_BREAKPOINT } from 'Components/SearchTable/constants';
import { PropertyLease, PropertySale } from 'api';
import styled from 'styled-components';
import { ExportOrUnlockButtonBase, ExportOrUnlockButtonType } from 'ui/UI';

type Props<T extends CompType> = {
	compIds: number[];
	isMufa?: boolean;
	getComps?: () => (T extends 'lease'
		? PropertyLease
		: T extends 'sale'
			? PropertySale
			: PropertyComp)[];
	compType: T;
	buttonType: ExportOrUnlockButtonType;
} & ButtonProps;

export const ExportCompsButton = <T extends CompType>({
	compType,
	compIds,
	getComps,
	buttonType,
	isMufa = false,
	...props
}: Props<T>) => {
	const [isPopupOpen, setIsPopupOpen] = useState(false);

	const isCompact = useMediaQuery({ maxWidth: ACTION_COLUMN_BREAKPOINT });

	const tooltipText =
		buttonType === 'table'
			? compIds.length === 0
				? `Select ${pluralizeCompType(compType, false)} to export`
				: `Export multiple ${pluralizeCompType(compType, false)}`
			: 'Export comp';

	const buttonText =
		buttonType === 'table' ? (
			`Export (${formatInteger(compIds.length)})`
		) : buttonType === 'standalone' ? (
			'Export'
		) : !isCompact && buttonType === 'wide-row' ? (
			<>
				<span>|</span>
				<span>Export</span>
			</>
		) : undefined;

	return (
		<Popover.Root open={isPopupOpen} onOpenChange={setIsPopupOpen}>
			<TooltipV2 content={tooltipText}>
				<Popover.Trigger asChild>
					<ExportButton
						variant="primary"
						dataTestId="export-button"
						disabled={buttonType === 'table' ? compIds.length === 0 : undefined}
						onClick={() => setIsPopupOpen(true)}
						size={buttonType === 'row' || buttonType === 'wide-row' ? 'm' : 'l'}
						buttonType={buttonType}
						{...props}
					>
						<Download isInheritedColor />
						{buttonText}
					</ExportButton>
				</Popover.Trigger>
			</TooltipV2>

			{isPopupOpen && (
				<CompExportPopup
					compIds={compIds}
					getComps={getComps}
					type={compType}
					isMufa={isMufa}
					closePopup={() => setIsPopupOpen(false)}
					side={
						buttonType === 'row' || buttonType === 'wide-row'
							? 'right'
							: 'bottom'
					}
				/>
			)}
		</Popover.Root>
	);
};

const ExportButton = styled(ExportOrUnlockButtonBase)``;
