import { SaleFilters } from '../sales';
import {
	apnFilterStub,
	filterDateIntervalStub,
	filterNumberIntervalStub,
	marketFilterStub,
	multiPolygonFilterStub,
	polygonFilterStub,
	radiusFilterStub,
	submarketsFilterStub,
} from './filterStubs';

export const saleFiltersStub: {
	[K in keyof SaleFilters]: NonNullable<SaleFilters[K]>;
} = {
	portfolioId: 1,
	polygon: polygonFilterStub,
	multiPolygon: multiPolygonFilterStub,
	radius: radiusFilterStub,
	address: [],
	buildingClassId: [],
	buildingFloorsCount: filterNumberIntervalStub,
	buildingName: '',
	buildingSize: filterNumberIntervalStub,
	buildingPropertyTypeId: [],
	buildingPropertySubtype: [],
	buildingYearBuilt: filterNumberIntervalStub,
	buildingYearRenovated: filterNumberIntervalStub,
	dateCreated: filterDateIntervalStub,
	dateCreatedTimestamp: filterDateIntervalStub,
	propertySqFtExpiringInTwelveMonths: filterNumberIntervalStub,
	buyers: [],
	sellers: [],
	interestType: 'Full',
	isPortfolio: 'true',
	market: marketFilterStub,
	markets: [marketFilterStub],
	owns: 'own',
	totalSalePrice: filterNumberIntervalStub,
	lastSalePrice: filterNumberIntervalStub,
	saleDate: filterDateIntervalStub,
	salePricePsf: filterNumberIntervalStub,
	pricePerUnit: filterNumberIntervalStub,
	pricePerAcre: filterNumberIntervalStub,
	submarkets: submarketsFilterStub,
	opportunityZoneId: [],
	transactionSize: filterNumberIntervalStub,
	partial: 'true',
	hidden: 'hidden',
	dataSource: 'CompStak',
	propertyMarketStartingRent: filterNumberIntervalStub,
	propertyMarketEffectiveRent: filterNumberIntervalStub,
	propertyAverageTransactionSize: filterNumberIntervalStub,
	city: [],
	zip: [],
	sortField: 'dataSource',
	sortDirection: 'asc',
	propertyId: [],
	lastAttributeUpdate: filterDateIntervalStub,
	fips: [],
	apn: apnFilterStub,
};
