import { defaultTheme } from '@compstak/ui-kit';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export type RenewalHistoryProps = {
	title: string;
	link: string;
	text: string;
};

export const RenewalHistoryItem = ({
	title,
	link,
	text,
}: RenewalHistoryProps) => {
	return (
		<HistoryItem>
			{title}
			<HistoryLink target="_blank" to={link}>
				{text}
			</HistoryLink>
		</HistoryItem>
	);
};

const HistoryItem = styled.div`
	display: flex;
	flex-direction: column;
	text-align: right;
	font-weight: 400;
	color: ${defaultTheme.colors.gray.gray700};
`;

const HistoryLink = styled(Link)`
	text-decoration: underline;
`;
