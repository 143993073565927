import {
	VictoryAxis,
	VictoryBar,
	VictoryChart,
	VictoryVoronoiContainer,
	VictoryLabel,
	VictoryTooltip,
} from 'victory';
import { ChartBox, ChartOption } from '../ChartSelect';
import { useChartTopQuery } from 'api';
import abbreviateNumber from 'ui/util/abbreviateNumber';
import { PORTFOLIO_CHART_COLORS } from '../../constants';
import { formatInteger } from 'format';
import { useNavigate } from 'react-router';
import { routes } from 'router';
import { FiltersState } from 'PortfolioAnalytics/PortfolioFiltersProvider';
import { ChartStateWrapper } from '../ChartStateWrapper';
import { useMemo } from 'react';
import {
	CHART_AXIS_TICK_STYLE,
	VICTORY_TOOLTIP_STYLE,
} from '../chartsConstants';

const TOP_COUNT = 10;
const TENANT_EXPOSURE_CHART_NAME = `Top ${TOP_COUNT} Tenant Exposure`;

type TenantExposureChartProps = {
	portfolioId: number;
	filters: FiltersState;
};

export const TenantExposureChartV2 = (props: TenantExposureChartProps) => {
	return (
		<ChartBox<'lease'> chartName={TENANT_EXPOSURE_CHART_NAME}>
			{(selectedChart) => (
				<TenantExposureChartV2Content
					{...props}
					selectedChart={selectedChart}
				/>
			)}
		</ChartBox>
	);
};

type TenantExposureChartContentProps = TenantExposureChartProps & {
	selectedChart: ChartOption<'lease'>;
};

const TenantExposureChartV2Content = ({
	portfolioId,
	filters,
	selectedChart,
}: TenantExposureChartContentProps) => {
	const { data, isFetching, isError } = useChartTopQuery(portfolioId, {
		filters,
		chartMetric:
			selectedChart.type === 'sqft' ? 'transactionSize' : selectedChart.type,
		chartGrouping: 'tenantName',
		chartLimit: TOP_COUNT,
	});

	const navigate = useNavigate();

	const chartData = useMemo(() => {
		if (!data) {
			return [];
		}

		return data.values
			.map(({ label, value }) => ({
				x: label,
				y: value,
			}))
			.reverse();
	}, [data]);

	return (
		<ChartStateWrapper
			isError={isError}
			isFetching={isFetching}
			noData={data?.values.length === 0}
			showNoDataMessage={false}
		>
			<VictoryChart
				height={550}
				containerComponent={
					<VictoryVoronoiContainer
						voronoiDimension="x"
						responsive={true}
						style={{
							margin: '0 auto',
							width: '500px',
							maxWidth: '95%',
							maxHeight: '500px',
						}}
						labels={() => ' '}
						labelComponent={
							<VictoryTooltip
								{...VICTORY_TOOLTIP_STYLE}
								text={(d) => {
									const tenantName =
										d?.datum?.xName && d?.datum?.xName.length > 20
											? d?.datum?.xName.substring(0, 20) + '...'
											: d?.datum?.xName;
									return `(${formatInteger(d?.datum?.y || 0)}) \n Click the bar to view comps \n for ${tenantName}`;
								}}
								pointerLength={0}
								centerOffset={{ x: 0, y: 45 }}
								constrainToVisibleArea={true}
							/>
						}
					/>
				}
				padding={{ left: 120, top: 50, right: 50, bottom: 50 }}
				style={{
					parent: {
						color: '#A3A3A3',
						fontSize: 10,
						lineHeight: 10,
						maxWidth: '80%',
						margin: '0 auto',
					},
				}}
			>
				<VictoryAxis
					dependentAxis
					fixLabelOverlap
					offsetY={50}
					tickCount={4}
					tickLabelComponent={<VictoryLabel style={CHART_AXIS_TICK_STYLE} />}
					tickFormat={(d: string) => abbreviateNumber(d)}
					style={{
						axis: { stroke: '#F0F0F0', strokeWidth: 2 },
						grid: { stroke: '#F0F0F0' },
						tickLabels: {
							padding: 0,
						},
					}}
				/>
				<VictoryAxis
					domain={{ x: [0.5, chartData.length + 0.5] }}
					fixLabelOverlap
					tickLabelComponent={
						<VictoryLabel renderInPortal style={CHART_AXIS_TICK_STYLE} />
					}
					tickFormat={(text: string) => {
						const splitTick = text
							.split(' ')
							.map((word) => {
								if (word.length > 18) {
									const midpoint = Math.ceil(word.length / 2);
									return `${word.slice(0, midpoint)} ${word.slice(midpoint)}`;
								}
								return word;
							})
							.join(' ');
						return splitTick.replace(
							new RegExp(`(?![^\\n]{1,18}$)([^\\n]{1,18})\\s`, 'g'),
							'$1\n'
						);
					}}
					style={{
						axis: { stroke: '#F0F0F0', strokeWidth: 2 },
						grid: { stroke: '#F0F0F0', border: '1px solid black' },
						tickLabels: {
							padding: 5,
						},
					}}
				/>
				<VictoryBar
					labels={({ datum }) => formatInteger(datum.y)}
					alignment="middle"
					barRatio={1}
					barWidth={15}
					horizontal
					width={120}
					data={chartData}
					style={{
						data: { cursor: 'pointer', fill: PORTFOLIO_CHART_COLORS[0] },
					}}
					events={[
						{
							target: 'data',
							eventHandlers: {
								onClick: (_, clickedProps) => {
									const tenantName = clickedProps.datum.x as string;
									navigate(
										routes.portfolioByIdView.toHref(
											{ portfolioId: portfolioId, viewType: 'list' },
											{
												tenantNames: [tenantName],
											}
										)
									);
								},
							},
						},
					]}
				/>
			</VictoryChart>
		</ChartStateWrapper>
	);
};
