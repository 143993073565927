import {
	Button,
	ButtonProps,
	MQB,
	SemanticPaletteKeys,
	TooltipV2,
} from '@compstak/ui-kit';
import { AddToPortfolioDialog } from 'Components/AddToPortfolioDialog/AddToPortfolioDialog';
import { MultiPropertyInfoModal } from 'Components/Modals/MultiPropertyModal/MultiPropertyModal';
import { useIsPortfolioAnalyticsAccessible } from 'hooks/useIsPortfolioAnalyticsAccessible';
import { useModal } from 'providers/ModalProvider';
import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';

import IconAddToPortfolio from 'ui/svg_icons/add_to_portfolio.svg';

type Props = {
	propertyIds: number[];
	isPortfolio?: boolean;
	marketIds: number[];
} & ButtonProps;

export const AddToPortfolioButton = ({
	propertyIds,
	isPortfolio,
	variant = 'primary2',
	marketIds,
	...props
}: Props) => {
	const isPortfolioAnalyticsAccessible = useIsPortfolioAnalyticsAccessible({
		marketIds,
	});

	const [isAddToPortfolioDialogOpen, setIsAddToPortfolioDialogOpen] =
		useState(false);

	const { openModal } = useModal();

	const isLargeScreen = useMediaQuery({ minWidth: MQB.D_1280 });

	const handleClick = () => {
		if (isPortfolio) {
			openModal({
				modalContent: (
					<MultiPropertyInfoModal
						totalProperties={propertyIds.length}
						setIsAddToPortfolioDialogOpen={setIsAddToPortfolioDialogOpen}
					/>
				),
			});
		} else {
			setIsAddToPortfolioDialogOpen(true);
		}
	};

	return isPortfolioAnalyticsAccessible ? (
		<>
			<TooltipV2 content={isLargeScreen ? '' : 'Add to Portfolio'}>
				<ButtonStyled
					variant={variant}
					onClick={handleClick}
					icon={<IconAddToPortfolio />}
					{...props}
				>
					{isLargeScreen ? 'Add to Portfolio' : ''}
				</ButtonStyled>
			</TooltipV2>

			{isAddToPortfolioDialogOpen && (
				<AddToPortfolioDialog
					closeDialog={() => setIsAddToPortfolioDialogOpen(false)}
					propertyIds={propertyIds}
				/>
			)}
		</>
	) : null;
};

const ButtonStyled = styled(Button)<{ variant: SemanticPaletteKeys }>`
	gap: 0.25rem;

	svg path {
		fill: ${({ theme, variant }) =>
			!['primary2', 'ghost'].includes(variant) ? theme.colors.white.white : ''};
	}
`;
