import { memo } from 'react';
import { formatValue } from 'util/comp-format/src/format';
import { nullValueText } from '@compstak/common';
import { PortfolioItem } from 'types/comp';
import { StyledKeyValueTable } from '../../Components';
import styled from 'styled-components';

type Props = {
	data: PortfolioItem;
};

export const AdditionalPropertiesDetails = memo<Props>(({ data }) => {
	return (
		<AdditionalPropertiesDetailsContainer>
			<DetailsTitle>Additional property details</DetailsTitle>
			<StyledKeyValueTable
				// @ts-expect-error TS2769: No overload matches this call....
				data={formatAdditionalPropertyDetails(data)}
				columns={1}
			/>
		</AdditionalPropertiesDetailsContainer>
	);
});

AdditionalPropertiesDetails.displayName = 'AdditionalPropertiesDetails';

export const DetailsTitle = styled.h3`
	color: ${({ theme }) => theme.colors.gray.gray700};
	text-transform: capitalize;
	font-weight: 325;
	font-size: 20px;
	line-height: 24px;
	border-bottom: 1px solid ${({ theme }) => theme.colors.gray.gray700};
	padding-bottom: 8px;
`;

const AdditionalPropertiesDetailsContainer = styled.div`
	margin-top: 32px;
`;

const propertyDetails: { key: keyof PortfolioItem; name: string }[] = [
	{
		key: 'lotSize',
		name: 'Lot Size (Acres)',
	},
	{
		key: 'numberOfUnits',
		name: 'Number of Units',
	},
	{
		key: 'buildingPropertySubtype',
		name: 'Property Subtype',
	},
	{
		key: 'buildingParkingRatio',
		name: 'Parking Ratio',
	},
	{
		key: 'propertyMarketStartingRent',
		name: 'Property Market Starting Rent (PSF)',
	},
	{
		key: 'propertyMarketEffectiveRent',
		name: 'Property Market Effective Rent (PSF)',
	},
	{
		key: 'propertyAverageTransactionSize',
		name: 'Property Average Transaction SqFt',
	},
];

function formatAdditionalPropertyDetails(data: PortfolioItem) {
	return propertyDetails.map(({ key, name }) => {
		return [
			name,
			data[key]
				? formatValue(key, data[key], false, 'sale', false)
				: nullValueText,
		];
	});
}
