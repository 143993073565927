import {
	CompPopupActionsV2,
	CompPopupRoot,
	CompPopupRootLoading,
	PropertyDetails,
	PropertyPopupHeader,
} from 'maps';
import { PropertyPageLink } from 'maps/components/Popups/PropertyPageLink';
import { RemovePropertyPopupButton } from './RemovePropertyPopupButton';
import { PortfolioV2, usePropertyByIdQuery } from 'api';
import { Spinner } from '@compstak/ui-kit';

export type PortfolioPopupProps = {
	closePopup: () => void;
	isMinified?: boolean;
	propertyId: number;
	portfolio: PortfolioV2;
};

export const PortfolioPopup = ({
	isMinified,
	closePopup,
	propertyId,
	portfolio,
}: PortfolioPopupProps) => {
	const { data: property } = usePropertyByIdQuery({ id: propertyId });

	if (!property) {
		return (
			<CompPopupRootLoading>
				<Spinner size="l" />
			</CompPopupRootLoading>
		);
	}

	return (
		<CompPopupRoot {...(isMinified && { width: 'auto' })}>
			<PropertyPopupHeader property={property} closePopup={closePopup} />
			{!isMinified && <PropertyDetails property={property} />}
			<CompPopupActionsV2>
				<PropertyPageLink propertyId={property.id} target="_blank">
					Detailed Property Info
				</PropertyPageLink>
				<RemovePropertyPopupButton portfolio={portfolio} property={property} />
			</CompPopupActionsV2>
		</CompPopupRoot>
	);
};
