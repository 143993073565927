import { Button, MQB, colors } from '@compstak/ui-kit';
import IconLock from 'ui/svg_icons/lock.svg';
import styled, { css } from 'styled-components';

export const APP_CONTAINER_ID = 'app-container';
export const APP_WRAPPER_ID = 'app-wrapper';
const SIDEBAR_WIDTH = 300;

export const DarkSidebar = styled.div<{ sideBarState: 'open' | 'closed' }>`
	display: flex;
	flex-direction: column;
	min-width: ${SIDEBAR_WIDTH}px;
	max-width: ${SIDEBAR_WIDTH}px;
	background-color: #303441;
	position: relative;
	overflow-y: auto;
	transition: transform 0.2s ease-in-out;
	height: 100%;

	${({ sideBarState }) =>
		sideBarState === 'closed' &&
		css`
			@media (max-width: ${MQB.T_834}) {
				position: absolute;
				top: 0;
				transform: translate(-${SIDEBAR_WIDTH}px);
				z-index: ${(props) => props.theme.zIndex.menu};
			}
		`}
`;

export const IconLockGrey = styled(IconLock)`
	fill: ${colors.neutral.n70};
	width: 13px;
	height: 15px;
`;

export type ExportOrUnlockButtonType =
	| 'row'
	| 'table'
	| 'standalone'
	| 'wide-row';

export const ExportOrUnlockButtonBase = styled(Button)<{
	buttonType: ExportOrUnlockButtonType;
}>`
	display: flex;
	gap: 0.5rem;
	font-weight: 400;

	${({ buttonType }) =>
		buttonType === 'wide-row' &&
		css`
			width: 100%;
		`}

	${({ buttonType }) =>
		(buttonType === 'wide-row' || buttonType === 'row') &&
		css`
			font-size: 0.75rem;
		`}
`;
