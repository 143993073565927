import IconAnonymous from 'ui/svg_icons/admin-panel-settings.svg';
import IconDocumentScanner from 'ui/svg_icons/document-scanner.svg';
import UploadSVG from 'ui/svg_icons/upload2.svg';
import styled from 'styled-components';
import { MQB } from '@compstak/ui-kit';

const mail = 'comps@compstak.com';

export function CompInfoBadges() {
	return (
		<BadgesList>
			<InfoContainer>
				<div>
					<IconAnonymous />
				</div>
				<div>
					<BadgeTitle>ANONYMOUS</BadgeTitle>
					<BadgeDescription>
						Comp uploads are anonymous and secure. We respect your privacy.
					</BadgeDescription>
				</div>
			</InfoContainer>
			<InfoContainer>
				<div>
					<IconDocumentScanner />
				</div>
				<div>
					<BadgeTitle>ANY FILE ACCEPTABLE</BadgeTitle>
					<BadgeDescription>
						Send us files in ANY format (e.g. Excel, JPEG, PNG, PDF, etc).
					</BadgeDescription>
				</div>
			</InfoContainer>
			<InfoContainer>
				<div>
					<UploadIcon />
				</div>
				<div>
					<BadgeTitle>UPLOAD OR EMAIL US COMPS</BadgeTitle>
					<BadgeDescription>
						Upload your files directly to CompStak or send files to{' '}
						<Mail
							href={`mailto:${mail}?subject=Comp Submissions`}
							target="_blank"
							rel="noreferrer"
						>
							{mail}
						</Mail>
					</BadgeDescription>
				</div>
			</InfoContainer>
		</BadgesList>
	);
}

export const BadgeTitle = styled.div`
	font-weight: 500;
	margin-bottom: 0.2rem;
`;

export const BadgeDescription = styled.div`
	font-size: 0.875rem;
`;

export const BadgesList = styled.section`
	display: flex;
	justify-content: center;
	gap: 1.8rem;
	margin-bottom: 2.5rem;

	@media (max-width: ${MQB.D_1536}) {
		flex-direction: column;
	}

	@media (max-width: ${MQB.D_1280}) {
		max-width: 680px;
		margin-left: auto;
		margin-right: auto;
	}
`;

export const InfoContainer = styled.div`
	display: flex;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	width: 320px;
	gap: 16px;

	@media (max-width: ${MQB.D_1536}) {
		width: 100%;
	}
`;

export const Mail = styled.a`
	display: inline;
	text-decoration: underline;
	font-size: 0.875rem;
	font-weight: 350;
	color: ${({ theme }) => theme.colors.blue.blue400};

	&:hover {
		text-decoration: none;
	}
`;

export const UploadIcon = styled(UploadSVG)`
	width: 3.1rem;
	height: 3.1rem;

	path {
		fill: currentColor;
	}
`;
