import React, { useMemo } from 'react';
import {
	VictoryAxis,
	VictoryChart,
	VictoryLabel,
	VictoryLine,
	VictoryArea,
	VictoryTooltip,
	VictoryVoronoiContainer,
	VictoryScatter,
} from 'victory';

import { PortfolioTrendChartFlyout } from 'PortfolioAnalytics/Charts/PortfolioTrend/Chart/PortfolioTrendChartFlyout';
import {
	TrendMetricFilter,
	Bubbles,
} from 'PortfolioAnalytics/portfolioAnalyticsTypes';
import { formatDate } from 'PortfolioAnalytics/Charts/PortfolioTrend/util';
import {
	CHART_AXIS_TICK_STYLE,
	VICTORY_CONTAINER_STYLE_800,
} from 'PortfolioAnalytics/Charts/chartsConstants';

type PortfolioTrendChartData = { x: Date; y: number };
type SinglePortfolioData = {
	portfolio: PortfolioTrendChartData[];
	points: Bubbles[];
};
type SinglePortfolioDataWithColorIndex = SinglePortfolioData & {
	colorIndex: number;
};
type PortfolioTrendChartProps = {
	data: SinglePortfolioData[];
	noData: boolean;
	metricFilter: TrendMetricFilter;
	timeSpan: number;
	allChartsAreBubble: boolean;
	colors: string[];
};

const CHART_HEIGHT = 320;
const CHART_WIDTH = 700;

const filterDataByTimeSpan = (data: Bubbles[], timeSpan: number) => {
	const cutoffDate = new Date();
	cutoffDate.setFullYear(cutoffDate.getFullYear() - timeSpan);
	return data.filter((item) => new Date(item.x) >= cutoffDate);
};

const hasSignificantYearRange = (
	items: SinglePortfolioData[],
	key: 'portfolio' | 'points'
) =>
	items.some((item) => {
		const dates = item[key].map(({ x }) => x.getFullYear());
		const firstYear = Math.min(...dates);
		const lastYear = Math.max(...dates);
		return lastYear - firstYear > 3;
	});

const processData = (
	data: SinglePortfolioData[],
	timeSpan: number,
	allChartsAreBubble: boolean
) => {
	const dataWithColorIndex = data.map((portfolioData, index) => ({
		...portfolioData,
		colorIndex: index,
	}));
	const lineCharts: SinglePortfolioDataWithColorIndex[] = [];
	const bubbleCharts: SinglePortfolioDataWithColorIndex[] = [];
	dataWithColorIndex.map((portfolioData) => {
		const { points } = portfolioData;
		if (points.length > 0) {
			bubbleCharts.push({
				...portfolioData,
				points: allChartsAreBubble
					? points
					: filterDataByTimeSpan(points, timeSpan),
			});
		} else {
			lineCharts.push(portfolioData);
		}
	});
	return { bubbleCharts, lineCharts };
};

export const BenchmarkingTrendChartComponent = ({
	data,
	noData,
	metricFilter,
	timeSpan,
	allChartsAreBubble,
	colors,
}: PortfolioTrendChartProps) => {
	const { bubbleCharts, lineCharts } = useMemo(
		() => processData(data, timeSpan, allChartsAreBubble),
		[data, timeSpan, allChartsAreBubble]
	);

	const axisStyle = useMemo(
		() => ({
			...CHART_AXIS_TICK_STYLE,
			fontSize: '14px',
			opacity: noData ? 0 : 1,
		}),
		[noData]
	);

	const shouldFormatDateAsYear = useMemo(() => {
		return (
			hasSignificantYearRange(bubbleCharts, 'points') ||
			hasSignificantYearRange(lineCharts, 'portfolio')
		);
	}, [bubbleCharts, lineCharts]);

	const { minY, maxY } = useMemo(() => {
		const lineYValues = lineCharts.flatMap((chart) =>
			chart.portfolio.map((point) => point.y)
		);
		const lineMinY = lineYValues.length ? Math.min(...lineYValues) : Infinity;
		const lineMaxY = lineYValues.length ? Math.max(...lineYValues) : -Infinity;

		const bubbleYValues = bubbleCharts.flatMap((chart) =>
			chart.points.map((point) => point.y)
		);
		const bubbleMinY = bubbleYValues.length
			? Math.min(...bubbleYValues)
			: Infinity;
		const bubbleMaxY = bubbleYValues.length
			? Math.max(...bubbleYValues)
			: -Infinity;

		return {
			minY: Math.min(lineMinY, bubbleMinY),
			maxY: Math.max(lineMaxY, bubbleMaxY),
		};
	}, [lineCharts, bubbleCharts, timeSpan]);

	// adding a buffer to the lowest and highest y axis points
	const yAxisMin = minY * 0.98;
	const yAxisMax = maxY * 1.02;
	const domainHeight = yAxisMax - yAxisMin > 0 ? yAxisMax - yAxisMin : 1;
	const yAxisLabelMargin = allChartsAreBubble ? -20 : 0;
	const isMonthsMetric = ['freeMonths', 'leaseTerm'].includes(metricFilter);
	const leftPadding = useMemo(() => {
		const basicPadding = allChartsAreBubble ? 80 : 60;
		if (isMonthsMetric) {
			return basicPadding + 25;
		}
		return basicPadding;
	}, [allChartsAreBubble, isMonthsMetric]);
	const gradients = useMemo(() => {
		const gradientColors = colors.map((color) => `${color}80`);
		return data.map((portfolioDataset, index) => {
			const yValues = portfolioDataset.portfolio.map((d) => d.y);
			if (yValues.length === 0) {
				return {
					gradientColor: gradientColors[index],
					pixelMinY: '0',
					pixelMaxY: '0',
				};
			}
			const minY = Math.min(...yValues);
			const maxY = Math.max(...yValues);
			const pixelMinY = ((minY / domainHeight) * CHART_HEIGHT).toString();
			const pixelMaxY = ((maxY / domainHeight) * CHART_HEIGHT).toString();

			return {
				gradientColor: gradientColors[index],
				pixelMinY,
				pixelMaxY,
			};
		});
	}, [data, domainHeight, colors]);

	return (
		<div>
			<VictoryChart
				domainPadding={2}
				padding={{
					top: 10,
					bottom: 30,
					left: leftPadding,
					right: 20,
				}}
				height={CHART_HEIGHT}
				width={CHART_WIDTH}
				scale={{ x: 'time' }}
				containerComponent={
					<VictoryVoronoiContainer
						voronoiDimension="x"
						style={VICTORY_CONTAINER_STYLE_800}
						labels={() => ' '}
						labelComponent={
							<VictoryTooltip
								flyoutComponent={
									<PortfolioTrendChartFlyout
										isMonthsMetric={isMonthsMetric}
										width={220}
										metricFilter={metricFilter}
										chartWidth={CHART_WIDTH}
									/>
								}
							/>
						}
					/>
				}
			>
				<VictoryAxis
					tickLabelComponent={<VictoryLabel style={axisStyle} />}
					tickFormat={(d: Date) =>
						formatDate(d, shouldFormatDateAsYear, allChartsAreBubble)
					}
					style={{
						axis: { stroke: 'none' },
						grid: { stroke: allChartsAreBubble ? '#F1F2F5' : 'none' },
					}}
				/>
				<VictoryAxis
					dependentAxis
					domain={[yAxisMin, yAxisMax]}
					tickLabelComponent={
						<VictoryLabel dx={yAxisLabelMargin} style={axisStyle} />
					}
					tickFormat={(m: number) => {
						return m > 0 ? (isMonthsMetric ? `${m} mo.` : `$${m}`) : m;
					}}
					style={{
						axis: { stroke: 'none' },
						grid: { stroke: noData ? 'none' : '#F1F2F5' },
					}}
				/>
				<defs>
					{gradients.map((gradient, index) => (
						<linearGradient
							key={`gradient-${index}`}
							id={`gradient-${index}`}
							x1="0"
							y1={gradient.pixelMinY}
							x2="0"
							y2={gradient.pixelMaxY}
							gradientUnits="userSpaceOnUse"
						>
							<stop
								style={{ stopColor: gradient.gradientColor, stopOpacity: 0.3 }}
							/>
							<stop
								offset="1"
								style={{ stopColor: 'white', stopOpacity: '0' }}
							/>
						</linearGradient>
					))}
				</defs>
				{lineCharts.map((portfolioDataset, index) => {
					if (portfolioDataset.portfolio.length === 0) return null;
					return (
						<VictoryLine
							key={index}
							data={portfolioDataset.portfolio}
							style={{
								data: { stroke: `${colors[portfolioDataset.colorIndex]}` },
							}}
						/>
					);
				})}
				;
				{lineCharts.map((portfolioDataset, index) => {
					if (portfolioDataset.portfolio.length === 0) return null;
					return (
						<VictoryArea
							key={index}
							data={portfolioDataset.portfolio}
							style={{
								data: {
									fill: `url(#gradient-${portfolioDataset.colorIndex})`,
								},
							}}
						/>
					);
				})}
				{bubbleCharts.map((portfolioDataset, index) => (
					<VictoryScatter
						key={index}
						data={portfolioDataset.points}
						minBubbleSize={4}
						style={{
							data: {
								stroke: `${colors[portfolioDataset.colorIndex]}`,
								strokeWidth: '1px',
								fill: `${colors[portfolioDataset.colorIndex]}`,
								opacity: 0.7,
							},
						}}
					/>
				))}
			</VictoryChart>
		</div>
	);
};
