import {
	MARKER_COLOR_MAP_RGBA,
	MVTLayer,
	PINPOINT_CIRCLE_ZOOM_BREAKPOINT,
	useMapRef,
	useViewState,
	WHITE_RGBA,
} from '@compstak/maps';
import { filtersToServerJSON } from 'models/filters/util/serverJson';
import { useMemo, useRef, useState } from 'react';
import {
	AggTileFeature,
	HitTileFeature,
	TileFeature,
} from 'Pages/Search/Map/Components/PinsMVTLayer/types';
import {
	isAggOrHitTileFeature,
	isHitTileFeature,
} from 'Pages/Search/Map/Components/PinsMVTLayer/util';
import { getAccessToken, refreshAccessToken } from 'auth/auth';
import { PortfolioPopup } from './PortfolioPopup';
import { PortfolioV2 } from 'api';
import { Position } from 'geojson';
import { BENCHMARKING_COLOR_MAP } from 'PortfolioAnalytics/Benchmarking/constants';
import { PortfolioMVTFilters, ServerMVTPortfolioFilterItem } from './types';

type PortfolioMVTLayerProps = {
	id?: string;
	filters: PortfolioMVTFilters;
	portfolio: PortfolioV2;
	pinColor: keyof typeof BENCHMARKING_COLOR_MAP;
};

export const PortfolioMVTLayer = ({
	id,
	filters,
	portfolio,
	pinColor,
}: PortfolioMVTLayerProps) => {
	const { map } = useMapRef();
	const [viewState] = useViewState();

	const isRetrying = useRef(false);
	const [accessToken, setAccessToken] = useState(getAccessToken());

	const pointType =
		(viewState.zoom ?? 0) < PINPOINT_CIRCLE_ZOOM_BREAKPOINT ? 'circle' : 'icon';

	const tileDataUpdateTriggers = [filters, accessToken];

	const filter = useMemo(() => {
		const { portfolioId, ...restFilters } = filters;
		const portfolioIdFilterItem: ServerMVTPortfolioFilterItem = {
			property: 'portfolioId',
			...portfolioId,
		};
		return [...filtersToServerJSON(restFilters, true), portfolioIdFilterItem];
	}, [filters]);

	return (
		<MVTLayer<AggTileFeature | HitTileFeature>
			id={id}
			binary={false}
			data={`/api/properties/mvt/{z}/{x}/{y}?${
				(viewState.zoom ?? 0) < PINPOINT_CIRCLE_ZOOM_BREAKPOINT
					? 'size=0'
					: 'grid_precision=0'
			}`}
			loadOptions={{
				fetch: {
					method: 'POST',
					body: JSON.stringify({
						filter,
					}),
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				},
			}}
			pointType={pointType}
			getPopupContent={(f, closePopup) => {
				return (
					isHitTileFeature(f) && (
						<PortfolioPopup
							closePopup={closePopup}
							propertyId={f.properties.propertyId}
							portfolio={portfolio}
						/>
					)
				);
			}}
			getTooltipContent={(f) => {
				return isHitTileFeature(f)
					? f.properties.buildingAddressAndCity
					: undefined;
			}}
			getFillColor={() => MARKER_COLOR_MAP_RGBA[pinColor]}
			getLineColor={WHITE_RGBA}
			getIcon={() => pinColor}
			updateTriggers={{
				getTileData: tileDataUpdateTriggers,
			}}
			onTileLoad={(tile) => {
				const deleteTileFeature = (tileFeature: TileFeature) => {
					const tileData = tile.data as TileFeature[];
					tileData.splice(tileData.findIndex((f) => f === tileFeature));
				};

				//@ts-expect-error dataInWGS84 is not declared as a property of a tile
				const tileDataInWGS84 = tile.dataInWGS84 as TileFeature[];
				for (const tileFeature of tileDataInWGS84) {
					if (isAggOrHitTileFeature(tileFeature)) {
						// assign coordinates in World Geodetic Coordinate System for each tile features
						tileFeature.properties.coordinatesInWGS84 = jitterPosition(
							tileFeature.geometry.coordinates
						);

						if (isHitTileFeature(tileFeature)) {
							tileFeature.properties.propertyId = tileFeature.properties.id;
						}
					} else {
						// deleting any feature which is not `aggs` or `hits`
						deleteTileFeature(tileFeature);
					}
				}
			}}
			onClick={(info) => {
				if (!map) return;
				const feature = info.object;
				if (feature && isHitTileFeature(feature)) {
					map.flyTo({
						center: [
							feature.geometry.coordinates[0],
							feature.geometry.coordinates[1],
						],
						easing: (t) => t,
					});
				}
			}}
			onTileError={async (err) => {
				if (isRetrying.current) return;
				// we assume that an error was caused by stale access token because
				// @loaders.gl/core/src/lib/utils/response-utils.ts#getResponseError absorbs the whole response and returns a simple string
				console.error(
					'Failed to load a tile. We assume it happened due to a stale token, refreshing it.',
					err
				);
				isRetrying.current = true;
				const accessToken = await refreshAccessToken();
				accessToken && setAccessToken(accessToken);
				isRetrying.current = false;
			}}
			pickable={pointType === 'icon'}
		/>
	);
};

function jitterPosition([lng, lat]: Position): Position {
	const offset = 0.0001;
	return [
		lng + (Math.random() - 0.5) * offset,
		lat + (Math.random() - 0.5) * offset,
	];
}
