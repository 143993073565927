import { NameCell, RankCell } from '../../LeagueTables/TableCells';
import { CompanyLogo } from '../../../Components/CompanyLogo/CompanyLogo';
import { FiltersObject } from '../../../models/filters/types';
import { LinkStyled } from '../styles';
import { filtersToQueryString } from '../../../models/filters/util';
import { Dash, numberUtils, SimpleTableColumn } from '@compstak/ui-kit';

interface BaseType {
	name: string;
	[key: string]: string | number | undefined;
}

interface BaseColumn {
	header: string;
}

interface Props<T extends BaseType> {
	filterName: keyof FiltersObject;
	baseLinkUrl: string;
	filters: Partial<FiltersObject>;
	nameColumn: BaseColumn;
	volumeColumn: BaseColumn & {
		prefix?: string;
		key: Exclude<keyof T, symbol>;
		numberProcess: (value: number) => number;
	};
	countColumn: BaseColumn & {
		key: Exclude<keyof T, symbol>;
	};
}

export function getPremierListTableColumns<T extends BaseType>({
	filterName,
	baseLinkUrl,
	filters,
	volumeColumn,
	countColumn,
	nameColumn,
}: Props<T>): SimpleTableColumn<T>[] {
	return [
		{
			id: 'index',
			header: '#',
			body: ({ rowIndex }) => <RankCell>{rowIndex + 1}</RankCell>,
			width: 40,
		},
		{
			id: 'name',
			header: nameColumn.header,
			body: ({ row }) => (
				<NameCell>
					<CompanyLogo companyName={row.name} />
					<span title={row.name}>{row.name}</span>
				</NameCell>
			),
			width: 150,
			flex: 3,
		},
		{
			id: volumeColumn.key,
			header: volumeColumn.header,
			body: ({ row }) => {
				const value = row[volumeColumn.key];
				if (value && typeof value === 'number') {
					return (
						<span>
							{volumeColumn.prefix}
							{numberUtils.formatNumber(
								volumeColumn.numberProcess(value),
								'twoDecimals'
							)}
						</span>
					);
				}
				return <Dash />;
			},
			width: 150,
			flex: 1,
			hAlign: 'right',
		},
		{
			id: countColumn.key,
			header: countColumn.header,
			body: ({ row }) => {
				const targetFilters: Partial<FiltersObject> = {
					[filterName]: [row.name],
					...filters,
				};
				if (row[countColumn.key]) {
					return (
						<LinkStyled
							href={`${baseLinkUrl}?${filtersToQueryString(targetFilters)}`}
						>
							{row[countColumn.key]}
						</LinkStyled>
					);
				}
				return <Dash />;
			},
			width: 150,
			flex: 1,
			hAlign: 'right',
		},
	];
}
