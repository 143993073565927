import * as Accordion from '@radix-ui/react-accordion';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import * as Popover from '@radix-ui/react-popover';
import styled, { css } from 'styled-components';
import IconArrowBottom from '../../../ui/svg_icons/arrow_bottom.svg';
import IconTick from '../../../ui/svg_icons/tick.svg';
import {
	defaultDropdownContentCss,
	defaultDropdownItemCheckedCss,
	defaultDropdownItemCss,
} from 'Components';
import { Button, MQB } from '@compstak/ui-kit';

export const FilterDropdownContent = styled(DropdownMenu.Content)`
	${defaultDropdownContentCss};
`;

export const FilterPopoverContent = styled(Popover.Content)`
	${defaultDropdownContentCss};
`;

const filterButtonCss = css`
	all: unset;
	display: flex;
	justify-content: space-between;
	gap: 0.5rem;
	cursor: pointer;
	border: 1px solid ${(p) => p.theme.colors.neutral.n40};
	border-radius: 0.125rem;
	padding: 0.25rem 0.5rem;
	height: 44px;
	min-width: 120px;
	max-width: 200px;
	box-sizing: border-box;
	&:hover {
		border-color: ${(p) => p.theme.colors.neutral.n50};
	}
`;

export const FilterDropdownButton = styled(DropdownMenu.Trigger)`
	${filterButtonCss};
`;

export const FilterPopoverButton = styled(Popover.Trigger)`
	${filterButtonCss};
`;

export const FilterRadioItem = styled(DropdownMenu.RadioItem)`
	${defaultDropdownItemCss}
`;

const filterCheckboxItemCss = css`
	${defaultDropdownItemCss};
	justify-content: flex-start;
	gap: 0.75rem;
`;

export const FilterDropdownCheckboxItem = styled(DropdownMenu.CheckboxItem)`
	${filterCheckboxItemCss}
`;

export const FilterCheckboxItem = styled.div<{ checked: boolean }>`
	${filterCheckboxItemCss};
	${(p) =>
		p.checked &&
		css`
			${defaultDropdownItemCheckedCss};
		`}
`;

export const FilterAccordionTrigger = styled(Accordion.Trigger)<{
	checked: boolean;
}>`
	${filterCheckboxItemCss};
	line-height: unset;
	border: 0;
	background-color: transparent;
	${(p) =>
		p.checked &&
		css`
			${defaultDropdownItemCheckedCss};
		`}
`;

export const FilterButtonIconArrow = styled(IconArrowBottom)`
	fill: ${(p) => p.theme.colors.neutral.n400};
`;

export const FilterItemIconTick = styled(IconTick)`
	fill: ${(p) => p.theme.colors.neutral.n200};
`;

export const FilterButtonLabelContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow: hidden;
	color: ${(p) => p.theme.colors.gray.gray500};
`;

export const FilterButtonLabel = styled.div`
	font-size: 0.8em;
	text-transform: uppercase;
`;

export const FilterButtonValue = styled.div`
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

export const FilterButtonIconContainer = styled.div`
	display: flex;
	align-items: center;
`;

export const AddNewPropertyButton = styled(Button)`
	font-size: 0.875em;
	@media (max-width: ${MQB.D_1280}) {
		svg {
			display: none;
		}
	}
`;
