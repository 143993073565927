import styled from 'styled-components';
import IconAddNotes from 'ui/svg_icons/add-notes.svg';
import IconAsterisk from 'ui/svg_icons/asterisk.svg';

export function CreditAwardNotes() {
	return (
		<ul>
			<LiWithSvg>
				<IconAddNotes /> Receive 1 credit for each comp that is already in our
				database.
			</LiWithSvg>
			<LiWithSvg>
				<IconAddNotes /> Unlock 1 comp for your first submission everyday.
			</LiWithSvg>
			<LiWithSvg>
				<IconAsterisk /> See details of required fields and premium fields
				below.
			</LiWithSvg>
		</ul>
	);
}

export const LiWithSvg = styled.li`
	display: flex;
	align-items: center;
	gap: 0.3rem;
`;
