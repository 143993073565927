import { nullValueText } from '@compstak/common';
import { Flex, MQB, defaultTheme } from '@compstak/ui-kit';
import { LockIconStyled } from 'Components/UnlockButton/UnlockButtonComponent';
import { memo, useMemo } from 'react';
import styled from 'styled-components';
import { CompKeys, SalesComp } from 'types/comp';
import IconBuilding from 'ui/svg_icons/building-multiple.svg';
import { Locale } from 'util/comp-format/attributes';
import { formatValue } from 'util/comp-format/src/format';
import dateFormat from 'util/comp-format/src/util/calendarDate';
import formatNumber from 'util/comp-format/src/util/number';
import {
	UnlockButton,
	UNLOCK_BUTTON_STATE,
} from '../../Components/UnlockButton';
import { KeyInfoItem, KeyInfoItems, Value } from 'Components/KeyInfoItem';
import { AddToPortfolioButton } from 'Components';
import { ExportCompsButton } from 'Components/Export';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { FeatureFlags } from 'api/featureFlags/types';
import { compFieldDataIsMissing } from 'Pages/SalesDetailsV2/utils/missingCompDataUtils';

type Props = {
	data: SalesComp;
	hasEnoughCredits: UNLOCK_BUTTON_STATE;
	isPortfolio?: boolean;
};

export const KeyInfo = memo<Props>(
	({ data, hasEnoughCredits, isPortfolio }) => {
		const featureFlags = useFeatureFlags();
		const saleDateText = data.saleDate
			? `Sale on ${dateFormat(data.saleDate, true, data.locale ?? 'en_US')}`
			: 'Sale';

		const showBuyer =
			(data.own === false && data.buyer === null) ||
			(data.buyer && data.buyer.length) ||
			!data.recordedBuyer ||
			!data.recordedBuyer.length;

		const showSeller =
			(data.own === false && data.seller === null) ||
			(data.seller && data.seller.length) ||
			!data.recordedSeller ||
			!data.recordedSeller.length;

		const propertyIds = useMemo(
			() => data.portfolio.map((p) => p.propertyId),
			[data.portfolio]
		);

		const keyInfoParams = { featureFlags, data };
		const formatKeyInfo = formatKeyInfoFactory(keyInfoParams);
		const keyInfo = getKeyInfo(keyInfoParams);

		return (
			<FlexContainer alignItems="flex-start" isPortfolio={isPortfolio}>
				<div>
					<SaleDateContainer>
						{isPortfolio && (
							<IconPortfolioContainer>
								<StyledIconBuilding />
							</IconPortfolioContainer>
						)}
						<SaleDate>
							{isPortfolio ? 'Multi-Property' : ''} {saleDateText}
						</SaleDate>
						{!data.own && (
							<StyledUnlockButton
								data={data}
								hasEnoughCredits={hasEnoughCredits}
							/>
						)}
					</SaleDateContainer>
					<KeyInfoItems>
						<TotalSaleValue
							title={keyInfo['totalSalePrice'] ?? ''}
							value={formatKeyInfo({
								key: 'totalSalePrice',
								value: data['totalSalePrice'],
								own: data.own,
								locale: data.locale,
							})}
						/>
						{Object.typedKeys(keyInfo)
							.slice(1)
							.map((key) => {
								const value =
									key === 'percentOccupied'
										? data.portfolio[0][key]
										: data[key];
								return (
									<KeyInfoItem
										key={key}
										title={keyInfo[key] ?? ''}
										value={formatKeyInfo({
											key,
											value,
											own: data.own,
											locale: data.locale,
										})}
									/>
								);
							})}
					</KeyInfoItems>
					<KeyInfoItems>
						<KeyInfoItem
							title={showBuyer ? 'Buyer' : 'Recorded buyer'}
							value={
								showBuyer
									? formatKeyInfo({
											key: 'buyer',
											value: data.buyer,
											own: data.own,
										})
									: formatKeyInfo({
											key: 'recordedBuyer',
											value: data.recordedBuyer,
											own: data.own,
										})
							}
						/>
						<KeyInfoItem
							title={showSeller ? 'Seller' : 'Recorded seller'}
							value={
								showSeller
									? formatKeyInfo({
											key: 'seller',
											value: data.seller,
											own: data.own,
										})
									: formatKeyInfo({
											key: 'recordedSeller',
											value: data.recordedSeller,
											own: data.own,
										})
							}
						/>
					</KeyInfoItems>
				</div>

				<Flex gap="0.5rem">
					<AddToPortfolioButton
						marketIds={[data.marketId]}
						propertyIds={propertyIds}
						isPortfolio={isPortfolio}
					/>

					<ExportCompsButton
						compType="sale"
						compIds={[data.id]}
						buttonType="standalone"
						disabled={!data.own}
					/>
				</Flex>
			</FlexContainer>
		);
	}
);

KeyInfo.displayName = 'KeyInfo';

const FlexContainer = styled(Flex)<{ isPortfolio?: boolean }>`
	padding: 16px 24px 8px;
	font-family: ${({ theme }) => theme.typography.fontFamily.gotham};

	/* // TODO: Put this color to DefaultTheme interface  */
	${({ isPortfolio }) =>
		isPortfolio &&
		`
		background-color: #FCF5FF;
	`}

	flex-wrap: wrap;

	@media (min-width: ${MQB.D_1280}) {
		flex-wrap: nowrap;
	}
`;

const IconPortfolioContainer = styled.div`
	display: flex;
	min-width: 56px;
	height: 56px;
	/* // TODO: add color to the ui-kit color palette  */
	background-color: #7f20c7;
	border-radius: 50%;
	margin-right: 16px;
`;
const StyledIconBuilding = styled(IconBuilding)`
	width: 32px;
	height: 32px;

	align-self: center;
	margin: auto;

	& path {
		fill: ${defaultTheme.colors.white.white};
	}
`;

const TotalSaleValue = styled(KeyInfoItem)`
	${Value} {
		font-size: 24px;
		line-height: 24px;
	}
`;
const SaleDate = styled.span`
	font-size: 32px;
	color: ${({ theme }) => theme.colors.gray.gray700};
	line-height: 40px;
	font-weight: 400;
`;

const SaleDateContainer = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 32px;
	white-space: nowrap;
`;

const StyledUnlockButton = styled(UnlockButton)`
	height: 38px;
	margin-left: 40px;
	align-items: center;
	border-radius: 3px;
`;

type FormatKeyInfoParams = {
	featureFlags: FeatureFlags;
	data: SalesComp;
};

function getKeyInfo(params: FormatKeyInfoParams) {
	const keyInfoCurrencyKeys = getKeyInfoCurrencyKeys(params);
	const keyInfo = {
		...keyInfoCurrencyKeys,
		...keyInfoPercentKeys,
	};
	return keyInfo;
}

function getKeyInfoCurrencyKeys({ featureFlags, data }: FormatKeyInfoParams) {
	const keyInfoCurrencyKeys = {
		totalSalePrice: 'Total Sale Price',
		salePricePsf: 'Sale Price ($/SqFt)',
		...(featureFlags.ffAppsTotalUnitsAcresBuildingsPricePerAcre
			? {
					...(!compFieldDataIsMissing(data.pricePerUnit)
						? { pricePerUnit: 'Sale Price ($/Unit)' }
						: {}),
					...(!compFieldDataIsMissing(data.pricePerAcre)
						? { pricePerAcre: 'Sale Price ($/Acre)' }
						: {}),
				}
			: {}),
		totalNetOperatingIncome: 'Total NOI',
	};
	return keyInfoCurrencyKeys;
}

const keyInfoPercentKeys = {
	capRate: 'Cap Rate',
	percentOccupied: 'Occupancy Rate',
};

function formatKeyInfoFactory(params: FormatKeyInfoParams) {
	return function formatKeyInfo({
		key,
		value,
		own,
		locale,
	}: {
		key: CompKeys;
		value: string | number | string[] | null | undefined;
		own?: boolean;
		locale?: Locale;
	}) {
		const keyInfoCurrencyKeys = getKeyInfoCurrencyKeys(params);
		if (own === false && value === null)
			return <LockIconStyled width={14} height={16} hasDarkColor />;
		if ((!value && value !== 0) || (Array.isArray(value) && !value.length))
			return nullValueText;
		if (locale && typeof value === 'number') {
			const currency = locale === 'en_GB' ? '£' : '$';
			if (Object.keys(keyInfoCurrencyKeys).includes(key))
				return `${currency}${formatNumber(value.toFixed(2))}`;
		}
		if (
			Object.keys(keyInfoPercentKeys).includes(key) &&
			Number.isInteger(value)
		)
			return `${value}%`;
		return formatValue(key, value, false, 'sale', false, {}, locale);
	};
}
