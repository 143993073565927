import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import Between from 'Components/Filters/Base/Between';
import styled from 'styled-components';

import { usePortfolioFilters } from 'PortfolioAnalytics/PortfolioFiltersProvider';

import {
	FilterDropdownButton,
	FilterButtonLabelContainer,
	FilterButtonLabel,
	FilterButtonValue,
	FilterButtonIconContainer,
	FilterButtonIconArrow,
	FilterDropdownContent,
} from '../UI';
import { ComponentProps, useMemo, useState } from 'react';
import { ErrorMessage } from '@compstak/ui-kit';
import { FilterErrors } from 'Components/Filters/Base/Filter/Container';

type Props = {
	label: string;
	buttonProps?: Omit<ComponentProps<'button'>, 'ref'>;
	emptyValueLabel: string;
};

export const BuildingSizeFilter = ({
	emptyValueLabel,
	label,
	buttonProps,
}: Props) => {
	const { filters, setFilters } = usePortfolioFilters();

	const displayValue = useMemo(() => {
		const minSize =
			filters.buildingSize?.min !== null &&
			!isNaN(Number(filters.buildingSize?.min))
				? filters.buildingSize?.min.toLocaleString()
				: '';
		const maxSize =
			filters.buildingSize?.max !== null &&
			!isNaN(Number(filters.buildingSize?.max))
				? filters.buildingSize?.max.toLocaleString()
				: '';

		return !minSize && !maxSize ? emptyValueLabel : `${minSize} - ${maxSize}`;
	}, [filters.buildingSize?.min, filters.buildingSize?.max, emptyValueLabel]);
	const [errors, setErrors] = useState<FilterErrors>(null);

	return (
		<DropdownMenu.Root modal={false}>
			<FilterDropdownButton {...buttonProps}>
				<FilterButtonLabelContainer>
					<FilterButtonLabel>{label}</FilterButtonLabel>
					<FilterButtonValue>{displayValue}</FilterButtonValue>
				</FilterButtonLabelContainer>
				<FilterButtonIconContainer>
					<FilterButtonIconArrow width={10} height={6} />
				</FilterButtonIconContainer>
			</FilterDropdownButton>
			<DropdownMenu.Portal>
				<StyledFilterDropdownContent>
					<strong>{label}</strong>

					<Between
						attribute="buildingSize"
						filter={filters.buildingSize}
						onFilterChange={(f) =>
							setFilters({ ...filters, buildingSize: f.buildingSize ?? null })
						}
						touch={() => {}}
						setError={setErrors}
						thousandSeparator
					/>

					{typeof errors === 'object' && (
						<ErrorMessage>{errors?.min || errors?.max}</ErrorMessage>
					)}
				</StyledFilterDropdownContent>
			</DropdownMenu.Portal>
		</DropdownMenu.Root>
	);
};

const StyledFilterDropdownContent = styled(FilterDropdownContent)`
	padding: 0.5rem 1rem;

	div {
		display: flex;
		align-items: center;
		gap: 0.5rem;

		input {
			height: 40px;
			margin: unset;
		}
	}
`;
