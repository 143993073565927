import React from 'react';

const DEFAULT_ZOOM_DOMAIN: ChartZoomDomain = { x: [0, 100], y: [-100, 100] };

type ChartZoomDomain = {
	x: [number, number];
	y: [number, number];
};

export const isChartZoomDomain = (value: unknown): value is ChartZoomDomain => {
	if (!value || typeof value !== 'object') {
		return false;
	}
	const domain = value as Record<string, unknown>;

	if (!Array.isArray(domain.x) || !Array.isArray(domain.y)) {
		return false;
	}

	if (domain.x.length !== 2 || domain.y.length !== 2) {
		return false;
	}

	if (
		!domain.x.every((n) => typeof n === 'number') ||
		!domain.y.every((n) => typeof n === 'number')
	) {
		return false;
	}

	if (domain.x[1] <= domain.x[0] || domain.y[1] <= domain.y[0]) {
		return false;
	}
	return true;
};

const calculateZoomRange = (domain: ChartZoomDomain) => {
	const [lowX, highX] = domain.x;
	const [lowY, highY] = domain.y;
	return {
		lowX,
		highX,
		lowY,
		highY,
		xrange: highX - lowX,
		yrange: highY - lowY,
	};
};

export const handleZoom = ({
	zoomOut,
	initialZoomDomain,
	setZoomDomain,
}: {
	zoomOut?: boolean;
	initialZoomDomain: ChartZoomDomain;
	setZoomDomain: React.Dispatch<React.SetStateAction<ChartZoomDomain>>;
}) => {
	if (!isChartZoomDomain(initialZoomDomain)) return;
	const { xrange: initialXRange, yrange: initialYRange } =
		calculateZoomRange(initialZoomDomain);

	const xStep = zoomOut ? -initialXRange * 0.04 : initialXRange * 0.04;
	const yStep = zoomOut ? -initialYRange * 0.04 : initialYRange * 0.04;

	setZoomDomain((currentZoomDomain: ChartZoomDomain) => {
		if (!isChartZoomDomain(currentZoomDomain)) return currentZoomDomain;
		const {
			lowX: currentLowX,
			highX: currentHighX,
			lowY: currentLowY,
			highY: currentHighY,
		} = calculateZoomRange(currentZoomDomain);
		const newZoomDomain: ChartZoomDomain = {
			x: [currentLowX + xStep, currentHighX - xStep],
			y: [currentLowY + yStep, currentHighY - yStep],
		};

		const { xrange: newXRange, yrange: newYRange } =
			calculateZoomRange(newZoomDomain);

		const shouldNotZoomOut =
			zoomOut && (newXRange > initialXRange || newYRange > initialYRange);
		const shouldNotZoomIn =
			!zoomOut &&
			(newXRange <= initialXRange * 0.2 || newYRange <= initialYRange * 0.2);
		if (shouldNotZoomIn || !isChartZoomDomain(newZoomDomain)) {
			return currentZoomDomain;
		}
		if (shouldNotZoomOut) {
			return initialZoomDomain;
		}
		return newZoomDomain;
	});
};

export const handleResetZoom = (
	initialZoomDomain: ChartZoomDomain,
	setZoomDomain: React.Dispatch<React.SetStateAction<ChartZoomDomain>>
) => {
	if (isChartZoomDomain(initialZoomDomain)) {
		setZoomDomain(initialZoomDomain);
	} else {
		setZoomDomain(DEFAULT_ZOOM_DOMAIN);
	}
};
