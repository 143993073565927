import { useState } from 'react';
import { FilterBase } from 'Components/Filters/Base/Filter/FilterBase';
import { FilterDescription } from 'Components/Filters/Base/Filter/FilterDescription';
import { useFilterFieldContext } from '../FilterFieldContextProvider';
import { LeaseFilters } from 'models/filters/lease';

const LIMITED_DETAILS_ATTRIBUTES: Record<
	keyof Pick<LeaseFilters, 'hasFullOrLimitedDetails' | 'hasLimitedDetails'>,
	string
> = {
	hasFullOrLimitedDetails: 'Include comps missing one required field',
	hasLimitedDetails: 'Show only comps missing one required field',
} as const;

export const LimitedDetailsFilter = () => {
	const { filters } = useFilterFieldContext();

	const [attribute, setAttribute] = useState(() =>
		Object.typedKeys(LIMITED_DETAILS_ATTRIBUTES).find((attr) => filters[attr])
	);

	return (
		<FilterBase attribute={attribute ?? 'hasFullOrLimitedDetails'}>
			{(props) => (
				<>
					<div>
						{Object.typedKeys(LIMITED_DETAILS_ATTRIBUTES).map((attr) => {
							const id = 'filter-selector-' + attr;
							const attrTitle = LIMITED_DETAILS_ATTRIBUTES[attr];

							return (
								<div key={attr}>
									<input
										type="radio"
										className="radio"
										name={'limited-details-filter'}
										id={id}
										data-qa-id={id}
										value={attr}
										checked={attr === attribute && !!props.filters[attribute]}
										onChange={(e) => {
											const newAttribute = e.target
												.value as keyof typeof LIMITED_DETAILS_ATTRIBUTES;
											setAttribute(newAttribute);
											props.onFilterChange({
												[newAttribute]: 'true',
											});
											if (attribute) {
												props.onFilterChange({
													[attribute]: null,
												});
											}
											props.touch();
										}}
									/>
									<label htmlFor={id}>{attrTitle}</label>
								</div>
							);
						})}
					</div>
					<FilterDescription>
						Required fields include: Tenant Name, Transaction SqFt, Rent Info,
						Date Info
					</FilterDescription>
				</>
			)}
		</FilterBase>
	);
};
