import { Feature, Point } from '@turf/helpers';
import { LeasePopup, PropertyPopup, SalePopup } from '../..';
import { CompType, PropertyComp } from '../../../types';

type CompPopupProps = {
	property: PropertyComp;
	closePopup: () => void;
};

export const getCompPopup =
	(compType: CompType, onClose?: NoArgCallback) =>
	// eslint-disable-next-line react/display-name
	(f: Feature<Point, PropertyComp>, closePopup: NoArgCallback) => {
		const handleClose = () => {
			closePopup();
			onClose?.();
		};

		const props: CompPopupProps = {
			closePopup: handleClose,
			property: f.properties,
		};

		if (compType === 'lease') return <LeasePopup {...props} />;
		if (compType === 'sale') return <SalePopup {...props} />;
		if (compType === 'property') return <PropertyPopup {...props} />;
	};
