import {
	SimpleTable,
	SimpleTableBodyCellRoot,
	defaultTheme,
} from '@compstak/ui-kit';
import { useBenchmarkTableConfig } from './useBenchmarkTableConfig';
import styled from 'styled-components';
import { portfolioWidgetCss } from 'PortfolioAnalytics/UI';

const TABLE_ID = 'benchmark_table';
const HEADER_HEIGHT = 60;

export const BenchmarkingTable = () => {
	const { transposedRows, transposedColumns } = useBenchmarkTableConfig();

	return (
		<TableContainer>
			<SimpleTableWrapper>
				<SimpleTable
					columns={transposedColumns}
					rows={transposedRows}
					id={TABLE_ID}
					getBodyRowProps={({ rowIndex }) => ({
						style: {
							background:
								rowIndex % 2 === 0 ? 'none' : defaultTheme.colors.gray.gray200,
							width: 'fit-content',
						},
					})}
					getBodyCellProps={() => ({
						style: {
							fontSize: '0.875em',
						},
					})}
					headerHeight={HEADER_HEIGHT}
				/>
			</SimpleTableWrapper>
		</TableContainer>
	);
};

const SimpleTableWrapper = styled.div`
	${SimpleTableBodyCellRoot} {
		&:first-child {
			font-weight: 600;
		}
	}
`;

const TableContainer = styled.div`
	${portfolioWidgetCss}
`;
