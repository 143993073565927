import {
	BaseDropdownMenu,
	DropdownListItem,
	DropdownListStyled,
	FormSelect,
	Flex,
	SelectOnChangeParams,
	OpenButton,
	ListItemButton,
	SelectProps,
	TooltipV2,
	Button,
	MQB,
} from '@compstak/ui-kit';
import IconEdit from 'ui/svg_icons/edit.svg';
import { DataSet, DataSetType } from '../../../analytics';
import { UnderlyingDataTable } from './UnderlyingDataTable';
import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
	ReactNode,
} from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { Chart, InsightDataResponse } from 'Pages/Analytics/analytics';
import {
	useUnderlyingDataTableContext,
	CompVisibilityOption,
	COMP_VISIBILITY_OPTIONS,
} from '../UnderlyingDataTableProvider';
import { useLocalStorage } from 'utils/useLocalStorage';
import { chartBuilderActions } from 'Pages/Analytics/Builder/actions';
import { useMarkets } from 'hooks/useMarkets';
import { useDispatch } from 'react-redux';
import { useShowRestoreDataSetConfirmationModal } from './RestoreDataSetConfirmationModal';
import { RadioButton } from 'Components/RadioButton/RadioButton';
import { ATTRIBUTE_TO_PLOT_LABEL } from '../../chartBuilderConstants';
import { useToast } from 'providers/ToastProvider';
interface UnderlyingDataTableTabContentProps {
	dataSets: DataSet[];
	chartDraft: Chart;
	insightsData: InsightDataResponse[];
}

type TitledParagraphSelectOption = DropdownListItem & { body: string };
type CompVisibilityForAllDatasets = { [key: number]: CompVisibilityOption };
type EditDatasetOption =
	| 'exclude-selected-comps'
	| 'include-selected-comps'
	| 'restore-dataset'
	| '';

const EDIT_DATASET_OPTIONS: Record<string, EditDatasetOption> = {
	excludeSelectedComps: 'exclude-selected-comps',
	includeSelectedComps: 'include-selected-comps',
	restoreDataset: 'restore-dataset',
};

export const UnderlyingDataTableTabContent = ({
	dataSets,
	chartDraft,
	insightsData,
}: UnderlyingDataTableTabContentProps) => {
	const isMoreThan1280 = useMediaQuery({ minWidth: MQB.D_1280 });

	const showRestoreDataSetConfirmationModal =
		useShowRestoreDataSetConfirmationModal();

	const {
		selectedCompIds,
		compVisibilitySelectedOption,
		setCompVisibilitySelectedOption,
		excludedCompIdsForDataset,
		setExcludedCompIdsForDataset,
		selectedDataSetIndex,
		setSelectedDataSetIndex,
		underlyingDataCount,
	} = useUnderlyingDataTableContext();

	useEffect(() => {
		if (
			!selectedDataSetIndex ||
			dataSets[selectedDataSetIndex]?.type === DataSetType.MUFA ||
			dataSets.length - 1 < selectedDataSetIndex
		) {
			setSelectedDataSetIndex(
				dataSets.findIndex((dataSet) => dataSet.type !== DataSetType.MUFA) || 0
			);
		}
	}, [dataSets]);

	const markets = useMarkets();
	const dispatch = useDispatch();
	const { addToast } = useToast();

	const selectedDataSet: DataSet | undefined = useMemo(
		() => dataSets[selectedDataSetIndex],
		[dataSets, selectedDataSetIndex]
	);

	const selectedDataSetId: number = useMemo(
		() => selectedDataSet?.id ?? 0,
		[dataSets, selectedDataSetIndex]
	);

	const shapeCheckerForCompVisibilityForAllDatasets = useCallback(
		(value: unknown): CompVisibilityForAllDatasets => {
			if (typeof value !== 'object' || value === null) {
				return {};
			}
			Object.keys(value).map((key) => {
				if (typeof key !== 'number') {
					return {};
				}
				if (!Object.values(COMP_VISIBILITY_OPTIONS).includes(value[key])) {
					return {};
				}
			});
			return value as CompVisibilityForAllDatasets;
		},
		[]
	);

	const [compVisibilityForAllDatasets, saveCompVisibilityForAllDatasets] =
		useLocalStorage<CompVisibilityForAllDatasets>(
			'compVisibilityForAllDatasets',
			{},
			shapeCheckerForCompVisibilityForAllDatasets
		);

	useEffect(() => {
		let newVisibilityOption: CompVisibilityOption;
		if (excludedCompIdsForDataset.length > 0) {
			newVisibilityOption =
				compVisibilityForAllDatasets[selectedDataSetId] ||
				compVisibilitySelectedOption ||
				COMP_VISIBILITY_OPTIONS.showAllComps;
		} else {
			newVisibilityOption = COMP_VISIBILITY_OPTIONS.showAllComps;
		}

		setCompVisibilitySelectedOption(newVisibilityOption);
	}, [
		selectedDataSetId,
		compVisibilityForAllDatasets,
		compVisibilitySelectedOption,
		excludedCompIdsForDataset,
	]);

	const [editDatasetSelectedOption, setEditDatasetSelectedOption] =
		useState<EditDatasetOption>('');

	useEffect(() => {
		setExcludedCompIdsForDataset(selectedDataSet?.excludedCompIds || []);
	}, [selectedDataSet]);

	const { leaseCount, includedLeaseCount, excludedLeaseCount } = useMemo(() => {
		const leaseCountCalc = underlyingDataCount;
		const excludedLeaseCountCalc = excludedCompIdsForDataset.length ?? 0;
		const includedLeaseCountCalc =
			leaseCountCalc - excludedLeaseCountCalc >= 0
				? leaseCountCalc - excludedLeaseCountCalc
				: 0;
		return {
			leaseCount: leaseCountCalc,
			includedLeaseCount: includedLeaseCountCalc,
			excludedLeaseCount: excludedLeaseCountCalc,
		};
	}, [
		insightsData,
		selectedDataSetIndex,
		excludedCompIdsForDataset,
		chartDraft.timespan,
		underlyingDataCount,
	]);

	const disabledDataSetSelectorItems = useMemo(
		() =>
			dataSets.reduce<NonNullable<SelectProps['disabledOptionButtons']>>(
				(acc, dataSet, index) => {
					if (dataSet.type === DataSetType.MUFA) {
						acc[index] = true;
					}
					return acc;
				},
				{}
			),
		[dataSets]
	);

	const disabledEditDataSetOptions = useMemo(() => {
		return {
			excludeCompsIsDisabled:
				selectedCompIds.length === 0 ||
				selectedCompIds.every((compId: number) =>
					excludedCompIdsForDataset.includes(compId)
				),
			includeCompsIsDisabled:
				selectedCompIds.length === 0 ||
				!selectedCompIds.some((compId: number) =>
					excludedCompIdsForDataset.includes(compId)
				),
			restoreDatasetIsDisabled: excludedCompIdsForDataset.length === 0,
		};
	}, [selectedCompIds, excludedCompIdsForDataset]);

	const disabledRadioButtons = useMemo(() => {
		return excludedCompIdsForDataset.length === 0;
	}, [excludedCompIdsForDataset]);

	const dataSetSelectorItemRenderer = useCallback<
		NonNullable<SelectProps['optionContentRenderer']>
	>(
		(item) => {
			if (disabledDataSetSelectorItems[item.value]) {
				return (
					<TooltipV2
						content={
							'CompStak does not support the display of underlying data for RealPage datasets.'
						}
					>
						<span>{item.title}</span>
					</TooltipV2>
				);
			}
			return <span title={item.title}>{item.title}</span>;
		},
		[disabledDataSetSelectorItems]
	);

	const titledParagraphItemRenderer = useCallback(
		(item: TitledParagraphSelectOption): ReactNode => {
			const active =
				compVisibilitySelectedOption === item.value ||
				editDatasetSelectedOption === item.value;
			const disabled =
				(item.value === EDIT_DATASET_OPTIONS.restoreDataset &&
					disabledEditDataSetOptions.restoreDatasetIsDisabled) ||
				(item.value === EDIT_DATASET_OPTIONS.excludeSelectedComps &&
					disabledEditDataSetOptions.excludeCompsIsDisabled) ||
				(item.value === EDIT_DATASET_OPTIONS.includeSelectedComps &&
					disabledEditDataSetOptions.includeCompsIsDisabled);
			return (
				<CompVisibilityOptionContainerOuter
					onClick={item.onClick}
					active={active}
					disabled={disabled}
				>
					{item.title}
					<CompVisibilityOptionContainerInner>
						{item.body}
					</CompVisibilityOptionContainerInner>
				</CompVisibilityOptionContainerOuter>
			);
		},
		[
			compVisibilitySelectedOption,
			editDatasetSelectedOption,
			disabledEditDataSetOptions,
		]
	);

	const handleDataSetChange = (e: SelectOnChangeParams) => {
		const selectedDataSetValue = e.selectedItem?.value;

		if (
			selectedDataSetValue == null ||
			disabledDataSetSelectorItems[selectedDataSetValue]
		) {
			return;
		}

		setSelectedDataSetIndex(Number(selectedDataSetValue));
	};

	const handleVisibilitySelection = (option: CompVisibilityOption) => {
		setCompVisibilitySelectedOption(option);
		const newState = {
			...compVisibilityForAllDatasets,
			[selectedDataSetId]: option,
		};
		saveCompVisibilityForAllDatasets(newState);
	};

	const handleShowAllComps = () =>
		handleVisibilitySelection(COMP_VISIBILITY_OPTIONS.showAllComps);

	const handleHideExcludedComps = () =>
		handleVisibilitySelection(COMP_VISIBILITY_OPTIONS.hideExcludedComps);

	const handleShowOnlyExcludedComps = () =>
		handleVisibilitySelection(COMP_VISIBILITY_OPTIONS.showOnlyExcludedComps);

	const restoreDataSetFn = useCallback(
		(dataSetWithoutExcludedCompIds: DataSet) => {
			chartBuilderActions.updateDataSet(
				chartDraft,
				dataSetWithoutExcludedCompIds,
				markets,
				() => {
					setExcludedCompIdsForDataset([]);

					addToast({
						title: 'The dataset has been restored to its original state',
					});
				}
			)(dispatch);
		},
		[chartDraft, markets]
	);

	const editDatasetSelectOptions: TitledParagraphSelectOption[] = [
		{
			onClick: () => {
				setEditDatasetSelectedOption(EDIT_DATASET_OPTIONS.excludeSelectedComps);
				const updatedExcludedCompIds: number[] = [
					...new Set([...excludedCompIdsForDataset, ...selectedCompIds]),
				];
				setExcludedCompIdsForDataset(updatedExcludedCompIds);
				const dataSetWithUpdatedExcludedCompIds = {
					...selectedDataSet,
					['excludedCompIds']: updatedExcludedCompIds,
				};

				const count = selectedCompIds.filter(
					(compId) => !excludedCompIdsForDataset.includes(compId)
				).length;
				const additional =
					excludedCompIdsForDataset?.length > 0 ? 'additional' : '';
				const feedbackMessage =
					count > 1
						? `${count} ${additional} comps are now excluded from the dataset and graph`
						: `${count} ${additional} comp is now excluded from the dataset and graph`;

				chartBuilderActions.updateDataSet(
					chartDraft,
					dataSetWithUpdatedExcludedCompIds,
					markets,
					() => {
						addToast({ title: feedbackMessage });
					}
				)(dispatch);
			},
			title: 'Exclude Selected Comps',
			value: EDIT_DATASET_OPTIONS.excludeSelectedComps,
			body: 'Exclude the selected comps from the dataset and update the graph.',
		},
		{
			onClick: () => {
				setEditDatasetSelectedOption(EDIT_DATASET_OPTIONS.includeSelectedComps);
				const updatedExcludedCompIds = excludedCompIdsForDataset.filter(
					(id) => !selectedCompIds.includes(id)
				);
				setExcludedCompIdsForDataset(updatedExcludedCompIds);
				const dataSetWithUpdatedExcludedCompIds = {
					...selectedDataSet,
					['excludedCompIds']: updatedExcludedCompIds,
				};
				const count = selectedCompIds.filter((compId) =>
					excludedCompIdsForDataset.includes(compId)
				).length;

				const feedbackMessage =
					count > 1
						? `${count} additional comps are now included in the dataset and graph`
						: `${count} additional comp is now included in the dataset and graph`;

				chartBuilderActions.updateDataSet(
					chartDraft,
					dataSetWithUpdatedExcludedCompIds,
					markets,
					() => {
						addToast({ title: feedbackMessage });
					}
				)(dispatch);
			},
			title: 'Include Selected Comps',
			value: EDIT_DATASET_OPTIONS.includeSelectedComps,
			body: 'Add the selected comps to the dataset and update the graph.',
		},
		{
			onClick: () => {
				setEditDatasetSelectedOption(EDIT_DATASET_OPTIONS.restoreDataset);
				handleVisibilitySelection(COMP_VISIBILITY_OPTIONS.showAllComps);
				const dataSetWithoutExcludedCompIds = {
					...selectedDataSet,
					['excludedCompIds']: [],
				};
				showRestoreDataSetConfirmationModal({
					restoreDataSetFn,
					dataSetWithoutExcludedCompIds,
				});
			},
			title: 'Restore Dataset',
			value: EDIT_DATASET_OPTIONS.restoreDataset,
			body: 'Restore the dataset to its original state and update the graph.',
		},
	];

	const plottedAttribute = ATTRIBUTE_TO_PLOT_LABEL[selectedDataSet?.series];

	return (
		<div>
			<HeaderWrap>
				<LabelWrap>
					{isMoreThan1280 && (
						<UnderlyingDataLabel>Underlying Data for</UnderlyingDataLabel>
					)}
					<FormSelect
						items={dataSets.map((dataSet, index) => ({
							value: index,
							title: dataSet.name,
						}))}
						value={selectedDataSetIndex}
						onChange={handleDataSetChange}
						noResultMessage="There is no data..."
						showScrollbarOnHover
						isSearchable
						disabledOptionButtons={disabledDataSetSelectorItems}
						optionContentRenderer={dataSetSelectorItemRenderer}
						optionButtonCustomStyles={{
							overflow: 'hidden',
							textOverflow: 'ellipsis',
						}}
					/>
				</LabelWrap>
				<BaseDropdownMenu
					OpenButton={({ onClick, isOpen }) => (
						<StyledButton
							variant="ghost2"
							icon={<IconEditWithMargin />}
							onClick={onClick}
							isOpen={isOpen}
						>
							Edit Dataset
						</StyledButton>
					)}
					MenuContainer={MenuContainer}
					dropdownItemRenderer={titledParagraphItemRenderer}
					items={editDatasetSelectOptions}
				/>
			</HeaderWrap>
			<RadioButtonsContainer>
				<strong>View:</strong>
				<RadioButton
					disabled={disabledRadioButtons}
					checked={
						compVisibilitySelectedOption ===
						COMP_VISIBILITY_OPTIONS.showAllComps
					}
					onChange={handleShowAllComps}
					value={COMP_VISIBILITY_OPTIONS.showAllComps}
					data-qa-id="chartbuilder-show-all-comps-radio"
				>
					<TooltipV2
						content={`Only comps with the plotted attribute ${plottedAttribute} are displayed in the table below.`}
					>
						<span>All Comps ({leaseCount})</span>
					</TooltipV2>
				</RadioButton>
				<RadioButton
					disabled={disabledRadioButtons}
					checked={
						compVisibilitySelectedOption ===
						COMP_VISIBILITY_OPTIONS.hideExcludedComps
					}
					onChange={handleHideExcludedComps}
					value={COMP_VISIBILITY_OPTIONS.hideExcludedComps}
					data-qa-id="chartbuilder-hide-excluded-comps-radio"
				>
					Included Only ({includedLeaseCount})
				</RadioButton>
				<RadioButton
					disabled={disabledRadioButtons}
					checked={
						compVisibilitySelectedOption ===
						COMP_VISIBILITY_OPTIONS.showOnlyExcludedComps
					}
					onChange={handleShowOnlyExcludedComps}
					value={COMP_VISIBILITY_OPTIONS.showOnlyExcludedComps}
					data-qa-id="chartbuilder-show-only-excluded-comps-radio"
				>
					Excluded Only ({excludedLeaseCount})
				</RadioButton>
			</RadioButtonsContainer>
			<UnderlyingDataTable dataSet={selectedDataSet} chart={chartDraft} />
		</div>
	);
};

const HeaderWrap = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	${OpenButton}, ${OpenButton} input {
		min-height: unset;
		height: 2.625rem;
		&:focus {
			outline: none;
		}
		width: 240px;
	}

	${OpenButton} {
		& > span {
			text-overflow: ellipsis;
			overflow: hidden;
			margin-right: 1rem;
		}
	}
`;

const UnderlyingDataLabel = styled.span`
	font-weight: 500;
	font-size: 1.25rem;
	line-height: 2.5rem;
	white-space: nowrap;
	font-family: ${({ theme }) => theme.typography.fontFamily.gotham};
`;

const LabelWrap = styled.span`
	display: flex;
	align-items: center;
	column-gap: 0.5rem;
`;

const CompVisibilityOptionContainerOuter = styled(ListItemButton)<{
	onClick?: React.MouseEventHandler<HTMLButtonElement>;
	active: boolean;
	disabled: boolean;
}>`
	height: 104px;
	padding: 1rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5rem;
	text-align: left;
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
	opacity: ${({ disabled }) => (disabled ? 0.75 : 1)};
	background-color: ${({ active, theme }) =>
		active ? theme.colors.gray.gray900 : theme.colors.white.white};
	&:hover {
		background-color: ${({ theme }) => theme.colors.gray.gray400};
	}
	&:focus {
		outline: none;
	}
`;

const CompVisibilityOptionContainerInner = styled.div`
	width: 100%;
	word-wrap: break-word;
	white-space: normal;
	word-break: break-word;
	font-weight: 325;
	font-size: 14px;
	line-height: 1.5rem;
	color: ${({ theme }) => theme.colors.gray.n100};
`;

const MenuContainer = styled.div`
	position: relative;

	${DropdownListStyled} {
		width: 20rem;
		max-height: 32rem;
		left: auto;
		right: 0;
		box-shadow: none;
		top: 50px;
		padding: 4px 0;
		border-radius: 3px;
		border: 1px solid ${({ theme }) => theme.colors.gray.gray300};
	}
`;

const StyledButton = styled(Button)<{ isOpen: boolean }>`
	background-color: ${({ isOpen, theme }) =>
		isOpen ? '#00000033' : theme.colors.white.white};
	margin-left: 0.5rem;
`;

const IconEditWithMargin = styled(IconEdit)`
	margin-top: 3px;
	margin-left: 3px;
`;

const RadioButtonsContainer = styled(Flex)`
	justify-content: flex-start;
	gap: 1rem;
	margin-top: 1rem;
`;
