import {
	SearchSalesPayload,
	SearchSalesResponse,
	UnlockSalesResponse,
} from 'api';
import { SalesComp } from 'types';
import factory from './factory';
import pointService from './salePoints';

const serviceController = factory.create<number, SalesComp>({
	createUrl: (id) => '/api/salesComps/' + id,
	timeout: 1000 * 60 * 5,
});

export const onSalesUnlock = (data: UnlockSalesResponse) => {
	pointService.clearAll();
	data.comps.forEach(function (sale) {
		serviceController.service.clear(sale.id);
		service.add(sale.id, sale);
	});
};

const service = serviceController.exportable({
	// @ts-expect-error TS7006: Parameter 'id' implicitly has ...
	sendSuggestion(id, suggestion, market) {
		return factory
			.post('/api/salesComps/feedback', {
				compId: id,
				suggestion: suggestion,
				market: market,
			})
			.then(function (data) {
				return data;
			});
	},

	// @ts-expect-error TS7006: Parameter 'id' implicitly has ...
	share(id, email) {
		return factory
			.post('/api/salesComps/share', { compId: id, email: email })
			.then(function (data) {
				return data;
			});
	},
	unlock(ids: number[]) {
		return factory
			.put<
				{ ids: number[] },
				UnlockSalesResponse
			>('/api/salesComps/unlock', { ids: ids })
			.then(function (data) {
				onSalesUnlock(data);
				return data.comps;
			});
	},
});

// @ts-expect-error TS2322: Type '(idsOrPromises: number[]...
service.loadMany = function (idsOrPromises) {
	if (idsOrPromises.length === 1) {
		return Promise.all([service.load(idsOrPromises[0])]);
	}

	// Should it be this complicated? It seems like it shouldn't.
	// The reasons it is:
	// • We don't want to load comps we don't have to load.
	// • The cache should be filled immediately in case this is called twice

	return Promise.all(idsOrPromises).then(function (ids) {
		const resolves = {};
		const rejects = {};

		const idsToLoad = ids.filter(function (id) {
			return !service.has(id);
		});

		// This is the array of promises that will be returned.
		const promises = ids.map(function (id) {
			if (service.has(id)) {
				return service.load(id);
			}
			// Since we're loading the comps ourselves, we need to
			// create the promises and fill the cache ourselves.
			const promise = new Promise(function (resolve, reject) {
				// @ts-expect-error TS7053: Element implicitly has an 'any...
				resolves[id] = resolve;

				// @ts-expect-error TS7053: Element implicitly has an 'any...
				rejects[id] = reject;
			});
			service.add(id, promise);
			return promise;
		});

		// If everything has been loaded already, short circuit.
		if (idsToLoad.length === 0) {
			return Promise.all(promises);
		}

		const loader = factory.post<SearchSalesPayload, SearchSalesResponse>(
			'/api/salesComps/search',
			{
				filter: [{ property: 'id', value: idsToLoad }],
				offset: 0,
				limit: idsToLoad.length,
				order: 'desc',
				sort: 'id',
			},
			true
		);
		loader.then(function (searchResults) {
			searchResults.comps.forEach(function (sale) {
				// @ts-expect-error TS7053: Element implicitly has an 'any...
				resolves[sale.id](sale);
			});
		});

		return Promise.all(promises);
	});
};

export default service;
