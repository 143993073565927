import React, { useMemo } from 'react';
import { VictoryTooltipProps } from 'victory';
import { TrendMetricFilter } from 'PortfolioAnalytics/portfolioAnalyticsTypes';
import { Styled } from './styled';
import { formatDate } from '../util';
import money from 'ui/util/money';
import { METRIC_DROPDOWN_DATA } from '../Filters/PortfolioTrendFilterComponent';

type MarketTrendChartFlyoutProps = VictoryTooltipProps & {
	isMonthsMetric: boolean;
	width: number;
	metricFilter: TrendMetricFilter;
	chartWidth: number;
};

const BASIC_FLYOUT_HEIGHT = 25;
const FLYOUT_ITEM_HEIGHT = 23;
const TOOLTIP_BAR_WIDTH = 5;
const DEFAULT_COLOR = '#000';

export const PortfolioTrendChartFlyout = ({
	x,
	activePoints,
	isMonthsMetric,
	width,
	metricFilter,
	chartWidth,
}: MarketTrendChartFlyoutProps) => {
	const activePointsIsValid = useMemo(() => {
		return (
			Array.isArray(activePoints) &&
			activePoints.length > 0 &&
			activePoints.every(
				(point) =>
					typeof point === 'object' &&
					point !== null &&
					'x' in point &&
					'y' in point &&
					'childName' in point &&
					point.x instanceof Date &&
					typeof point.y === 'number' &&
					typeof point.childName === 'string'
			)
		);
	}, [activePoints]);

	if (!activePoints || !activePointsIsValid || !(x && typeof x === 'number'))
		return null;

	const xValue = activePoints[0].x;
	const metricType = METRIC_DROPDOWN_DATA.find(
		(m) => m.id === metricFilter
	)?.value;
	const symbol = isMonthsMetric ? '(Months)' : '($/PSF)';
	const showRightTooltipBar = x > chartWidth / 2;
	const values = activePoints
		.filter(
			(point) =>
				point.childName.includes('chart-line') ||
				point.childName.includes('chart-scatter')
		)
		.map((point) => ({
			y: isMonthsMetric ? point.y.toFixed(1) : money(point.y, 'USD', 2, false),
			color: point.style.data.stroke || DEFAULT_COLOR,
		}));

	const numberOfDatasets = values.length;

	const flyoutHeight =
		BASIC_FLYOUT_HEIGHT + FLYOUT_ITEM_HEIGHT * numberOfDatasets;

	const translateX = showRightTooltipBar
		? x + TOOLTIP_BAR_WIDTH - width
		: x - TOOLTIP_BAR_WIDTH;

	return (
		<g transform={`translate(${translateX}, ${20})`}>
			<foreignObject width={width} height={280}>
				<Styled.CustomTooltipContainer>
					<Styled.CustomTooltipBar hide={showRightTooltipBar} height={'280px'}>
						<div />
					</Styled.CustomTooltipBar>
					<Styled.CustomTooltipInfoBox style={{ height: flyoutHeight }}>
						<Styled.CustomTooltipInfoBoxHeading>
							<div>{formatDate(xValue, false)}</div>
							<div>{`${metricType} ${symbol}`}</div>
						</Styled.CustomTooltipInfoBoxHeading>
						{values.map((value, index) => {
							return (
								<Styled.CustomTooltipInfoBoxData key={index}>
									<div>
										<Styled.CustomTooltipInfoBoxIndicator color={value.color} />
									</div>
									<div>
										<span>{value.y}</span>
									</div>
								</Styled.CustomTooltipInfoBoxData>
							);
						})}
					</Styled.CustomTooltipInfoBox>
					<Styled.CustomTooltipBar
						hide={!showRightTooltipBar}
						right={showRightTooltipBar}
						height={'280px'}
					>
						<div />
					</Styled.CustomTooltipBar>
				</Styled.CustomTooltipContainer>
			</foreignObject>
		</g>
	);
};
