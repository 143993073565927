import { API, APIClientNotOkResponseError } from '@compstak/common';
import {
	UseMutationOptions,
	useMutation,
	useQueryClient,
} from '@tanstack/react-query';
import { QUERY_KEYS } from 'api/constants';
import { PortfolioInteractions } from './usePortfolioInteractionsQuery';

export const useSelectPortfolioMutation = (
	options?: UseMutationOptions<
		void,
		APIClientNotOkResponseError,
		SelectPortfolioParams
	>
) => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: async ({ portfolioId, selected }: SelectPortfolioParams) => {
			const res = await API.post<void, Pick<SelectPortfolioParams, 'selected'>>(
				`/pa/v2/api/portfolios/${portfolioId}/interactions/selection`,
				{ selected }
			);
			return res.data;
		},
		...options,
		onSuccess: (data, params, context) => {
			queryClient.setQueryData<PortfolioInteractions>(
				[QUERY_KEYS.PORTFOLIO_INTERACTIONS],
				(oldData) => {
					const portfolioInteraction = oldData?.[params.portfolioId];

					if (portfolioInteraction) {
						return {
							...oldData,
							[portfolioInteraction.portfolioId]: {
								...oldData[portfolioInteraction.portfolioId],
								selected: params.selected,
							},
						};
					}

					return oldData;
				}
			);
			options?.onSuccess?.(data, params, context);
		},
	});
};

type SelectPortfolioParams = {
	portfolioId: number;
	selected: boolean;
};
