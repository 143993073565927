import { mapValues, sortBy } from 'lodash';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import {
	VictoryAxis,
	VictoryBar,
	VictoryChart,
	VictoryVoronoiContainer,
	VictoryLabel,
	VictoryTooltip,
} from 'victory';
import { ChartBox, ChartDataMappingLease, TotalValue } from '../ChartSelect';
import { Portfolio, PortfolioTenant } from 'api';
import abbreviateNumber from 'ui/util/abbreviateNumber';
import { PORTFOLIO_CHART_COLORS } from '../../constants';
import { groupBy } from 'lodash';
import { formatInteger } from 'format';
import { useNavigate } from 'react-router';
import { routes } from 'router';
import { ChartStateWrapper } from '../ChartStateWrapper';
import {
	CHART_AXIS_TICK_STYLE,
	VICTORY_TOOLTIP_STYLE,
} from '../chartsConstants';
type TenantExposureChartProps = {
	portfolio: Portfolio;
};

const TOP_COUNT = 10;
const TENANT_INDUSTRY_EXPOSURE_CHART_NAME = `Top ${TOP_COUNT} Tenant Exposure`;

export const TenantExposureChart = ({
	portfolio,
}: TenantExposureChartProps) => {
	const navigate = useNavigate();

	const tenantExposureData: ChartDataMappingLease = useMemo(() => {
		const tenantNameGroups = groupBy(
			portfolio.tenantNames,
			(t) => t.tenantName
		);
		const tenantData = Object.values(
			mapValues(tenantNameGroups, getTotalValues)
		);

		return {
			sqft: getChartOptionData(tenantData, 'totalSqFt'),
			leaseCount: getChartOptionData(tenantData, 'activeLeaseCount'),
			leaseAmount: getChartOptionData(tenantData, 'activeLeaseAmount'),
		};
	}, [portfolio]);

	return (
		<ChartBox<'lease'> chartName={TENANT_INDUSTRY_EXPOSURE_CHART_NAME}>
			{(selectedChart) => {
				const data = tenantExposureData[selectedChart.type];
				return (
					<ChartStateWrapper noData={!data.length}>
						<Centered>
							<VictoryChart
								height={550}
								containerComponent={
									<VictoryVoronoiContainer
										voronoiDimension="x"
										responsive={true}
										style={{
											margin: '0 auto',
											width: '500px',
											maxWidth: '75%',
											maxHeight: '500px',
										}}
										labels={() => ' '}
										labelComponent={
											<VictoryTooltip
												{...VICTORY_TOOLTIP_STYLE}
												text={(d) => {
													const tenantName =
														d?.datum?.xName && d?.datum?.xName.length > 20
															? d?.datum?.xName.substring(0, 20) + '...'
															: d?.datum?.xName;
													return `(${formatInteger(d?.datum?.y || 0)}) \n Click the bar to view comps \n for ${tenantName}`;
												}}
												pointerLength={0}
												centerOffset={{ x: 0, y: 45 }}
												constrainToVisibleArea={true}
											/>
										}
									/>
								}
								padding={{ left: 90, top: 50, right: 50, bottom: 50 }}
								style={{
									parent: {
										color: '#A3A3A3',
										fontSize: 10,
										lineHeight: 10,
										maxWidth: '80%',
										margin: '0 auto',
									},
								}}
							>
								<VictoryAxis
									dependentAxis
									fixLabelOverlap
									offsetY={50}
									tickLabelComponent={
										<VictoryLabel style={CHART_AXIS_TICK_STYLE} />
									}
									tickFormat={(d: string) => abbreviateNumber(d)}
									style={{
										axis: { stroke: '#F0F0F0', strokeWidth: 2 },
										grid: { stroke: '#F0F0F0' },
									}}
								/>
								<VictoryAxis
									domain={{ x: [0.5, data.length + 0.5] }}
									fixLabelOverlap
									tickLabelComponent={
										<VictoryLabel
											renderInPortal
											style={CHART_AXIS_TICK_STYLE}
										/>
									}
									tickFormat={(text: string) => {
										const splitTick = text
											.split(' ')
											.map((word) => {
												if (word.length > 18) {
													const midpoint = Math.ceil(word.length / 2);
													return `${word.slice(0, midpoint)} ${word.slice(midpoint)}`;
												}
												return word;
											})
											.join(' ');
										return splitTick.replace(
											new RegExp(`(?![^\\n]{1,18}$)([^\\n]{1,18})\\s`, 'g'),
											'$1\n'
										);
									}}
									style={{
										axis: { stroke: '#F0F0F0', strokeWidth: 2 },
										grid: { stroke: '#F0F0F0', border: '1px solid black' },
										tickLabels: {
											padding: 5,
										},
									}}
								/>
								<VictoryBar
									labels={({ datum }) => formatInteger(datum.y)}
									alignment="middle"
									barRatio={1}
									barWidth={15}
									horizontal
									width={120}
									height={500}
									data={data}
									style={{
										data: {
											cursor: 'pointer',
											fill: PORTFOLIO_CHART_COLORS[0],
										},
									}}
									events={[
										{
											target: 'data',
											eventHandlers: {
												onClick: (_, clickedProps) => {
													const tenantName = clickedProps.datum.x as string;
													navigate(
														routes.portfolioByIdView.toHref(
															{ portfolioId: portfolio.id, viewType: 'list' },
															{
																tenantNames: [tenantName],
															}
														)
													);
												},
											},
										},
									]}
								/>
							</VictoryChart>
						</Centered>
					</ChartStateWrapper>
				);
			}}
		</ChartBox>
	);
};

const Centered = styled.div`
	align-items: center;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
`;

export const getTotalValues = (groups: PortfolioTenant[]) => {
	return groups.reduce(
		(
			total,
			{ activeLeaseCount, activeLeaseAmount, totalSqFt, tenantName }
		) => ({
			activeLeaseCount: total.activeLeaseCount + activeLeaseCount,
			activeLeaseAmount: total.activeLeaseAmount + activeLeaseAmount,
			totalSqFt: total.totalSqFt + totalSqFt,
			tenantName,
		}),
		{
			activeLeaseCount: 0,
			activeLeaseAmount: 0,
			totalSqFt: 0,
			tenantName: '',
		}
	);
};

export const getChartOptionData = (
	tenantData: Pick<PortfolioTenant, keyof TotalValue<'lease'> | 'tenantName'>[],
	chartOptionKey: keyof TotalValue<'lease'>
) => {
	return sortBy(tenantData, (tenant) => tenant[chartOptionKey])
		.slice(-TOP_COUNT)
		.map((tenant) => ({
			x: tenant.tenantName,
			y: tenant[chartOptionKey],
		}));
};
