import {
	ModalButton,
	ModalButtons,
	ModalParagraph,
	ModalTitle,
} from 'Components/Modals/common/UI';
import { useModal } from 'providers/ModalProvider';
import styled from 'styled-components';

export const MarketStatsOurCalculationModal = () => {
	const { closeModal } = useModal();

	return (
		<div>
			<ModalTitle>Our calculations</ModalTitle>
			<ModalParagraph>
				<div style={{ marginBottom: '0.25rem' }}>
					Here's how we define and calculate our metrics:
				</div>
				<List>
					<ListItem>
						For each attribute, we provide the 25th percentile (p25), average,
						and 75th percentile (p75) values for three data groups: Competitive
						Set, Submarket, and Market. These values are calculated using
						weighted averages based on transaction size.
					</ListItem>
					<ListItem>
						Subject Property values are also calculated using weighted averages,
						specific to the property, based on transaction size.
					</ListItem>
					<ListItem>
						Space Type Driven Statistics: We display stats based on the dominant
						space type for each property (e.g., if a property has 5 office
						leases and 2 retail leases, only office lease stats are shown). If
						multiple space types are available, you can select a different one
						to view stats specific to that space type.
					</ListItem>
					<ListItem>
						<a
							href="https://support.compstak.com/hc/en-us/articles/26369138365709-What-is-the-difference-between-Net-and-Gross-leases"
							className="underline"
							target="_blank"
							rel="noreferrer"
						>
							Read our white paper about Net and Gross Calculations
						</a>
					</ListItem>
				</List>
			</ModalParagraph>
			<ModalParagraph>
				<BoldLabel>Starting Rent (PSF)</BoldLabel>
				<div>
					The initial rent per square foot at the beginning of a lease term.
				</div>
			</ModalParagraph>
			<ModalParagraph>
				<BoldLabel>Effective Rent (PSF)</BoldLabel>
				<div>
					A calculation of the average monthly rental cost, which incorporates
					landlord rental concessions such as TI allowances and free rent.
				</div>
				<List>
					<li>
						<a
							href="https://support.compstak.com/hc/en-us/articles/24740168037389-How-do-you-calculate-NER"
							className="underline"
							target="_blank"
							rel="noreferrer"
						>
							Read our white paper about Effective Rent
						</a>
					</li>
				</List>
			</ModalParagraph>
			<ModalParagraph>
				<BoldLabel>Transaction Size (SqFt)</BoldLabel>
				<div>
					The amount of space (in square feet) leased by the tenant for the
					given transaction.
				</div>
			</ModalParagraph>
			<ModalParagraph>
				<BoldLabel>Tenant Improvement (PSF)</BoldLabel>
				<div>
					The negotiated allowance that the landlord is giving back to the
					tenant to renovate/improve the space leased, or the estimated value of
					such work in the case of pre-built spaces.
				</div>
			</ModalParagraph>
			<ModalParagraph>
				<BoldLabel>Free Rent (Months)</BoldLabel>
				<div>
					The number of months in a lease for which rent is not charged.
				</div>
			</ModalParagraph>
			<ModalButtons>
				<ModalButton variant="secondary" onClick={closeModal}>
					Go Back
				</ModalButton>
			</ModalButtons>
		</div>
	);
};

const List = styled.ul`
	list-style-type: disc;
	padding-left: 20px;
`;

const ListItem = styled.li`
	&:not(:last-child) {
		margin-bottom: 0.5rem;
	}
`;

const BoldLabel = styled.div`
	font-weight: 500;
`;
