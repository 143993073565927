import dayjs from 'dayjs';

import cloneDeep from 'lodash/cloneDeep';

import {
	PREPARE_LEASES,
	UPDATE_CUSTOM_EXPORT_VALUE,
	HIDE_CUSTOM_EXPORT_FIELD,
	SHOW_CUSTOM_EXPORT_FIELD,
	EXPORT_CUSTOM_LEASES,
	UPDATE_CUSTOM_EXPORT_METADATA,
	RESTORE_EXPORT,
	CLEAR_CUSTOM_EXPORT,
	ExportAction,
} from 'actions/export';

import { formatComp, sectionedMapping } from 'util/comp-format/src/format';
import { LeaseComp, SalesComp } from 'types/comp';
import { DATE_FORMATS } from 'constants/dateFormats';
import { MarketModel, Market } from '@compstak/common';

export type ExportDataState = {
	title?: string;
	subjectProperty?: number;
	dateCreated?: string;
	clientLogo?: string;
	companyLogo?: string;
	headshot?: string;
	preparedFor?: string;
	preparedBy?: string;
	notes?: string;
	market?: Market;
	viewport?: {};
	leases: LeaseComp[] | SalesComp[];
	formattedLeases: LeaseComp[] | SalesComp[];
	sectionedLeases: LeaseComp[] | SalesComp[];
	hiddenFields: unknown[];
};

export type ExportState = {
	exportData: ExportDataState;
};

const getInitialExportDataState = () => ({
	leases: [],
	formattedLeases: [],
	sectionedLeases: [],
	hiddenFields: [],
});

const initialState: ExportState = {
	exportData: getInitialExportDataState(),
};

const exportReducer = (
	state = initialState,
	action: ExportAction
): ExportState => {
	switch (action.type) {
		case PREPARE_LEASES + '_FULFILLED': {
			//@ts-expect-error payload is missing some fields
			const market = new MarketModel(action.payload.market);
			//@ts-expect-error payload is missing some fields
			const comps = action.payload.comps;
			const isMultiMarket = comps.some(
				// @ts-expect-error TS7006: Parameter 'comp' implicitly ha...
				(comp) => comp.marketId !== comps[0].marketId
			);
			const titlePrefix = `CompStak Export - ${
				//@ts-expect-error MarketModel is missing some fields
				isMultiMarket ? '' : market.displayName + ' - '
			}`;
			const title = `${titlePrefix}${dayjs().format(
				//@ts-expect-error MarketModel is missing some fields
				market.countryCode !== 'US'
					? DATE_FORMATS['DD/MM/YYYY']
					: DATE_FORMATS['MM/DD/YYYY']
			)}`;

			let compsForExport;

			// @ts-expect-error meta is not defined
			const compType = action.meta.compType;

			if (compType === 'sale') {
				// @ts-expect-error TS7006: Parameter 'comp' implicitly ha...
				compsForExport = comps.flatMap((comp) => {
					return comp.isPortfolio
						? comp
						: {
								...comp,
								...comp.portfolio[0],
							};
				});
			} else {
				compsForExport = comps;
			}

			// @ts-expect-error TS7006: Parameter 'comp' implicitly ha...
			let sectionedLeases = compsForExport.flatMap((comp) => {
				if (comp.isPortfolio) {
					// @ts-expect-error TS7006: Parameter 'property' implicitl...
					return comp.portfolio.map((property) =>
						sectionedMapping({ ...comp, ...property }, compType)
					);
				}
				return sectionedMapping(comp, compType);
			});

			// @ts-expect-error TS7006: Parameter 'sectionedLease' imp...
			sectionedLeases = sectionedLeases.map((sectionedLease) => {
				return Object.keys(sectionedLease).reduce((acc, key) => {
					// @ts-expect-error TS7053: Element implicitly has an 'any...
					acc[key] = sectionedLease[key].filter(
						// @ts-expect-error TS7006: Parameter 'field' implicitly h...
						(field) => field.inDetailScreen
					);
					return acc;
				}, {});
			});

			const formattedLeases =
				compType === 'sale'
					? // @ts-expect-error TS7006: Parameter 'comp' implicitly ha...
						compsForExport.map((comp) => {
							return comp.isPortfolio
								? {
										...formatComp(comp, compType),
										// @ts-expect-error TS7006: Parameter 'portfolioItem' impl...
										portfolio: comp.portfolio.map((portfolioItem) =>
											formatComp(portfolioItem, compType)
										),
									}
								: formatComp(comp, compType);
						})
					: // @ts-expect-error TS7006: Parameter 'comp' implicitly ha...
						compsForExport.map((comp) => formatComp(comp, compType));

			return {
				...state,
				exportData: {
					...state.exportData,
					leases: compsForExport,
					//@ts-expect-error MarketModel is missing some fields
					market: market,
					title: title,
					dateCreated: dayjs().format(DATE_FORMATS['MMMM D, YYYY [at] h:mm A']),
					// @ts-expect-error meta is not defined
					preparedBy: action.meta.preparedBy,
					formattedLeases,
					sectionedLeases,
				},
			};
		}

		case PREPARE_LEASES + '_REJECTED': {
			return state;
		}

		case CLEAR_CUSTOM_EXPORT:
			return {
				...state,
				exportData: getInitialExportDataState(),
			};

		case RESTORE_EXPORT:
			return {
				...state,
				exportData: action.payload,
			};

		case EXPORT_CUSTOM_LEASES + '_FULFILLED':
		case EXPORT_CUSTOM_LEASES + '_PENDING':
			return {
				...state,
				exportData: getInitialExportDataState(),
			};

		case EXPORT_CUSTOM_LEASES + '_REJECTED': {
			return state;
		}

		case UPDATE_CUSTOM_EXPORT_VALUE: {
			let exportData = { ...state.exportData };
			const exportLeases = exportData.leases || [];

			const leaseIndex = exportLeases.findIndex(
				(lease) => lease.id === action.payload.leaseId
			);

			const leases = [...exportLeases] as LeaseComp[] | SalesComp[];
			const formattedLeases = [...(exportData.formattedLeases ?? [])] as
				| LeaseComp[]
				| SalesComp[];
			const sectionedLeases = [...(exportData.sectionedLeases ?? [])] as
				| LeaseComp[]
				| SalesComp[];

			leases[leaseIndex] = cloneDeep(leases[leaseIndex]);
			formattedLeases[leaseIndex] = cloneDeep(formattedLeases[leaseIndex]);
			sectionedLeases[leaseIndex] = cloneDeep(sectionedLeases[leaseIndex]);

			// @ts-expect-error TS7053: Element implicitly has an 'any...
			leases[leaseIndex][action.payload.name] = action.payload.value;
			// @ts-expect-error TS7053: Element implicitly has an 'any...
			formattedLeases[leaseIndex][action.payload.name] = action.payload.value;

			if (action.payload.section) {
				// @ts-expect-error TS7053: Element implicitly has an 'any...
				const section = sectionedLeases[leaseIndex][action.payload.section];
				// @ts-expect-error TS7006: Parameter 'attribute' implicit...
				section.forEach((attribute) => {
					if (attribute.name === action.payload.name) {
						attribute.value = action.payload.value;
					}
				});
			}

			exportData = {
				...exportData,
				leases,
				formattedLeases,
				sectionedLeases,
			};

			return {
				...state,
				exportData,
			};
		}

		case UPDATE_CUSTOM_EXPORT_METADATA: {
			const exportData = { ...state.exportData };
			// @ts-expect-error TS7053: Element implicitly has an 'any...
			exportData[action.payload.name] = action.payload.value;
			return {
				...state,
				exportData,
			};
		}

		case HIDE_CUSTOM_EXPORT_FIELD: {
			const exportData = { ...state.exportData };
			let hiddenFields = exportData.hiddenFields || [];
			if (hiddenFields.indexOf(action.payload.name) === -1) {
				hiddenFields = [...hiddenFields, action.payload.name];
			}
			exportData.hiddenFields = hiddenFields;
			return {
				...state,
				exportData,
			};
		}

		case SHOW_CUSTOM_EXPORT_FIELD: {
			const exportData = { ...state.exportData };
			const hiddenFields = exportData.hiddenFields || [];
			const fieldIndex = hiddenFields.indexOf(action.payload.name);
			if (fieldIndex !== -1) {
				hiddenFields.splice(fieldIndex, 1);
				exportData.hiddenFields = [...hiddenFields];
			}
			return {
				...state,
				exportData,
			};
		}

		default:
			return state;
	}
};

export default exportReducer;
