import { usePortfolioPerformanceQueries } from 'api/portfolio/charts/usePortfolioPerformanceQuery';
import { usePortfolioFilters } from 'PortfolioAnalytics/PortfolioFiltersProvider';
import { useMemo } from 'react';
import {
	VictoryChart,
	VictoryAxis,
	VictoryBar,
	VictoryGroup,
	VictoryContainer,
	VictoryLabel,
} from 'victory';
import {
	BENCHMARKING_COLORS,
	BENCHMARKING_SELECT_PORTFOLIOS_MESSAGE,
} from '../Benchmarking/constants';
import { portfolioNameById } from 'PortfolioAnalytics/Benchmarking/utils';
import { colors } from '@compstak/ui-kit';
import { usePortfoliosByIdsQueriesV2 } from 'api/portfolio/portfolioById/usePortfoliosByIdsQueriesV2';
import { ChartLegendV2 as Legend } from '../Benchmarking/BenchmarkingCharts/ChartLegend';
import { ChartStateWrapper } from './ChartStateWrapper';
import { ChartAxisLabel } from './UI';
import {
	CHART_AXIS_TICK_STYLE,
	VICTORY_CONTAINER_STYLE_650,
	RISK_BOUNDARIES,
	PERFORMANCE_BOUNDARIES,
} from './chartsConstants';
import { useNavigate } from 'react-router';
import { routes } from 'router';
import { useMediaQuery } from 'react-responsive';

const GROUPS = {
	highPerformanceLowRisk: 'High Performance, Low Exp. Risk',
	lowPerformanceLowRisk: 'Low Performance, Low Exp. Risk',
	highPerformanceHighRisk: 'High Performance, High Exp. Risk',
	lowPerformanceHighRisk: 'Low Performance, High Exp. Risk',
} as const;

const groups = Object.values(GROUPS);

const { gray400 } = colors.gray;

export const PERFORMANCE_DIST_CHART_TYPE = {
	singlePortfolio: 'singlePortfolio',
	benchmarking: 'benchmarking',
} as const;

type PerformanceDistributionChartType =
	keyof typeof PERFORMANCE_DIST_CHART_TYPE;

type PerformanceDistributionChartProps = {
	portfolioIds: number[];
	chartType?: PerformanceDistributionChartType;
};

const getGroupBoundaries = (group: (typeof GROUPS)[keyof typeof GROUPS]) => {
	const isLowRisk =
		group === GROUPS.lowPerformanceLowRisk ||
		group === GROUPS.highPerformanceLowRisk;
	const isHighPerformance =
		group === GROUPS.highPerformanceLowRisk ||
		group === GROUPS.highPerformanceHighRisk;

	return {
		riskMin: isLowRisk ? RISK_BOUNDARIES.LOW.MIN : RISK_BOUNDARIES.HIGH.MIN,
		riskMax: isLowRisk ? RISK_BOUNDARIES.LOW.MAX : RISK_BOUNDARIES.HIGH.MAX,
		performanceMin: isHighPerformance
			? PERFORMANCE_BOUNDARIES.HIGH.MIN
			: PERFORMANCE_BOUNDARIES.LOW.MIN,
		performanceMax: isHighPerformance
			? PERFORMANCE_BOUNDARIES.HIGH.MAX
			: PERFORMANCE_BOUNDARIES.LOW.MAX,
	};
};

export const PerformanceDistributionChartContent = (
	props: PerformanceDistributionChartProps
) => {
	const is1470 = useMediaQuery({ minWidth: '1470px' });
	const { portfolioIds, chartType = PERFORMANCE_DIST_CHART_TYPE.benchmarking } =
		props;

	const chartConfig = useMemo(() => {
		return {
			chartPadding: {
				top: 15,
				bottom: 40,
				left: 200,
				right: 50,
			},
			barWidth: chartType === PERFORMANCE_DIST_CHART_TYPE.benchmarking ? 7 : 20,
			chartHeight:
				chartType === PERFORMANCE_DIST_CHART_TYPE.benchmarking
					? 240
					: !is1470
						? 300
						: 240,
			xAxisGrid:
				chartType === PERFORMANCE_DIST_CHART_TYPE.benchmarking
					? { stroke: 'transparent' }
					: { stroke: gray400 },
		};
	}, [chartType, is1470]);

	const { filters } = usePortfolioFilters();
	const performanceData = usePortfolioPerformanceQueries(portfolioIds, filters);

	const portfoliosResults = usePortfoliosByIdsQueriesV2(
		portfolioIds.map((id) => ({ id, filters }))
	);

	const legendData = useMemo(() => {
		return chartType === PERFORMANCE_DIST_CHART_TYPE.benchmarking
			? portfolioIds.map((_portfolioId, index) => {
					const portfolioData = performanceData[index];
					return {
						hasData: Boolean(
							portfolioData?.data?.values &&
								portfolioData.data.values.length > 0
						),
						name: portfolioNameById(portfoliosResults, index),
					};
				})
			: [];
	}, [performanceData, portfoliosResults, portfolioIds, chartType]);

	const isError =
		performanceData.some((portfolioData) => portfolioData.isError) ||
		portfoliosResults.some((result) => result.isError);
	const isFetching =
		performanceData.some((portfolioData) => portfolioData.isFetching) ||
		portfoliosResults.some((result) => result.isFetching);

	const valueToDisplay =
		chartType === PERFORMANCE_DIST_CHART_TYPE.benchmarking
			? 'percentage'
			: 'count';

	const barData = useMemo(() => {
		return performanceData
			.map((portfolioData, index) => {
				if (!portfolioData.data?.values) return [];
				const portfolioId = portfolioIds[index];
				const totalProperties = portfolioData.data.values.length;
				const groupCounts = groups.map((group) => {
					if (!portfolioData.data.values) return null;

					const boundaries = getGroupBoundaries(group);
					const count = portfolioData.data.values.filter((property) => {
						return (
							property.risk >= boundaries.riskMin &&
							property.risk <= boundaries.riskMax &&
							property.performance >= boundaries.performanceMin &&
							property.performance <= boundaries.performanceMax
						);
					}).length;
					const percentage =
						totalProperties > 0 ? (count / totalProperties) * 100 : 0;

					return {
						group,
						count,
						percentage,
						portfolioId,
						color: BENCHMARKING_COLORS[index],
					};
				});
				return groupCounts.filter((group) => group !== null);
			})
			.filter((portfolioData) =>
				portfolioData.some((data) => data && data[valueToDisplay] > 0)
			);
	}, [performanceData, portfolioIds, valueToDisplay]);

	const noData = useMemo(
		() =>
			portfolioIds.length === 0 ||
			barData.every((bars) => bars.every((bar) => bar?.[valueToDisplay] === 0)),
		[portfolioIds, barData, valueToDisplay]
	);

	const allPortfoliosEmpty = useMemo(
		() => !!portfolioIds.length && noData,
		[portfolioIds, noData]
	);

	const maxValue = !noData
		? Math.max(...barData.flat().map((d) => d?.[valueToDisplay] || 0))
		: 0;

	const noDataMessage =
		chartType === PERFORMANCE_DIST_CHART_TYPE.benchmarking
			? BENCHMARKING_SELECT_PORTFOLIOS_MESSAGE
			: '';
	const labelStyle = { ...CHART_AXIS_TICK_STYLE, fontSize: '10px' };

	const navigate = useNavigate();

	return (
		<ChartStateWrapper
			isError={isError}
			isFetching={isFetching}
			noData={noData}
			showNoDataMessage={
				chartType === PERFORMANCE_DIST_CHART_TYPE.benchmarking &&
				!allPortfoliosEmpty
			}
			noDataMessage={noDataMessage}
		>
			<VictoryChart
				containerComponent={
					<VictoryContainer
						style={{
							...VICTORY_CONTAINER_STYLE_650,
							width: '100%',
						}}
					/>
				}
				domainPadding={{ x: 30, y: 100 }}
				padding={chartConfig.chartPadding}
				height={chartConfig.chartHeight}
				maxDomain={{ y: maxValue + 1 }}
			>
				{/* X Axis */}
				<VictoryAxis
					tickLabelComponent={<VictoryLabel style={labelStyle} />}
					style={{
						axis: { stroke: gray400 },
						grid: chartConfig.xAxisGrid,
					}}
				/>
				{/* Y Axis */}
				<VictoryAxis
					dependentAxis
					crossAxis={false}
					tickLabelComponent={<VictoryLabel style={labelStyle} />}
					label={() => ''}
					axisLabelComponent={
						<ChartAxisLabel
							text={valueToDisplay === 'percentage' ? 'Percent' : 'Count'}
							fontSize={10}
						/>
					}
					style={{
						axis: { stroke: gray400 },
						grid: { stroke: gray400 },
						tickLabels: {
							padding: 0,
						},
					}}
					tickFormat={(d: string) =>
						valueToDisplay === 'percentage' ? `${d}%` : d
					}
					tickCount={5}
				/>
				<VictoryGroup horizontal offset={chartConfig.barWidth}>
					{barData.reverse().map((portfolio, index) => (
						<VictoryBar
							horizontal
							key={index}
							data={portfolio.reverse()}
							x="group"
							y={valueToDisplay}
							style={{
								data: {
									fill: portfolio[0]?.color || gray400,
									pointerEvents:
										chartType === PERFORMANCE_DIST_CHART_TYPE.benchmarking
											? 'none'
											: 'auto',
									cursor: 'pointer',
								},
							}}
							barWidth={chartConfig.barWidth}
							events={[
								{
									target: 'data',
									eventHandlers: {
										onClick: (_e, clickedProps) => {
											if (!clickedProps?.datum) {
												return;
											}
											const { group, portfolioId } = clickedProps.datum;
											const boundaries = getGroupBoundaries(group);
											navigate(
												routes.portfolioByIdView.toHref(
													{
														portfolioId: portfolioId,
														viewType: 'list',
													},
													boundaries
												)
											);
										},
									},
								},
							]}
						/>
					))}
				</VictoryGroup>
			</VictoryChart>
			{chartType === PERFORMANCE_DIST_CHART_TYPE.benchmarking && (
				<Legend
					portfoliosLegendData={legendData}
					colors={BENCHMARKING_COLORS}
				/>
			)}
		</ChartStateWrapper>
	);
};
