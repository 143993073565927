import { AveragesResponse } from 'api/types/averages';
import { AllKeys } from 'types/utils';
import { createDisplayObject } from './aggregationFormatting';

export const formatAverages = (
	isMonthly: boolean,
	source: AveragesResponse
) => {
	const destination = {} as {
		[key in AllKeys<AveragesResponse>]: ReturnType<typeof createDisplayObject>;
	};

	(Object.keys(source) as AllKeys<AveragesResponse>[]).forEach((key) => {
		// @ts-expect-error TS2367: This comparison appears to be ...
		if (key === 'medians') {
			// @ts-expect-error TS2339: Property 'medians' does not ex...
			Object.keys(source.medians).forEach((medianKey) => {
				// @ts-expect-error TS7053: Element implicitly has an 'any...
				destination[medianKey + 'Median'] = createDisplayObject(
					// @ts-expect-error TS2345: Argument of type 'string' is n...
					medianKey + 'Median',
					// @ts-expect-error TS2339: Property 'medians' does not ex...
					source.medians[medianKey],
					isMonthly
				);
			});
		} else {
			// @ts-expect-error TS7053: Element implicitly has an 'any...
			destination[key] = createDisplayObject(key, source[key], isMonthly);
		}
	});
	return destination;
};
