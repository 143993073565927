import { useUserIsSalesTrader } from 'hooks/userHooks';
import { UPLOAD_PAGE_REQUIRED_FIELDS } from '../uploadFieldConstants';
import styled from 'styled-components';
import { WrapFlex } from './Ui';

const {
	leaseRequiredFields,
	saleRequiredFields,
	leasePremiumFields,
	salePremiumFields,
} = UPLOAD_PAGE_REQUIRED_FIELDS;

export function RequiredCompFieldsSection() {
	const salesTrader = useUserIsSalesTrader();
	return (
		<Container>
			<Title>SEE WHAT DATA TO INCLUDE</Title>
			<WrapFlex gap="5rem" justifyContent="center">
				{!salesTrader && (
					<Card>
						<CardTitle>Lease Comp</CardTitle>
						<RequiredFieldsTitle />
						<ul>
							{leaseRequiredFields.map((field) => (
								<li key={field}>{field}</li>
							))}
						</ul>
						<FieldsTitle>Premium fields</FieldsTitle>
						<FieldsSubTitle>
							Lease comp containing all 3 fields below is worth extra credits.
						</FieldsSubTitle>
						<ul>
							{leasePremiumFields.map((field) => (
								<li key={field}>{field}</li>
							))}
						</ul>
					</Card>
				)}
				<Card>
					<CardTitle>Sale Comp</CardTitle>
					<RequiredFieldsTitle />
					<ul>
						{saleRequiredFields.map((field) => (
							<li key={field}>{field}</li>
						))}
					</ul>
					<FieldsTitle>Premium fields</FieldsTitle>
					<FieldsSubTitle>
						Sale comp containing at least 2 of the fields below is worth extra
						credits.
					</FieldsSubTitle>
					<ul>
						{salePremiumFields.map((field) => (
							<li key={field}>{field}</li>
						))}
					</ul>
				</Card>
			</WrapFlex>
		</Container>
	);
}

function RequiredFieldsTitle() {
	return (
		<>
			<FieldsTitle>Required fields</FieldsTitle>
			<FieldsSubTitle>
				Each comp must include all of the following details
			</FieldsSubTitle>
		</>
	);
}

const Container = styled.section`
	padding-bottom: 2rem;
`;

const Title = styled.div`
	text-align: center;
	margin-bottom: 48px;
	font-weight: 400;
`;

const Card = styled.div`
	background-color: white;
	width: 560px;
	max-width: 90%;
	min-height: 640px;
	padding: 24px;

	filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

	& ul {
		margin-left: 24px;
	}

	& ul > li {
		/* TODO: Ui-kit color pallete */
		border-bottom: 2px solid hsl(226, 15%, 96%);
		padding: 4px 0px;
	}
`;

const CardTitle = styled.div`
	text-align: center;
	margin-top: 24px;
	font-size: 20px;
	font-weight: 400;
`;

const FieldsTitle = styled.b`
	display: block;
	margin-top: 24px;
`;

const FieldsSubTitle = styled.div`
	margin-bottom: 12px;
`;
