import { useModal } from 'providers/ModalProvider';
import { useCallback } from 'react';
import { RemovePortfolioModal } from '../BenchmarkingModals/RemovePortfolioModal';

import { ComparedPortfolio } from './useBenchmarkTableConfig';
import { StyledIconTrash } from './UI';

type Props = {
	portfolio: ComparedPortfolio;
};

export const PortfolioDeleteButton = ({ portfolio }: Props) => {
	const { id, portfolioName } = portfolio;
	const { openModal } = useModal();

	const handleOpenModal = useCallback(
		({ portfolioName, id }: { portfolioName: string; id: number }) => {
			openModal({
				modalContent: (
					<RemovePortfolioModal
						portfolioName={portfolioName}
						portfolioId={id}
					/>
				),
			});
		},
		[openModal]
	);

	return (
		<div onClick={() => handleOpenModal({ id, portfolioName })}>
			<StyledIconTrash />
		</div>
	);
};
