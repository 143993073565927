import { radiusExpanderSearch } from 'actions/search';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useFilters } from 'reducers/filtersReducer';
import { CompType, PropertyComp } from 'types/comp';
import { PopupActionButton, StyledArrowRightIcon } from './UI';
import IconRadius from 'ui/svg_icons/radius.svg';

type Props = {
	compType: CompType;
	property: PropertyComp;
	closePopup: () => void;
	['data-qa-id']: string;
	children: React.ReactNode;
};

export const NearbyCompsButton = ({
	compType,
	property,
	closePopup,
	['data-qa-id']: dataQaId,
	children,
}: Props) => {
	const [filters] = useFilters();
	const dispatch = useDispatch();

	const onClickNearbyLeaseComps = async () => {
		dispatch(
			radiusExpanderSearch({
				compType,
				filters,
				lat: property.geoPoint.lat,
				lon: property.geoPoint.lon,
			})
		);
		closePopup();
	};

	return (
		<PopupActionButton
			dataTestId={dataQaId}
			variant="secondary"
			onClick={onClickNearbyLeaseComps}
			icon={<IconRadius />}
		>
			{children}

			<StyledArrowRightIcon />
		</PopupActionButton>
	);
};
