import { Flex, TooltipV2 } from '@compstak/ui-kit';
import { TOOLTIP_TEXT } from 'PortfolioAnalytics/Dashboard/PortfolioOverview/AveragesV2';

import IconQuestionMark from 'ui/svg_icons/question_mark_outline.svg';

export const TitleWithTooltip = ({
	title,
	tooltipContent,
}: {
	title: string;
	tooltipContent: keyof typeof TOOLTIP_TEXT;
}) => {
	return (
		<Flex gap="0.25rem" justifyContent="flex-start">
			<span>{title}</span>
			<TooltipV2 content={TOOLTIP_TEXT[tooltipContent]}>
				<span>
					<IconQuestionMark width="1.25em" height="1.25em" />
				</span>
			</TooltipV2>
		</Flex>
	);
};
