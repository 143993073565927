import { getSetFilterKeys } from './getSetFilters';
import dayjs from 'dayjs';
import lzString from 'lz-string';
import { MarketsState } from 'Pages/Login/reducers';
import { createFilters, CreateFiltersArg } from 'models/filters';
import { setFilters } from './setFilters';
import {
	FiltersObject,
	FilterDateInterval,
	FilterNumberInterval,
	FilterStringInterval,
	FiltersTypesKeys,
	MultiPolygonFilter,
	PolygonFilter,
	APNFilter,
} from '../types';
import { processCompatibleFiltersQueryParams } from './filtersCompatibilityUtils';
import {
	isArrayFilter,
	isDateIntervalFilterValue,
	isFilterKey,
	isIntervalFilterValue,
	isMarketFilter,
	isMarketsFilter,
	isMultiPolygonFilter,
	isNumberIntervalFilterValue,
	isPolygonFilter,
	isRadiusFilter,
	isSubmarketsFilter,
	isAPNFilter,
	isCompIdFilter,
	isDateTimestampFilterKey,
} from './filterHelpers';
import { allFiltersStub } from '../stubs/allFiltersStub';
import { DATE_FORMATS } from 'constants/dateFormats';
import { SUBMARKETS_ID_TO_NAME } from 'constants/submarkets';
import { isDateIntervalFilterKey } from './isIntervalFilterKey';

const MAX_QUERY_PARAM_LENGTH = 10000; // equals to 10KB

export function filterToQueryString<K extends FiltersTypesKeys>(
	value: FiltersObject[K],
	key: K
) {
	if (!isFilterKey(key) || value == null) {
		return '';
	}

	if (isMarketFilter(value)) {
		return `marketName=${encodeURIComponent(value.name)}`;
	}

	if (isMarketsFilter(value)) {
		const encodedArrayValue = JSON.stringify(
			value.map(({ name }) => encodeURIComponent(name))
		);
		return `marketNames=${encodedArrayValue}`;
	}

	if (isSubmarketsFilter(value)) {
		const encodedArrayValue = JSON.stringify(value.map(({ id }) => id));
		return `submarketId=${encodedArrayValue}`;
	}

	if (isIntervalFilterValue(value)) {
		const returnVal: string[] = [];

		const isDate = isDateIntervalFilterKey(key);
		const format = isDateTimestampFilterKey(key)
			? DATE_FORMATS['YYYY-MM-DDTHH:mm:ss']
			: DATE_FORMATS['MM/DD/YYYY'];

		if ('min' in value && value.min != null) {
			returnVal.push(
				key + 'Min=' + (isDate ? dayjs(value.min).format(format) : value.min)
			);
		}
		if ('max' in value && value.max != null) {
			returnVal.push(
				key + 'Max=' + (isDate ? dayjs(value.max).format(format) : value.max)
			);
		}
		if ('allowFallback' in value && value.allowFallback != null) {
			returnVal.push(key + 'AllowFallback=' + value.allowFallback);
		}
		return returnVal.join('&');
	}

	if (isPolygonFilter(value)) {
		const returnVal = value.reduce<string[]>((acc, point) => {
			acc.push(`"${point.lat},${point.lng}"`);
			return acc;
		}, []);
		return `${key}=[${returnVal}]`;
	}

	if (isMultiPolygonFilter(value)) {
		const returnVal = value.reduce<string[]>((acc, points) => {
			acc.push(`[${points.map((point) => `"${point.lat},${point.lng}"`)}]`);
			return acc;
		}, []);
		return `${key}=[${returnVal}]`;
	}

	if (isRadiusFilter(value)) {
		return `${key}=${value.center.lat},${value.center.lng},${value.distance}km${value.buildingAddressAndCity ? `,${value.buildingAddressAndCity}` : ''}`;
	}

	if (isAPNFilter(value)) {
		// AP-14560 - `area` serialization can produce a very long URL param and exceed the quota (HTTP 431 error code in such case)
		// Thus we don't serialize/deserialize it to URL string
		// We imply that `APN / County` filter applies area filter depending on fips & apn values
		const { fips, apn } = value;
		return `${key}=${JSON.stringify({
			fips: encodeURIComponent(fips),
			apn: encodeURIComponent(apn),
		})}`;
	}

	if (isCompIdFilter(value)) {
		// AP-16938 this is a newly added "filter" only used in connection with chartBuilder to exclude compIds.
		//It does not need to be serialized to URL
		return;
	}

	if (isArrayFilter(value)) {
		let queryParamValue = JSON.stringify(value);

		if (
			key !== 'opportunityZoneId' &&
			key !== 'address' &&
			queryParamValue.length > MAX_QUERY_PARAM_LENGTH
		) {
			queryParamValue = queryParamValue.slice(0, MAX_QUERY_PARAM_LENGTH);
			const lastCommaIndex = queryParamValue.lastIndexOf(
				encodeURIComponent(',')
			);
			queryParamValue =
				queryParamValue.slice(0, lastCommaIndex) + encodeURIComponent(']');
		}

		if (key === 'opportunityZoneId' || key === 'address') {
			const compressedQueryParamValue =
				lzString.compressToEncodedURIComponent(queryParamValue);
			return `${key}=${compressedQueryParamValue}`;
		}

		return `${key}=${encodeURIComponent(queryParamValue)}`;
	}

	return (
		key + '=' + (typeof value === 'number' ? value : encodeURIComponent(value))
	);
}

export const getParamsFromQueryString = (queryString: string) => {
	return Object.fromEntries(new URLSearchParams(queryString).entries());
};

type FromQueryParamsExtraOptions = {
	ignoreErrors?: boolean;
	warnOnErrors?: boolean;
};

export const filtersFromQueryParams = <T extends CreateFiltersArg>({
	compType,
	markets,
	queryParams,
	extraOptions,
}: {
	compType: T;
	markets: MarketsState;
	queryParams: Record<string, string>;
	extraOptions?: FromQueryParamsExtraOptions;
}) => {
	let filters = createFilters(compType);
	processCompatibleFiltersQueryParams(queryParams);
	filters = Object.keys(queryParams).reduce((acc, key) => {
		try {
			return setFromQueryParams(markets, acc, queryParams[key], key);
		} catch (err) {
			if (!extraOptions?.ignoreErrors) {
				throw err;
			}
			if (extraOptions?.warnOnErrors) {
				console.warn(err);
			}
			return acc;
		}
	}, filters);
	return filters;
};

export const filtersFromQueryString = <T extends CreateFiltersArg>({
	compType,
	markets,
	queryString,
	extraOptions,
}: {
	compType: T;
	markets: MarketsState;
	queryString: string;
	extraOptions?: FromQueryParamsExtraOptions;
}) => {
	const queryParams = getParamsFromQueryString(queryString);

	return filtersFromQueryParams({
		compType,
		markets,
		queryParams,
		extraOptions,
	});
};

function setFromQueryParams(
	markets: MarketsState,
	filters: FiltersObject,
	value: string,
	key: string
) {
	const isMax = key.endsWith('Max');
	const isMin = key.endsWith('Min');
	if (isMin || isMax) {
		key = key.slice(0, key.length - 'Min'.length);
	}

	const isAllowFallback = key.endsWith('AllowFallback');
	if (isAllowFallback) {
		key = key.slice(0, key.length - 'AllowFallback'.length);
	}

	if (key === 'marketName') {
		key = 'market';
	}

	if (key === 'marketNames') {
		key = 'markets';
	}

	if (key === 'submarketId') {
		key = 'submarkets';
	}

	if (key === 'sortDirection' || key === 'sortField') {
		// @ts-expect-error TS2345: Argument of type 'string' is n...
		return setFilters(filters, key, value);
	}

	if (!isFilterKey(key)) {
		console.warn(`Unknown filter (key: ${key}, value: ${value})`);
		return filters;
	}
	if (isCompIdFilter(value)) {
		// AP-16938 compId Filter is irrelevant here
		return filters;
	}
	const filterValueType = allFiltersStub[key];

	if (isMarketFilter(filterValueType)) {
		const market = markets[value];
		if (!market) {
			console.warn(
				'Tried to set a market from query params, but its an invalid market name or id! ' +
					value
			);
			return filters;
		}
		return setFilters(filters, 'market', market);
	}

	if (isMarketsFilter(filterValueType)) {
		const queryMarkets = JSON.parse(value).map(decodeURIComponent) as string[];
		const multipleMarkets = queryMarkets
			.map((marketName) => {
				const market = markets[marketName];
				if (!market) {
					console.warn(
						'Tried to set markets from query params, but one of the markets has an invalid market name or id! ' +
							marketName
					);
				}
				return market;
			})
			.filter(Boolean);

		if (!multipleMarkets.length) {
			return filters;
		}

		return setFilters(filters, 'markets', multipleMarkets);
	}

	if (isSubmarketsFilter(filterValueType)) {
		const querySubmarkets = JSON.parse(value) as number[];
		const filterSubmarkets = querySubmarkets
			.map((id) => {
				// TODO: AP-12875 extract submarkets from submarkets state
				const name = SUBMARKETS_ID_TO_NAME[id]?.name;
				if (!name) {
					console.warn(
						'Tried to set submarkets from query params, but one of the submarkets has an invalid id! ' +
							id
					);
				}
				return { id, name };
			})
			.filter(({ name }) => !!name);

		if (!filterSubmarkets.length) {
			return filters;
		}

		return setFilters(filters, 'submarkets', filterSubmarkets);
	}

	if (isRadiusFilter(filterValueType)) {
		const queryRadius = decodeURIComponent(value).split(',');
		const [lat, lng] = queryRadius;
		let [, , distance, buildingAddressAndCity] = queryRadius;
		if (distance.endsWith('km') || distance.endsWith('mi')) {
			distance = distance.substring(0, distance.length - 2);
		}
		const radius = {
			center: { lat: Number(lat), lng: Number(lng) },
			distance: Number(distance),
			buildingAddressAndCity,
		};
		return setFilters(filters, 'radius', radius);
	}

	if (isPolygonFilter(filterValueType)) {
		const points = JSON.parse(decodeURIComponent(value)) as string[];
		const polygon: PolygonFilter = [];
		for (const point of points) {
			const [lat, lng] = point.split(',');
			polygon.push({ lat: Number(lat), lng: Number(lng) });
		}
		return setFilters(filters, 'polygon', polygon);
	}

	if (isMultiPolygonFilter(filterValueType)) {
		const points2D = JSON.parse(decodeURIComponent(value)) as string[][];
		const multiPolygon: MultiPolygonFilter = [];
		for (const points of points2D) {
			const pointObjects: PolygonFilter = [];
			for (const point of points) {
				const [lat, lng] = point.split(',');
				pointObjects.push({ lat: Number(lat), lng: Number(lng) });
			}
			multiPolygon.push(pointObjects);
		}
		return setFilters(filters, 'multiPolygon', multiPolygon);
	}

	if (isAPNFilter(filterValueType)) {
		// AP-14560 - `area` serialization can produce a very long URL param and exceed the quota (HTTP 431 error code in such case)
		// Thus we don't serialize/deserialize it to URL string
		// We imply that `APN / County` filter applies area filter depending on fips & apn values
		const apnFilter = JSON.parse(decodeURIComponent(value)) as APNFilter;
		return setFilters(filters, 'apn', apnFilter);
	}

	if (isIntervalFilterValue(filterValueType)) {
		const isNumberInterval = isNumberIntervalFilterValue(filterValueType);
		const isDateInterval = isDateIntervalFilterValue(filterValueType);
		const format = isDateTimestampFilterKey(key)
			? DATE_FORMATS['YYYY-MM-DDTHH:mm:ss[Z]']
			: DATE_FORMATS['MM/DD/YYYY'];

		const intervalFilter = filters[key] as
			| FilterNumberInterval
			| FilterDateInterval
			| FilterStringInterval;

		if (isAllowFallback) {
			return setFilters(filters, key, {
				...intervalFilter,
				allowFallback: JSON.parse(value),
			});
		} else if (isMin) {
			return setFilters(filters, key, {
				...intervalFilter,
				// @ts-expect-error TS2322: Type 'string | number | Date' ...
				min: isNumberInterval
					? parseFloat(value)
					: isDateInterval
						? dayjs(value, format).toDate()
						: value,
			});
		} else if (isMax) {
			return setFilters(filters, key, {
				...intervalFilter,
				// @ts-expect-error TS2322: Type 'string | number | Date' ...
				max: isNumberInterval
					? parseFloat(value)
					: isDateInterval
						? dayjs(value, format).toDate()
						: value,
			});
		}
	}

	if (isArrayFilter(filterValueType)) {
		const arrayValue = JSON.parse(
			key === 'opportunityZoneId' || key === 'address'
				? lzString.decompressFromEncodedURIComponent(value)
				: decodeURIComponent(value)
		);
		return setFilters(filters, key, arrayValue);
	}

	if (typeof filterValueType === 'boolean') {
		if (value !== 'true' && value !== 'false') {
			console.warn(
				'Tried to convert ' +
					value +
					' to a boolean when applying query parameters'
			);
			return filters;
		}
		// @ts-expect-error TS2345: Argument of type 'boolean' is ...
		return setFilters(filters, key, value === 'true');
	}

	if (typeof filterValueType === 'number') {
		return setFilters(filters, key, parseFloat(value));
	}

	return setFilters(filters, key, decodeURIComponent(value));
}

export function filtersToQueryString(filters: Partial<FiltersObject>) {
	const urlComponents = getSetFilterKeys(filters)
		.map((key) => {
			return filterToQueryString(filters[key], key);
		})
		.filter(Boolean);

	if (filters.sortField) {
		urlComponents.push(`sortField=${filters.sortField}`);
	}

	if (filters.sortDirection) {
		urlComponents.push(`sortDirection=${filters.sortDirection}`);
	}

	return urlComponents.join('&');
}
