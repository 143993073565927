import { MapInteractivity, MapStyle, useViewState } from '@compstak/maps';
import dayjs from 'dayjs';
import html2canvas from 'html2canvas';
import { MapAnalyticsType } from 'Layouts/SearchProvider';
import { useRef } from 'react';
import styled from 'styled-components';
import { useSearchMapState } from '../../SearchMapProvider';
import { LeaseExpirationAnalytics } from '../LeaseExpirationAnalytics';
import { MarketRentAnalytics } from '../MarketRentAnalytics';
import { SpaceTypeAnalytics } from '../SpaceTypeAnalytics';
import { TenantIndustryAnalytics } from '../TenantIndustryAnalytics';
import { LeaseExpirationExportLegend } from './LeaseExpirationAnalyticsExportLegend';
import { MarketRentAnalyticsExportLegend } from './MarketRentAnalyticsExportLegend';
import { SpaceTypeAnalyticsExportLegend } from './SpaceTypeAnalyticsExportLegend';
import { TenantIndustryAnalyticsExportLegend } from './TenantIndustryAnalyticsExportLegend';
import { useExportFilename } from './useExportFilename';
import { MapWrapper } from 'maps/components/MapWrapper/MapWrapper';

type Props = {
	mapAnalyticsType: MapAnalyticsType;
};

export const MapExport = ({ mapAnalyticsType }: Props) => {
	const exportRef = useRef<HTMLDivElement>(null);
	const [, setSearchMapState] = useSearchMapState();
	const [viewState] = useViewState();
	const fileName = useExportFilename(mapAnalyticsType);

	const exportImage = () => {
		const elm = exportRef.current;
		if (elm) {
			html2canvas(elm, {
				ignoreElements: (node) => {
					return (
						node.className === 'mapboxgl-control-container' ||
						node.className === 'actions export-discard' ||
						node.className === 'MenuUI__CloseContainer-sc-6r1ohw-10 eTZaaq'
					);
				},
				imageTimeout: 1000,
			}).then((canvas) => {
				const img = canvas.toDataURL('image/png');
				const a = document.createElement('a');
				a.setAttribute('download', `${fileName}.png`);
				a.setAttribute('href', img);
				a.click();
				a.remove();
				setSearchMapState((s) => ({ ...s, isExporting: false }));
			});
		}
	};

	const todaysDate = dayjs().format('MM/DD/YYYY');

	return (
		<Root ref={exportRef}>
			<MapContainer>
				<MapWrapper
					viewState={viewState}
					mapStyle={MapStyle.MONOCHROME}
					interactivity={MapInteractivity.STATIC}
					mapProps={{
						onLoad: () => {
							exportImage();
						},
					}}
					mapDomain="search"
				>
					{mapAnalyticsType === 'marketRent' && (
						<>
							<MarketRentAnalytics />
							<MarketRentAnalyticsExportLegend />
						</>
					)}
					{mapAnalyticsType === 'leaseExpiration' && (
						<>
							<LeaseExpirationAnalytics />
							<LeaseExpirationExportLegend />
						</>
					)}
					{mapAnalyticsType === 'spaceType' && (
						<>
							<SpaceTypeAnalytics />
							<SpaceTypeAnalyticsExportLegend />
						</>
					)}
					{mapAnalyticsType === 'tenantIndusty' && (
						<>
							<TenantIndustryAnalytics />
							<TenantIndustryAnalyticsExportLegend />
						</>
					)}
				</MapWrapper>
			</MapContainer>
			<Footer>
				<Copyright>
					<span>Data provided by CompStak on {todaysDate}</span>
					<br />
					<span>Copyright © 2020 CompStak</span>
				</Copyright>
				<LogoContainer>
					<svg height="22px" viewBox="0 0 35 40">
						<path
							d="M35,10v4.1l-17.5,10V20l13.9-7.9L17.5,4.1L3.6,12.1v15.9l13.9,7.9l17.5-10V30L17.5,40L0,30V10L17.5,0L35,10z
	 M17.5,32.1L34.9,22v-4.1L17.5,28V32.1z"
						/>
					</svg>
					<span>COMPSTAK</span>
				</LogoContainer>
				<FooterContent>
					All information is not guaranteed and should be verified by personal
					inspection by and/or with the appropriate professional(s).
				</FooterContent>
			</Footer>
		</Root>
	);
};

const Root = styled.div`
	position: absolute;
	top: -9999px;
	left: -9999px;
`;

const MapContainer = styled.div`
	position: relative;
	width: 1140px;
	height: 800px;
`;

const Footer = styled.div`
	align-items: center;
	width: 1140px;
	height: 42px;
	position: relative;
	background-color: white;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	font-size: 10px;
	font-family: 'Gotham', 'Open Sans', Helvetica, Arial, sans-serif;
`;

const Copyright = styled.div`
	margin-left: 1rem;
`;

const LogoContainer = styled.div`
	align-items: center;
	font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	display: flex;
	flex-direction: row;
	height: 22px;
	font-size: 16px;

	svg {
		height: 22px;
	}

	span {
		margin-left: 5px;
	}
`;

const FooterContent = styled.span`
	margin-right: 1rem;
	max-width: 33%;
	text-align: right;
`;
