import { BenchmarkingTrendChart } from './Trend/BenchmarkingTrendChart';
import { BenchmarkingPerformanceDistributionChart } from './PerformanceDistribution/BenchmarkingPerformanceDistributionChart';
import { ChartsContainerV2 } from 'PortfolioAnalytics/styles/PortfolioUI';
import { BenchmarkingLeaseExpirationChartV1 } from './LeaseExpiration/BenchmarkingLeaseExpirationChartV1';
import { BenchmarkingLeaseExpirationChartV2 } from './LeaseExpiration/BenchmarkingLeaseExpirationChartV2';
import { BenchmarkingTenantIndustryChart } from './TenantIndustry/BenchmarkingTenantIndustryChart';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { BenchmarkingTenantIndustryChartV2 } from './TenantIndustry/BenchmarkingTenantIndustryChartV2';

export const BenchmarkingCharts = () => {
	const { portfolioChartsV2EndpointFF } = useFeatureFlags();

	return (
		<>
			<ChartsContainerV2 narrow={true}>
				<BenchmarkingPerformanceDistributionChart />
				<BenchmarkingTrendChart />
			</ChartsContainerV2>
			<ChartsContainerV2 narrow={true}>
				{portfolioChartsV2EndpointFF ? (
					<>
						<BenchmarkingTenantIndustryChartV2 />
						<BenchmarkingLeaseExpirationChartV2 />
					</>
				) : (
					<>
						<BenchmarkingTenantIndustryChart />
						<BenchmarkingLeaseExpirationChartV1 />
					</>
				)}
			</ChartsContainerV2>
		</>
	);
};
