import { SimpleTableColumn } from '@compstak/ui-kit';
import { Buyer } from 'api/topBuyers/useTopBuyersQuery';
import { CompanyLogo } from 'Components/CompanyLogo/CompanyLogo';
import { FiltersObject } from 'models/filters/types';
import { useMarkets } from 'hooks/useMarkets';
import { useMemo } from 'react';
import { useFilters } from 'reducers/filtersReducer';
import { LeagueTable } from '../LeagueTable';
import { NameCell, NumberCell, RankCell } from '../TableCells';
import {
	TOP_BUYERS_DEFAULT_PAGE_SIZE,
	useTopBuyersInfiniteQuery,
} from 'api/topBuyers/useTopBuyersInfiniteQuery';
import { useInfiniteTableRows } from 'utils/useInfiniteTableRows';

export const TopBuyerLeagueTable = () => {
	const [filters] = useFilters();
	const markets = useMarkets().list;
	const { data, isFetching, fetchNextPage, hasNextPage, isFetchingNextPage } =
		useTopBuyersInfiniteQuery({ filters });

	const rows = useInfiniteTableRows({
		data,
		getRows: (page) => page.buyers ?? [],
		isFetching: isFetchingNextPage,
		pageSize: TOP_BUYERS_DEFAULT_PAGE_SIZE,
	});

	const columns: SimpleTableColumn<Buyer>[] = useMemo(
		() => [
			{
				id: 'index',
				header: 'Rank',
				body: ({ rowIndex }) => <RankCell>{rowIndex + 1}</RankCell>,
				width: 60,
			},
			{
				id: 'name',
				header: 'Name',
				body: ({ row }) => (
					<NameCell>
						<CompanyLogo companyName={row.name} />
						<span title={row.name}>{row.name}</span>
					</NameCell>
				),
				width: 200,
				flex: 2,
			},
			{
				id: 'sumOfSalesPrice',
				header: () => (
					<>
						Volume $<br />
						(sum of sales price)
					</>
				),
				body: ({ row }) => <NumberCell value={row.sumOfSalesPrice} />,
				width: 100,
				flex: 2,
			},
			{
				id: 'totalSalesCount',
				header: '# of transactions',
				body: ({ row }) => {
					const targetFilters: Partial<FiltersObject> = {
						buyers: [row.name],
					};
					return (
						<NumberCell value={row.totalSalesCount} filters={targetFilters} />
					);
				},
				width: 100,
				flex: 1,
			},
			{
				id: 'totalPropertiesCount',
				header: '# of properties',
				body: ({ row }) => <NumberCell value={row.totalPropertiesCount} />,
				width: 100,
				flex: 1,
			},
			{
				id: 'averageDealSize',
				header: 'Average Deal Size $',
				body: ({ row }) => <NumberCell value={row.averageDealSize} />,
				width: 100,
				flex: 2,
			},
			{
				id: 'globalSumOfSalesPrice',
				header: 'Total US Volume $',
				body: ({ row }) => <NumberCell value={row.globalSumOfSalesPrice} />,
				width: 100,
				flex: 2,
			},
			{
				id: 'globalTotalSalesCount',
				header: 'Total US Transactions',
				body: ({ row }) => {
					const targetFilters: Partial<FiltersObject> = {
						buyers: [row.name],
						markets,
					};
					return (
						<NumberCell
							value={row.globalTotalSalesCount}
							filters={targetFilters}
						/>
					);
				},
				width: 100,
				flex: 1,
			},
			{
				id: 'officePropertiesCount',
				header: 'Office (# Properties)',
				body: ({ row }) => {
					const targetFilters: Partial<FiltersObject> = {
						buyers: [row.name],
						buildingPropertyTypeId: [1],
					};
					return (
						<NumberCell
							value={row.officePropertiesCount}
							filters={targetFilters}
						/>
					);
				},
				width: 100,
				flex: 1,
			},
			{
				id: 'industrialPropertiesCount',
				header: 'Industrial (#)',
				body: ({ row }) => {
					const targetFilters: Partial<FiltersObject> = {
						buyers: [row.name],
						buildingPropertyTypeId: [7],
					};
					return (
						<NumberCell
							value={row.industrialPropertiesCount}
							filters={targetFilters}
						/>
					);
				},
				width: 100,
				flex: 1,
			},
			{
				id: 'retailPropertiesCount',
				header: 'Retail (#)',
				body: ({ row }) => {
					const targetFilters: Partial<FiltersObject> = {
						buyers: [row.name],
						buildingPropertyTypeId: [16],
					};
					return (
						<NumberCell
							value={row.retailPropertiesCount}
							filters={targetFilters}
						/>
					);
				},
				width: 100,
				flex: 1,
			},
			{
				id: 'otherPropertiesCount',
				header: 'Other (#)',
				body: ({ row }) => {
					const targetFilters: Partial<FiltersObject> = {
						buyers: [row.name],
						buildingPropertyTypeId: [14, 17, 18, 2, 8],
					};
					return (
						<NumberCell
							value={row.otherPropertiesCount}
							filters={targetFilters}
						/>
					);
				},
				width: 100,
				flex: 1,
			},
		],
		[markets]
	);

	return (
		<LeagueTable
			columns={columns}
			data={rows}
			id="TopBuyersLeagueTable"
			isFetching={isFetching}
			pageSize={TOP_BUYERS_DEFAULT_PAGE_SIZE}
			fetchNextPage={fetchNextPage}
			hasNextPage={hasNextPage}
			isFetchingNextPage={isFetchingNextPage}
		/>
	);
};
