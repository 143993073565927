import { abbreviateNumber } from './util';
import { PDFPanel } from './UI';
import { VictoryScatter, VictoryLabel } from 'victory';
import dashboardStyles from './dashboard.less';
import React from 'react';
import sortBy from 'lodash/sortBy';
import { useExchangeDashboardLargeLandlords } from 'ExchangeDashboard/hooks/largestLandlords/useExchangeDashboardLargeLandlords';
import { AverageIndicator } from 'ExchangeDashboard/LargestLandlordsChart';

export default () => {
	const { data: largestLandlordsData } = useExchangeDashboardLargeLandlords();

	const largestLandlordsPlot =
		largestLandlordsData?.largestLandlords.map((landlord) => {
			const formattedLandlord =
				landlord.landlord.length > 30
					? `${landlord.landlord.slice(0, 30)}…`
					: landlord.landlord;
			return {
				...landlord,
				label: `${abbreviateNumber(landlord.sqft)} SqFt\n ${formattedLandlord}`,
				x: `${formattedLandlord}\n${abbreviateNumber(landlord.sqft)} SqFt`,
				y: 1,
			};
		}) ?? [];

	const data = sortBy(largestLandlordsPlot, 'sqft')
		.reverse()
		.filter((d, i) => i < 6);

	return (
		<PDFPanel
			id="largest-landlords"
			title="Largest Landlords"
			cta={
				<AverageIndicator>
					{`${abbreviateNumber(
						largestLandlordsData?.averageSqft
					)} SqFt, Average Landlord Size`}
				</AverageIndicator>
			}
		>
			<div className={dashboardStyles.largestLandlords}>
				{data.length === 0 && (
					<div>No Data To Display. Update Filters and Try Again.</div>
				)}
				{data.length > 0 && (
					<VictoryScatter
						height={150}
						width={800}
						style={{
							data: { cursor: 'pointer', fill: '#228fff' },
							labels: { fontSize: 10 },
						}}
						size={data?.length ?? 0}
						maxBubbleSize={30}
						minBubbleSize={5}
						bubbleProperty="sqft"
						labelComponent={<VictoryLabel dy={60} />}
						data={data}
					/>
				)}
			</div>
		</PDFPanel>
	);
};
