import { User } from '@compstak/common';
import { Flex, Tooltip } from '@compstak/ui-kit';
import React, { useState } from 'react';
import styled from 'styled-components';

import button from 'ui/styles/button.less';
import settings from '../styles/settings.less';

import { LinkButton } from 'Components/MultiFactorAuth/styles';
import { useAppSelector } from 'util/useAppSelector';
import { useDispatch } from 'react-redux';
import { changePassword } from 'actions/user';
import IconError from 'ui/svg_icons/error.svg';
import { MFA_LOGIC_ENABLED } from 'Components/MultiFactorAuth/constants';

type Props = {
	user: User;
	showSetupMfaScreen: () => void;
	showDisableMfaScreen: () => void;
};

type State = {
	oldPassword: string;
	newPassword: string;
	confirmNewPassword: string;
	passwordIsInvalid: boolean;
	passwordMatchProblem: boolean;
};

const DEFAULT_STATE: State = {
	oldPassword: '',
	newPassword: '',
	confirmNewPassword: '',
	passwordIsInvalid: false,
	passwordMatchProblem: false,
};

export const PasswordSettings = (props: Props) => {
	const [state, setState] = useState<State>(DEFAULT_STATE);
	const dispatch = useDispatch();

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setState((prevState) => ({
			...prevState,
			[event.target.name]: event.target.value,
		}));
	};

	const handlePasswordChange = (event: React.MouseEvent<HTMLSpanElement>) => {
		event.preventDefault();
		if (state.newPassword === state.confirmNewPassword) {
			dispatch(
				changePassword(props.user, state.oldPassword, state.newPassword)
			);
			setState(DEFAULT_STATE);
		}
	};

	const handleNewPasswordChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		handleChange(event);

		const newPassword = event.target.value;
		if (event.target.value.length === 0) {
			setState((prevState) => ({
				...prevState,
				passwordIsInvalid: false,
			}));
		} else if (
			event.target.value.length < 5 ||
			/[A-Z]/i.test(newPassword) === false ||
			/[0-9]/.test(newPassword) === false
		) {
			setState((prevState) => ({
				...prevState,
				passwordIsInvalid: true,
			}));
		} else {
			setState((prevState) => ({
				...prevState,
				passwordIsInvalid: false,
			}));
		}
	};

	let passwordDisabledClass = '';
	switch (true) {
		case state.passwordIsInvalid:
		case state.newPassword !== state.confirmNewPassword:
		case state.oldPassword.length === 0:
		case state.newPassword.length === 0:
		case state.confirmNewPassword.length === 0:
		case state.newPassword.length > 72:
			passwordDisabledClass = button.disabled;
	}

	const passwordMatchProblem =
		state.confirmNewPassword && state.confirmNewPassword !== state.newPassword;

	return (
		<div className={`settings_content ${settings.contentGrid}`}>
			<div className={`form ${settings.form}`}>
				<form>
					<div className="form_section">
						<div className={settings.inputWithValidation}>
							<input
								type="password"
								name="oldPassword"
								value={state.oldPassword}
								placeholder="Current password"
								onChange={handleChange}
							/>
						</div>
						<div className={settings.inputWithValidation}>
							<input
								type="password"
								name="newPassword"
								value={state.newPassword}
								placeholder="New password"
								onChange={handleNewPasswordChange}
							/>
							{state.passwordIsInvalid ? (
								<PasswordTooltip errorMessage="Passwords must be 5 characters and contain one letter and one number" />
							) : null}
							{state.newPassword.length > 72 ? (
								<PasswordTooltip errorMessage="Max characters reached (72)" />
							) : null}
						</div>
						<div className={settings.inputWithValidation}>
							<input
								type="password"
								name="confirmNewPassword"
								value={state.confirmNewPassword}
								placeholder="Confirm new password"
								onChange={handleChange}
							/>
							{passwordMatchProblem ? (
								<PasswordTooltip errorMessage="Passwords don't match" />
							) : null}
						</div>
					</div>
					<div className="form_section">
						<a
							className={`${button.large} ${button.blue} ${passwordDisabledClass}`}
						>
							<span onClick={handlePasswordChange}>Change Password</span>
						</a>
					</div>
					{MFA_LOGIC_ENABLED && (
						<MfaSetupSection
							showSetupMfaScreen={props.showSetupMfaScreen}
							showDisableMfaScreen={props.showDisableMfaScreen}
						/>
					)}
				</form>
			</div>
			<div className={`explanation ${settings.explanation}`}>
				<h1>Account</h1>
			</div>
		</div>
	);
};

function MfaSetupSection({
	showSetupMfaScreen,
	showDisableMfaScreen,
}: {
	showSetupMfaScreen: NoArgCallback;
	showDisableMfaScreen: NoArgCallback;
}) {
	const mfAuthEnabled = useAppSelector((store) => !!store.user?.mfaEnabled);
	// @ts-expect-error TS2339: Property 'mfaRequired' does no...
	const mfAuthRequired = useAppSelector((store) => !!store.user?.mfaRequired);
	return (
		<section>
			<StyledMfaSectionTitle>Two-Factor Authentication</StyledMfaSectionTitle>
			{mfAuthEnabled ? (
				<>
					<LinkButton
						data-qa-id="reset-mfa-btn"
						type="button"
						onClick={showSetupMfaScreen}
						noPadding
					>
						Reset
					</LinkButton>
					<Flex alignItems="baseline">
						<p>
							Two-factor authentication is enabled
							{mfAuthRequired && ' and required'}
						</p>
						{!mfAuthRequired && (
							<LinkButton
								data-qa-id="disable-mfa-btn"
								type="button"
								onClick={showDisableMfaScreen}
							>
								Disable
							</LinkButton>
						)}
					</Flex>
				</>
			) : (
				<LinkButton
					data-qa-id="enable-mfa-btn"
					type="button"
					onClick={showSetupMfaScreen}
				>
					Enable two-factor authentication
				</LinkButton>
			)}
		</section>
	);
}

const PasswordTooltip = ({ errorMessage }: { errorMessage: string }) => {
	return (
		<span className={settings.error}>
			<Tooltip
				tooltipComponent={<p>{errorMessage}</p>}
				placement="bottom"
				tooltipRootStyle={{
					margin: '0 16px 0 0',
				}}
			>
				<ErrorIconStyled width={20} height={20} />
			</Tooltip>
		</span>
	);
};

const StyledMfaSectionTitle = styled.h2`
	font-size: 16px;
	font-weight: 450;
	margin-bottom: 16px;
`;

const ErrorIconStyled = styled(IconError)`
	fill: hsl(226, 15%, 62%);
`;
