import { LeagueTableHeader } from '../../LeagueTables/LeagueTableHeader';
import ViewIcon from './view_icon.svg';
import { LeagueTable, LeagueTableProps } from '../../LeagueTables/LeagueTable';
import { Button } from '@compstak/ui-kit';

interface PremierListTableViewProps<T> extends LeagueTableProps<T> {
	buttonHref: string;
	buttonText: string;
	title: string;
	description: string;
	isFetching: boolean;
}

export const PremierListTableView = <T,>({
	id,
	isFetching,
	columns,
	data,
	buttonHref,
	buttonText,
	title,
	description,
	pageSize,
}: PremierListTableViewProps<T>) => {
	return (
		<div>
			<LeagueTableHeader
				title={title}
				description={description}
				rightArea={
					<Button href={buttonHref} variant="ghost2" icon={<ViewIcon />}>
						{buttonText}
					</Button>
				}
			/>
			<LeagueTable
				isFetching={isFetching}
				columns={columns}
				data={data}
				id={id}
				pageSize={pageSize}
			/>
		</div>
	);
};
