import { FiltersState } from 'PortfolioAnalytics/PortfolioFiltersProvider';
import { PortfolioFilterQuery } from 'api';
import { encodeQuery } from 'util/encodeQuery';

export const getQueryStringFromPortfolioFilters = (
	filters?: FiltersState,
	extraQueryParams?: Record<string | number, any>
) => {
	const { buildingSize, markets, ...otherFilters } = filters ?? {};

	const buildingSizeMin = buildingSize?.min;
	const buildingSizeMax = buildingSize?.max;
	const marketIds = markets?.map((market) => market.id);

	const queryParams: PortfolioFilterQuery = {
		buildingSizeMin,
		buildingSizeMax,
		marketIds,
		...otherFilters,
	};

	const query = encodeQuery({ ...queryParams, ...extraQueryParams });
	return query;
};
