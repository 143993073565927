import { Flex } from '@compstak/ui-kit';
import { OverviewTabCharts } from 'PortfolioAnalytics/Charts/OverviewTabCharts';
import { PortfolioOverview } from 'PortfolioAnalytics/Dashboard/PortfolioOverview/PortfolioOverview';

type PortfolioTabProps = {
	portfolioId: number;
};

export const OverviewTab = ({ portfolioId }: PortfolioTabProps) => {
	return (
		<Flex gap="1rem" direction="column" alignItems="normal">
			<PortfolioOverview portfolioId={portfolioId} />
			<OverviewTabCharts portfolioId={portfolioId} />
		</Flex>
	);
};
