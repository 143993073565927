import { ReactNode } from 'react';
import styled from 'styled-components';
import IconBars from 'ui/svg_icons/bars.svg';
import IconBarsSmall from 'ui/svg_icons/bars_small.svg';
import { colors } from '@compstak/ui-kit';

type NoDataTabProps = NoDataMessageProps & {
	minHeight?: string;
};

const { n100 } = colors.neutral;

export const NoDataTab = ({ minHeight, ...props }: NoDataTabProps) => {
	return (
		<Root minHeight={minHeight}>
			<NoDataMessage {...props} />
		</Root>
	);
};

export const NoDataTabCompact = ({
	minHeight,
	message,
	action,
}: NoDataTabProps) => {
	return (
		<Root minHeight={minHeight}>
			<IconBarsSmall />
			<TitleCompact>No data available</TitleCompact>
			{message && <MessageCompact>{message}</MessageCompact>}
			{action}
		</Root>
	);
};

type NoDataMessageProps = {
	message?: ReactNode;
	action?: ReactNode;
};

export const NoDataMessage = ({ message, action }: NoDataMessageProps) => {
	return (
		<>
			<IconBars />
			<Title>No data available</Title>
			{message && <Message>{message}</Message>}
			{action}
		</>
	);
};

const Root = styled.div<{ minHeight?: string }>`
	font-family: ${(p) => p.theme.typography.fontFamily.openSans};
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 0.5rem;
	padding: 5rem 0;
	${({ minHeight }) => minHeight && `min-height: ${minHeight};`}
`;

const Title = styled.div`
	font-size: 1.5rem;
	font-weight: 500;
	line-height: 1.25;
`;

const TitleCompact = styled.div`
	font-size: 1em;
	font-weight: 500;
	line-height: 1.25;
`;

const Message = styled.div`
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	text-align: center;
`;

const MessageCompact = styled.div`
	font-size: 0.875em;
	font-weight: 400;
	line-height: 1.5;
	text-align: center;
	color: ${n100};
`;
