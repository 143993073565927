import { useMemo } from 'react';
import { groupBy } from 'lodash';
import {
	Portfolio,
	PortfolioLeaseExpirationsBySpaceTypesByYears,
	SpaceTypeName,
} from 'api';
import {
	Legend,
	LegendColor,
	LegendItem,
	LegendLabel,
	ChartAndLegendContainer,
} from 'PortfolioAnalytics/styles/PortfolioUI';
import {
	CHART_AXIS_TICK_STYLE,
	VICTORY_CONTAINER_STYLE_400,
	VICTORY_TOOLTIP_STYLE,
} from '../chartsConstants';
import {
	VictoryAxis,
	VictoryBar,
	VictoryChart,
	VictoryVoronoiContainer,
	VictoryLabel,
	VictoryStack,
	VictoryTooltip,
} from 'victory';
import { ChartBox } from '../ChartSelect';
import { PORTFOLIO_CHART_COLORS } from '../../constants';
import { useNavigate } from 'react-router';
import { routes } from 'router';
import { YEAR_RANGE, filterValidYears, getSelectedChartValue } from './utils';
import { ChartStateWrapper } from '../ChartStateWrapper';
import { formatMoney, formatNumber } from 'format';

type Props = {
	portfolio: Portfolio;
};

const LEASE_EXPIRATION__BY_SPACE_TYPE_CHART_NAME = 'Upcoming Lease Expirations';
const MINIMUM_BAR_HEIGHT_PERCENTAGE = 2;

export const LeaseExpirationsBySpaceTypeChart = ({ portfolio }: Props) => {
	const { leaseExpirationsBySpaceTypesByYears } = portfolio;

	const chartData = useMemo(() => {
		if (!leaseExpirationsBySpaceTypesByYears) return [];
		const expirationsByValidYears =
			leaseExpirationsBySpaceTypesByYears.filter(filterValidYears);
		const expirationsGroupedBySpaceType = groupBy(
			expirationsByValidYears,
			(e) => e.spaceType
		);
		return Object.entries(expirationsGroupedBySpaceType) as [
			SpaceTypeName,
			PortfolioLeaseExpirationsBySpaceTypesByYears[],
		][];
	}, [leaseExpirationsBySpaceTypesByYears]);

	const navigate = useNavigate();

	return (
		<ChartBox<'lease'> chartName={LEASE_EXPIRATION__BY_SPACE_TYPE_CHART_NAME}>
			{(selectedChart) => {
				const maxValue = Math.max(
					...chartData.flatMap(([, spaceTypeGroup]) =>
						spaceTypeGroup.map((data) =>
							getSelectedChartValue(selectedChart.type, data)
						)
					)
				);
				const minVisibleValue =
					(maxValue * MINIMUM_BAR_HEIGHT_PERCENTAGE) / 100;
				const halfwayVerticalAxis = maxValue * 0.5;
				return (
					<ChartStateWrapper noData={!chartData.length}>
						<ChartAndLegendContainer>
							<VictoryChart
								height={520}
								padding={{ left: 30, top: 20, right: 0, bottom: 50 }}
								containerComponent={
									<VictoryVoronoiContainer
										responsive={true}
										style={{
											...VICTORY_CONTAINER_STYLE_400,
											width: '80%',
										}}
										activateData={true}
										labels={({ datum }: { datum: BarData }) => {
											if (!datum || !datum.yValue) return '';
											const value = datum.yValue;
											const formattedValue =
												selectedChart.type === 'leaseAmount'
													? formatMoney(value, {
															decimalPlaces: 2,
															shorten: true,
														})
													: formatNumber(value, { shorten: true });
											return datum.yValue > 0
												? `${formattedValue}\nClick the bar to view\n${datum.spaceTypeName} leases\nexpiring in ${datum.expirationYear}`
												: `No ${datum.spaceTypeName} leases\nexpiring in ${datum.expirationYear}`;
										}}
										labelComponent={
											<VictoryTooltip
												{...VICTORY_TOOLTIP_STYLE}
												centerOffset={{
													x: 0,
													y: ({ datum }) => {
														return datum?._y1 > halfwayVerticalAxis ? 100 : 0;
													},
												}}
												flyoutWidth={135}
											/>
										}
									/>
								}
							>
								<VictoryAxis
									dependentAxis
									offsetX={30}
									tickFormat={(yAxisValue: number) =>
										selectedChart.type === 'leaseAmount'
											? formatMoney(yAxisValue, {
													decimalPlaces: 0,
													shorten: true,
												})
											: formatNumber(yAxisValue, { shorten: true })
									}
									tickLabelComponent={
										<VictoryLabel
											renderInPortal
											style={CHART_AXIS_TICK_STYLE}
										/>
									}
									style={{
										axis: { stroke: '#F0F0F0', strokeWidth: 2 },
										axisLabel: {
											fontSize: 20,
											color: '#A3A3A3',
											textOverflow: 'ellipsis',
											whiteSpace: 'no-wrap',
											overflow: 'hidden',
										},
										grid: { stroke: '#F0F0F0' },
										ticks: { stroke: '#F0F0F0' },
										tickLabels: {
											padding: 5,
										},
									}}
								/>
								<VictoryAxis
									offsetX={0}
									fixLabelOverlap
									tickFormat={(yAxisValue: number) => `${yAxisValue}`}
									tickLabelComponent={
										<VictoryLabel
											renderInPortal
											style={CHART_AXIS_TICK_STYLE}
										/>
									}
									style={{
										axis: { stroke: '#F0F0F0', strokeWidth: 2 },
										axisLabel: { fontSize: 20, padding: 0, color: '#A3A3A3' },
										grid: { stroke: '#F0F0F0', border: '1px solid black' },
										ticks: { stroke: '#F0F0F0' },
										tickLabels: {
											padding: 5,
										},
									}}
									domain={{
										x: [
											YEAR_RANGE[0] - 0.99,
											YEAR_RANGE[YEAR_RANGE.length - 1] + 0.99,
										],
									}}
								/>
								<VictoryStack
									colorScale={PORTFOLIO_CHART_COLORS}
									style={{
										parent: {
											height: 300,
										},
									}}
								>
									{chartData.map(([spaceTypeName, spaceTypeGroup]) => {
										const aggregatedDataBySpaceTypeAndExpiration =
											spaceTypeGroup.reduce(
												(acc: BarDataAccumulator, data) => {
													const expirationYear = data.expirationYear;

													acc[expirationYear].yValue += getSelectedChartValue(
														selectedChart.type,
														data
													);

													return acc;
												},
												Object.fromEntries(
													YEAR_RANGE.map((year) => [
														year,
														{ spaceTypeName, expirationYear: year, yValue: 0 },
													])
												)
											);

										const barData = Object.values(
											aggregatedDataBySpaceTypeAndExpiration
										);
										const barDataWithMinVisibleValue = barData
											.filter((d) => d.yValue > 0)
											.map((data) => ({
												...data,
												yValue:
													data.yValue > 0
														? Math.max(data.yValue, minVisibleValue)
														: data.yValue,
												actualValue: data.yValue,
											}));
										return (
											<VictoryBar
												key={spaceTypeName}
												barRatio={0.6}
												data={barDataWithMinVisibleValue}
												x="expirationYear"
												y="yValue"
												style={{
													data: {
														cursor: 'pointer',
														width: 24,
													},
												}}
												events={[
													{
														target: 'data',
														eventHandlers: {
															onClick: (e, clickedProps) => {
																const { expirationYear } = clickedProps.datum;
																navigate(
																	routes.portfolioByIdView.toHref(
																		{
																			portfolioId: portfolio.id,
																			viewType: 'list',
																		},
																		{
																			expirationYears: [expirationYear],
																			spaceTypes: [spaceTypeName],
																		}
																	)
																);
															},
														},
													},
												]}
											/>
										);
									})}
								</VictoryStack>
							</VictoryChart>
							<Legend>
								{chartData.map(([spaceType], i) => (
									<LegendItem key={i}>
										<LegendColor color={PORTFOLIO_CHART_COLORS[i]} />
										<LegendLabel>{spaceType}</LegendLabel>
									</LegendItem>
								))}
							</Legend>
						</ChartAndLegendContainer>
					</ChartStateWrapper>
				);
			}}
		</ChartBox>
	);
};

type BarData = {
	expirationYear: number;
	yValue: number;
	spaceTypeName: SpaceTypeName;
};

type BarDataAccumulator = {
	[year: number]: BarData;
};
