import { PROPERTY_TYPE_NAME_TO_ID, PropertyTypeName } from 'api';
import { FiltersState } from 'PortfolioAnalytics/PortfolioFiltersProvider';

import {
	Legend,
	LegendColor,
	LegendItem,
	LegendLabel,
	ChartAndLegendContainer,
} from 'PortfolioAnalytics/styles/PortfolioUI';
import {
	VictoryAxis,
	VictoryBar,
	VictoryChart,
	VictoryVoronoiContainer,
	VictoryLabel,
	VictoryStack,
	VictoryTooltip,
	Flyout,
	FlyoutProps,
} from 'victory';
import { ChartBox } from './ChartSelect';
import { PORTFOLIO_CHART_COLORS } from '../constants';
import { useMemo } from 'react';
import {
	CHART_AXIS_TICK_STYLE,
	CHART_HEIGHT,
	VICTORY_CONTAINER_STYLE_400,
	VICTORY_TOOLTIP_STYLE,
} from './chartsConstants';
import { ChartStateWrapper } from './ChartStateWrapper';
import React from 'react';
import { routes } from 'router';
import { useNavigate } from 'react-router';
import { useChartPropertiesHistogramQuery } from 'api/portfolio/charts/properties/useChartPropertiesHistogramQueries';
import { ChartAxisLabel } from './UI';

type PropertyCountByPropertySqftChartProps = {
	portfolioId: number;
	filters: FiltersState;
	minBarChartWidth?: number;
};

type SqftRangeValue = {
	range: SqftRangeFormattedLabel;
	propertyCount: number;
};

type ChartDataItem = [PropertyTypeName, SqftRangeValue[]];

type SqftRangeLabel =
	| '< 5000'
	| '5000 - 10K'
	| '10K - 50K'
	| '50K - 100K'
	| '100K - 500K'
	| '500K - 1M'
	| '> 1M';

type SqftRangeFormattedLabel =
	| '< 5K'
	| '5K-10K'
	| '10K-50K'
	| '50K-100K'
	| '100K-500K'
	| '500K-1M'
	| '> 1M';

export const SQFT_RANGES_TO_BUILDING_SIZE_FILTER: Record<
	SqftRangeFormattedLabel,
	FiltersState['buildingSize']
> = {
	'< 5K': { min: 0, max: 4999 },
	'5K-10K': { min: 5000, max: 9999 },
	'10K-50K': { min: 10000, max: 49999 },
	'50K-100K': { min: 50000, max: 99999 },
	'100K-500K': { min: 100000, max: 499999 },
	'500K-1M': { min: 500000, max: 999999 },
	'> 1M': { min: 1000000, max: null },
};

const MINIMUM_BAR_HEIGHT_PERCENTAGE = 2;

export const PropertyCountByPropertySqftChart = ({
	portfolioId,
	filters,
	minBarChartWidth,
}: PropertyCountByPropertySqftChartProps) => {
	const {
		data: propertyCountByPropertySqftResults,
		isFetching,
		isError,
	} = useChartPropertiesHistogramQuery(portfolioId, {
		filters,
		chartGrouping: 'buildingPropertyType',
		chartLimit: 5,
		chartMetric: 'propertyCount',
	});

	const {
		chartData,
		rangeTotals,
	}: {
		chartData: ChartDataItem[];
		rangeTotals: Record<SqftRangeFormattedLabel, number>;
	} = useMemo(() => {
		const rangeTotals: Record<SqftRangeFormattedLabel, number> = {
			'< 5K': 0,
			'5K-10K': 0,
			'10K-50K': 0,
			'50K-100K': 0,
			'100K-500K': 0,
			'500K-1M': 0,
			'> 1M': 0,
		};
		if (!propertyCountByPropertySqftResults?.values)
			return { chartData: [], rangeTotals };

		const propertyTypesSet = new Set(
			propertyCountByPropertySqftResults.values.flatMap((range) =>
				range.values.map((value) => value.label as PropertyTypeName)
			)
		);

		const propertyTypesArray = Array.from(propertyTypesSet);

		const chartData: ChartDataItem[] = propertyTypesArray.map(
			(propertyType): [PropertyTypeName, SqftRangeValue[]] => {
				const rangeData: SqftRangeValue[] =
					propertyCountByPropertySqftResults.values.map((range) => {
						const rangeLabel = formatSqftLabel(range.label as SqftRangeLabel);
						const propertyCount =
							range.values.find((v) => v.label === propertyType)?.value || 0;
						rangeTotals[rangeLabel] += propertyCount;
						return {
							range: rangeLabel,
							propertyCount,
						};
					});
				return [propertyType, rangeData];
			}
		);
		return { chartData, rangeTotals };
	}, [propertyCountByPropertySqftResults]);

	const maxValue = Math.max(...Object.values(rangeTotals));
	const minVisibleValue = Math.round(
		(maxValue * MINIMUM_BAR_HEIGHT_PERCENTAGE) / 100
	);

	const xRange = useMemo(() => {
		if (!propertyCountByPropertySqftResults?.values) return [];
		return propertyCountByPropertySqftResults.values.map((range) =>
			formatSqftLabel(range.label as SqftRangeLabel)
		);
	}, [propertyCountByPropertySqftResults]);

	const navigate = useNavigate();

	return (
		<ChartBox
			chartName={'Property Count by Property SqFt'}
			displaySelector={false}
			chartDataType="property"
		>
			{() => {
				return (
					<ChartStateWrapper
						isFetching={isFetching}
						isError={isError}
						noData={!chartData.length}
					>
						<ChartAndLegendContainer>
							<VictoryChart
								domain={{ x: [0.4, xRange.length + 0.6] }}
								width={minBarChartWidth ? minBarChartWidth - 80 : 400}
								height={CHART_HEIGHT}
								padding={{ left: 20, top: 50, right: 0, bottom: 60 }}
								containerComponent={
									<VictoryVoronoiContainer
										responsive={true}
										style={{
											...VICTORY_CONTAINER_STYLE_400,
											width: '90%',
										}}
										labels={() => ' '}
										labelComponent={
											<VictoryTooltip
												flyoutComponent={<CustomFlyout />}
												{...VICTORY_TOOLTIP_STYLE}
												text={(d) => {
													if (!d?.datum) {
														return '';
													}
													const datum = d.datum as CustomDatum;
													const tooltipText =
														datum.y && datum.propertyTypeName
															? `${datum.propertyTypeName} properties: ${datum.actualValue}`
															: '';
													return tooltipText;
												}}
												flyoutHeight={40}
												constrainToVisibleArea={true}
											/>
										}
									/>
								}
							>
								{/* y axis */}
								<VictoryAxis
									dependentAxis
									offsetX={20}
									tickLabelComponent={
										<VictoryLabel
											renderInPortal
											style={CHART_AXIS_TICK_STYLE}
										/>
									}
									style={{
										axis: { stroke: 'transparent' },
										grid: { stroke: '#F0F0F0' },
									}}
								/>
								{/* x axis */}
								<VictoryAxis
									offsetY={59}
									tickValues={xRange}
									tickFormat={(label: SqftRangeLabel) =>
										label.replace('-', '- \n')
									}
									fixLabelOverlap
									tickLabelComponent={
										<VictoryLabel
											renderInPortal
											style={CHART_AXIS_TICK_STYLE}
										/>
									}
									label={() => ''}
									axisLabelComponent={<ChartAxisLabel text="SqFt" />}
									style={{
										axis: { stroke: '#F0F0F0', strokeWidth: 1 },
										ticks: { stroke: '#F0F0F0' },
										tickLabels: {
											padding: 5,
										},
										axisLabel: {
											padding: 50,
										},
									}}
								/>
								<VictoryStack
									colorScale={PORTFOLIO_CHART_COLORS}
									style={{
										parent: {
											height: 300,
										},
									}}
								>
									{chartData.map(([propertyTypeName, propertyTypeGroup]) => {
										const barData = propertyTypeGroup.map<CustomDatum>(
											(range) => {
												return {
													x: range.range,
													y:
														range.propertyCount > minVisibleValue ||
														range.propertyCount === 0
															? range.propertyCount
															: minVisibleValue,
													actualValue: range.propertyCount,
													propertyTypeName: propertyTypeName,
												};
											}
										);
										return (
											<VictoryBar
												key={propertyTypeName}
												barRatio={0.9}
												data={barData}
												style={{
													data: {
														cursor: 'pointer',
													},
												}}
												events={[
													{
														target: 'data',
														eventHandlers: {
															onClick: (_e, clickedProps) => {
																if (!clickedProps?.datum) {
																	return;
																}

																const { propertyTypeName, x } =
																	clickedProps.datum as CustomDatum;
																navigate(
																	routes.portfolioByIdView.toHref(
																		{
																			portfolioId: portfolioId,
																			viewType: 'list',
																		},
																		{
																			propertyTypeIds: [
																				PROPERTY_TYPE_NAME_TO_ID[
																					propertyTypeName
																				],
																			],
																			buildingSize:
																				SQFT_RANGES_TO_BUILDING_SIZE_FILTER[x],
																		}
																	)
																);
															},
														},
													},
												]}
											/>
										);
									})}
								</VictoryStack>
							</VictoryChart>
							<Legend>
								{chartData.map(([propertyType], index) => (
									<LegendItem key={index}>
										<LegendColor color={PORTFOLIO_CHART_COLORS[index]} />
										<LegendLabel>{propertyType}</LegendLabel>
									</LegendItem>
								))}
							</Legend>
						</ChartAndLegendContainer>
					</ChartStateWrapper>
				);
			}}
		</ChartBox>
	);
};

const formatSqftLabel = (label: SqftRangeLabel): SqftRangeFormattedLabel => {
	switch (label) {
		case '< 5000':
			return '< 5K';
		case '5000 - 10K':
			return '5K-10K';
		default:
			return label.replace(' - ', '-') as SqftRangeFormattedLabel;
	}
};

interface CustomDatum {
	x: SqftRangeFormattedLabel;
	y: number;
	propertyTypeName: PropertyTypeName;
	actualValue: number;
}

interface CustomFlyoutProps extends Omit<FlyoutProps, 'datum'> {
	datum?: CustomDatum;
}

const CustomFlyout = (props: CustomFlyoutProps) => {
	if (!props.datum?.y) {
		return null;
	}
	return <Flyout {...props} />;
};
