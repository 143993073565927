import { FiltersObject } from 'models/filters/types';
import {
	getLabelForFilterAttribute,
	getUnsupportedFilters,
} from 'models/filters/util/filterHelpers';
import { DataSetType } from 'Pages/Analytics/analytics';
import { createSendToAnalyticsHref } from 'Pages/Analytics/Builder/chartBuilderHelpers';
import { renderToStaticMarkup } from 'react-dom/server';
import { CompType } from 'types';

import { useIsExchangePlusUser } from 'exchange/exchangePlus/exchangePlusStatus';
import { useAppConfig } from 'Pages/Login/reducers';
import { useMemo } from 'react';
import styled from 'styled-components';
import { useFeatureFlags } from './useFeatureFlags';
import { useCurrentFiltersMarketsHaveFeature } from 'api/marketsFeatures/useMarketsHaveFeature';

export function useHasMufaChartbuilderAccess() {
	const { data: isAnalyticsAccessible } = useCurrentFiltersMarketsHaveFeature(
		'analytics',
		'some'
	);
	const { data: isMultifamilyAccessible } =
		useCurrentFiltersMarketsHaveFeature('multifamilyAccess');

	return !!isAnalyticsAccessible && !!isMultifamilyAccessible;
}

function getSendToAnalyticsBtnInfo({
	filters,
	compType,
	hasMufaChartbuilderAccess,
	hasLeaseData,
	hasRealpageData,
	title,
}: {
	filters: FiltersObject;
	compType: CompType;
	hasMufaChartbuilderAccess: boolean;
	hasLeaseData: boolean;
	hasRealpageData: boolean;
	title?: string;
}) {
	const useMufaFilters = Boolean(
		hasMufaChartbuilderAccess && compType === 'property'
	);

	const unsupportedFilters = useMufaFilters
		? []
		: getUnsupportedFilters(filters);

	const dataSetTypes: DataSetType[] = (() => {
		/** Temporary, until an old logic for creation of datasets
		 from the Properties is used
		*/
		if (compType === 'sale') {
			return [DataSetType.SALES];
		}

		if (!useMufaFilters) {
			return [DataSetType.COMMERCIAL];
		}
		const ret: DataSetType[] = [];
		if (hasLeaseData) {
			ret.push(DataSetType.COMMERCIAL);
		}
		if (hasRealpageData) {
			ret.push(DataSetType.MUFA);
		}
		return ret;
	})();

	const hasUnsupportedFilters = unsupportedFilters.length > 0;

	const isEnabled = !hasUnsupportedFilters && dataSetTypes.length > 0;

	const href = isEnabled
		? createSendToAnalyticsHref({
				title,
				filters,
				dataSetTypes,
				compType,
			})
		: undefined;

	const tooltipText: string = (() => {
		if (isEnabled) {
			return 'Send to Chartbuilder';
		}
		if (hasUnsupportedFilters) {
			const unsupportedFiltersLabels = unsupportedFilters.map((attribute) =>
				getLabelForFilterAttribute({
					attribute,
					compType,
				})
			);
			return renderToStaticMarkup(
				<UnsuportedFiltersTooltipContent>
					Selected filters are currently not supported by Compstak Analytics.
					Please remove these filters to use this feature:
					<UnsuportedFiltersTooltipList>
						{unsupportedFiltersLabels.map((label) => (
							<UnsuportedFiltersTooltipListItem key={label}>
								{label}
							</UnsuportedFiltersTooltipListItem>
						))}
					</UnsuportedFiltersTooltipList>
				</UnsuportedFiltersTooltipContent>
			);
		}
		return noDataWarning;
	})();

	return {
		isEnabled,
		href,
		tooltipText,
	};
}

export function useIsSendToAnalyticsBtnAllowed(compType?: CompType): boolean {
	const { isEnterprise } = useAppConfig();
	const isExchangePlusUser = useIsExchangePlusUser();
	const { salesCompsAnalytics2 } = useFeatureFlags();

	if (compType === 'sale') {
		return salesCompsAnalytics2 && (isEnterprise || isExchangePlusUser);
	}

	return isEnterprise || isExchangePlusUser;
}

export function useSendToAnalyticsBtn({
	filters,
	compType,
	title,
}: {
	filters: FiltersObject;
	compType: CompType;
	title?: string;
}) {
	const hasMufaChartbuilderAccess = useHasMufaChartbuilderAccess();

	const btnData = useMemo(
		() =>
			getSendToAnalyticsBtnInfo({
				filters,
				compType,
				hasMufaChartbuilderAccess,
				hasLeaseData: true,
				hasRealpageData: false,
				title,
			}),
		[filters, compType, hasMufaChartbuilderAccess, title]
	);

	return btnData;
}

const UnsuportedFiltersTooltipContent = styled.div`
	width: 100%;
	font-family: Gotham;
	font-style: normal;
	font-weight: 0;
	font-size: 11px;
	letter-spacing: 0.3px;
	color: #ffffff;
	border-radius: 3px;
`;

const UnsuportedFiltersTooltipList = styled.ul`
	list-style-type: disc;
	margin-top: 16px;
	margin-bottom: 8px;
`;

const UnsuportedFiltersTooltipListItem = styled.li`
	margin-left: 8px;
	margin-right: 8px;
`;

const noDataWarning = renderToStaticMarkup(
	<UnsuportedFiltersTooltipContent>
		There is no lease or property data for this set that can be applied in
		Analytics. Please adjust filters or select a different set of properties.
	</UnsuportedFiltersTooltipContent>
);
