import { MQB } from '@compstak/ui-kit';
import { APP_HEADER_HEIGHT } from 'constants/sizes';
import { CSSProperties } from 'react';
import styled, { css } from 'styled-components';

export const MfaPageContainerFlex = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	overflow-y: auto;
	padding-bottom: 0;

	@media (min-height: ${MQB.T_834}) {
		padding-bottom: ${APP_HEADER_HEIGHT}px;
	}
`;

export const StyledSection = styled.section<{ width?: string }>`
	width: ${({ width }) => width || 'auto'};
	max-width: 680px;
	max-height: 100%;
	overflow-y: auto;
	border: solid 1px ${({ theme }) => theme.colors.neutral.n70};
	padding: 0px 64px;

	& > * {
		margin-top: 32px;
		margin-bottom: 32px;
	}
`;

export const ErrorMessage = styled.p`
	margin: 0;
	height: 16px; // Fixed height to not have ui size changes when {children} is empty.
	color: ${({ theme }) => theme.colors.red.red500};
`;

export const InnerSection = styled.div<{ gap?: CSSProperties['gap'] }>`
	& > *,
	/* Specificity hack. There are some evil .less files out there */
	& > input[type='text'] {
		margin-top: ${({ gap }) => gap};
		margin-bottom: ${({ gap }) => gap};
	}
`;
InnerSection.defaultProps = { gap: '16px' };

export const SectionTitle = styled.h1`
	font-size: 20px;
	line-height: 24px;
	font-weight: 450;
	margin-bottom: 32px;
`;

export const TotpCodeInputWrapper = styled.div<{ error?: boolean }>`
	${({ error, theme }) =>
		error
			? css`
					> input {
						border-color: ${theme.colors.red.red500};
					}
				`
			: ''}
`;

export const LinkButton = styled.button<{ noPadding?: boolean }>`
	/* This button is styled to look like an anchor tag link */
	color: ${(props) => props.theme.colors.blue.blue400};
	border: none;
	background: transparent;
	${(props) =>
		props.noPadding &&
		css`
			padding: 0;
		`}
`;
