import { Flex } from '@compstak/ui-kit';
import { useQueryClient } from '@tanstack/react-query';
import { useShowUploadSuccessModal } from 'Components/Modals/UploadSuccessModal/UploadSuccessModal';
import { useReferenceData } from 'Pages/Login/reducers';
import { useUserQuery } from 'api';
import { invalidateUserQuery } from 'hooks/userHooks';
import { useState } from 'react';
import { useAppDispatch } from 'util/useAppDispatch';
import { useAppSelector } from 'util/useAppSelector';
import { omit, pick } from 'utils';
import { sendSubmission, updateLeaseSubmissionFormState } from '../actions';
import { LeaseSubmission } from '../types';
import {
	currentYear,
	getNumsInRange,
	isManualCompFormValid,
} from '../utils/manualUploadFormUtils';
import { getLocaleForUserMarketId } from '../utils/submissionLocale';
import { getLeaseSubmissionMapFromAppStore } from '../utils/submissionMap';
import {
	DEFAULT_UPLOAD_FORM_FIELD,
	FieldProps,
	mappedUploadFormComponentsFactory,
} from './MappedComponents';
import { SubmitManualCompFormButton } from './SubmitManualCompButton';
import {
	AsteriskCount,
	HorizontalLine,
	ManualUploadFormBody,
	PROPERTY_TYPE_OPTIONS,
	RequiredFieldsAsteriskLegend,
	Row12,
	STATE_SELECT_OPTIONS,
	ShowAdditionalFieldsButton,
	WORK_TYPE_OPTIONS,
	getSelectOptionsFromRefrenceData,
} from './UploadFormCommon';
import { usePropertySubtypeOptions } from './usePropertySubtypeOptions';
import { withErrorBoundaryDefault } from 'Components/ErrorBoundary';

export const LeaseCompUploadForm = withErrorBoundaryDefault(
	LeaseCompUploadFormUnsafe
);
LeaseCompUploadForm.displayName = 'LeaseCompUploadForm';

function LeaseCompUploadFormUnsafe() {
	const dispatch = useAppDispatch();
	const submission = useAppSelector((store) => store.uploads.leaseSubmission);
	const refrenceData = useReferenceData();
	const { leaseTypeOptions, spaceTypeOptions, transactionTypeOptions } =
		getSelectOptionsFromRefrenceData(refrenceData);
	const isFormDataValid = isManualCompFormValid({
		submission,
		requiredKeys: REQUIRED_KEYS,
		oneFromListsRequired: ONE_FROM_LISTS_REQUIRED,
		dateFields: DATE_FIELDS,
	});
	const user = useUserQuery().data;
	const [extraFieldsShown, setExtraFieldsShown] = useState(false);
	const queryClient = useQueryClient();
	const showUploadSuccessModal = useShowUploadSuccessModal();

	const submitBtnJsx = (
		<SubmitManualCompFormButton
			disabled={!isFormDataValid}
			onClick={() => {
				if (!submission) {
					throw new Error('No lease submission found');
				}
				const formattedSubmission = {
					...omit(submission, 'spaceType'),
					rawSpaceType: submission?.spaceType,
					dataType: 'lease',
					locale: getLocaleForUserMarketId(user?.primaryMarketId ?? -1),
				};
				dispatch(
					sendSubmission({
						submission: formattedSubmission,
						onSuccess: (compsAwarded) => {
							showUploadSuccessModal({ compsAwarded: compsAwarded });
							invalidateUserQuery(queryClient);
						},
					})
				);
			}}
		>
			Submit Comps
		</SubmitManualCompFormButton>
	);
	const requiredFieldsLegend = (
		<RequiredFieldsAsteriskLegend twoAsteriskText="At least one is required: Execution Date/Commencement Date and Lease Term/Expiration Date" />
	);

	const propertySubtypeOptions = usePropertySubtypeOptions();

	return (
		<ManualUploadFormBody>
			<Row12>
				<FormInput width={6} name="tenantName" />
				<FormSelect width={6} name="spaceType" options={spaceTypeOptions} />
			</Row12>
			<Row12>
				<FormInput width={6} name="buildingAddress" avoidAutofillByName />
				<FormInput width={4} name="city" avoidAutofillByName />
				<FormSelect
					width={2}
					name="state"
					options={STATE_SELECT_OPTIONS}
					avoidAutofillByName
				/>
			</Row12>
			<Row12>
				<FormInput width={4} name="transactionSize" />
				<FormInput width={4} name="floorOccupancies" />
				<FormInput width={4} name="suite" />
			</Row12>
			<Row12>
				<FormSelect
					width={6}
					name="buildingPropertyType"
					options={PROPERTY_TYPE_OPTIONS}
				/>
				<FormSelect
					width={6}
					name="buildingPropertySubtype"
					options={propertySubtypeOptions}
				/>
			</Row12>
			<HorizontalLine />
			<Row12>
				<FormInput width={4} name="startingRent" />
				<FormInput width={4} name="askingRent" />
				<FormInput width={4} name="leaseTerm" />
			</Row12>
			<Row12>
				<FormDate
					width={4}
					name="executionDate"
					yearOptions={EXEC_DATE_OPTIONS}
				/>
				<FormDate
					width={4}
					name="commencementDate"
					yearOptions={COMMENCEMENT_DATE_OPTIONS}
				/>
				<FormDate
					width={4}
					name="expirationDate"
					yearOptions={EXPIRATION_DATE_OPTIONS}
				/>
			</Row12>
			<Row12>
				<FormInput width={8} name="comments" />
				<FormRadio width={4} name="sublease" />
			</Row12>
			{!extraFieldsShown ? (
				<>
					{requiredFieldsLegend}
					<Flex justifyContent="center" alignItems="center">
						<ShowAdditionalFieldsButton
							onClick={() => setExtraFieldsShown(!extraFieldsShown)}
						/>
					</Flex>
					{submitBtnJsx}
				</>
			) : (
				<>
					<HorizontalLine />

					<Row12>
						<FormSelect
							width={6}
							name="transactionType"
							options={transactionTypeOptions}
						/>
						<FormInput width={6} name="effectiveRent" />
					</Row12>
					<Row12>
						<FormSelect width={3} name="leaseType" options={leaseTypeOptions} />
						<FormInput width={3} name="rentBumpsPercent" />
						<FormInput width={3} name="rentBumpsDollar" />
						<FormInput width={3} name="leaseEscalations" />
					</Row12>
					<Row12>
						<FormInput width={4} name="freeMonths" />
						<FormSelect
							width={4}
							name="workType"
							labelOverride="TI/Work Type"
							options={WORK_TYPE_OPTIONS}
						/>
						<FormInput
							width={4}
							name="workValue"
							labelOverride="TI/Work Value"
							unitOverride="$"
						/>
					</Row12>

					<HorizontalLine />

					<Row12>
						<FormInput width={12} name="landlordName" />
					</Row12>
					<Row12>
						<FormInput width={12} name="sublessorName" />
					</Row12>
					<Row12>
						<FormInput width={12} name="landlordRealtyBrokers" />
					</Row12>
					<Row12>
						<FormInput width={12} name="landlordRealtyCompanies" />
					</Row12>
					<Row12>
						<FormInput width={12} name="tenantRealtyBrokers" />
					</Row12>
					<Row12>
						<FormInput width={12} name="tenantRealtyCompanies" />
					</Row12>
					{requiredFieldsLegend}
					{submitBtnJsx}
				</>
			)}
		</ManualUploadFormBody>
	);
}

type SubmissionKey = keyof LeaseSubmission;

function useFormField(name: SubmissionKey): FieldProps {
	const dispatch = useAppDispatch();
	const fieldConfigData = useAppSelector(
		(store) =>
			getLeaseSubmissionMapFromAppStore(store)[name] ??
			DEFAULT_UPLOAD_FORM_FIELD
	);
	const value: string = useAppSelector(
		(store) => store.uploads.leaseSubmission?.[name] ?? ''
	);
	const asteriskCount = ((): AsteriskCount => {
		if (REQUIRED_KEYS.includes(name)) return 1;
		if (TWO_ASTERISK_KEYS.includes(name)) return 2;
		return 0;
	})();
	return {
		...pick(fieldConfigData, 'label', 'unit', 'postUnit'),
		value,
		asteriskCount,
		update: (newValue: string) => {
			dispatch(updateLeaseSubmissionFormState(name, newValue));
		},
	};
}

const { FormInput, FormSelect, FormRadio, FormDate } =
	mappedUploadFormComponentsFactory<SubmissionKey>({
		useFormField,
		displayNamePrefix: 'Lease',
	});

const EXEC_DATE_OPTIONS = getNumsInRange({
	start: 2000,
	stop: currentYear(),
	asc: false,
}).map((year) => ({
	label: String(year),
	value: String(year),
}));

const COMMENCEMENT_DATE_OPTIONS = getNumsInRange({
	start: currentYear() - 10,
	stop: currentYear() + 10,
	asc: false,
}).map((year) => ({
	label: String(year),
	value: String(year),
}));

const EXPIRATION_DATE_OPTIONS = getNumsInRange({
	start: currentYear() - 10,
	stop: currentYear() + 100,
	asc: false,
}).map((year) => ({
	label: String(year),
	value: String(year),
}));

const REQUIRED_KEYS: ReadonlyArray<SubmissionKey> = Object.freeze([
	'tenantName',
	'buildingAddress',
	'city',
	'state',
	'spaceType',
	'transactionSize',
	'startingRent',
]);

const ONE_FROM_LISTS_REQUIRED = Object.freeze([
	// At least one field from each list must be filled for the form to be submittable.
	['leaseTerm', 'expirationDate'],
	['executionDate', 'commencementDate'],
] as const);

const TWO_ASTERISK_KEYS: ReadonlyArray<SubmissionKey> = Object.freeze(
	ONE_FROM_LISTS_REQUIRED.flat()
);

const DATE_FIELDS = [
	'executionDate',
	'commencementDate',
	'expirationDate',
] as const;
