import React, { useCallback } from 'react';
import {
	TrendFilters,
	TrendMetricFilter,
	TrendTimespanFilter,
	TrendMovingAverageFilter,
} from 'PortfolioAnalytics/portfolioAnalyticsTypes';
import Dropdown, { DropdownContainer, DropdownItem } from '../Dropdown';
import { useMediaQuery } from 'react-responsive';
import { MQB } from '@compstak/ui-kit';
import IconTrendLine from 'ui/svg_icons/trend_line.svg';
import IconCalendar from 'ui/svg_icons/calendar.svg';

type MarketTrendFiltersProps = {
	filters: TrendFilters;
	setMarketTrendFilters: React.Dispatch<React.SetStateAction<TrendFilters>>;
	isBubbleChart: boolean;
	isLoading?: boolean;
	isBenchmarkingTrend?: boolean;
};

export const METRIC_DROPDOWN_DATA: Array<DropdownItem<TrendMetricFilter>> = [
	{ id: 'effectiveRent', value: 'Effective Rent' },
	{ id: 'startingRent', value: 'Starting Rent' },
	{ id: 'freeMonths', value: 'Free Rent' },
	{ id: 'ti', value: 'Tenant Improvement' },
	{ id: 'leaseTerm', value: 'Lease Term' },
];
const timeSpanDropdownData: Array<DropdownItem<TrendTimespanFilter>> = [
	{ id: 15, value: '15 years' },
	{ id: 10, value: '10 years' },
	{ id: 5, value: '5 years' },
	{ id: 3, value: '3 years' },
	{ id: 1, value: '1 year' },
];
const movingAverageDropdownData: Array<DropdownItem<TrendMovingAverageFilter>> =
	[
		{ id: 3, value: '3 Month Moving Avg.' },
		{ id: 6, value: '6 Month Moving Avg.' },
		{ id: 12, value: '12 Month Moving Avg.' },
	];

const metricDropdownDataWithIcon = METRIC_DROPDOWN_DATA.map((item) => ({
	...item,
	icon: <IconTrendLine />,
}));
const timeSpanDropdownDataWithIcon = timeSpanDropdownData.map((item) => ({
	...item,
	icon: <IconCalendar />,
}));

const DATA_QA_ID = {
	metric: 'metric-selector',
	timeSpan: 'time-span-selector',
	movAvg: 'mov-avg-selector',
};

export const PortfolioTrendFiltersComponent = ({
	filters,
	setMarketTrendFilters,
	isBubbleChart,
	isLoading,
}: MarketTrendFiltersProps) => {
	const onChangeMetric = useCallback(
		(metric: TrendMetricFilter) =>
			setMarketTrendFilters((state) => ({ ...state, metric })),
		[setMarketTrendFilters]
	);
	const onChangeTimeSpan = useCallback(
		(timeSpan: TrendTimespanFilter) =>
			setMarketTrendFilters((state) => ({ ...state, timeSpan })),
		[setMarketTrendFilters]
	);
	const onChangeMovingAverage = useCallback(
		(movAverage: TrendMovingAverageFilter) =>
			setMarketTrendFilters((state) => ({ ...state, movAverage })),
		[setMarketTrendFilters]
	);

	const isMin1536 = useMediaQuery({
		minWidth: MQB.D_1536,
	});

	const isMin1920 = useMediaQuery({
		minWidth: MQB.D_1920,
	});

	if (isLoading) return null;

	return (
		<DropdownContainer data-html2canvas-ignore>
			<Dropdown
				width={isMin1920 ? 200 : isMin1536 ? 150 : 120}
				activeItemId={filters.metric}
				items={metricDropdownDataWithIcon}
				onSelect={onChangeMetric}
				displayArrow={false}
				dataQaId={DATA_QA_ID.metric}
			/>

			{!isBubbleChart && (
				<>
					<Dropdown
						width={isMin1536 ? 110 : 100}
						items={timeSpanDropdownDataWithIcon}
						onSelect={onChangeTimeSpan}
						activeItemId={filters.timeSpan}
						displayArrow={false}
						dataQaId={DATA_QA_ID.timeSpan}
					/>
					<Dropdown
						width={isMin1536 ? 110 : 100}
						items={movingAverageDropdownData}
						onSelect={onChangeMovingAverage}
						activeItemId={filters.movAverage}
						displayArrow={true}
						dataQaId={DATA_QA_ID.movAvg}
					/>
				</>
			)}
		</DropdownContainer>
	);
};
