import {
	Button,
	Flex,
	Plus as IconPlus,
	Trash as IconTrash,
} from '@compstak/ui-kit';
import { useQueryClient } from '@tanstack/react-query';
import { withErrorBoundaryDefault } from 'Components/ErrorBoundary';
import { useShowUploadSuccessModal } from 'Components/Modals/UploadSuccessModal/UploadSuccessModal';
import { useUserQuery } from 'api';
import { invalidateUserQuery } from 'hooks/userHooks';
import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useAppDispatch } from 'util/useAppDispatch';
import { useAppSelector } from 'util/useAppSelector';
import { pick } from 'utils';
import { sendSubmission, updateSaleSubmissionFormState } from '../actions';
import { SaleAdditionalAddress, SaleSubmission } from '../types';
import {
	currentYear,
	getNumsInRange,
	isManualCompFormValid,
} from '../utils/manualUploadFormUtils';
import { getLocaleForUserMarketId } from '../utils/submissionLocale';
import { getSaleSubmissionMapFromAppStore } from '../utils/submissionMap';
import {
	DEFAULT_UPLOAD_FORM_FIELD,
	FieldProps,
	mappedUploadFormComponentsFactory,
} from './MappedComponents';
import { SubmitManualCompFormButton } from './SubmitManualCompButton';
import {
	AsteriskCount,
	HorizontalLine,
	INTEREST_TYPE_OPTIONS,
	LabeledInput,
	ManualUploadFormBody,
	PROPERTY_TYPE_OPTIONS,
	RequiredFieldsAsteriskLegend,
	Row12,
	RowElementSC,
	STATE_SELECT_OPTIONS,
	ShowAdditionalFieldsButton,
} from './UploadFormCommon';
import { usePropertySubtypeOptions } from './usePropertySubtypeOptions';

export const SaleCompUploadForm =
	withErrorBoundaryDefault(SaleUploadFormUnsafe);
SaleCompUploadForm.displayName = 'SaleCompUploadForm';

function SaleUploadFormUnsafe() {
	const dispatch = useAppDispatch();
	const submission = useAppSelector((state) => state.uploads.saleSubmission);
	const isFormDataValid =
		isManualCompFormValid({
			submission,
			requiredKeys: REQUIRED_KEYS,
			oneFromListsRequired: ONE_FROM_LISTS_REQUIRED,
			dateFields: ['saleDate'],
		}) && isAdditionalAddressesValid(submission);
	const user = useUserQuery().data;
	const [extraFieldsShown, setExtraFieldsShown] = useState(false);
	const isCondoSale =
		useAppSelector((store) => store.uploads.saleSubmission?.condo ?? '') ===
		'yes';
	const isPortfolioSale =
		useAppSelector(
			(store) => store.uploads.saleSubmission?.isPortfolio ?? ''
		) === 'yes';
	const queryClient = useQueryClient();
	const showUploadSuccessModal = useShowUploadSuccessModal();

	const submitBtnJsx = (
		<SubmitManualCompFormButton
			disabled={!isFormDataValid}
			onClick={() => {
				if (!submission) {
					throw new Error('No sale submission found');
				}
				const additionalAddressesFormatted = submission.isPortfolio
					? submission.additionalAddresses
							.map(
								(addr) => `${addr.buildingAddress} ${addr.city} ${addr.state}`
							)
							.join(', ')
					: '';
				const formattedSubmission = {
					...submission,
					dataType: 'sales',
					locale: getLocaleForUserMarketId(user?.primaryMarketId ?? -1),
					additionalAddresses: additionalAddressesFormatted,
				};
				dispatch(
					sendSubmission({
						submission: formattedSubmission,
						onSuccess: (compsAwarded) => {
							showUploadSuccessModal({ compsAwarded: compsAwarded });
							invalidateUserQuery(queryClient);
						},
					})
				);
			}}
		>
			Submit Comps
		</SubmitManualCompFormButton>
	);
	const requiredFieldsLegend = (
		<RequiredFieldsAsteriskLegend twoAsteriskText="At least one size (Building or Lot), Buyer (or Recorded Buyer), Seller (or Recorded Seller), and Price (Total or $/SqFt) is required" />
	);

	const propertySubtypeOptions = usePropertySubtypeOptions();

	return (
		<ManualUploadFormBody>
			<Row12>
				<FormInput width={6} name="buildingAddress" avoidAutofillByName />
				<FormInput width={4} name="city" avoidAutofillByName />
				<FormSelect
					width={2}
					name="state"
					options={STATE_SELECT_OPTIONS}
					avoidAutofillByName
				/>
			</Row12>
			<Row12>
				<FormDate width={12} name="saleDate" yearOptions={SALE_DATE_OPTIONS} />
			</Row12>
			<Row12>
				<FormInput width={6} name="buildingSize" />
				<FormInput width={6} name="lotSize" />
			</Row12>
			<Row12>
				<FormInput width={6} name="buyer" />
				<FormInput width={6} name="recordedBuyer" />
			</Row12>
			<Row12>
				<FormInput width={6} name="seller" />
				<FormInput width={6} name="recordedSeller" />
			</Row12>
			<HorizontalLine />
			<Row12>
				<FormInput width={3} name="totalSalePrice" />
				<FormInput
					width={3}
					name="salePricePsf"
					labelOverride="Sales Price"
					postUnitOverride="PSF"
				/>
				<FormInput width={3} name="totalAskingSalePrice" />
				<FormInput width={3} name="askingSalePricePsf" />
			</Row12>
			<Row12>
				<FormInput width={3} name="capRate" avoidAutofillByName />
				<FormInput width={9} name="capRateNotes" avoidAutofillByName />
			</Row12>
			<Row12>
				<FormInput width={3} name="totalNetOperatingIncome" />
				<FormInput width={3} name="netOperatingIncomePsf" />
				<FormInput
					width={3}
					name="totalOperatingExpenses"
					labelOverride="Total Operating Expenses"
				/>
				<FormInput width={3} name="operatingExpensesValue" />
			</Row12>
			<Row12>
				<FormInput width={12} name="comments" />
			</Row12>
			{!extraFieldsShown ? (
				<>
					{requiredFieldsLegend}
					<Flex justifyContent="center" alignItems="center">
						<ShowAdditionalFieldsButton
							onClick={() => setExtraFieldsShown(!extraFieldsShown)}
						/>
					</Flex>
					{submitBtnJsx}
				</>
			) : (
				<>
					<HorizontalLine />
					<Row12>
						<FormSelect
							width={6}
							name="buildingPropertyType"
							options={PROPERTY_TYPE_OPTIONS}
						/>
						<FormSelect
							width={6}
							name="buildingPropertySubtype"
							options={propertySubtypeOptions}
						/>
					</Row12>
					<Row12>
						<FormInput width={6} name="interestPercentage" />
						<FormSelect
							width={6}
							name="interestType"
							options={INTEREST_TYPE_OPTIONS}
						/>
					</Row12>
					<Row12>
						<FormInput width={12} name="parcelNumber" />
					</Row12>
					<Row12>
						<FormInput width={12} name="financingInfo" />
					</Row12>
					<Row12>
						<FormInput width={12} name="propertyRights" />
					</Row12>
					<Row12>
						<FormInput width={6} name="buyerRepBrokers" />
						<FormInput width={6} name="buyerRepCompanies" />
					</Row12>
					<Row12>
						<FormInput width={6} name="sellerRepBrokers" />
						<FormInput width={6} name="sellerRepCompanies" />
					</Row12>
					<HorizontalLine />
					<Row12>
						<RadioLabel width={2}>Is a Portfolio Sale</RadioLabel>
						<FormRadio name="isPortfolio" width={3} hideLabel />
					</Row12>
					{isPortfolioSale && (
						<>
							<Row12>
								<FormInput width={6} name="numberOfBuildings" />
								<FormInput width={6} name="transactionSize" />
							</Row12>
							<SalePortfolioAddresses />
						</>
					)}
					<HorizontalLine />
					<Row12>
						<RadioLabel width={2}>Is a Condo Sale</RadioLabel>
						<FormRadio name="condo" width={3} hideLabel />
					</Row12>
					{isCondoSale && (
						<Row12>
							<FormInput width={4} name="floorOccupancies" />
							<FormInput width={4} name="suite" />
							<FormInput width={4} name="transactionSize" />
						</Row12>
					)}
					<HorizontalLine />
					{requiredFieldsLegend}
					{submitBtnJsx}
				</>
			)}
		</ManualUploadFormBody>
	);
}

function SalePortfolioAddresses() {
	const addresses = useAppSelector(
		(state) => state.uploads.saleSubmission?.additionalAddresses ?? []
	);
	const lastAddrKey = Math.max(...addresses.map((addr) => addr.key), 0);
	const newAddress = (): SaleAdditionalAddress => ({
		buildingAddress: '',
		city: '',
		state: '',
		key: lastAddrKey + 1,
	});
	const dispatch = useAppDispatch();
	const setAddresses = useCallback(
		(newAddresses: Array<SaleAdditionalAddress>) => {
			dispatch(
				updateSaleSubmissionFormState('additionalAddresses', newAddresses)
			);
		},
		[dispatch]
	);
	const updateAddress = <K extends keyof SaleAdditionalAddress>(
		key: number,
		field: K,
		value: SaleAdditionalAddress[K]
	) => {
		const newAddresses = addresses.map((addr) =>
			addr.key === key ? { ...addr, [field]: value } : addr
		);
		setAddresses(newAddresses);
	};

	return (
		<>
			{addresses.map((addr, idx) => (
				<Row12 key={addr.key}>
					{(
						[
							{
								name: 'buildingAddress',
								width: 6,
								label: 'Street Address #' + (idx + 2),
							},
							{ name: 'city', width: 3, label: 'City' },
							{ name: 'state', width: 2, label: 'State' },
						] as const
					).map(({ name, label, width }) => (
						<LabeledInput
							key={name}
							width={width}
							name={name}
							label={label}
							avoidAutofillByName
							unit=""
							postUnit=""
							asteriskCount={1}
							value={addr[name]}
							onChange={(ev) => updateAddress(addr.key, name, ev.target.value)}
						/>
					))}
					<RowElementSC width={1} padding="24px 0 0 0">
						<DeleteButton
							variant="ghost2"
							onClick={() => {
								setAddresses(addresses.filter((a) => a.key !== addr.key));
							}}
						>
							<IconTrash />
						</DeleteButton>
					</RowElementSC>
				</Row12>
			))}
			<Row12>
				<RowElementSC width={4}>
					<Button
						variant="ghost2"
						onClick={() => {
							setAddresses([...addresses, newAddress()]);
						}}
					>
						<IconPlus />
						Additional Addresses
					</Button>
				</RowElementSC>
			</Row12>
		</>
	);
}

type SubmissionKey = keyof Omit<SaleSubmission, 'additionalAddresses'>;

function useFormField(name: SubmissionKey): FieldProps {
	const dispatch = useAppDispatch();
	const fieldConfigData = useAppSelector(
		(store) =>
			getSaleSubmissionMapFromAppStore(store)[name] ?? DEFAULT_UPLOAD_FORM_FIELD
	);
	const value: string = useAppSelector(
		(store) => store.uploads.saleSubmission?.[name] ?? ''
	);
	const asteriskCount = ((): AsteriskCount => {
		if (REQUIRED_KEYS.includes(name)) return 1;
		if (TWO_ASTERISK_KEYS.includes(name)) return 2;
		return 0;
	})();
	return {
		...pick(fieldConfigData, 'label', 'unit', 'postUnit'),
		value,
		asteriskCount,
		update: (newValue: string) => {
			dispatch(updateSaleSubmissionFormState(name, newValue));
		},
	};
}

const { FormInput, FormSelect, FormRadio, FormDate } =
	mappedUploadFormComponentsFactory<SubmissionKey>({
		useFormField,
		displayNamePrefix: 'Sale',
	});

const RadioLabel = styled(RowElementSC)`
	display: flex;
	align-items: center;
`;

const DeleteButton = styled(Button)`
	height: 48px;
	border: 1px solid ${({ theme }) => theme.colors.neutral.n50};
	svg {
		color: ${({ theme }) => theme.colors.red.red500};
	}
`;

function isAdditionalAddressesValid(submission: SaleSubmission): boolean {
	if (submission.isPortfolio !== 'yes') return true;
	return submission.additionalAddresses.every(
		(addr) =>
			addr.buildingAddress.trim() !== '' &&
			addr.city.trim() !== '' &&
			addr.state.trim() !== ''
	);
}

const SALE_DATE_OPTIONS = getNumsInRange({
	start: currentYear() - 10,
	stop: currentYear(),
	asc: false,
}).map((year) => ({
	label: String(year),
	value: String(year),
}));

const REQUIRED_KEYS: Readonly<Array<SubmissionKey>> = Object.freeze([
	'buildingAddress',
	'city',
	'state',
	'saleDate',
]);

const ONE_FROM_LISTS_REQUIRED = Object.freeze([
	// At least one field from each list must be filled for the form to be submittable.
	['buildingSize', 'lotSize'], // at least one size
	['buyer', 'recordedBuyer'], // at least one buyer
	['seller', 'recordedSeller'], // at least one seller
	['totalSalePrice', 'salePricePsf'], // at least one price
] as const);

const TWO_ASTERISK_KEYS: ReadonlyArray<SubmissionKey> = Object.freeze(
	ONE_FROM_LISTS_REQUIRED.flat()
);
