import { useUserIsSalesTrader } from 'hooks/userHooks';
import { useAppSelector } from 'util/useAppSelector';
import { CreditAwardCard } from './Components/CreditAwardCard';
import { DragAndDropComp } from './Components/DragAndDropComp';
import { RequiredCompFieldsSection } from './Components/RequiredCompFields';
import { WrapFlex } from './Components/Ui';
import UploadFormsContainer from './Components/UploadFormsContainer';
import { UploadPageRouteProps } from './types';
import * as SC from './uploadPage.styles';
import { CompInfoBadges } from './Components/CompInfoBadges';
import { CreditAwardNotes } from './Components/CreditAwardNotes';
import { AllRequiredFieldsLi } from './Components/AllRequiredFieldsLi';

export function UploadPage({ params }: UploadPageRouteProps) {
	const salesTrader = useUserIsSalesTrader();
	const uploadFormVisible = useAppSelector(
		(store) => store.uploads.uploadFormVisible
	);

	if (uploadFormVisible) {
		return <UploadFormsContainer params={params} />;
	}

	return (
		<SC.Page>
			<SC.Content>
				<SC.GreenHeader>
					EARN CREDITS FOR YOUR {salesTrader ? '' : 'LEASE AND '}SALE COMPS
				</SC.GreenHeader>
				<SC.CreditsWrapper>
					<CompInfoBadges />
					<SC.CreditsContent>
						<CreditAwardCards />
						<DragAndDropComp />
					</SC.CreditsContent>
				</SC.CreditsWrapper>
				<RequiredCompFieldsSection />
				<SC.Footer>
					<div>Still have questions? Contact us:</div>
					<div>Email: credits@compstak.com</div>
					<div>Call: 612-729-9183</div>
				</SC.Footer>
			</SC.Content>
		</SC.Page>
	);
}

function CreditAwardCards() {
	const defaultBonusDescription = useDefaultBonusSectionDescription();
	const defaultBonus = {
		// Bonuses use to be different per card.
		// They changed a lot and they might change in the future too,
		// so I'm not hardcoding them yet in the CreditAwardCard implementation.
		bonusAward: 25,
		description: defaultBonusDescription,
	};
	return (
		<SC.CreditsAwardsCards>
			<WrapFlex justifyContent="center" gap="24px" marginBottom="16px">
				<CreditAwardCard
					awardCredits={50}
					requirements={
						<ul>
							<AllRequiredFieldsLi />
							<li>
								has a transaction date <SC.Bi>within the past 30 days</SC.Bi>
							</li>
						</ul>
					}
					bonus={defaultBonus}
				/>
				<CreditAwardCard
					awardCredits={25}
					requirements={
						<ul>
							<AllRequiredFieldsLi />
							<li>
								has a transaction date <SC.Bi>within</SC.Bi> the past 12 months
							</li>
						</ul>
					}
					bonus={defaultBonus}
				/>
				<CreditAwardCard
					awardCredits={5}
					requirements={
						<ul>
							<AllRequiredFieldsLi />
							<li>
								has a transaction date <b>older</b> than 12 months
							</li>
						</ul>
					}
					bonus={defaultBonus}
				/>
			</WrapFlex>
			<CreditAwardNotes />
		</SC.CreditsAwardsCards>
	);
}

function useDefaultBonusSectionDescription(): string[] {
	const salesTrader = useUserIsSalesTrader();
	return [
		...(!salesTrader ? ['3 lease comp premium fields*'] : []),
		'2 sale comp premium fields*',
	];
}
