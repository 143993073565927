import { APIClientResponse } from '@compstak/common';
import { Flex } from '@compstak/ui-kit';
import { MutateOptions } from '@tanstack/react-query';
import { SpaceTypeName } from 'api';
import { SummaryFeedbackUpdate } from 'api/marketSummary';
import React, { useState } from 'react';
import { FeedbackRequest, ThumbStatus } from 'types/marketSummary';
import {
	ModalContainer,
	ModalInputLabelStyled,
	ModalParagraphStyled,
} from '../UI';
import { useModal } from 'providers/ModalProvider';
import {
	ModalButton,
	ModalButtons,
	ModalParagraph,
	ModalTitle,
} from 'Components/Modals/common/UI';
import { useToast } from 'providers/ToastProvider';

type Props = {
	updateFeedbackMutation: (
		variables: SummaryFeedbackUpdate,
		options?:
			| MutateOptions<
					APIClientResponse<unknown>,
					unknown,
					SummaryFeedbackUpdate,
					void
			  >
			| undefined
	) => void;
	marketId: number;
	spaceType: SpaceTypeName;
	userId: number;
	thumbStatus: ThumbStatus;
};

export const MarketSummaryModal = ({
	updateFeedbackMutation,
	marketId,
	spaceType,
	userId,
	thumbStatus,
}: Props) => {
	const [feedbackRequest, setFeedbackRequest] = useState<FeedbackRequest>({
		comments: '',
		helpfulInsights: false,
		importantTopics: false,
		positive: thumbStatus,
		informativeInsights: false,
	});

	const handleCheckboxChange = (
		name: keyof Omit<FeedbackRequest, 'comments' | 'positive'>,
		checked: boolean
	) => {
		setFeedbackRequest((prevFeedbackData) => ({
			...prevFeedbackData,
			[name]: checked,
		}));
	};

	const isButtonDisabled = Object.entries(feedbackRequest)
		.filter(([key]) => key !== 'positive')
		.every(([, value]) => !value);

	const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setFeedbackRequest((prevFeedbackData) => ({
			...prevFeedbackData,
			[e.target.name]: e.target.value,
		}));
	};

	const feedbackOptions: {
		id: keyof Omit<FeedbackRequest, 'comments' | 'positive'>;
		label: string;
		checked: boolean | undefined;
	}[] = [
		{
			id: 'importantTopics',
			label: thumbStatus
				? 'The summary topics were important to me'
				: "The summary topics aren't important to me",
			checked: feedbackRequest?.importantTopics,
		},
		{
			id: 'informativeInsights',
			label: thumbStatus
				? 'The insights provided me with new information'
				: 'The insights did not provide me with new information',
			checked: feedbackRequest?.informativeInsights,
		},
		{
			id: 'helpfulInsights',
			label: thumbStatus
				? 'The insights provided would help me with my work'
				: 'The insights would not help me with my work',
			checked: feedbackRequest?.helpfulInsights,
		},
	];

	const question = thumbStatus
		? 'Which of these did you like about the market summary?'
		: 'Which of these did you not like about the market summary?';

	const { closeModal } = useModal();
	const { addToast } = useToast();

	return (
		<>
			<ModalTitle>Thanks for your Feedback!</ModalTitle>
			<ModalContainer>
				<form>
					<ModalParagraph>
						Thanks for letting us know how you feel about our Market Summary
						beta. To make this feature more impactful, please answer the
						question below (optional):
					</ModalParagraph>

					<ModalParagraph>{question}</ModalParagraph>

					{feedbackOptions.map((option) => {
						return (
							<React.Fragment key={option.id}>
								<Flex
									alignItems="center"
									justifyContent="flex-start"
									gap="0.5rem"
								>
									<input
										type="checkbox"
										id={option.id}
										checked={option.checked}
										onChange={(ev: React.FormEvent<HTMLInputElement>) =>
											handleCheckboxChange(option.id, ev.currentTarget.checked)
										}
									/>

									<ModalInputLabelStyled htmlFor={option.id}>
										{option.label}
									</ModalInputLabelStyled>
								</Flex>
							</React.Fragment>
						);
					})}

					<ModalParagraphStyled>
						Let us know if you have any additional thoughts or questions
					</ModalParagraphStyled>
					<textarea
						placeholder="I also want to mention"
						name="comments"
						onChange={handleTextareaChange}
					></textarea>

					<Flex gap="1rem" justifyContent="flex-end">
						<ModalButtons>
							<ModalButton
								onClick={() => {
									addToast({
										title: 'Thanks for your Feedback!',
									});

									updateFeedbackMutation({
										marketId,
										spaceType,
										userId,
										body: feedbackRequest,
									});

									closeModal();
								}}
								disabled={isButtonDisabled}
							>
								Submit feedback
							</ModalButton>
						</ModalButtons>
					</Flex>
				</form>
			</ModalContainer>
		</>
	);
};

MarketSummaryModal.displayName = 'MarketSummaryModal';
