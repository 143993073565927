import { PortfolioStats } from '../Components/PortfolioStats';
import { ListMapToggler, View } from '../Components/ListMapToggler';
import { BUILDING_CLASS_ID_TO_NAME, PortfolioV2 } from 'api';
import { PROPERTY_TYPE_ID_TO_NAME } from 'api';
import { useNavigate } from 'react-router';
import { useMemo } from 'react';
import { routes, PortfolioByIdRouteSearchParams } from 'router';
import { styled } from 'styled-components';
import { BackToDashboardArrowLeft, BackToDashboardLink, Toolbar } from '../UI';
import { MQB } from '@compstak/ui-kit';
import { SQFT_RANGES_TO_BUILDING_SIZE_FILTER } from 'PortfolioAnalytics/Charts/PropertyCountByPropertySqftChart';
import { isEqual } from 'lodash';
import {
	RISK_BOUNDARIES,
	PERFORMANCE_BOUNDARIES,
} from 'PortfolioAnalytics/Charts/chartsConstants';

type Props = {
	view: View;
	portfolio: PortfolioV2;
	searchParams: PortfolioByIdRouteSearchParams;
};

export const ListViewAndMapViewToolbar = ({
	view,
	portfolio,
	searchParams,
}: Props) => {
	const stats = {
		activeLeaseCount: portfolio.metrics.activeLeaseCount,
		propertyCount: portfolio.metrics.propertyCount,
		totalSqFt: portfolio.metrics.totalSqFt,
	};

	const isLeasesList =
		view === 'list' &&
		(!!searchParams.expirationYears ||
			!!searchParams.tenantNames ||
			!!searchParams.tenantIndustry);

	const navigate = useNavigate();

	const displayedAppliedFilters = useMemo(() => {
		const mappings = [
			{
				key: 'tenantNames',
				label: 'Tenant Name',
				value: searchParams.tenantNames?.[0],
			},
			{
				key: 'tenantIndustry',
				label: 'Industry',
				value:
					searchParams.tenantIndustry && searchParams.tenantIndustry?.length > 1
						? 'Other Industries'
						: searchParams.tenantIndustry?.[0],
			},
			{
				key: 'expirationYears',
				label: 'Expiration Year',
				value: searchParams.expirationYears?.[0],
			},
			{
				key: 'spaceTypes',
				label: 'Space Type',
				value: searchParams.spaceTypes?.[0],
			},
			{
				key: 'propertyTypeIds',
				label: 'Property Type',
				value: searchParams.propertyTypeIds?.[0]
					? PROPERTY_TYPE_ID_TO_NAME[searchParams.propertyTypeIds?.[0]]
					: undefined,
			},
			{
				key: 'buildingClassIds',
				label: 'Building Class',
				value: searchParams.buildingClassIds?.[0]
					? BUILDING_CLASS_ID_TO_NAME[searchParams.buildingClassIds?.[0]]
					: undefined,
			},
			{
				key: 'buildingSize',
				label: 'Building Size',
				value: searchParams.buildingSize
					? Object.typedEntries(SQFT_RANGES_TO_BUILDING_SIZE_FILTER).find(
							(entry) => isEqual(searchParams.buildingSize, entry[1])
						)?.[0]
					: undefined,
			},
			{
				key: 'performanceMax',
				label: 'Performance',
				value: searchParams.performanceMax
					? searchParams.performanceMax >= PERFORMANCE_BOUNDARIES.HIGH.MIN
						? 'High'
						: 'Low'
					: undefined,
			},
			{
				key: 'riskMax',
				label: 'Exp. Risk',
				value: searchParams.riskMax
					? searchParams.riskMax >= RISK_BOUNDARIES.HIGH.MIN
						? 'High'
						: 'Low'
					: undefined,
			},
		] satisfies {
			key: keyof PortfolioByIdRouteSearchParams;
			label: string;
			value?: string | number;
		}[];
		const match = mappings.filter(({ value }) => value);
		return match.map((match) => `${match.label}: ${match.value}`);
	}, [searchParams]);

	const title = `${isLeasesList ? 'Leases' : 'Properties'}${displayedAppliedFilters.length ? ` • ${displayedAppliedFilters.join(' • ')}` : ''}`;

	return (
		<Toolbar>
			<Container>
				<BackToDashboardLink
					to={routes.portfolioByIdView.toHref({
						portfolioId: portfolio.portfolio.id,
						viewType: 'dashboard',
					})}
				>
					<BackToDashboardArrowLeft />
					Back to Dashboard
				</BackToDashboardLink>
				<PortfolioStats stats={stats} />
			</Container>
			<Spacer />
			<Container>
				<ListTitle>{title}</ListTitle>
				<ListMapToggler
					view={view}
					onViewClick={(view) =>
						navigate(
							routes.portfolioByIdView.toHref(
								{
									portfolioId: portfolio.portfolio.id,
									viewType: view,
								},
								searchParams
							)
						)
					}
				/>
			</Container>
		</Toolbar>
	);
};

const Container = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 0.5rem;
`;

const Spacer = styled.div`
	height: 1rem;
`;

const ListTitle = styled.span`
	font-size: 1rem;
	font-style: normal;
	font-weight: 325;
	line-height: 1.5rem;
	letter-spacing: 0.215px;
	color: ${({ theme }) => `${theme.colors.gray.gray700}`};
	overflow: hidden;
	max-width: 500px;
	white-space: nowrap;
	text-overflow: ellipsis;
	@media (min-width: ${MQB.D_1280}) {
		max-width: 800px;
		font-size: 1.25rem;
	}
`;
