import { SimpleTableColumn, TooltipV2 } from '@compstak/ui-kit';
import { MufaFloorPlan } from 'api';
import { nullValueText } from '@compstak/common';
import { TableCellGapper } from 'Components/UI';
import { formatInteger, formatPercent } from 'format';
// TODO: deprecated icon - use 'ui/svg_icons/help_v2.svg' instead
import IconHelp from 'ui/svg_icons/help.svg';
import { formatMoneyMufa } from './utils';

export const getMufaTableColumns = () => {
	const columns: SimpleTableColumn<MufaFloorPlan>[] = [
		{
			id: 'unitType',
			header: 'Unit Type',
			body: ({ row }) => `${row.bedrooms} Bedroom/${row.bathrooms} Bath`,
			width: 200,
			flex: 2,
		},
		{
			id: 'unitSqFt',
			header: () => (
				<TableCellGapper justifyContent="center">
					Unit Size (SqFt)
					<TooltipV2 content={TOOLTIPS.unitSqFt}>
						<div>
							<IconHelp width={12} />
						</div>
					</TooltipV2>
				</TableCellGapper>
			),
			body: ({ row }) => formatInteger(row.unitSqFt, { shorten: false }),
			width: 100,
			flex: 1,
			hAlign: 'center',
		},
		{
			id: 'unitCount',
			header: 'Total Units',
			body: ({ row }) => row.unitCount,
			width: 100,
			hAlign: 'center',
			flex: 1,
		},
		{
			id: 'askingRentPerUnit',
			header: () => (
				<TableCellGapper justifyContent="center">
					Asking Rent $/Unit
					<TooltipV2 content={TOOLTIPS.askingRentPerUnit}>
						<div>
							<IconHelp width={12} />
						</div>
					</TooltipV2>
				</TableCellGapper>
			),
			body: ({ row }) => formatMoneyMufa(row.askingRentPerUnit),
			width: 100,
			hAlign: 'center',
			flex: 1,
		},
		{
			id: 'askingRentPerSqFt',
			header: 'Asking Rent $/SqFt',
			body: ({ row }) => formatMoneyMufa(row.askingRentPerSqFt, 2),
			width: 100,
			hAlign: 'center',
			flex: 1,
		},
		{
			id: 'effectiveRentPerUnit',
			header: 'Effective Rent $/Unit',
			body: ({ row }) => formatMoneyMufa(row.effectiveRentPerUnit),
			width: 100,
			hAlign: 'center',
			flex: 1,
		},
		{
			id: 'effectiveRentPerSqFt',
			header: 'Effective Rent $/SqFt',
			body: ({ row }) => formatMoneyMufa(row.effectiveRentPerSqFt, 2),
			width: 100,
			hAlign: 'center',
			flex: 1,
		},
		{
			id: 'concessionValue',
			header: () => (
				<TableCellGapper justifyContent="center">
					Concessions ($)
					<TooltipV2 content={TOOLTIPS.concessionValue}>
						<div>
							<IconHelp width={12} />
						</div>
					</TooltipV2>
				</TableCellGapper>
			),
			body: ({ row }) => {
				if (row.concessionValue === 0) return nullValueText;
				return formatMoneyMufa(row.concessionValue);
			},
			width: 100,
			hAlign: 'center',
			flex: 1,
		},
		{
			id: 'concessionsPercentage',
			header: 'Concessions (%)',
			body: ({ row }) => {
				if (row.concessionsPercentage === 0) return nullValueText;
				return formatPercent(row.concessionsPercentage, 2);
			},
			width: 100,
			hAlign: 'center',
			flex: 1,
		},
	];

	return columns;
};

const TOOLTIPS = {
	unitSqFt: `Livable square footage of an apartment unit. RealPage typically tracks
	area using net square feet versus gross square feet. As such, patios,
	 garages or other non-livable square footage are not calculated. Units
		are averaged to the property using a weighted average.`,
	askingRentPerUnit: `The property owner’s average monthly rent, without taking into account concessions or discounts. 
	Rates reflect a property’s new-lease rent structure at the time of survey, 
	rather than the property’s actual revenues (which would be shaped by rents in place
	 when ongoing leases were signed). Rents are calculated for each property’s individual 
	 floorplans (e.g., one bedroom, 750 square feet), not by general floorplan category 
	 (e.g., one bedroom, 600 to 875 square feet). The highest and lowest rents for each 
	 floorplan are averaged (weighted average when unit-level detail is available). 
	 No adjustments are made for utilities or for specific amenities.`,
	concessionValue: `Average concession offered on asking rent, expressed as a prorated 
	discount in dollar terms during the period. Concessions include any 
	offered rental concession (such as one-month free rent) but do not 
	include marketing programs excluded from a rent roll, such as gift cards.`,
};
