import styled from 'styled-components';
import { Button, MQB } from '@compstak/ui-kit';
import { usePortfolioFilters } from '../../../PortfolioFiltersProvider';
import { BuildingClassFilter } from './BuildingClassFilter';
import { LeaseExpirationFilter } from './LeaseExpirationFilter';
import { PropertyTypeFilter } from './PropertyTypeFilter';
import { SubmarketFilter } from './SubmarketFilter';
import { BuildingSizeFilter } from './BuildingSizeFilter';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { SpaceTypeFilter } from './SpaceTypeFilter';

type Props = {
	disabled?: boolean;
};

export const PortfolioFilters = ({ disabled }: Props) => {
	const { portfolioMarkets, resetFilters } = usePortfolioFilters();
	const { portfolioRevampBenchmarkingFF } = useFeatureFlags();

	return (
		<FiltersContainer disabled={disabled}>
			<SubmarketFilter portfolioMarkets={portfolioMarkets} />
			{portfolioRevampBenchmarkingFF ? (
				<SpaceTypeFilter />
			) : (
				<PropertyTypeFilter />
			)}
			<BuildingClassFilter />
			<BuildingSizeFilter label="Building Size" emptyValueLabel="All" />
			<Spacer>
				<LeaseExpirationFilter />
			</Spacer>
			<ResetFiltersButton variant="ghost" onClick={resetFilters}>
				Reset filters
			</ResetFiltersButton>
		</FiltersContainer>
	);
};

const Spacer = styled.div`
	@media screen and (max-width: ${MQB.D_1280}) {
		margin-right: 1rem;
	}
	margin-right: 2rem;
`;

const FiltersContainer = styled.div<{ disabled?: boolean }>`
	display: flex;
	flex-wrap: wrap;
	gap: 0.5rem;
	font-size: 0.875em;
	margin-top: 1.25rem;
	margin-bottom: 0.5rem;
	align-items: center;

	${({ disabled, theme }) =>
		disabled &&
		`
	cursor: not-allowed;
	button {
		background-color: ${theme.colors.neutral.n20};
		pointer-events: none;
	}`}
`;

const ResetFiltersButton = styled(Button)`
	color: ${(p) => p.theme.colors.blue.blue500};
	font-size: 0.875em;
	text-transform: uppercase;
	font-weight: 325;
	line-height: 1.25em;
	letter-spacing: 0.3px;
	height: 44px;
	&:hover {
		background-color: ${(p) => p.theme.colors.gray.gray400TransparentCC};
	}
`;
