import { Button, MQB, TooltipV2 } from '@compstak/ui-kit';
import { useSendToAnalyticsBtn } from 'hooks/chartBuilderHooks';
import { FiltersObject } from 'models/filters/types';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { CompType } from 'types';
import MonitoringIcon from 'ui/svg_icons/monitoring.svg';
import abbreviateNumber from 'ui/util/abbreviateNumber';

type Props = {
	compType: CompType;
	count?: number;
	filters: FiltersObject;
};

export const SendToChartBuilderButton = ({
	compType,
	count,
	filters,
}: Props) => {
	const { isEnabled, href, tooltipText } = useSendToAnalyticsBtn({
		filters,
		compType,
	});
	const isLargeScreen = useMediaQuery({ minWidth: MQB.D_1920 });

	return (
		<TooltipV2 content={tooltipText}>
			<ButtonStyled
				variant="primary3"
				size="l"
				href={href}
				dataTestId="send-to-chartbuilder"
				disabled={!isEnabled}
			>
				<MonitoringIcon />
				{isLargeScreen && 'Send To Chartbuilder'}
				{!!count && count > 0 && ` (${abbreviateNumber(count)})`}
			</ButtonStyled>
		</TooltipV2>
	);
};

const ButtonStyled = styled(Button)`
	min-width: min-content;
	display: flex;
	gap: 0.25rem;
`;
