import { styled } from 'styled-components';
import IconPlusExtraLight from 'ui/svg_icons/plus_extra_light.svg';
import IconMinusExtraLight from 'ui/svg_icons/minus_extra_light.svg';
import IconResetExtraLight from 'ui/svg_icons/reset_extra_light.svg';
import { Button } from '@compstak/ui-kit';

const ZoomControlsContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1px;
	align-items: center;
	margin-bottom: 7%;
`;

const ZoomControlButton = styled(Button)`
	display: flex;
	justify-content: center;
	align-items: center;
	background: ${({ theme }) => theme.colors.gray.gray700};
	border-radius: 1.667px 1.667px 0px 0px;
	padding: 2px;
`;

type ZoomControlProps = {
	handleZoom: ({ zoomOut }: { zoomOut: boolean }) => void;
	handleResetZoom: () => void;
};

export const PropertyPerformanceMatrixZoomControls = ({
	handleZoom,
	handleResetZoom,
}: ZoomControlProps) => {
	return (
		<ZoomControlsContainer>
			<ZoomControlButton
				fitContent={true}
				onClick={() => handleZoom({ zoomOut: false })}
			>
				<IconPlusExtraLight />
			</ZoomControlButton>
			<ZoomControlButton
				fitContent={true}
				onClick={() => handleZoom({ zoomOut: true })}
			>
				<IconMinusExtraLight />
			</ZoomControlButton>
			<ZoomControlButton fitContent={true} onClick={handleResetZoom}>
				<IconResetExtraLight />
			</ZoomControlButton>
		</ZoomControlsContainer>
	);
};
