import { Flex, Spinner, defaultTheme } from '@compstak/ui-kit';
import styled from 'styled-components';

export const MapAnalyticsFormSection = styled.div`
	margin-bottom: 6px;
	font-size: 0.75rem;
`;

export const MapAnalyticsSectionTitle = styled.h2`
	color: #1a1d23;
	font-size: 10px;
	font-weight: 500;
	text-transform: uppercase;
	margin-bottom: 0.25rem;
`;

// TODO: ui-kit
export const RadioButton = styled.input.attrs({
	type: 'radio',
})`
	display: none;
	visibility: hidden;
	position: absolute;
	left: -9999px;

	& + label {
		display: flex;
		align-items: center;
		gap: 0.5rem;
		&:before {
			content: '';
			display: inline-block;
			width: 22px;
			height: 22px;
			background: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='11' cy='11' r='10.5' fill='white' stroke='%23E6E6E6'/%3E%3C/svg%3E%0A");
		}
	}
	&:checked + label {
		&:before {
			background: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='11' cy='11' r='10.5' fill='white' stroke='%23E6E6E6'/%3E%3Ccircle cx='11' cy='11' r='4' fill='%23438FF7'/%3E%3C/svg%3E%0A");
		}
	}
`;

export const RadioButtonLabel = styled.label`
	opacity: 1;
`;

export const FormControlOptionLabel = styled.span``;

export const MapAnalyticsTooltipTitle = styled.div`
	color: ${(p) => p.theme.colors.white.white};
	font-family: ${(p) => p.theme.typography.fontFamily.gotham};
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	letter-spacing: 1px;
	line-height: 14px;
`;

export const MapAnalyticsTooltipDescription = styled.span`
	color: ${(p) => p.theme.colors.white.white};
	font-family: ${(p) => p.theme.typography.fontFamily.gotham};
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	letter-spacing: 1px;
	line-height: 12px;
`;

export const MapAnalyticsLegendCircle = styled.div`
	width: 10px;
	height: 10px;
	border-radius: 10px;
	flex-shrink: 0;
`;

export const DashboardSettingsMenuContainer = styled.div`
	background-color: rgba(255, 255, 255, 0.9);
	box-shadow:
		0px 4px 12px rgba(50, 50, 71, 0.08),
		0px 16px 24px rgba(50, 50, 71, 0.08);
	border-radius: 3px;
	width: 180px;
	position: absolute;
	bottom: 38px;
	left: 8px;
	z-index: 3;
`;

export const DashboardSettingsForm = styled.form`
	font-size: 0.75rem;
	padding: 0.5rem;
	font-family: ${(p) => p.theme.typography.fontFamily.gotham};
`;

export const FormControl = styled.div`
	font-style: normal;
	font-weight: normal;
	line-height: 1rem;
	letter-spacing: 0.48px;
	color: ${(p) => p.theme.colors.gray.gray700};
	margin-bottom: 0.5rem;
	&:last-child {
		margin-bottom: 0;
	}
`;

export const AnalyticsErrorMessageContainer = styled.div`
	background-color: ${(p) => p.theme.colors.white.white};
	border-top: 2px solid #ef6647;
	box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.1);
	max-width: 210px;
	min-height: 200px;
	margin: 0 0 1rem;
	padding: 1rem;
`;

export const AnalyticsErrorMessageTitle = styled.h4`
	font-size: 16px;
	color: ${(p) => p.theme.colors.gray.gray500};
	margin-bottom: 1rem;
	font-family: ${(p) => p.theme.typography.fontFamily.gotham};
	text-align: center;
	font-weight: 400;
`;

export const AnalyticsErrorMessageDescription = styled.div`
	font-family: ${(p) => p.theme.typography.fontFamily.gotham};
	font-size: 12px;
	color: ${(p) => p.theme.colors.gray.gray700};
`;

export const MapSpinner = () => {
	return (
		<MapSpinnerContainer justifyContent="flex-end">
			<Spinner
				thickness="3px"
				color={defaultTheme.colors.gray.gray0}
				size="l"
			/>
		</MapSpinnerContainer>
	);
};

const MapSpinnerContainer = styled(Flex)`
	margin-top: 0.75rem;
	margin-right: 1rem;
`;

export const MapLegendContainer = styled.div`
	bottom: 0;
	right: 0;
	border-radius: 3px;
	margin: 0 1.25rem 1.75rem 1.25rem;
	position: absolute;
	max-width: 10rem;
	background-color: ${({ theme }) => theme.colors.white.white};
`;

export const MapButtonsContainer = styled.div`
	display: flex;
	bottom: 0;
	left: 0;
	gap: 1rem;
	margin: 0 1.25rem 1.75rem 1.25rem;
	position: absolute;
	max-width: 50%;
`;

export const MapCenteredContainer = styled.div`
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	padding: 0.75rem 1rem;
	border-radius: 3px;
	font-size: 1.25rem;
	background-color: ${({ theme }) => theme.colors.neutral.n500};
	color: ${({ theme }) => theme.colors.neutral.n70};
	font-family: ${({ theme }) => theme.typography.fontFamily.gotham};
`;
