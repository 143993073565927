import { CenterOnPoint, MapInteractivity, PinsLayer } from '@compstak/maps';
import { point } from '@turf/helpers';
import { MapWrapper } from 'maps/components/MapWrapper/MapWrapper';
import { memo } from 'react';
import styled from 'styled-components';
import { GeoPoint } from 'types/comp';

type CompMapImageProps = {
	geoPoint: GeoPoint;
};

const CompMapImage = memo<CompMapImageProps>(({ geoPoint }) => {
	const { lon, lat } = geoPoint;
	return (
		<Root>
			<MapWrapper
				interactivity={MapInteractivity.STATIC}
				attributionProps={{ style: { display: 'none' } }}
				mapDomain="leasePage"
			>
				<PinsLayer data={[point([lon, lat], {})]} />
				<CenterOnPoint latitude={lat} longitude={lon} duration={0} zoom={14} />
			</MapWrapper>
		</Root>
	);
});

export default CompMapImage;

CompMapImage.displayName = 'CompMapImage';

const Root = styled.div`
	width: 100%;
	height: 100%;
	&&& .mapboxgl-ctrl-logo {
		display: none;
	}
`;
