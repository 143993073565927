import React from 'react';
import button from 'ui/styles/button.less';
import spinner from 'ui/styles/spinner.less';
import ArrowRightIcon from '../../../../ui/svg_icons/arrow_right.svg';
import styles from '../styles/details.less';
import CompCredits from './CompCredits';
import CompVerify from './CompVerify';
import './ShareAComp';
import CompMapImage from './CompMapImage';
import styled from 'styled-components';
import { Apn } from 'Components/CompPageApn/Apn';
import { defaultTheme } from '@compstak/ui-kit';
import ShareIcon from 'ui/svg_icons/share-icon.svg';
import { useDispatch } from 'react-redux';
import { LeaseComp } from 'types';
import { useIsExchange } from 'hooks';
import { showMenu } from 'Singletons/Menu/actions';
import { ShareACompProps } from './ShareAComp';
import { Modal } from 'Components/Modals/common/UI';
import { MultiMarketUpgradeModal } from 'Components/Modals/UpgradeModal/MultiMarketUpgradeModal';
import { Market } from '@compstak/common';
import { CompSectionedMapping } from 'util/comp-format/src/format';
import { PermissionsState } from 'Pages/Login/reducers';
import { mapStoreToProps } from '..';
import { RouteComponentProps } from 'router/migration/types';
import { CompSections } from './CompSections';
import { CompInfoBarButtons } from './CompInfoBarButtons';
import { FeatureFlags } from 'api/featureFlags/types';

function LeaseFooterButton({
	// @ts-expect-error TS7031: Binding element 'comps' implic...
	comps,
	// @ts-expect-error TS7031: Binding element 'index' implic...
	index,
	// @ts-expect-error TS7031: Binding element 'newSearch' im...
	newSearch,
	// @ts-expect-error TS7031: Binding element 'search' impli...
	search,
	// @ts-expect-error TS7031: Binding element 'locationState...
	locationState,
	// @ts-expect-error TS7031: Binding element 'setNextClass'...
	setNextClass,
}) {
	let classNames = `${button.button} ${styles.nextOrNewSearchButton} ${styles.fullSection}`;
	const nextComp = comps[index + 1];

	if (!nextComp) {
		return (
			<a href={newSearch} className={classNames}>
				New Search
			</a>
		);
	} else if (nextComp) {
		if (search && search.total - 1 === index) {
			classNames += ` ${button.disabled}`;
		} else if (comps && comps.size - 1 === index) {
			classNames += ` ${button.disabled}`;
		}
		return (
			<a
				className={classNames}
				data-modal="true"
				data-replace="true"
				data-index={index + 1}
				data-query-string={locationState.queryString}
				onMouseEnter={setNextClass}
				href={`/comps/leases/${nextComp.id}`}
			>
				Next Lease Comp <ArrowRightIcon />
			</a>
		);
	} else {
		return (
			<a href={newSearch} className={classNames}>
				New Search
			</a>
		);
	}
}

type Props = {
	markets: Market[];
	comp: LeaseComp;
	sectionedAttrs: CompSectionedMapping;
	permissions: PermissionsState;
	featureFlags: FeatureFlags;
} & ReturnType<typeof mapStoreToProps> &
	RouteComponentProps;

type CompPanelState = typeof CompPanel.defaultProps;

export default class CompPanel extends React.Component<Props, CompPanelState> {
	static defaultProps = {
		newSearch: '/search/leases/home',
		getCredits: '/upload',
	};

	shouldComponentUpdate(props: Props, state: CompPanelState) {
		switch (true) {
			case state !== this.state:
			case props.comp !== this.props.comp:
			case props.search !== this.props.search:
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'comps' does not exist on type '{}'.
			case props.comps !== this.props.comps:
			case props.user !== this.props.user:
			case props.verifyComp !== this.props.verifyComp:
				return true;
		}
		return false;
	}

	compCredits() {
		// @ts-expect-error ts-migrate(2339) FIXME: Property 'user' does not exist on type 'Readonly<{... Remove this comment to see the full error message
		if (this.props.user.userType === 'exchange') {
			return <CompCredits {...this.props} />;
		} else {
			return false;
		}
	}

	createSections() {
		const sections = Object.keys(this.props.sectionedAttrs).filter(
			(s) => this.props.sectionedAttrs[s].length
		);

		return (
			<CompSections
				sections={sections}
				sectionedAttrs={this.props.sectionedAttrs}
				comp={this.props.comp}
			/>
		);
	}

	renderContent = () => {
		return (
			<div className={styles.dataContainer} data-columns>
				{this.createSections()}
			</div>
		);
	};

	hasPermission = () => {
		return this.props.permissions[this.props.comp.marketId].leaseAccess;
	};

	onUpgradeModalClose = () => {
		this.props.router.goBack();
	};

	render() {
		if (!this.props.comp) {
			return (
				<div className={`${styles.compPanel} ${styles.locked}`}>
					<div className={spinner.large} />
				</div>
			);
		}

		return this.hasPermission() ? (
			<div className={`${styles.panel} ${styles.locked}`}>
				<div className={styles.header}>
					<div className={styles.map}>
						<CompMapImage geoPoint={this.props.comp.geoPoint} />
					</div>
					<div className={styles.infoBar}>
						<Address lease={this.props.comp} />
						<div className={`${styles.rightSide} ${styles.actions}`}>
							<CompInfoBarButtons comp={this.props.comp} />
						</div>
					</div>
				</div>

				{this.compCredits()}

				<CompVerify {...this.props} compType="lease" />

				{this.renderContent()}

				<div className={styles.footer}>
					<div className={styles.meta}>
						<div className={styles.left}>
							<span className={styles.id}>
								Comp ID: {this.props.comp.id} | Created:{' '}
								{this.props.comp.dateCreated}
							</span>
						</div>
						<div className={styles.right}>
							<span className={styles.analyst}>
								Comp reviewed by CompStak Analyst, {this.props.comp.analyst}
							</span>
						</div>
					</div>
					{
						!this.props.featureFlags.LeaseCompUX23Q2 && (
							// @ts-expect-error ts-migrate(2740) FIXME: Type '{ children?: ReactNode; }' is missing the fo... Remove this comment to see the full error message
							<LeaseFooterButton {...this.props} />
						)
					}
				</div>
			</div>
		) : (
			<Modal onClose={this.onUpgradeModalClose}>
				<MultiMarketUpgradeModal
					compType="lease"
					selectedMarkets={[this.props.comp.marketId]}
					onClose={this.onUpgradeModalClose}
				/>
			</Modal>
		);
	}
}

const Address = ({ lease }: { lease: LeaseComp }) => {
	const dispatch = useDispatch();
	const isExchange = useIsExchange();

	return (
		<div className={styles.address}>
			<StreetAddressStyled className={`${styles.addressItem} ${styles.white}`}>
				<span>{lease.buildingName || lease.buildingAddress} </span>
				{isExchange && (
					<a
						data-qa-id="share-a-comp"
						data-tooltipdelay="0"
						data-tooltip="Share Comp"
						onClick={(event) =>
							dispatch(
								showMenu<ShareACompProps>(
									'share-a-comp',
									event.target,
									'below',
									{
										comp: lease,
										compType: 'lease',
									}
								)
							)
						}
					>
						<ShareIcon width={25} height={20} />
					</a>
				)}
			</StreetAddressStyled>
			{lease.buildingName && (
				<div className={`${styles.addressItem} ${styles.white}`}>
					{lease.buildingAddress}
				</div>
			)}
			<div className={styles.addressItem}>
				{lease.city}, {lease.state} {lease.zip}
			</div>
			<div className={styles.addressItem}>
				<Apn
					comp={lease}
					pendingProps={{ bgColor: defaultTheme.colors.neutral.n400 }}
				/>
			</div>
		</div>
	);
};

const StreetAddressStyled = styled.div`
	display: flex;
	gap: 30px;
`;
