import styled from 'styled-components';
import { MarketRentAnalyticsLegendVariant } from './types';
import { MapLegendContainer } from '../UI';

export const MarketRentAnalyticsLegendContainer = styled(MapLegendContainer)<{
	variant: MarketRentAnalyticsLegendVariant;
}>`
	min-width: ${(p) =>
		p.variant === MarketRentAnalyticsLegendVariant.MEDIUM
			? '170px'
			: undefined};
	min-height: ${(p) =>
		p.variant === MarketRentAnalyticsLegendVariant.MEDIUM
			? '164px'
			: undefined};
	padding: 8px;
	margin: 0 0.5rem 0.5rem 0.5rem;
`;
