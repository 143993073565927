import { PortfolioV2, SPACE_TYPE_NAME_TO_ID } from 'api';
import { FiltersState } from 'PortfolioAnalytics/PortfolioFiltersProvider';
import dayjs from 'dayjs';
import { CreateFiltersArg, createFilters } from 'models/filters';
import { FiltersObject } from 'models/filters/types';

type Props = FiltersState & {
	compType?: CreateFiltersArg;
	portfolio: PortfolioV2;
};

export const getFiltersFromPortfolioFilters = ({
	buildingClassIds,
	leaseExpirationsMonths,
	propertyTypeIds,
	markets,
	submarketIds,
	buildingSize,
	spaceTypes,
	compType = 'lease',
	portfolio,
}: Props): FiltersObject => {
	const filters = createFilters(compType);

	filters.markets = markets;
	filters.submarkets = portfolio.markets
		.flatMap((market) => market.submarkets)
		.map(({ submarketId, submarket }) => ({
			id: submarketId,
			name: submarket,
		}))
		.filter(({ id }) => submarketIds.includes(id));

	const isPropertyFilters =
		compType === 'property' || compType === 'properties';

	if (!isPropertyFilters) {
		filters.portfolioId = portfolio.portfolio.id;
		filters.partial = 'false';

		if (spaceTypes.length > 0) {
			filters.spaceTypeId = spaceTypes.map(
				(spaceTypeName) => SPACE_TYPE_NAME_TO_ID[spaceTypeName]
			);
		}

		if (leaseExpirationsMonths) {
			filters.expirationDate = {
				min: null,
				max: dayjs().add(leaseExpirationsMonths, 'month').toDate(),
				allowFallback: false,
			};
		}
	}

	if (buildingClassIds.length > 0) {
		filters.buildingClassId = buildingClassIds;
	}

	if (propertyTypeIds.length > 0) {
		filters.buildingPropertyTypeId = propertyTypeIds;
	}

	if (buildingSize) {
		filters.buildingSize = buildingSize;
	}

	return filters;
};
