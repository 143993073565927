import React from 'react';
import StackingPlanIcon from 'ui/svg_icons/auto-fill.svg';
import ReportIcon from 'ui/svg_icons/report.svg';
import { Button } from '@compstak/ui-kit';
import { AddToPortfolioButton } from 'Components/AddToPortfolioButton';
import { routes } from 'router';
import styled from 'styled-components';
import { LeaseComp } from 'types/comp';
import { ExportCompsButton } from 'Components/Export';
import { useIsExchange } from 'hooks';
import { UnlockCompButton } from 'Components/Unlock/UnlockCompButton';

type Props = {
	comp: LeaseComp;
};

export const CompInfoBarButtons = ({ comp }: Props) => {
	const isExchange = useIsExchange();

	return (
		<Container>
			{!isExchange && (
				<AddToPortfolioButton
					variant="secondary"
					propertyIds={[comp.propertyId]}
					marketIds={[comp.marketId]}
				/>
			)}
			<Button
				icon={<ReportIcon width="1em" />}
				href={routes.propertyById.toHref({ propertyId: comp.propertyId })}
				variant="secondary"
				size="m"
			>
				Property Report
			</Button>
			{comp.own ? (
				<ExportCompsButton
					buttonType="standalone"
					compIds={[comp.id]}
					compType="lease"
					size="m"
				/>
			) : (
				<UnlockCompButton
					type="lease"
					comp={comp}
					buttonType="standalone"
					size="m"
				/>
			)}
			{isExchange && comp.own && (
				<Button
					icon={<StackingPlanIconStyled />}
					href={routes.stackingPlan.toHref({
						propertyId: comp.propertyId,
					})}
				>
					Stacking Plan
				</Button>
			)}
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	gap: 0.5rem;
	flex-wrap: wrap;
`;

const StackingPlanIconStyled = styled(StackingPlanIcon)`
	width: 0.875rem;

	g {
		fill: ${({ theme }) => theme.colors.white.white};
	}
`;
