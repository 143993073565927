import {
	Flex,
	SimpleTableColumn,
	TooltipV2,
	defaultTheme,
	renderTableValue,
} from '@compstak/ui-kit';
import { CheckedState } from '@radix-ui/react-checkbox';
import { Checkbox, StopPropagation } from 'Components';
import { ExportCompsButton } from 'Components/Export';
import { UnlockButtonRow } from 'Components/Unlock';
import { PropertyLease, usePropertyById } from 'api';
import {
	formatCompQuarter,
	formatMoney,
	formatMonths,
	formatRentBumpsCombined,
	formatObfuscatedSizeRange,
	formatRentWithPostfix,
	formatRentWithPostfixAsString,
} from 'format';
import { formatSqft } from 'format/formatSqft';
import { useIsExchange } from 'hooks';
import { routes } from 'router';
import { IconLockGrey } from 'ui/UI';
import {
	SelectionState,
	getIsMonthlyMarket,
	getShouldShowLockIcon,
} from 'utils';
import IconQuestionMark from 'ui/svg_icons/question_mark_outline.svg';
import { UseUnlockCompsProps } from 'Components/Unlock/useUnlockComps';
import { COMP_FIELD_TOOLTIPS } from '../../constants/tooltips';
import { LeaseComp } from 'types';
import styled from 'styled-components';

export type LeaseTableColumnsProps<T extends PropertyLease | LeaseComp> = {
	selection: SelectionState<T>;
} & Pick<UseUnlockCompsProps, 'onUnlockLeasesSuccess'>;

export const useLeasesTableColumns = <T extends PropertyLease | LeaseComp>({
	selection: leasesSelection,
	onUnlockLeasesSuccess,
}: LeaseTableColumnsProps<T>) => {
	const isExchange = useIsExchange();

	const {
		getAreAllSelected,
		areSomeSelected,
		toggleRow,
		toggleAllSelected,
		selection,
	} = leasesSelection;

	let checkboxState: CheckedState = false;
	if (areSomeSelected && !getAreAllSelected()) {
		checkboxState = 'indeterminate';
	} else if (getAreAllSelected()) {
		checkboxState = true;
	}

	const { data: property } = usePropertyById({
		propertyId: routes.propertyById.useParams().propertyId,
	});

	if (!property) return [];

	const isMonthly = getIsMonthlyMarket(property.marketId);

	const columns: SimpleTableColumn<T>[] = [
		{
			id: 'selection',
			header: () => {
				return (
					<Checkbox
						id="selectAll"
						name="selectAll"
						data-qa-id="select-all-checkbox"
						checked={checkboxState}
						onCheckedChange={toggleAllSelected}
						bgColorUnchecked={defaultTheme.colors.white.white}
					/>
				);
			},
			body: ({ row }) => {
				return (
					<Checkbox
						name={`selectRow-${row.id}`}
						data-qa-id={`select-row-checkbox-${row.id}`}
						checked={selection.some((id) => id === row.id)}
						onCheckedChange={() => toggleRow(row.id)}
						onClick={(e) => e.stopPropagation()}
					/>
				);
			},
			width: 50,
			hAlign: 'center',
		},
		{
			id: 'actions',
			header: () => '',
			body: ({ row }) => (
				<StyledFlex alignItems="center">
					<StopPropagation handler="onClick">
						{row.own ? (
							<ExportCompsButton
								compType="lease"
								compIds={[row.id]}
								buttonType="row"
							/>
						) : (
							<UnlockButtonRow
								comp={{ id: row.id, cost: row.cost }}
								type="lease"
								onUnlockLeasesSuccess={onUnlockLeasesSuccess}
							/>
						)}
					</StopPropagation>
				</StyledFlex>
			),
			width: isExchange ? 96 : 82,
		},
		{
			id: 'executionQuarter',
			header: () => 'Trans. Qtr.',
			width: 90,
			body: ({ row }) => {
				return renderTableValue(row.executionQuarter, formatCompQuarter);
			},
		},
		{
			id: 'tenantName',
			header: () => 'Tenant',
			width: 120,
			body: ({ row }) => {
				return (
					<div style={{ textAlign: 'left' }}>
						{renderTableValue(row.tenantName)}
					</div>
				);
			},
			flex: 1,
			hAlign: 'left',
			tooltip: ({ row }) => row.tenantName,
		},
		{
			id: 'spaceType',
			header: 'Space Type',
			width: 100,
			body: ({ row }) => renderTableValue(row.spaceType),
			tooltip: ({ row }) => row.spaceType,
		},
		{
			id: 'transactionSize',
			header: () => (
				<Flex gap="0.5rem" justifyContent="flex-end">
					<div>Size</div>
					<TooltipV2 content="Total SqFt reported. If Retail, total Main floor SqFt only.">
						<span>
							<IconQuestionMark />
						</span>
					</TooltipV2>
				</Flex>
			),
			width: 115,
			body: ({ row }) => {
				return renderTableValue(row.transactionSize, (v) =>
					row.own ? formatSqft(v) : formatObfuscatedSizeRange(v)
				);
			},
			hAlign: 'right',
			tooltip: ({ row }) =>
				row.own
					? formatSqft(row.transactionSize)
					: formatObfuscatedSizeRange(row.transactionSize),
		},
		{
			id: 'floors',
			header: () => 'Floor(s)',
			width: 120,
			body: ({ row }) => {
				if (getShouldShowLockIcon(row.floorOccupancies, row, isExchange)) {
					return (
						<Flex justifyContent="right">
							<IconLockGrey />
						</Flex>
					);
				}

				return renderTableValue(row.floorOccupancies?.formatted);
			},
			hAlign: 'right',
			tooltip: ({ row }) => row.floorOccupancies?.formatted,
		},
		{
			id: 'startingRent',
			header: () => (
				<Flex gap="0.5rem" justifyContent="flex-end">
					<div>Starting Rent</div>
					<TooltipV2 content={COMP_FIELD_TOOLTIPS.startingRent}>
						<span>
							<IconQuestionMark />
						</span>
					</TooltipV2>
				</Flex>
			),
			width: 100,
			body: ({ row }) => {
				if (row.startingRent === undefined) {
					const otherRent = row.own
						? otherRents.find((rent) => row[rent])
						: otherRents.find((rent) => row[rent] === null);
					if (otherRent)
						return (
							<TooltipV2 content={`${otherRentsLabels[otherRent]} Only`}>
								<span>
									<IconQuestionMark />
								</span>
							</TooltipV2>
						);
				}
				if (getShouldShowLockIcon(row.startingRent, row, isExchange)) {
					return (
						<Flex justifyContent="right">
							<IconLockGrey />
						</Flex>
					);
				}
				return renderTableValue(row.startingRent, (v) =>
					formatRentWithPostfix(v, isMonthly)
				);
			},
			hAlign: 'right',
			flex: 1,
			tooltip: ({ row }) =>
				row.startingRent
					? formatRentWithPostfixAsString(row.startingRent, isMonthly)
					: undefined,
		},
		{
			id: 'rentSchedule',
			header: () => 'Rent Schedule',
			width: 100,
			body: ({ row }) => {
				if (
					row.own === false &&
					[
						row.rentBumpsPercent,
						row.rentBumpsDollar,
						row.leaseEscalations,
					].includes(null)
				)
					return (
						<Flex justifyContent="right">
							<IconLockGrey />
						</Flex>
					);
				return renderTableValue(formatRentBumpsCombined(row));
			},
			flex: 1,
			hAlign: 'right',
			tooltip: ({ row }) => formatRentBumpsCombined(row),
		},
		{
			id: 'freeRent',
			header: () => 'Free Rent',
			width: 72,
			body: ({ row }) => {
				if (getShouldShowLockIcon(row.freeMonths, row, isExchange)) {
					return (
						<Flex justifyContent="right">
							<IconLockGrey />
						</Flex>
					);
				}

				return renderTableValue(row.freeMonths, (v) => formatMonths(v));
			},
			hAlign: 'right',
			tooltip: ({ row }) =>
				typeof row.freeMonths === 'number'
					? formatMonths(row.freeMonths)
					: undefined,
		},
		{
			id: 'workValue',
			header: () => 'TI Value/Work Value',
			width: 150,
			body: ({ row }) => {
				if (getShouldShowLockIcon(row.workValue, row, isExchange)) {
					return (
						<Flex justifyContent="right">
							<IconLockGrey />
						</Flex>
					);
				}

				return renderTableValue(row.workValue, (v) => formatMoney(v));
			},
			hAlign: 'right',
			tooltip: ({ row }) =>
				typeof row.workValue === 'number'
					? formatMoney(row.workValue)
					: undefined,
		},
		{
			id: 'leaseTerm',
			header: () => 'Term',
			width: 96,
			body: ({ row }) => {
				if (getShouldShowLockIcon(row.leaseTerm, row, isExchange)) {
					<Flex justifyContent="right">
						<IconLockGrey />
					</Flex>;
				}

				return renderTableValue(row.leaseTerm, (v) => formatMonths(v));
			},
			hAlign: 'right',
			tooltip: ({ row }) =>
				typeof row.leaseTerm === 'number'
					? formatMonths(row.leaseTerm)
					: undefined,
		},
		{
			id: 'leaseType',
			header: () => 'Lease Type',
			width: 116,
			body: ({ row }) => {
				if (getShouldShowLockIcon(row.leaseType, row, isExchange)) {
					return <IconLockGrey />;
				}
				return renderTableValue(row.leaseType);
			},
			hAlign: 'left',
			tooltip: ({ row }) => (row.leaseType ? row.leaseType : undefined),
		},
	];

	return columns;
};

export const otherRentsLabels: Record<OtherRent, string> = {
	effectiveRent: 'Effective Rent',
	avgRent: 'Average Rent',
	blendedRent: 'Blended Rent',
} as const;

//ordered by priority
export const otherRents: OtherRent[] = [
	'effectiveRent',
	'avgRent',
	'blendedRent',
];

type OtherRent = keyof Pick<
	LeaseComp,
	'effectiveRent' | 'avgRent' | 'blendedRent'
>;

// Created this styled component to enable the button to align to the center since we don't use flex in UI-KIT
const StyledFlex = styled(Flex)`
	width: 100%;
	height: 100%;

	overflow: unset;
`;
