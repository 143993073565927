import { UseQueryOptions, useQueries, useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from 'api/constants';
import { getQueryStringFromPortfolioFilters } from 'util/getQueryStringsFromPortfolioFilter';
import { API } from '@compstak/common';
import { ChartPropertiesTopParams, ChartPropertiesTopResponse } from './types';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

const chartPropertiesTopQueryFn = async (
	id: number,
	params: ChartPropertiesTopParams,
	extraQueryParams?: Record<string | number, any>
) => {
	const { filters, ...extraParams } = params;
	const queryParams = getQueryStringFromPortfolioFilters(filters, {
		...extraParams,
		...extraQueryParams,
	});

	const response = await API.get<ChartPropertiesTopResponse>(
		`/pa/v2/api/portfolios/${id}/properties/chart/top${queryParams}`
	);

	return response.data;
};

export const useChartPropertiesTopQuery = (
	id: number,
	params: ChartPropertiesTopParams,
	options?: UseQueryOptions<ChartPropertiesTopResponse>
) => {
	const { portfolioExcludeSubleasesFF } = useFeatureFlags();

	return useQuery({
		queryKey: [QUERY_KEYS.CHART_TOP_PROPERTIES, id, params],
		queryFn: () =>
			chartPropertiesTopQueryFn(id, params, {
				excludeSubleases: portfolioExcludeSubleasesFF,
			}),
		...options,
	});
};

export const useChartPropertiesTopQueries = (
	ids: number[],
	params: ChartPropertiesTopParams,
	options?: UseQueryOptions<ChartPropertiesTopResponse>
) => {
	const { portfolioExcludeSubleasesFF } = useFeatureFlags();

	return useQueries({
		queries: ids.map((id) => {
			return {
				queryKey: [QUERY_KEYS.CHART_TOP_PROPERTIES, id, params],
				queryFn: () =>
					chartPropertiesTopQueryFn(id, params, {
						excludeSubleases: portfolioExcludeSubleasesFF,
					}),
				...options,
			};
		}),
	});
};
