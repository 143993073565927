import { COLUMN_CONFIG } from 'Components/SearchTable/columnConfigs';
import {
	BASE_PROPERTY_SECTIONS,
	LEASE_SECTIONS,
} from 'Components/SearchTable/columnSectionsConfig';
import { LeaseComp, PropertyComp } from 'types';

export const PORTFOLIO_LIMIT = 60_000;

export const MAP_VIEW_INCLUDE_FIELDS: (keyof PropertyComp)[] = [
	'id',
	'marketId',
	'buildingAddressAndCity',
	'geoPoint',
];

export const PROPERTIES_LIST_VIEW_INCLUDE_FIELDS =
	BASE_PROPERTY_SECTIONS.flatMap(({ ids }) => ids)
		.map((columnId) => COLUMN_CONFIG[columnId]?.name)
		.filter(Boolean) as (keyof PropertyComp)[];

export const LEASES_LIST_VIEW_INCLUDE_FIELDS = LEASE_SECTIONS.flatMap(
	({ ids }) => ids
).map((columnId) => COLUMN_CONFIG[columnId]?.name) as (keyof LeaseComp)[];
