import { Flex } from '@compstak/ui-kit';

import IconBars from '../ui/svg_icons/bars.svg';
import IconPlusVeryLight from '../ui/svg_icons/plus_very_light.svg';

import styled from 'styled-components';
import { routes, useNavigate } from 'router';
import { AddNewPropertyButton } from './Dashboard/DashboardHeader/UI';

export const EmptyPortfolio = () => {
	const navigate = useNavigate();

	return (
		<Container
			direction="column"
			alignItems="center"
			justifyContent="center"
			gap="1rem"
		>
			<IconBars />
			<h1>No properties added</h1>

			<AddNewPropertyButton
				variant="primary"
				icon={<IconPlusVeryLight />}
				onClick={() => {
					navigate(routes.searchPropertiesHome.toHref());
				}}
			>
				Add New Property
			</AddNewPropertyButton>
		</Container>
	);
};

const Container = styled(Flex)`
	margin: 0 auto;

	padding: 5rem;

	h1 {
		font-size: 1.5rem;
		font-weight: 400;
	}
`;
