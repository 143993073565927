import { formatCompField } from 'format/formatCompField';
import { useIsExchange } from './useIsExchange';
import { formatObfuscatedSizeRange } from 'format/formatObfuscatedSizeRange';
import { CompKeys, CompValues } from 'types';
import { isLeaseComp } from 'utils/compHelpers';
import { UnlockCompButton } from 'Components/Unlock/UnlockCompButton';

/*
    A hook to orchestrate any comp (lease | sale | property) field value formatting
*/
export const useRenderCompField = () => {
	const isExchange = useIsExchange();

	const renderCompField: typeof formatCompField = ({
		name,
		row,
		isMonthly,
		config,
	}) => {
		const value: CompValues | undefined = row[name as CompKeys];
		if (
			isExchange &&
			'own' in row &&
			row.own === false &&
			name === 'transactionSize' &&
			typeof value === 'number'
		) {
			return formatObfuscatedSizeRange(value);
		}

		if (isExchange && 'own' in row && row.own === false && value === null) {
			return (
				<UnlockCompButton
					type={isLeaseComp(row) ? 'lease' : 'sale'}
					comp={row}
					buttonType='row'
				/>
			);
		}

		return formatCompField({ name, row, isMonthly, config });
	};

	return renderCompField;
};
