import { SpaceTypeName } from 'api';
import { usePortfolioFilters } from '../../../PortfolioFiltersProvider';
import { CheckboxFilter } from './CheckboxFilter';
import { FilterOption } from '../types';
import { useReferenceData } from 'Pages/Login/reducers';

export const SpaceTypeFilter = () => {
	const { filters, setFilters } = usePortfolioFilters();

	const referenceData = useReferenceData();

	const options: FilterOption<SpaceTypeName>[] =
		referenceData.filterableSpaceTypes.map(({ name }) => ({
			label: name,
			value: name,
		}));

	return (
		<CheckboxFilter
			label="Space Type"
			emptyValueLabel="All Types"
			options={options}
			value={filters.spaceTypes}
			onChange={(value) => {
				setFilters((s) => ({
					...s,
					spaceTypes: value,
				}));
			}}
		/>
	);
};
