import React from 'react';
import {
	GraphTypeSelector,
	MovingAverageSelector,
	TimespanSelector,
	YSeriesSelector,
} from 'Components/Graphs/Selectors';
import { nullValueText } from '@compstak/common';
import button from 'ui/styles/button.less';
import spinner from 'ui/styles/spinner.less';
import { selectorStyles } from 'util/reactSelectStyles';
import styles from './table-graph.less';
import { CalculationOverviewLink } from 'ui/CalculationOverviewLink';
import { useModal } from 'providers/ModalProvider';
import { GraphExportModal } from 'Components/Modals/GrapghExportModal/GraphExportModal';
import {
	ChartType,
	GraphUserPreferences,
	InsightDataResponse,
} from 'Pages/Analytics/analytics';
import { FiltersObject } from 'models/filters/types';
import { Options } from 'highcharts';
import { formatPercent } from 'format';

type GraphOptionsProps = {
	data: InsightDataResponse[];
	filters: FiltersObject;
	hasMultiFamilyDatasets: boolean;
	copyForExcel: () => void;
	export: (options: Options) => void;
	userPreferences: GraphUserPreferences;
	changeType: (chartType: ChartType) => void;
	changeYProperty: (value: string) => void;
	changeTrendMonths: (value: number) => void;
	changeTimespan: (value: number) => void;
};

const GraphOptions = (props: GraphOptionsProps) => {
	const { openModal } = useModal();

	const copyForExcel = () => {
		props.copyForExcel();
	};

	const copyShouldBeHidden =
		props.data &&
		props.userPreferences.type === 'line' &&
		props.data[0].data.points;

	let i = 0;

	return [
		<label key={++i}>
			<span className={styles.sideLabel}>Attribute</span>
			<YSeriesSelector
				onChange={props.changeYProperty}
				value={props.userPreferences.yAxis}
				//@ts-expect-error className doesn't exist
				className={styles.select}
				styles={selectorStyles}
			/>
		</label>,
		<label key={++i}>
			<span className={styles.sideLabel}>Mov. Average</span>
			<MovingAverageSelector
				onChange={props.changeTrendMonths}
				value={props.userPreferences.trendMonths}
				styles={selectorStyles}
			/>
		</label>,
		<label key={++i}>
			<span className={styles.sideLabel}>Timespan</span>
			<TimespanSelector
				onChange={props.changeTimespan}
				value={props.userPreferences.timespan}
				styles={selectorStyles}
			/>
		</label>,
		<div className={styles.buttons} key={++i}>
			<span className={styles.sideLabel}>Graph Type</span>
			<GraphTypeSelector
				onChange={props.changeType}
				value={props.userPreferences.type}
				hasMultiFamilyDatasets={props.hasMultiFamilyDatasets}
			/>
		</div>,
		<div key={++i}>
			<div className={styles.sideLabel}>
				{props.userPreferences.timespan / 12}Y Growth Rate
			</div>
			<GrowthRate
				data={props.data}
				timespan={props.userPreferences.timespan}
				filters={props.filters}
			/>
		</div>,
		<div key={++i}>
			<div
				className={`${button.block} ${button.blue} ${styles.button}`}
				onClick={() =>
					openModal({
						modalContent: <GraphExportModal exportFn={props.export} />,
					})
				}
			>
				Save Image
			</div>
		</div>,
		<CalculationOverviewLink key={++i} context="charts" />,
		<div className={styles.copyForExcel} key={++i}>
			{copyShouldBeHidden ? null : (
				<span className={styles.copyForExcelButton}>
					<a onClick={copyForExcel}>Copy for Excel</a>
				</span>
			)}
		</div>,
	];
};

function GrowthRate(props: {
	filters: FiltersObject;
	data: InsightDataResponse[];
	timespan: number;
}) {
	if (!props.filters.spaceTypeId) {
		return <div className={styles.growthRate} />;
	}
	if (!props.data) {
		return (
			<div className={styles.growthRate}>
				<div className={spinner.small} />
			</div>
		);
	}
	const summary = props.data[0].data.summaries.find(
		(s) => s.months === props.timespan
	);
	if (!summary || summary.annualizedGrowth === 0) {
		return <span className={styles.growthRate}>{nullValueText}</span>;
	}
	return (
		<span className={styles.growthRate}>
			{formatPercent(summary.annualizedGrowth)}
		</span>
	);
}

export default GraphOptions;
