import { CompstakContactInfo } from '@compstak/common';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import {
	MarketRentAnalyticsProvider,
	TenantIndustryAnalyticsProvider,
} from 'maps';
import { useAppConfig, useUser } from 'Pages/Login/reducers';
import { loadSavedSearches } from 'Pages/SavedSearches/actions';
import { OverridedTopRightContainer } from 'Pages/Search/Map/Components';
import { FilterMenuToggler } from 'Pages/Search/Map/Components/FilterMenuToggler';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useExchangeDashboardState } from 'reducers/exchangeDashboard';
import { useSavedSearches } from 'reducers/savedSearches';
import factory from 'services/factory';
import styled from 'styled-components';
import { useAppDispatch } from 'util/useAppDispatch';
import { SearchToolbarButtons } from '../Pages/Search/SearchToolbar/SearchToolbarButtons';
import DownloadIcon from '../ui/svg_icons/download_icon.svg';
import Filter from '../ui/svg_icons/filter.svg';
import { DashboardExportDialog } from './Export';
import { LeaseActivity } from './LeaseActivity';
import { LeaseExpirationSubmarket } from './LeaseExpirationSubmarket';
import { LeaseInsights } from './LeaseInsights';
import Membership from './Membership';
import { SalesActivity } from './SalesActivity';
import { ExchangeDashboardSideBar } from './ExchangeDashboardSideBar';
import Table from './Table';
import { Section } from './types';
import { Container, Content, TopNav } from './UI';
import {
	useOnboardingSectionPosition,
	UserflowOnboardingTable,
} from './UserflowOnboardingTable';
import { useFilters } from '../reducers/filtersReducer';
import { SpaceTypeModal } from './SpaceTypeModal';
import { useLocalStorage } from '../utils/useLocalStorage';
import { z } from 'zod';
import { useIsExchange } from '../hooks';
import { FilterFieldContextProvider } from 'Components/Filters/Fields/FilterFieldContextProvider';
import { FiltersObject } from 'models/filters/types';
import { DashboardExportProvider } from './Export/DashboardExportProvider';
import { isSet } from 'models/filters/util/getSetFilters';
import { MarketSummary } from './MarketSummary';
import { Button, MQB } from '@compstak/ui-kit';

const FILTER_CONTEXT = 'exchange-dashboard';

const EXCHANGE_SPACE_TYPE_SELECTED_KEY = 'exchange-space-type-selected';
const EXCHANGE_SPACE_TYPE_SELECTED_SHAPE = z.boolean();

function useSections(): Section[] {
	const onboardingSectionPosition = useOnboardingSectionPosition();
	const appConfig = useAppConfig();

	const ONBOARDING_SECTION: Section = {
		type: 'onboarding-section',
		label: 'Onboard',
	};
	const MEMBERSHIP_SECTION: Section = {
		type: 'membership',
		label: 'Membership',
	};

	const sections: Section[] = [
		...(onboardingSectionPosition === 'start' ? [ONBOARDING_SECTION] : []),

		{ type: 'lease-insights', label: 'Lease Insights' },
		{ type: 'lease-activity', label: 'Lease Activity' },
		{ type: 'sales-activity', label: 'Sales Activity' },

		...(appConfig.isExchange ? [MEMBERSHIP_SECTION] : []),

		...(onboardingSectionPosition === 'end' ? [ONBOARDING_SECTION] : []),
	];
	return sections;
}

type InternalExchangeDashboardProps = {
	filters: FiltersObject;
};

const InternalExchangeDashboard = ({
	filters,
}: InternalExchangeDashboardProps) => {
	const appConfig = useAppConfig();
	const dispatch = useAppDispatch();
	const savedSearches = useSavedSearches();
	const user = useUser();
	const { MarketSummaryFF } = useFeatureFlags();
	const onboardingSectionPosition = useOnboardingSectionPosition();
	const sections = useSections();
	const {
		fullPageState,
		setFullPageState,
		isLeaseExpirationPropertyTypeVisible,
	} = useExchangeDashboardState();
	const [sideBarState, setSideBarState] = React.useState<'open' | 'closed'>(
		'closed'
	);
	const toggleSideBarState = useCallback(
		() => setSideBarState((prev) => (prev === 'open' ? 'closed' : 'open')),
		[]
	);
	const [compstakContactInfo, setCompstakContactInfo] =
		React.useState<CompstakContactInfo | null>(null);
	const [sectionsInView, setSectionsInView] = React.useState<Section['type'][]>(
		[]
	);

	React.useEffect(() => {
		if (savedSearches.savedSearchList == null) {
			dispatch(loadSavedSearches(user));
		}
	}, [savedSearches.savedSearchList == null]);

	React.useEffect(() => {
		factory
			.get('/api/dashboard/manager')
			// @ts-expect-error TS2345: Argument of type 'unknown' is ...
			.then((response) => setCompstakContactInfo(response));
	}, []);

	const resetPageState = useCallback(() => {
		setFullPageState?.(null);
	}, [setFullPageState]);

	useEffect(() => {
		return resetPageState;
	}, []);

	const [isExportDialogOpen, setIsExportDialogOpen] = useState(false);
	const [isSpaceTypeExchangeSelected, setIsSpaceTypeExchangeSelected] =
		useLocalStorage<boolean>(
			EXCHANGE_SPACE_TYPE_SELECTED_KEY,
			false,
			EXCHANGE_SPACE_TYPE_SELECTED_SHAPE.parse
		);
	const isExchange = useIsExchange();
	const canShowSpaceTypeModalToOnboardingUsers =
		onboardingSectionPosition === 'start' &&
		isLeaseExpirationPropertyTypeVisible;
	const userIsNotInOnboardingStartPosition =
		onboardingSectionPosition !== 'start';

	const [shouldOpenSpaceTypeModal, setShouldOpenSpaceTypeModal] = useState(
		!isSet(filters.spaceTypeId, 'spaceTypeId') &&
			(!isExchange || !isSpaceTypeExchangeSelected)
	);

	const isSpaceTypeModalOpen = useMemo(() => {
		if (
			!shouldOpenSpaceTypeModal ||
			onboardingSectionPosition === 'isLoading'
		) {
			return false;
		}
		return (
			canShowSpaceTypeModalToOnboardingUsers ||
			userIsNotInOnboardingStartPosition
		);
	}, [
		shouldOpenSpaceTypeModal,
		onboardingSectionPosition,
		isLeaseExpirationPropertyTypeVisible,
	]);

	return (
		<MarketRentAnalyticsProvider>
			<TenantIndustryAnalyticsProvider>
				<Container>
					<ExchangeDashboardSideBar
						sideBarState={sideBarState}
						sections={sections}
					/>
					{fullPageState && (
						<FullPageContainer>
							<FullPageHeader>
								<BackButton
									onClick={resetPageState}
									data-variant={'default'}
									data-placement="bottom"
									data-tooltipdelay="0"
									data-tooltip="Back to Dashboard"
								>
									&lt; Back
								</BackButton>
								{fullPageState?.type === 'list' && (
									<SearchToolbarButtons
										compType="lease"
										params={{ view: 'list' }}
										filterContext="exchange-dashboard"
									/>
								)}
							</FullPageHeader>

							{fullPageState?.type === 'list' && <Table />}
							{fullPageState?.type === 'graph' &&
								fullPageState.field === 'submarket' && (
									<LeaseExpirationSubmarket />
								)}
						</FullPageContainer>
					)}

					<Content scrollState={fullPageState ? 'no-scroll' : 'scroll'}>
						<MarketDashboardNav
							sectionsInView={sectionsInView}
							onClickExport={() => setIsExportDialogOpen(true)}
						/>
						<OverridedTopRightContainer>
							<FilterMenuToggler onClick={toggleSideBarState}>
								<StyledFilterIcon />
							</FilterMenuToggler>
						</OverridedTopRightContainer>
						{onboardingSectionPosition === 'start' && (
							<UserflowOnboardingTable setSectionsInView={setSectionsInView} />
						)}
						{MarketSummaryFF && <MarketSummary />}
						<LeaseInsights setSectionsInView={setSectionsInView} />
						<LeaseActivity setSectionsInView={setSectionsInView} />
						<SalesActivity setSectionsInView={setSectionsInView} />
						{appConfig.isExchange && (
							<Membership
								setSectionsInView={setSectionsInView}
								contactInfo={compstakContactInfo}
							/>
						)}
						{onboardingSectionPosition === 'end' && (
							<UserflowOnboardingTable setSectionsInView={setSectionsInView} />
						)}
					</Content>
					{isSpaceTypeModalOpen && (
						<SpaceTypeModal
							onClose={() => setShouldOpenSpaceTypeModal(false)}
							onSubmit={
								isExchange
									? () => setIsSpaceTypeExchangeSelected(true)
									: undefined
							}
						/>
					)}
					{isExportDialogOpen && (
						<DashboardExportProvider>
							<DashboardExportDialog
								closeDialog={() => setIsExportDialogOpen(false)}
							/>
						</DashboardExportProvider>
					)}
				</Container>
			</TenantIndustryAnalyticsProvider>
		</MarketRentAnalyticsProvider>
	);
};

export const ExchangeDashboard = () => {
	const [filters, setFilters] = useFilters(FILTER_CONTEXT);

	return (
		<FilterFieldContextProvider
			compType="lease"
			filters={filters}
			onFilterChange={setFilters}
			context={FILTER_CONTEXT}
		>
			<InternalExchangeDashboard filters={filters} />
		</FilterFieldContextProvider>
	);
};

type NavProps = {
	sectionsInView: Section['type'][];
	onClickExport: () => void;
};

const MarketDashboardNav = (props: NavProps) => {
	const sections = useSections();

	const isActiveSection = (type: Section['type']) =>
		props.sectionsInView.reduce<Section['type'] | null>((acc, l) => {
			return sections.findIndex((s) => s.type === l) <
				sections.findIndex((s) => s.type === acc)
				? l
				: acc;
		}, props.sectionsInView[0]) === type;

	const scrollToSection = (type: Section['type']) => {
		const sectionElement = document.getElementById(type);
		sectionElement?.scrollIntoView({ behavior: 'instant', block: 'start' });
	};

	return (
		<TopNav>
			<ul>
				{sections.map((section) => (
					<li key={section.type}>
						<a
							data-qa-id={`exchange-dash-nav-${section.type}`}
							className={isActiveSection(section.type) ? 'active' : ''}
							onClick={() => scrollToSection(section.type)}
						>
							{section.label}
						</a>
					</li>
				))}
			</ul>
			<ExportButton
				variant="primary3"
				onClick={props.onClickExport}
				data-qa-id="dashboard-export-btn"
				icon={<StyledDownloadIcon />}
			>
				Export
			</ExportButton>
		</TopNav>
	);
};

const StyledFilterIcon = styled(Filter)`
	fill: #ffffff;
	height: 17px;
	width: 23px;
`;

const ExportButton = styled(Button)`
	padding-right: 0.75em;
	padding-left: 0.75em;
`;

const StyledDownloadIcon = styled(DownloadIcon)`
	fill: #ffffff;
`;

const FullPageContainer = styled.div`
	background-color: #fff;
	bottom: 0;
	height: auto;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: auto;
	z-index: 12;
	overflow-y: auto;

	@media (min-width: ${MQB.T_834}) {
		left: 300px;
	}
`;

const FullPageHeader = styled.div`
	padding-left: 24px;
	display: flex;
	align-items: center;
	height: 90px;
	box-sizing: border-box;

	button {
		border: none;
		font-family: Gotham, Helvetica, Arial, Sans-Serif;
		font-size: 12px;
		cursor: pointer;
	}

	li {
		display: inline-block;
		margin: 0 0 0 24px;
	}
`;

const BackButton = styled.button`
	background-color: transparent;
	height: 50px;
	border-radius: 3px;
	padding: 0 15px;
	color: #2d77e6;

	&:hover {
		background-color: rgba(45, 119, 230, 0.1);
	}
`;
