import { useUnlockConditions } from 'api';

export const useUnlockButtonShortText = (comp: {
	id: number;
	cost: number;
}) => {
	const { isUserFetching, canUnlockForFree, totalCost } = useUnlockConditions({
		comps: [comp],
	});

	if (isUserFetching) return '';

	return canUnlockForFree ? ' | Free' : `(${totalCost})`;
};
