import { Flex } from '@compstak/ui-kit';
import { styled } from 'styled-components';
import { HelpTooltip } from './HelpTooltip';
import { BlueBox, BlueBoxLabel, BlueBoxLegend, BlueBoxValue } from './UI';
import { CompstakEstimate } from './hooks/useCompstakEstimatesData';
import { usePropertyPageDataContext } from './PropertyPageDataProvider';

export const CompstakEstimates = () => {
	const { compstakEstimates } = usePropertyPageDataContext();

	if (!compstakEstimates.showCompstakEstimates) return null;

	const { startingRent, effectiveRent } = compstakEstimates;

	return (
		<BlueBox>
			<BlueBoxLegend>
				<Flex gap="0.5rem">
					CompStak Rent Estimates <HelpTooltip text={tooltipText} />
				</Flex>
			</BlueBoxLegend>
			<Flex direction="row" gap="2rem" justifyContent="start">
				<div>
					<Label>{compstakEstimatesLabels.startingRent}</Label>
					<BlueBoxValue>{startingRent}</BlueBoxValue>
				</div>
				<div>
					<Label>{compstakEstimatesLabels.effectiveRent}</Label>
					<BlueBoxValue>{effectiveRent}</BlueBoxValue>
				</div>
			</Flex>
		</BlueBox>
	);
};

const Label = styled(BlueBoxLabel)`
	white-space: nowrap;
`;

const tooltipText =
	'An estimated rental price reflecting the current market value of this property. This figure indicates what the property might lease for today, taking into account historical data and lease conditions (Mark to Market).';

export const compstakEstimatesLabels: Record<CompstakEstimate, string> = {
	startingRent: 'Est. Starting Rent',
	effectiveRent: 'Est. Net Effective Rent',
};
