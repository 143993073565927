import { usePropertyCompsetQuery } from 'api';
import { useMemo } from 'react';
import { PropertyComp } from 'types';
import { createSearchLeasesUrl } from '../../../utils';
import { PopupActionButton, StyledArrowRightIcon } from './UI';
import { useMarketByNameOrId } from 'Pages/Login/reducers';
import { Spinner } from '@compstak/ui-kit';

import IconRelatedComps from '../../../ui/svg_icons/related_comps.svg';

type Props = {
	property: PropertyComp;
	closePopup: () => void;
};

export const GenerateCompSetButton = ({ property, closePopup }: Props) => {
	const { data, isFetching } = usePropertyCompsetQuery({ id: property.id });
	const addresses = data?.propertyCompSet.map(
		(comp) => comp.property.buildingAddressAndCity
	);

	const market = useMarketByNameOrId(property.marketId);

	const url = useMemo(() => {
		return createSearchLeasesUrl({
			market,
			address: addresses,
		});
	}, [market, addresses]);

	return (
		<PopupActionButton
			href={url}
			onClick={closePopup}
			icon={isFetching ? <Spinner isCentered size="s" /> : <IconRelatedComps />}
			variant="secondary"
		>
			Generate related comps
			<StyledArrowRightIcon />
		</PopupActionButton>
	);
};
