import { colors, typography } from '@compstak/ui-kit';
import {
	VictoryLabelProps,
	VictoryVoronoiContainerProps,
	VictoryTooltipProps,
} from 'victory';

export const CHART_HEIGHT = 380;
export const CUSTOM_AXIS_LABEL_PROPS: VictoryLabelProps = {
	dy: -10,
	textAnchor: 'middle',
	backgroundStyle: {
		fill: colors.gray.gray200,
	},
	backgroundPadding: 4,
	capHeight: 0.5,
};

export const CUSTOM_AXIS_LABEL_STYLE: VictoryLabelProps['style'] = {
	fontFamily: typography.fontFamily.gotham,
	fill: colors.gray.gray700,
	fontSize: 12,
};

export const CHART_AXIS_TICK_STYLE: VictoryLabelProps['style'] = {
	fontSize: '0.75em',
	fontFamily: typography.fontFamily.gotham,
	opacity: 1,
	fill: colors.gray.gray700,
	fontWeight: 400,
	letterSpacing: '0.06px',
};

export const VICTORY_CONTAINER_STYLE_800: Required<VictoryVoronoiContainerProps>['style'] =
	{
		maxWidth: '800px',
		margin: '0 auto',
	};

export const VICTORY_CONTAINER_STYLE_400: Required<VictoryVoronoiContainerProps>['style'] =
	{
		maxWidth: '400px',
		margin: '0 auto',
	};

export const VICTORY_CONTAINER_STYLE_650: Required<VictoryVoronoiContainerProps>['style'] =
	{
		maxWidth: '650px',
		margin: '0 auto',
	};

export const VICTORY_TOOLTIP_STYLE: Partial<VictoryTooltipProps> = {
	flyoutStyle: {
		fill: colors.gray.gray700,
	},
	style: {
		fill: colors.white.white,
		fontSize: 12,
		fontFamily: `${typography.fontFamily.gotham}, ${typography.fontFamily.openSans}`,
	},
	flyoutWidth: 180,
	flyoutHeight: 70,
};

const EPSILON = 1e-5; // 0.00001

export const RISK_BOUNDARIES = {
	LOW: {
		MIN: 0,
		MAX: 0.5,
	},
	HIGH: {
		MIN: 0.5 + EPSILON,
		MAX: 1,
	},
} as const;

export const PERFORMANCE_BOUNDARIES = {
	LOW: {
		MIN: -1,
		MAX: 0 - EPSILON,
	},
	HIGH: {
		MIN: 0,
		MAX: 1,
	},
} as const;
