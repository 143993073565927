import dayjs from 'dayjs';

import { useSearchLeasesQuery } from 'api';
import { ActivityTypeLease } from 'ExchangeDashboard/LeaseActivityTable';
import { SelectedSavedSearch } from 'ExchangeDashboard/SalesActivityTable'; // ! This is the common type
import { filtersToServerJSON } from 'models/filters/util';
import { useAppConfig } from 'Pages/Login/reducers';
import { useFilters } from 'reducers/filtersReducer';
import { mergeFilters } from 'models/filters/util/mergeFilters';

export const useExchangeDashboardLeasesActivity = ({
	activeTab,
	enabled,
	selectedSavedSearch,
}: {
	activeTab: ActivityTypeLease;
	enabled?: boolean;
	selectedSavedSearch?: SelectedSavedSearch;
}) => {
	const appConfig = useAppConfig();

	const viewsState = appConfig.isExchange
		? 'viewsInLast6MonthsByExchangeUsers'
		: 'viewsInLast6MonthsByEnterpriseUsers';

	let [filters] = useFilters('exchange-dashboard');

	if (activeTab === 'transactionSize') {
		const oneYearAgo = dayjs().subtract(12, 'month').startOf('month').toDate();
		const todaysDate = new Date();

		filters = mergeFilters(filters, {
			expirationDate: {
				min: todaysDate,
				max: null,
				allowFallback: true,
			},
			executionDate: {
				min: oneYearAgo,
				max: todaysDate,
			},
		});
	}

	const sort = activeTab === 'viewsInLast6Months' ? viewsState : activeTab;

	const leasesCompView = useSearchLeasesQuery(
		{
			filter: [
				...filtersToServerJSON(filters),
				...(selectedSavedSearch?.value.filters ?? []),
			],
			limit: 50,
			offset: 0,
			order: 'desc',
			properties: [
				'tenantName',
				'buildingAddress',
				'city',
				'submarket',
				'spaceType',
				'transactionSize',
				'effectiveRent',
				'leaseTerm',
				'dateCreated',
				'cost',
				'startingRent',
			],
			sort,
		},
		{ enabled }
	);

	return {
		...leasesCompView,
		type:
			activeTab === 'executionDate'
				? ('recentlySignedLeases' as const)
				: ('largestRecentLeases' as const),
	};
};
