// https://compstak.atlassian.net/issues/AP-19222
export const MFA_LOGIC_ENABLED = false;

export const QR_BOX_SIDE_LENGTH = '200px';
export const BUTTON_SIZE = 'l' as const;
export const POST_MVP = false;

export const PHONE_AUTH_APPS = [
	{
		name: 'Microsoft Authenticator',
		link: 'https://www.microsoft.com/en-us/security/mobile-authenticator-app',
	},
	{
		name: 'LastPass Authenticator',
		link: 'https://www.lastpass.com/solutions/authentication',
	},
];
