import styled from 'styled-components';
import { SalesComp } from 'types';

import {
	AdditionalPropertiesDetails,
	KeyDetails,
	PropertyAddress,
} from '../../sections';
import { GoogleMapSwitcherWrapper } from 'maps/components/MapWrapper/GoogleMapSwitcherWrapper';

type PropertyRailProps = {
	data: SalesComp;
};

export const PropertyRail = ({ data }: PropertyRailProps) => {
	return (
		<>
			<PropertyAddress data={data} />
			<KeyDetails data={data.portfolio[0]} />
			<MapSwitcherContainer>
				<GoogleMapSwitcherWrapper
					lat={data.portfolio[0].geoPoint.lat}
					lon={data.portfolio[0].geoPoint.lon}
					zoom={16}
					googleStreetViewId={SALES_COMP_STREET_VIEW_ID}
					mapDomain="salePage"
				/>
			</MapSwitcherContainer>
			<AdditionalPropertiesDetails data={data.portfolio[0]} />
		</>
	);
};

PropertyRail.displayName = 'PropertyRail';

const SALES_COMP_STREET_VIEW_ID = 'SalesCompStreetViewId';

const MapSwitcherContainer = styled.div`
	margin-top: 32px;
	position: relative;
	z-index: 0;
`;
