import constate from 'constate';
import { useEffect, useMemo, useState } from 'react';
import { DistributionMonths } from 'types/propertyTrendCharts';
import { useLeaseContext } from '../LeasesTab/LeaseProvider';
import { PropertyStatsSpaceType } from 'api/propertyStats/types';

export const [MarketStatsFiltersProvider, useMarketStatsFilters] = constate(
	() => {
		const [marketStatsFilters, setMarketStatsFilters] =
			useState<MarketStatsFilters>({
				duration: 36,
			});

		const { leasesGroupedBySpaceType } = useLeaseContext();

		const spaceTypeItems = useMemo(() => {
			return Object.typedKeys(leasesGroupedBySpaceType)
				.sort(
					(a, b) =>
						(leasesGroupedBySpaceType[b]?.length ?? 0) -
						(leasesGroupedBySpaceType[a]?.length ?? 0)
				)
				.map((spaceType) => ({
					value: spaceType as PropertyStatsSpaceType,
					title: spaceType,
				}));
		}, [leasesGroupedBySpaceType]);

		useEffect(() => {
			if (spaceTypeItems.length)
				setMarketStatsFilters((s) => ({
					...s,
					spaceType: spaceTypeItems[0].value,
				}));
		}, [spaceTypeItems]);

		return { marketStatsFilters, setMarketStatsFilters, spaceTypeItems };
	}
);

export type MarketStatsFilters = {
	duration: DistributionMonths;
	spaceType?: PropertyStatsSpaceType;
};
