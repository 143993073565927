import { Styled } from 'PortfolioAnalytics/Charts/PortfolioTrend/Chart/styled';
import { formatMoney, formatSqft } from 'format';
import { ChartTypeLease } from 'PortfolioAnalytics/Charts/ChartSelect';
import { VictoryTooltipProps } from 'victory';
import { colors } from '@compstak/ui-kit';

type Props = VictoryTooltipProps & {
	selectedChartType: ChartTypeLease;
};

export const LeaseExpirationTooltip = (props: Props) => {
	const {
		x = 0,
		y = 0,
		width,
		height,
		activePoints,
		selectedChartType,
	} = props;
	const defaultWidth = width ?? 320;
	const defaultHeight = height ?? 32;
	const offsetY = Math.max(y - 30, 0);
	const offsetX = Math.max(x - defaultWidth / 2, 0);
	const tooltipHeight = defaultHeight * (activePoints?.length ?? 0);

	const reversedActivePoints = activePoints?.slice().reverse();

	return (
		<g transform={`translate(${offsetX}, ${offsetY})`}>
			<foreignObject width={width} height={tooltipHeight}>
				<Styled.CustomTooltipContainer>
					<Styled.CustomTooltipInfoBox height={tooltipHeight}>
						{reversedActivePoints?.map((activePoint, index) => {
							if (!activePoint || !activePoint.y) return null;
							const formattedValue = formatValueByChartType({
								selectedChartType,
								value: activePoint.y,
							});
							return (
								<Styled.CustomTooltipInfoBoxData key={index}>
									<div>
										<Styled.CustomTooltipInfoBoxIndicator
											color={
												activePoint.style?.data?.fill || colors.gray.gray300
											}
										/>
									</div>
									<div>
										<span style={{ fontSize: 8 }}>{formattedValue}</span>
									</div>
								</Styled.CustomTooltipInfoBoxData>
							);
						})}
					</Styled.CustomTooltipInfoBox>
				</Styled.CustomTooltipContainer>
			</foreignObject>
		</g>
	);
};

const formatValueByChartType = ({
	selectedChartType,
	value,
}: {
	selectedChartType: ChartTypeLease;
	value: number;
}) => {
	if (selectedChartType === 'sqft') return formatSqft(value);

	if (selectedChartType === 'leaseAmount') return formatMoney(value);

	return value;
};
