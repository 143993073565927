import { PortfolioV2, PortfolioV2Metrics } from 'api';
import styled from 'styled-components';
import { PortfolioStatItem } from './PortfolioStatItem';
import { MQB } from '@compstak/ui-kit';
import { portfolioWidgetCss } from 'PortfolioAnalytics/UI';

type Props = {
	portfolio: PortfolioV2;
};

const PROPERTY_STATS_KEYS = [
	'marketCount',
	'propertyCount',
	'totalSqFt',
	'activeLeaseCount',
	'expiringSqFt24Months',
	'avgTransactionalSqFt',
] satisfies (keyof PortfolioV2Metrics)[];

const PORTFOLIO_RENT_STATS_KEYS = [
	'avgCurrentRent',
	'avgNetEffectiveRent',
	'avgFreeRent',
	'avgLeaseTerm',
	'avgTIImprovement',
] satisfies (keyof PortfolioV2Metrics)[];

export type StatKey =
	| (typeof PROPERTY_STATS_KEYS)[number]
	| (typeof PORTFOLIO_RENT_STATS_KEYS)[number];

export const PortfolioStats = ({ portfolio }: Props) => {
	return (
		<PortfolioStatsContainer>
			<PortfolioStatsSection>
				<PortfolioStatsSectionHeader>
					Property stats
				</PortfolioStatsSectionHeader>
				<PortfolioStatsItemsContainer>
					{PROPERTY_STATS_KEYS.map((key) => (
						<PortfolioStatItem key={key} statKey={key} portfolio={portfolio} />
					))}
				</PortfolioStatsItemsContainer>
			</PortfolioStatsSection>
			<PortfolioStatsSection>
				<PortfolioStatsSectionHeader>
					Portfolio Rent Stats
				</PortfolioStatsSectionHeader>
				<PortfolioStatsItemsContainer>
					{PORTFOLIO_RENT_STATS_KEYS.map((key) => (
						<PortfolioStatItem key={key} statKey={key} portfolio={portfolio} />
					))}
				</PortfolioStatsItemsContainer>
			</PortfolioStatsSection>
		</PortfolioStatsContainer>
	);
};

const PortfolioStatsContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

const PortfolioStatsSection = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1em;
	background: ${(p) => p.theme.colors.white.white};
	padding: 1em;

	${portfolioWidgetCss}
`;

const PortfolioStatsItemsContainer = styled.div`
	display: grid;
	gap: 0.75em;
	grid-template-columns: repeat(auto-fit, minmax(min(172.5px, 100%), 1fr));
	@media (min-width: 1470px) {
		grid-template-columns: repeat(
			${PROPERTY_STATS_KEYS.length},
			minmax(min(172.5px, 100%), 1fr)
		);
	}
	@media (min-width: ${MQB.D_1536}) {
		grid-template-columns: repeat(
			${PROPERTY_STATS_KEYS.length},
			minmax(min(185px, 100%), 1fr)
		);
	}
`;

const PortfolioStatsSectionHeader = styled.h3`
	font-size: 0.875em;
	font-weight: 500;
	line-height: 1.5em;
	text-transform: uppercase;
`;
