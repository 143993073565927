import {
	NAV_KEY,
	SearchTable,
	useSearchPropertiesNavHeaderContext,
} from 'Components';
import {
	SEARCH_INFINITE_PAGE_SIZE,
	SearchMufaPropertiesResponse,
	SearchPropertiesAverages,
	SearchPropertiesResponse,
	useSearchFilterParams,
	useSearchTableAverages,
} from 'api';
import { useSearchTableSelection } from 'hooks/useSearchTableSelection';
import { useCallback } from 'react';
import { useFilters } from 'reducers/filtersReducer';
import { routes } from 'router';
import { PropertyComp } from 'types';
import { usePropertiesAveragesFormatting } from '../usePropertiesAveragesFormatting';
import { useInfiniteTableRows } from '../../../utils/useInfiniteTableRows';
import { useSearchTableSort } from '../useSearchTableSort';
import { useUnifiedPropertiesInfiniteQuery } from './useUnifiedPropertiesInfiniteQuery';
import { ExportCompsButton } from 'Components/Export/ExportCompsButton';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { useIsMultifamily } from 'hooks';

export const PropertiesSearchTable = () => {
	const [filters] = useFilters();
	const markets = getFiltersMarkets(filters);
	const isMufa = useIsMultifamily({ markets });

	const params = useSearchFilterParams({ filters });

	const {
		data: propertiesData,
		fetchNextPage,
		isFetching,
		hasNextPage,
	} = useUnifiedPropertiesInfiniteQuery(params);

	const totalCount = propertiesData?.pages[0].totalCount ?? 0;
	const searchAverages = propertiesData?.pages[0].averages;

	const rows = useInfiniteTableRows<
		PropertyComp,
		SearchPropertiesResponse | SearchMufaPropertiesResponse
	>({
		data: propertiesData,
		getRows: useCallback((page) => page.properties, []),
		isFetching,
		pageSize: SEARCH_INFINITE_PAGE_SIZE,
	});

	const { data: averages } = useSearchTableAverages({
		compType: 'property',
		searchAverages,
		filters,
	});

	const averagesFormatting = usePropertiesAveragesFormatting(
		averages as SearchPropertiesAverages
	);

	const { initNavHeader } = useSearchPropertiesNavHeaderContext();

	const selectionProps = useSearchTableSelection({
		compType: 'property',
		totalCount,
		filters,
	});

	const sortProps = useSearchTableSort();

	return (
		<SearchTable
			{...selectionProps}
			{...sortProps}
			rows={rows}
			averages={averagesFormatting}
			noData={!isFetching && totalCount === 0}
			getRowId={(row) => row.id}
			onLoadMore={() => {
				if (hasNextPage && !isFetching) {
					fetchNextPage();
				}
			}}
			columnPreferencesType="property"
			renderActionButton={(row) => (
				<ExportCompsButton
					buttonType="wide-row"
					compType="property"
					compIds={[row.id]}
					isMufa={isMufa}
				/>
			)}
			getBodyCellProps={(row, rowIndex) => ({
				onClick: () => {
					initNavHeader(rowIndex, { filters });
				},
				state: {
					[NAV_KEY]: true,
				},
				to: routes.propertyById.toHref({ propertyId: row.id }),
			})}
		/>
	);
};
