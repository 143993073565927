import React, { memo } from 'react';
import { PROPERTY_TYPE_ID_TO_NAME, Portfolio, PropertyTypeId } from 'api';
import { formatPercent } from 'format';
import { groupBy, mapValues } from 'lodash';
import {
	ChartBox,
	ChartTypeProperty,
	getTotalValuesProperty,
} from '../ChartSelect';
import { PortfolioPieChart } from '../PortfolioPieChart';
import { useNavigate } from 'react-router';
import { routes } from 'router';
import { ChartStateWrapper } from '../ChartStateWrapper';

type PropertyTypeChartProps = {
	portfolio: Portfolio;
};

const PROPERTY_TYPE_BY_PROPERTY_SQFT_CHART_NAME = 'Property Type';

export const PropertyTypeByPropertySqftChart = memo(
	({ portfolio }: PropertyTypeChartProps) => {
		const groupsByPropertyTypeId = groupBy(
			portfolio.propertyMetricsPropertyTypesByMarkets,
			(p) => p.buildingPropertyTypeId
		);

		const propertyTypeIds = Object.keys(groupsByPropertyTypeId).map(
			(propertyTypeId) => Number(propertyTypeId)
		) as PropertyTypeId[];

		const totalValuesPerPropertyType = mapValues(
			groupsByPropertyTypeId,
			getTotalValuesProperty
		);

		const totalValuesForPortfolio = getTotalValuesProperty(
			Object.values(totalValuesPerPropertyType)
		);

		const propertyTypeByTotalBuildingSize = propertyTypeIds.map(
			(propertyTypeId) => {
				return getChartPoint(
					propertyTypeId,
					totalValuesPerPropertyType[propertyTypeId].totalBuildingSize,
					totalValuesForPortfolio.totalBuildingSize
				);
			}
		);

		const propertyTypeBypropertyCount = propertyTypeIds.map(
			(propertyTypeId) => {
				return getChartPoint(
					propertyTypeId,
					totalValuesPerPropertyType[propertyTypeId].propertyCount,
					totalValuesForPortfolio.propertyCount
				);
			}
		);

		const getData = (chartType: ChartTypeProperty) => {
			switch (chartType) {
				case 'totalBuildingSize':
					return propertyTypeByTotalBuildingSize;
				case 'propertyCount':
					return propertyTypeBypropertyCount;
				default:
					return [];
			}
		};

		const navigate = useNavigate();

		return (
			<ChartBox
				chartName={PROPERTY_TYPE_BY_PROPERTY_SQFT_CHART_NAME}
				chartDataType="property"
			>
				{(selectedChart) => {
					const data = getData(selectedChart.type);
					return (
						<ChartStateWrapper
							noData={data.length === 0 || !data.some((d) => d.y > 0)}
						>
							<PortfolioPieChart
								data={data}
								events={[
									{
										target: 'data',
										eventHandlers: {
											onClick: (_, clickedProps) => {
												const propertyTypeId = clickedProps.datum
													.propertyTypeId as PropertyTypeId;
												navigate(
													routes.portfolioByIdView.toHref(
														{ portfolioId: portfolio.id, viewType: 'list' },
														{ propertyTypeIds: [propertyTypeId] }
													)
												);
											},
										},
									},
								]}
								dataType="property"
							/>
						</ChartStateWrapper>
					);
				}}
			</ChartBox>
		);
	}
);

PropertyTypeByPropertySqftChart.displayName = 'BuildingClassChart';

const getChartPoint = (
	propertyTypeId: PropertyTypeId,
	value: number,
	totalValue: number
) => {
	return {
		x: PROPERTY_TYPE_ID_TO_NAME[propertyTypeId],
		y: value,
		label:
			totalValue !== 0
				? formatPercent(value / totalValue, 1)
				: formatPercent(0, 1),
		propertyTypeId,
	};
};
