import { MapStyle } from '@compstak/maps';
import { ButtonProps, defaultTheme } from '@compstak/ui-kit';
import GlobeIcon from 'ui/svg_icons/globe.svg';
import MapIcon from 'ui/svg_icons/map.svg';
import { MapButton } from './MapButton';

type Props = ButtonProps & {
	mapStyle: MapStyle;
};

export const MapStyleButton = ({ mapStyle, ...props }: Props) => {
	const Icon = iconMap[mapStyle];

	return (
		<MapButton
			variant="primary2"
			data-qa-id="map-style-btn"
			{...props}
			icon={<Icon fill={defaultTheme.colors.blue.blue400} />}
		>
			{textMap[mapStyle]}
		</MapButton>
	);
};

const iconMap = {
	[MapStyle.DEFAULT]: GlobeIcon,
	[MapStyle.MONOCHROME]: GlobeIcon,
	[MapStyle.SATELLITE]: MapIcon,
};

const textMap = {
	[MapStyle.DEFAULT]: 'satellite',
	[MapStyle.MONOCHROME]: 'satellite',
	[MapStyle.SATELLITE]: 'map view',
};
