import { PINPOINT_CIRCLE_ZOOM_BREAKPOINT } from '@compstak/maps';
import { Position } from '@turf/helpers';
import produce from 'immer';
import { PropertyComp } from 'types/comp';
import { getPromiseActionType } from 'types/redux-promise-middleware';
import {
	FOCUS_PROPERTY,
	REMOVE_PROPERTY,
	FOCUS_FILTER_SECTION,
	MapSearchAction,
} from './actions';
import { FiltersTypesKeys } from 'models/filters/types';

export type PropertyPopup = {
	coordinates: Position;
	zoom: number;
};

export type SearchMapState = {
	propertyPopup: PropertyPopup | null;
	property: PropertyComp | null;
	focusFilterSectionId: FiltersTypesKeys | null;
};

const initialState = {
	propertyPopup: null,
	property: null,
	focusFilterSectionId: null,
};

const mapReducer = (
	state: SearchMapState = initialState,
	action: MapSearchAction
): SearchMapState => {
	return produce(state, (draftState) => {
		switch (action.type) {
			case getPromiseActionType(FOCUS_PROPERTY, 'PENDING'): {
				const propertyPopup = action.meta.propertyPopup;
				if (propertyPopup) {
					draftState.propertyPopup = propertyPopup;
				} else {
					draftState.propertyPopup = null;
				}
				draftState.property = null;
				return;
			}

			case getPromiseActionType(FOCUS_PROPERTY, 'FULFILLED'): {
				const propertyPopup = action.meta.propertyPopup;
				const { lon, lat } = action.payload.geoPoint;

				if (!propertyPopup && !!lon && !!lat) {
					draftState.propertyPopup = {
						coordinates: [lon, lat],
						zoom: PINPOINT_CIRCLE_ZOOM_BREAKPOINT,
					};
				}
				draftState.property = action.payload;
				return;
			}

			case REMOVE_PROPERTY: {
				draftState.propertyPopup = null;
				draftState.property = null;
				return;
			}

			case FOCUS_FILTER_SECTION: {
				draftState.focusFilterSectionId = action.filterSectionId;
				return;
			}
		}
	});
};

export default mapReducer;
