import {
	SearchLeasesPayload,
	SearchLeasesResponse,
	UnlockLeasesResponse,
} from 'api';
import pointService from 'services/leasePoints';
import { LeaseComp } from 'types';
import factory from './factory';

const serviceController = factory.create<number, LeaseComp>({
	createUrl: (id) => '/api/comps/' + id,
	timeout: 1000 * 60 * 5,
});

export const onLeasesUnlock = (data: UnlockLeasesResponse) => {
	pointService.clearAll();
	data.comps.forEach(function (lease) {
		serviceController.service.clear(lease.id);
		service.add(lease.id, lease);
	});
};

const service = serviceController.exportable({
	unlock(ids: number[]) {
		return factory
			.put<
				{ ids: number[] },
				UnlockLeasesResponse
			>('/api/comps/actions/unlock', { ids: ids })
			.then(function (data) {
				onLeasesUnlock(data);
				return data.comps;
			});
	},

	// @ts-expect-error TS7006: Parameter 'id' implicitly has ...
	sendSuggestion(id, suggestion, market, dataType) {
		return factory
			.post('/api/comps/feedback', {
				compId: id,
				suggestion: suggestion,
				market: market,
				dataType: dataType,
			})
			.then(function (data) {
				return data;
			});
	},

	// @ts-expect-error TS7006: Parameter 'comp' implicitly ha...
	sendComp(comp) {
		return factory
			.post('/api/comps/actions/uploadForm', comp)
			.then(function (data) {
				return data;
			});
	},

	// @ts-expect-error TS7006: Parameter 'id' implicitly has ...
	share(id, email) {
		return factory
			.post('/api/comps/share', { compId: id, email: email })
			.then(function (data) {
				return data;
			});
	},
});

//@ts-expect-error uncomment to see the error
service.loadMany = function (idsOrPromises) {
	if (idsOrPromises.length === 1) {
		return Promise.all([service.load(idsOrPromises[0])]);
	}

	// Should it be this complicated? It seems like it shouldn't.
	// The reasons it is:
	// • We don't want to load comps we don't have to load.
	// • The cache should be filled immediately in case this is called twice

	return Promise.all(idsOrPromises).then(function (ids) {
		const resolves = {};
		const rejects = {};

		const idsToLoad = ids.filter(function (id) {
			return !service.has(id);
		});

		// This is the array of promises that will be returned.
		const promises = ids.map(function (id) {
			if (service.has(id)) {
				return service.load(id);
			}
			// Since we're loading the comps ourselves, we need to
			// create the promises and fill the cache ourselves.
			const promise = new Promise(function (resolve, reject) {
				// @ts-expect-error TS7053: Element implicitly has an 'any...
				resolves[id] = resolve;
				// @ts-expect-error TS7053: Element implicitly has an 'any...
				rejects[id] = reject;
			});
			service.add(id, promise);
			return promise;
		});

		// If everything has been loaded already, short circuit.
		if (idsToLoad.length === 0) {
			return Promise.all(promises);
		}

		const loader = factory.post<SearchLeasesPayload, SearchLeasesResponse>(
			'/api/comps/actions/search',
			{
				filter: [{ property: 'id', value: idsToLoad }],
				offset: 0,
				limit: idsToLoad.length,
				order: 'desc',
				sort: 'id',
			},
			true
		);

		loader.then(function (searchResults) {
			searchResults.comps.forEach(function (lease) {
				// @ts-expect-error TS7053: Element implicitly has an 'any...
				resolves[lease.id](lease);
			});
		});

		return Promise.all(promises);
	});
};

export default service;
