import { TopBuyerLeagueTable } from './TopBuyersLeagueTable';
import { LeagueTableHeader } from '../LeagueTableHeader';
// TODO: deprecated icon - use 'ui/svg_icons/help_v2.svg' instead
import HelpIcon from '../../../ui/svg_icons/help.svg';
import styled from 'styled-components';

export const SaleLeagueTables = () => {
	// TODO: current selected view conditionally renders a different table
	return (
		<>
			<LeagueTableHeader
				title={
					<HeaderLabelWrap>
						<HeaderLabel>League Tables</HeaderLabel>
						<HelpIcon />
					</HeaderLabelWrap>
				}
				// TODO: add view switcher here
			/>
			<TopBuyerLeagueTable />
		</>
	);
};

const HeaderLabel = styled.h1`
	margin: 0 8px 0 0;
	font-size: 32px;
	line-height: 48px;
	font-weight: 600;
`;

const HeaderLabelWrap = styled.span`
	display: flex;
	align-items: center;
`;
