import { MQB, TooltipV2 } from '@compstak/ui-kit';
import React, { forwardRef, memo, ReactNode, useCallback } from 'react';
import { Waypoint } from 'react-waypoint';
import styled, { keyframes } from 'styled-components';
// TODO: deprecated icon - use 'ui/svg_icons/help_v2.svg' instead
import Help from '../ui/svg_icons/help.svg';
import { Section } from './types';
import { css } from 'styled-components';
import { MapButtonsContainer } from 'maps';

export const Row = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;

	@media (max-width: ${MQB.T_834}) {
		flex-direction: column;
	}

	${({ theme }) => theme.breakpoints.up('D_1280')} {
		& > * {
			max-width: calc(50% - 0.75rem);
		}
	}
`;

const ExchangePanelTitleRow = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-bottom: 1rem;

	@media (max-width: ${MQB.T_834}) {
		flex-direction: column;
	}
`;

export const Container = styled.div`
	background: #fafafa;
	display: flex;
	flex-direction: row;
	height: calc(100vh - 60px);
	position: relative;
	z-index: 1;

	@media (max-width: ${MQB.T_834}) {
		height: calc(100vh - 40px);
	}
`;

export const Content = styled.main<{ scrollState: 'scroll' | 'no-scroll' }>`
	background-color: rgb(249, 249, 249);
	height: calc(100vh - 60px);
	width: 100%;
	padding: 0 0 4rem;
	overflow-y: ${(props) =>
		props.scrollState === 'scroll' ? 'auto' : 'hidden'};
	position: relative;

	@media (max-width: ${MQB.T_834}) {
		height: calc(100vh - 40px);
	}
`;

export const TopNav = styled.nav`
	background-color: #fff;
	border-bottom: 1px solid rgb(213, 213, 213);
	color: rgb(48, 52, 65);
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-family: ${({ theme }) => theme.typography.fontFamily.gotham};
	height: 50px;
	padding: 0 1.5rem;
	position: sticky;
	text-transform: normal;
	top: 0;
	width: 100%;
	z-index: 10;

	& ul {
		flex-wrap: wrap;
	}

	& [data-active='true'] {
		color: #333;
		border-radius: 0;
		border-bottom: 2px solid #4290f7;
	}

	ul li a {
		color: rgb(48, 52, 65);
		text-transform: uppercase;
		padding-bottom: 5px;
		&.active {
			border-bottom: 2px solid #4290f7;
		}
	}

	@media (max-width: ${MQB.T_834}) {
		display: none;
	}
`;

const SectionTitle = styled.h1`
	color: #303441;
	font-size: 1.25rem;
	font-style: normal;
	font-weight: 500;
	line-height: 2.125rem;
	margin: 1.5rem auto;
	text-align: center;
	text-transform: uppercase;
	width: max-content;
`;

export const SectionContainer = styled.div`
	padding: 0 1.5rem;
	position: relative;

	@media (max-width: ${MQB.T_834}) {
		padding: 0 0.75rem;
	}
`;

const SectionContainerFlex = styled.div<{ noBoxShadow?: boolean }>`
	background-color: #fff;
	border-radius: 3px;
	font-family: ${({ theme }) => theme.typography.fontFamily.gotham};

	padding: 1.5rem;
	position: relative;
	margin: 0 0 1.5rem;
	flex: 1;
	z-index: 1;

	${({ noBoxShadow }) =>
		// box-shadow breaks background color in PDFs
		!noBoxShadow &&
		css`
			box-shadow: 0px 1px 16px -4px rgba(51, 51, 51, 0.1);
			transition: box-shadow 300ms ease-in-out;
			&:hover {
				box-shadow:
					0px 4px 4px rgba(50, 50, 71, 0.08),
					0px 4px 8px rgba(50, 50, 71, 0.06);
			}
		`}
`;

export const PanelTitle = styled.h3`
	display: flex;
	align-items: center;
	font-size: 1rem;
	font-style: normal;
	font-weight: 500;
	line-height: 30px;
	text-transform: uppercase;
	white-space: pre;

	@media (max-width: ${MQB.T_834}) {
		font-size: 0.75rem;
	}
`;

type SectionProps = {
	id?: Section['type'];
	title?: string;
	cta?: ReactNode;
	setSectionsInView?: React.Dispatch<React.SetStateAction<Section['type'][]>>;
	children: ReactNode;
};

export const ExchangeSection = memo<SectionProps>(
	({ id, title, cta, setSectionsInView, children }) => {
		const onEnter = useCallback(() => {
			id &&
				setSectionsInView?.((oldSections) =>
					Array.from(new Set([...oldSections, id]))
				);
		}, [id, setSectionsInView]);
		const onLeave = useCallback(() => {
			id &&
				setSectionsInView?.((oldSections) =>
					oldSections.filter((s) => s !== id)
				);
		}, [id, setSectionsInView]);

		return (
			<Waypoint
				topOffset={150}
				bottomOffset={50}
				onEnter={onEnter}
				onLeave={onLeave}
			>
				<SectionContainer id={id}>
					<div>
						<SectionTitle>{title}</SectionTitle>
						{cta}
					</div>
					<Relative>{children}</Relative>
				</SectionContainer>
			</Waypoint>
		);
	}
);

ExchangeSection.displayName = 'ExchangeSection';

type ExchangePanelProps = {
	id: string;
	title?: ReactNode;
	cta?: ReactNode;
	tooltip?: ReactNode;
	noBoxShadow?: boolean;
	children: ReactNode;
};

export const ExchangePanel = forwardRef<HTMLDivElement, ExchangePanelProps>(
	(props, ref) => {
		return (
			<SectionContainerFlex
				id={props.id}
				ref={ref}
				noBoxShadow={props.noBoxShadow}
			>
				<Relative>
					{props.title != null && (
						<ExchangePanelTitleRow>
							<PanelTitle data-html2canvas-ignore>
								{props.title}{' '}
								{props.tooltip && (
									<TooltipV2 content={props.tooltip}>
										<span>
											<Help height={20} width={20} />
										</span>
									</TooltipV2>
								)}
							</PanelTitle>
							{props.cta}
						</ExchangePanelTitleRow>
					)}
					{props.children}
				</Relative>
			</SectionContainerFlex>
		);
	}
);

ExchangePanel.displayName = 'ExchangePanel';

const Relative = styled.div`
	position: relative;
	height: calc(100% - 3rem);
	margin: 0 auto;
	max-width: 2000px;
`;

export const TableTop = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 1rem;
	align-items: center;
	padding: 1rem;
	position: relative;
`;

export const TableTabs = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
`;

export const TableTab = styled.div<{ active: boolean }>`
	align-items: center;
	background-color: transparent;
	border-bottom: 2px solid
		${(props) => (props.active ? '#228fff' : 'transparent')};
	transition: border-color 250ms linear;
	color: rgb(48, 52, 65);
	cursor: pointer;
	display: flex;
	font-size: 11px;
	font-weight: 400;
	height: 40px;
	justify-content: center;
	letter-spacing: 0.1rem;
	padding: 0 0.5rem;
	text-align: center;
	text-transform: uppercase;
	position: relative;
	min-width: 174px;
	& span {
		padding: 0px 0.818em 0px 1em;
	}
`;

export const Table = styled.table`
	border: 1px solid rgb(209, 209, 209);
	width: 100%;
	display: table;
	white-space: normal;
	overflow-x: auto;
	table-layout: auto;

	${({ theme }) => theme.breakpoints.down('D_1280')} {
		display: block;
		white-space: nowrap;
	}

	& .td-container {
		min-height: 55px;
		color: inherit;
		padding: 8px 10px;
	}
`;

export const TableHead = styled.th`
	background-color: rgb(241, 242, 245);
	border: 1px solid rgb(223, 225, 232);
	color: #737b97;
	font-weight: 600;
	font-size: 12px;
	font-family: 'OpenSans', Helvetica, Arial, sans-serif;
	padding: 0 10px;
	height: 34px;
	max-width: 100%;
	white-space: nowrap;
`;
export const TableData = styled.td`
	background-color: #fff;
	border: 1px solid rgb(223, 225, 232);
	color: #464b5e;
	font-weight: 400;
	font-size: 12px;
	font-family: 'OpenSans', Helvetica, Arial, sans-serif;
	max-width: 100%;
	white-space: nowrap;
`;

export const TableRow = styled.tr`
	&:hover ${TableData} {
		background-color: #f5f5f5;
	}
`;

const Box = styled.div`
	background: #f9f9f9;
	border-radius: 3px;
	box-sizing: border-box;
	flex-grow: 1;
	flex: 1;
	margin: 1% 1% 0;
	min-height: 80px;
	padding: 0.75rem;
`;

const Property = styled.span`
	display: block;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	color: rgba(0, 0, 0, 0.87);
`;

const Value = styled.span`
	display: block;
	font-style: normal;
	font-weight: normal;
	font-size: 32px;
	line-height: 38.4px;
	color: rgba(0, 0, 0, 0.87);
	margin-bottom: 12px;
`;

type StatBoxProps = {
	property: string;
	value: string;
};

export const StatsContainer = styled.div`
	align-items: center;
	display: flex;
	flex-direction: row;
	justify-content: center;
	flex-wrap: wrap;
	margin-top: 12px;
`;

export const StatBox = (props: StatBoxProps) => {
	return (
		<Box>
			<Value>{props.value}</Value>
			<Property>{props.property}</Property>
		</Box>
	);
};

export const ExpandedChartContainer = styled.div`
	width: 100%;
	max-width: 1000px;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	padding: 0 50px 75px;
	h3 {
		font-size: 16px;
		text-transform: uppercase;
		color: rgba(0, 0, 0, 0.87);
	}
`;

export const ChartTooltip = styled.div`
	background-color: #313440;
	color: #fff;
	border-radius: 3px;
	width: 150px;
	height: 200px;
	padding: 8px;
	position: relative;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 20px;
	box-shadow:
		0px 8px 8px rgba(50, 50, 71, 0.08),
		0px 8px 16px rgba(50, 50, 71, 0.06);

	&:after {
		content: '';
		position: absolute;
		left: calc(75px - 8px);
		top: 200px;
		width: 0;
		height: 0;
		border-left: 8px solid transparent;
		border-right: 8px solid transparent;
		border-top: 8px solid #313440;

		clear: both;
	}

	.color {
		min-width: 8px;
		min-height: 8px;
		width: 8px;
		height: 8px;
		border-radius: 3px;
		margin-right: 8px;
	}
`;

export const TooltipRow = styled.div`
	align-items: center;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

const loadingAnimation = keyframes`
	0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

export const ViewButton = styled.button`
	color: #2d77e6;
	background-color: transparent;
	border: none;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	margin: 0;
	padding: 0;
	width: auto;
	overflow: visible;

	display: flex;
	align-items: center;
	gap: 5px;
`;

export const ButtonWrap = styled.div`
	display: flex;
	justify-content: flex-end;
	margin: 1rem 0;
	width: 100%;
`;

export const LoadingBox = styled.div<{
	height?: number;
	width?: string | number;
}>`
	background-color: #f8f8f8;
	background-image: linear-gradient(90deg, #f8f8f8, #fff, #f8f8f8);
	background-size: 200px 100%;
	background-repeat: no-repeat;
	-webkit-animation: ${loadingAnimation} 1.5s ease-in-out infinite;
	-moz-animation: ${loadingAnimation} 1.5s ease-in-out infinite;
	animation: ${loadingAnimation} 1.5s ease-in-out infinite;
	margin: 1rem 0;
	width: ${(props) => props?.width ?? '100%'};
	height: ${(props) => props?.height ?? '42'}px;
	border-radius: 3px;
	flex-grow: 1;
	flex: 1;
`;

export const LoadingChart = () => {
	return (
		<div>
			<LoadingBox />
			<LoadingBox />
			<LoadingBox />
			<LoadingBox />
			<LoadingBox />
			<LoadingBox />
			<LoadingBox />
		</div>
	);
};

export const MapMessageContainer = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	z-index: 2;
`;

export const ExchangeMapButtonsContainer = styled(MapButtonsContainer)`
	margin: 0 0.5rem 0.5rem 0.5rem;
	gap: 0.5rem;

	button {
		height: 30px;
	}
`;
