import { PortfolioPopup } from '../PortfolioMap/PortfolioPopup';
import { PortfolioPopupProps } from '../PortfolioMap/PortfolioPopup';
import { styled } from 'styled-components';

export type DataPoint = {
	performance: number;
	risk: number;
	reversedRisk: number;
	size: number;
	propertyId: number;
};

type PerformanceChartPopupProps = Omit<PortfolioPopupProps, 'propertyId'> & {
	dataPoint: DataPoint;
	top: string;
	left: string;
};

export const PerformanceChartPopup = (props: PerformanceChartPopupProps) => {
	const { closePopup, portfolio, dataPoint, left, top } = props;
	const { propertyId } = dataPoint;

	return (
		<PopupContainer left={left} top={top}>
			<PortfolioPopup
				closePopup={closePopup}
				isMinified={true}
				propertyId={propertyId}
				portfolio={portfolio}
			/>
		</PopupContainer>
	);
};

const PopupContainer = styled.div<{ left: string; top: string }>`
	position: absolute;
	left: ${({ left }) => left};
	top: ${({ top }) => top};
	transform: translate(-30%, 30%);
	padding: 2px;
	width: 240px;
	min-height: 100px;
	border-radius: 3px;
	background-color: #222530;
	z-index: ${({ theme }) => theme.zIndex.menu};
`;
