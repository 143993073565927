import { API } from '@compstak/common';
import { UseQueryOptions, useQueries } from '@tanstack/react-query';
import { QUERY_KEYS } from 'api/constants';
import { composeEnabled } from 'api/utils';
import { getQueryStringFromPortfolioFilters } from 'util/getQueryStringsFromPortfolioFilter';
import { PortfolioByIdResponse } from './usePortfolioByIdQuery';
import { FiltersState } from 'PortfolioAnalytics/PortfolioFiltersProvider';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

type PortfolioByIdParams = {
	id: number;
	filters?: FiltersState;
};

export const usePortfoliosByIdsQueries = (
	multiParams: PortfolioByIdParams[],
	options?: UseQueryOptions<PortfolioByIdResponse>
) => {
	const { portfolioExcludeSubleasesFF } = useFeatureFlags();

	return useQueries({
		queries: multiParams.map(({ id, filters }) => {
			const queryParams = getQueryStringFromPortfolioFilters(filters, {
				excludeSubleases: portfolioExcludeSubleasesFF,
			});

			return {
				queryKey: [QUERY_KEYS.PORTFOLIO_BY_ID, id, queryParams],
				queryFn: async () => {
					const response = await API.get<PortfolioByIdResponse>(
						`/pa/v2/api/portfolios/${id}${queryParams}`
					);
					return response.data;
				},
				...options,
				enabled: composeEnabled(id != null, options?.enabled),
			};
		}),
	});
};
