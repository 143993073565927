import { PropertyCountBeforeAddToPortfolioModal } from 'Components/AddToPortfolioDialog/PropertyCountBeforeAddToPortfolioModal';
import { ExportButtons } from 'Components/sidebarWidgets';
import { useMarketSelectorExpanded } from 'hooks/useMarketSelectorExpanded';
import 'Pages/SavedSearches/Components/SaveSearchMenu';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import MultiMarketSelector from '../../../Home/Sidebar/Components/MultiMarketSelector';
import { useDispatch } from 'react-redux';
import { showSaveSearchMenu } from 'Pages/SavedSearches/actions';
import { usePermissions, useUser } from 'Pages/Login/reducers';
import { FilterFields } from './FilterFields';
import { SidebarHeader } from './SidebarHeader';
import { getSetFilterKeysExceptMarkets } from 'models/filters/util/filterHelpers';
import { useNavigate } from 'react-router';
import { SEARCH_BY_VIEW_ROUTES } from 'router';
import { useFilterFieldContext } from 'Components/Filters/Fields/FilterFieldContextProvider';
import { useAppSelector } from 'util/useAppSelector';
import { SearchLayoutRouteParams } from 'Layouts/SearchLayout';
import { AddToPortfolioDialog } from 'Components/AddToPortfolioDialog/AddToPortfolioDialog';
import { extractPropertyIdsFromComps } from 'utils/compHelpers';

type SearchSidebarProps = {
	params: SearchLayoutRouteParams;
	onNewSearchClick: NoArgCallback;
};

export const SearchSidebar = ({
	onNewSearchClick: _onNewSearchClick,
	params,
}: SearchSidebarProps) => {
	const [isAddToPortfolioDialogOpen, setIsAddToPortfolioDialogOpen] =
		useState(false);
	const [isPropertyCountModalOpen, setIsPropertyCountModalOpen] =
		useState(false);
	const { compType, filters } = useFilterFieldContext();
	const marketSelectorExpanded = useMarketSelectorExpanded();
	const permissions = usePermissions();
	const selection = useAppSelector((store) => store.selection.list);
	const user = useUser();

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const onShowSaveSearchMenu = useCallback(
		(event: React.MouseEvent) => {
			dispatch(showSaveSearchMenu(filters, event.target, true, compType));
		},
		[dispatch, filters, compType]
	);

	const addToPortfolioPropertyIds = useMemo(() => {
		return extractPropertyIdsFromComps({ comps: selection, compType });
	}, [selection]);

	const setFiltersCount = useMemo(() => {
		return getSetFilterKeysExceptMarkets(filters).length;
	}, [filters]);

	const onNewSearchClick = () => {
		_onNewSearchClick();
		navigate(SEARCH_BY_VIEW_ROUTES[compType].toHref({ view: 'map' }));
	};

	return (
		<div>
			<SidebarHeader
				filterCount={setFiltersCount}
				onNewSearchClick={onNewSearchClick}
			></SidebarHeader>
			<MultiMarketWrap expanded={marketSelectorExpanded}>
				<MultiMarketSelector
					compType={compType}
					filters={filters}
					permissions={permissions}
					user={user}
					marketSelectorExpanded={marketSelectorExpanded}
					resetFiltersOnApply={false}
				/>
			</MultiMarketWrap>
			<div style={{ display: marketSelectorExpanded ? 'none' : 'block' }}>
				<FilterFields params={params} />
				<ExportButtons
					data-qa-id="ExportButtons-2"
					compType={compType}
					filters={filters}
					selection={selection}
					showAddToPortfolioModal={() => {
						compType === 'property'
							? setIsAddToPortfolioDialogOpen(true)
							: setIsPropertyCountModalOpen(true);
					}}
					showSaveSearchMenu={onShowSaveSearchMenu}
				/>
				{isPropertyCountModalOpen && (
					<PropertyCountBeforeAddToPortfolioModal
						propertyIds={addToPortfolioPropertyIds}
						closePropertyCountModal={() => setIsPropertyCountModalOpen(false)}
						compType={compType}
					/>
				)}
				{isAddToPortfolioDialogOpen && (
					<AddToPortfolioDialog
						propertyIds={addToPortfolioPropertyIds}
						closeDialog={() => setIsAddToPortfolioDialogOpen(false)}
					/>
				)}
			</div>
		</div>
	);
};

const MultiMarketWrap = styled.div<{ expanded: boolean }>`
	position: ${({ expanded }) => (expanded ? 'static' : 'sticky')};
	top: 3.5rem;
	background: ${({ theme }) => theme.colors.gray.gray700};
	z-index: ${({ theme }) => theme.zIndex.menu};
	padding: 0 1.25rem 0.625rem 1.25rem;
	margin: 0;
	.market-selector {
		margin-bottom: 0;
	}
`;
