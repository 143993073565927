import { Button } from '@compstak/ui-kit';
import styled from 'styled-components';

export const MapButton = styled(Button)`
	min-width: 100px;
	height: 40px;
	font-size: 0.75rem;
	font-weight: 400;
	box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.1);
	border: none;
	display: flex;
	gap: 0.5rem;
`;
