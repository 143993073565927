import { Flex, Select } from '@compstak/ui-kit';
import { useMarketStatsFilters } from './MarketStatsFiltersProvider';
import { distributionMonthsListItems } from 'types/propertyTrendCharts';

export const MarketStatsFilters = () => {
	const { marketStatsFilters, setMarketStatsFilters, spaceTypeItems } =
		useMarketStatsFilters();

	return (
		<Flex gap="0" justifyContent="start">
			{spaceTypeItems.length > 1 && (
				<Select
					items={spaceTypeItems}
					value={marketStatsFilters.spaceType}
					onChange={({ selectedItem }) => {
						if (selectedItem)
							setMarketStatsFilters((s) => ({
								...s,
								spaceType: selectedItem.value,
							}));
					}}
					placeholder={'Space Type'}
				/>
			)}
			<Select
				items={distributionMonthsListItems}
				value={marketStatsFilters.duration}
				onChange={({ selectedItem }) => {
					if (selectedItem) {
						setMarketStatsFilters((s) => ({
							...s,
							duration: selectedItem.value,
						}));
					}
				}}
			/>
		</Flex>
	);
};
