import { useMarkets } from 'hooks/useMarkets';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { PropertyComp } from 'types';
import { createSearchLeasesUrl } from 'utils';

export const NumberOfActiveLeasesCell = ({
	property,
}: {
	property: PropertyComp;
}) => {
	const markets = useMarkets();

	const searchLeasesUrl = createSearchLeasesUrl({
		market: markets[property.marketDisplayName],
		address: [`${property.buildingAddress}, ${property.city}`],
		expirationDate: {
			min: new Date(),
			max: null,
			allowFallback: false,
		},
	});

	return (
		<StyledLink
			to={searchLeasesUrl}
			target="_blank"
			onClick={(e) => e.stopPropagation()}
		>
			{property.notExpiredLeaseCount}
		</StyledLink>
	);
};

const StyledLink = styled(Link)`
	align-content: center;
	display: block;
	width: 100%;
	height: 100%;
`;
