import React, { RefCallback, useMemo, useState } from 'react';
import { PortfolioV2, useInsightsQuery } from 'api';
import { FiltersState } from '../../PortfolioFiltersProvider';
import { TrendFilters } from 'PortfolioAnalytics/portfolioAnalyticsTypes';
import { DownloadBranding } from 'PortfolioAnalytics/Singletons/DownloadBranding';
import {
	PortfolioTrendChartComponent as Chart,
	PortfolioTrendChartData,
} from './Chart/PortfolioTrendChartComponent';
import { PortfolioTrendFiltersComponent as Filters } from './Filters/PortfolioTrendFilterComponent';
import { styled } from 'styled-components';
import { Spacer } from 'PortfolioAnalytics/UI';
import {
	getTrendlineData,
	getDateForTimeSpan,
	getScatterData,
	getTrendQueryParams,
} from './util';
import { CalculationOverviewLink } from 'ui/CalculationOverviewLink';
import { ChartBox } from 'PortfolioAnalytics/Charts/ChartSelect';
import { ChartStateWrapper } from 'PortfolioAnalytics/Charts/ChartStateWrapper';
import { ChartRoot } from 'PortfolioAnalytics/styles/PortfolioUI';

type PortfolioTrendChartProps = {
	portfolio: PortfolioV2;
	filters: FiltersState;
	onChartRef: RefCallback<HTMLElement>;
};

export const PortfolioTrendChart = ({
	portfolio,
	filters,
	onChartRef,
}: PortfolioTrendChartProps) => {
	return (
		<ChartBox
			chartName="Trends"
			displaySelector={false}
			chartToolTip={PORTFOLIO_TREND_CHART_TOOLTIP}
		>
			<PortfolioTrendChartContent
				portfolio={portfolio}
				filters={filters}
				onChartRef={onChartRef}
			/>
		</ChartBox>
	);
};

const PortfolioTrendChartContent = ({
	portfolio,
	filters,
	onChartRef,
}: PortfolioTrendChartProps) => {
	const [trendFilters, setTrendFilters] = useState<TrendFilters>({
		metric: 'effectiveRent',
		timeSpan: 3,
		movAverage: 3,
	});

	const portfolioQueryParams = useMemo(() => {
		return getTrendQueryParams({
			filters,
			portfolio: portfolio,
			trendFilters: trendFilters,
		}).portfolioQueryParams;
	}, [filters, portfolio, trendFilters]);

	const {
		data: portfolioTrendData,
		isFetching,
		isError: isPortfolioTrendError,
	} = useInsightsQuery({
		params: portfolioQueryParams,
	});

	const isBubbleChart = !!portfolioTrendData?.points;

	const chartData: PortfolioTrendChartData = useMemo(() => {
		if (
			!portfolioTrendData?.trendLine?.length ||
			(portfolioTrendData.points && !portfolioTrendData.points.length)
		) {
			return {
				portfolio: [],
				points: [],
			};
		}

		const timeSpanDate = getDateForTimeSpan(trendFilters.timeSpan);

		return {
			portfolio: getTrendlineData(portfolioTrendData.trendLine, timeSpanDate),
			points: getScatterData(
				portfolioTrendData.points || [],
				trendFilters.metric
			),
		};
	}, [portfolioTrendData, trendFilters.timeSpan, trendFilters.metric]);

	const noData = !chartData.portfolio.length && !chartData.points.length;

	const title = 'Portfolio Trend';
	const downloadBannerId = title.replaceAll(' ', '');

	return (
		<ChartStateWrapper
			isError={isPortfolioTrendError}
			isFetching={isFetching}
			noData={noData}
			showNoDataMessage={false}
		>
			<ChartRoot>
				<Filters
					filters={trendFilters}
					setMarketTrendFilters={setTrendFilters}
					isLoading={isFetching}
					isBubbleChart={isBubbleChart}
				/>
				<Chart
					data={chartData}
					noData={noData}
					metricFilter={trendFilters.metric}
					timeSpan={trendFilters.timeSpan}
					isBubbleChart={isBubbleChart}
					onChartRef={onChartRef}
				/>
				<DownloadBranding bannerId={downloadBannerId} />
			</ChartRoot>
		</ChartStateWrapper>
	);
};

export const CalculationOverview = styled(CalculationOverviewLink)`
	color: ${({ theme }) => theme.colors.blue.blue500};
	font-size: 0.75rem;
	font-weight: 350;
	line-height: 1.125rem;
	letter-spacing: 0.3px;
	padding: 0.25rem 0;
	text-transform: uppercase;
	&:hover {
		color: ${({ theme }) => theme.colors.blue.blue800};
	}
`;

const PORTFOLIO_TREND_CHART_TOOLTIP = (
	<>
		<Spacer>
			The portfolio trend chart plots the moving averages of several portfolio
			lease metrics over time.
		</Spacer>
		<Spacer>
			You can use the Metric selector to switch between Net Effective Rent,
			Starting Rent, Lease Term, Free Rent and Tenant Improvement Allowance.
		</Spacer>
		<Spacer>
			You can also select a timespan between one year and 15 years, and adjust
			the moving average period.
		</Spacer>
		<Spacer>
			<CalculationOverview context="charts" />
		</Spacer>
	</>
);
