import Between from 'Components/Filters/Base/Between';
import { FilterBase } from 'Components/Filters/Base/Filter/FilterBase';

const attribute = 'pricePerUnit' as const;

export const PricePerUnitFilter = () => {
	return (
		<FilterBase attribute={attribute}>
			{(props) => <Between {...props} />}
		</FilterBase>
	);
};
