import { useSearchPageContext } from 'Layouts/SearchProvider';
import IconChevronUp from 'ui/svg_icons/chevron_up.svg';
import IconCross from 'ui/svg_icons/cross.svg';
import IconLayers from 'ui/svg_icons/layers.svg';
import { useSearchMapState } from '../SearchMapProvider';
import { MapButton } from 'maps/components/Buttons/MapButton';
import { defaultTheme } from '@compstak/ui-kit';

export const MapAnalyticsButton = () => {
	const { searchState, setSearchState } = useSearchPageContext();
	const [searchMapState, setSearchMapState] = useSearchMapState();

	let text = 'map analytics';
	let Icon = IconLayers;

	if (searchState.mapAnalyticsType) {
		text = 'change map';
		Icon = IconChevronUp;
	}
	if (searchMapState.isAnalyticsMenuOpen) {
		text = 'exit analytics';
		Icon = IconCross;
	}

	const onClick = () => {
		if (searchMapState.isAnalyticsMenuOpen) {
			setSearchMapState((s) => ({
				...s,
				isAnalyticsMenuOpen: false,
				isAnalyticsDetailMenuOpen: false,
			}));
			setSearchState((s) => ({
				...s,
				mapAnalyticsType: null,
			}));
		} else {
			setSearchMapState((s) => ({
				...s,
				isAnalyticsMenuOpen: true,
				isAnalyticsDetailMenuOpen: false,
			}));
		}
	};

	return (
		<MapButton
			variant="success"
			data-qa-id="map-analytics-btn"
			onClick={onClick}
			icon={<Icon fill={defaultTheme.colors.white.white} />}
		>
			{text}
		</MapButton>
	);
};
