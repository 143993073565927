import { usePortfolioLeasesFilters } from '../PortfolioLeasesFiltersProvider';
import styled from 'styled-components';
import { CriterionRadioButton } from './CriterionRadioButton';
import { Checkbox } from 'Components';
import { ROW_HEIGHT } from 'PortfolioAnalytics/constants';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

export const LeaseActivityToolbar = () => {
	const { filters, setFilters } = usePortfolioLeasesFilters();
	const { portfolioExcludeSubleasesFF } = useFeatureFlags();

	return (
		<Container>
			<strong>View:</strong>
			<CriterionRadioButton
				criterion="newOnCompstak"
				filters={filters}
				setFilters={setFilters}
			>
				New on CompStak
			</CriterionRadioButton>
			<CriterionRadioButton
				criterion="recentlyUpdated"
				filters={filters}
				setFilters={setFilters}
			>
				Recently Updated
			</CriterionRadioButton>
			<CriterionRadioButton
				criterion="allLeases"
				filters={filters}
				setFilters={setFilters}
			>
				All Leases
			</CriterionRadioButton>
			<Checkbox
				id="activeLeasesOnly"
				checked={filters.activeLeasesOnly}
				onClick={() =>
					setFilters((prevFilters) => ({
						...prevFilters,
						activeLeasesOnly: !prevFilters.activeLeasesOnly,
					}))
				}
			>
				Active Leases Only
			</Checkbox>
			{portfolioExcludeSubleasesFF && (
				<Checkbox
					id="sublease"
					checked={filters.includeSubleases}
					onClick={() =>
						setFilters((prevFilters) => ({
							...prevFilters,
							includeSubleases: !prevFilters.includeSubleases,
						}))
					}
				>
					Include Subleases
				</Checkbox>
			)}
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	align-items: center;
	gap: 1.5rem;
	padding: 0.5rem 1rem;
	min-height: ${ROW_HEIGHT}px;

	color: ${({ theme }) => theme.colors.gray.gray700};
	font-size: 0.875em;
`;
