import dayjs from 'dayjs';
import { FiltersObject } from 'models/filters/types';
import { PortfolioByIdRouteSearchParams } from 'router';

export const getFiltersFromPortfolioIdRouteSearchParams = (
	searchParams: PortfolioByIdRouteSearchParams
) => {
	const filters: Partial<FiltersObject> = {};

	if (searchParams.tenantNames) {
		filters.tenantNames = searchParams.tenantNames;
	}

	if (searchParams.tenantIndustry) {
		filters.tenantIndustry = searchParams.tenantIndustry;
	}

	if (searchParams.expirationYears) {
		const expirationYear = searchParams.expirationYears.sort()[0];
		const firstDayOfExpirationYear = dayjs(`${expirationYear}-01-01`);
		const lastDayOfExpirationYear = dayjs(`${expirationYear}-12-31`);
		filters.expirationDate = {
			min: firstDayOfExpirationYear.isAfter(dayjs())
				? firstDayOfExpirationYear.toDate()
				: dayjs().toDate(),
			max: lastDayOfExpirationYear.toDate(),
			allowFallback: false,
		};
	}

	return filters;
};
