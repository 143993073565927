import { Chip, Pending, TooltipV2, defaultTheme } from '@compstak/ui-kit';
import { formatNumber } from 'format';
import styled, { css } from 'styled-components';

import { PortfolioShortWithAggregations } from './usePortfoliosWithAggregations';
import { useNewLeasesInPortfolio } from './useNewLeasesInPortfolio';
import { ReactNode } from 'react';

type Props = {
	isSelected?: boolean;
	isDisabled?: boolean;
	portfolio: PortfolioShortWithAggregations;
	onClick: () => void;
	withAlerts?: boolean;
	children?: ReactNode;
};

export const PortfolioSidebarItem = ({
	isSelected,
	isDisabled,
	portfolio,
	onClick,
	children,
	withAlerts = true,
}: Props) => {
	const {
		data: newLeasesInPortfolio,
		isFetching: isFetchingNewLeasesInPortfolio,
		isNotificationShown,
	} = useNewLeasesInPortfolio(
		{
			portfolioId: portfolio.id,
		},
		{
			enabled: withAlerts && portfolio.propertyCount > 0,
		}
	);

	const totalSqft = portfolio.markets.reduce(
		(acc, market) => acc + market.totalSqFt,
		0
	);

	return (
		<ListItem isSelected={isSelected} isDisabled={isDisabled} onClick={onClick}>
			{children}
			<ListItemInfoContainer>
				<ListItemContentContainer>
					<TooltipV2
						align="start"
						shouldShowArrow
						content={
							<ListItemTitleTooltip>{portfolio.name}</ListItemTitleTooltip>
						}
						delayDuration={500}
					>
						<ListItemTitle>{portfolio.name}</ListItemTitle>
					</TooltipV2>

					<ListItemInfo>{portfolio.propertyCount} Properties</ListItemInfo>
				</ListItemContentContainer>

				<ListItemInfo2>
					{isFetchingNewLeasesInPortfolio ? (
						<StyledPending
							margin="0"
							bgColor={defaultTheme.colors.neutral.n300}
						/>
					) : (
						isNotificationShown &&
						!!newLeasesInPortfolio?.totalCount && (
							<StyledChip>
								{formatNumber(newLeasesInPortfolio.totalCount)} New
							</StyledChip>
						)
					)}
					<ListItemInfo>{formatNumber(totalSqft)} SqFt</ListItemInfo>
				</ListItemInfo2>
			</ListItemInfoContainer>
		</ListItem>
	);
};

const ListItemContentContainer = styled.div`
	overflow: hidden;
`;

const ListItem = styled.li<{ isSelected?: boolean; isDisabled?: boolean }>`
	display: flex;
	align-items: center;
	gap: 0.5em;
	padding: 1em 0.75em;
	cursor: pointer;
	border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.n300};
	min-height: 4.5em;
	color: ${(p) => p.theme.colors.white.white};

	${({ isSelected, theme }) =>
		isSelected &&
		`border-left: 6px solid ${theme.colors.blue.blue500}; background-color: ${theme.colors.neutral.n300};`}

	&:hover {
		background-color: ${({ theme }) => theme.colors.neutral.n300};
	}

	${({ isDisabled, theme }) =>
		isDisabled &&
		css`
			cursor: not-allowed;
			color: ${theme.colors.neutral.n70};
			${ListItemInfo} {
				color: ${theme.colors.neutral.n70};
			}
		`}
`;

const ListItemTitle = styled.div`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 1em;
	font-weight: 300;
`;

const ListItemTitleTooltip = styled.div`
	word-break: break-word;
`;

const ListItemInfoContainer = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	flex-grow: 1;
	gap: 0.5em;
	overflow: hidden;
`;

const ListItemInfo = styled.div`
	font-size: 0.75em;
	color: ${(p) => p.theme.colors.neutral.n50};
`;

const ListItemInfo2 = styled.div`
	display: flex;
	flex-direction: column;
	align-self: flex-end;
	gap: 0.125em;
	flex-shrink: 0;
`;

const StyledChip = styled(Chip)`
	align-self: flex-end;
	text-align: center;
	border-radius: 0.5rem;
	font-size: 0.75em;
	padding: 0.25em 0.5em;

	background-color: ${(p) => p.theme.colors.blue.blue400};
`;

const StyledPending = styled(Pending)`
	min-height: 1em;
`;
