import {
	GoogleMapConfigProvider,
	MapboxConfigProvider,
	useStreetViewEnabled,
} from '@compstak/maps';
import { defaultTheme } from '@compstak/ui-kit';
import * as Dialog from '@radix-ui/react-dialog';
import { Document } from '@react-pdf/renderer';
import {
	ModalCloseButton,
	ModalCloseIcon,
	ModalOverlay,
	onInteractOutsideModal,
	onPointerDownOutsideModal,
} from 'Components/Modals/common/UI';
import { useAppConfig } from 'Pages/Login/reducers';
import { EditContent } from 'pdf/UI';
import { useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { PdfPage } from '../../../pdf/PdfPage';
import { useSubmarketTrendsContext } from '../SubmarketTrendsTab/SubmarketTrendsProvider';
import { STREET_VIEW_RADIUS } from '../constants';
import { CompsetPage } from '../pdf/CompsetPage/CompsetPage';
import { EditCompsetPage } from '../pdf/CompsetPage/EditCompsetPage';
import { useEditableCompsetState } from '../pdf/CompsetPage/useEditableCompsetState';
import { useEditableLeasesInCompsetState } from '../pdf/CompsetPage/useEditableLeasesInCompsetState';
import { CoverPage } from '../pdf/CoverPage/CoverPage';
import { EditCoverPage } from '../pdf/CoverPage/EditCoverPage';
import { ExportPdfButton } from '../pdf/ExportPdfButton';
import {
	SECTIONS_MAP,
	SectionKey,
	useExportPdfContext,
} from '../pdf/ExportPdfProvider';
import { useExportPdfStateContext } from '../pdf/ExportPdfStateProvider';
import { EditLeasesPage } from '../pdf/LeasesPage/EditLeasesPage';
import { LeasesPage } from '../pdf/LeasesPage/LeasesPage';
import { useEditableLeasesState } from '../pdf/LeasesPage/useEditableLeasesState';
import { EditLoansPage } from '../pdf/LoansPage/EditLoansPage';
import { LoansPage } from '../pdf/LoansPage/LoansPage';
import { useEditablePublicLoans } from '../pdf/LoansPage/useEditablePublicLoans';
import { EditMarketStatsPage } from '../pdf/MarketStatsPage/EditMarketStatsPage';
import { MarketStatsPage } from '../pdf/MarketStatsPage/MarketStatsPage';
import { EditMultifamilyPage } from '../pdf/MultifamilyPage/EditMultifamilyPage';
import { MultifamilyPage } from '../pdf/MultifamilyPage/MultifamilyPage';
import { useEditableMultifamily } from '../pdf/MultifamilyPage/useEditableMultifamily';
import { EditOverviewPage } from '../pdf/OverviewPage/EditOverviewPage';
import { OverviewPage } from '../pdf/OverviewPage/OverviewPage';
import { useEditableCompstakEstimates } from '../pdf/OverviewPage/useEditableCompstakEstimates';
import { PropertyEditPage } from '../pdf/PropertyEditPage';
import { EditSalesPage } from '../pdf/SalesPage/EditSalesPage';
import { SalesPage } from '../pdf/SalesPage/SalesPage';
import { useEditableSalesState } from '../pdf/SalesPage/useEditableSalesState';
import { EditSubmarketTrends } from '../pdf/SubmarketTrends';
import { SubmarketTrendsPage } from '../pdf/SubmarketTrends/SubmarketTrendsPage';
import { useEditableSubmarketRecentLeases } from '../pdf/SubmarketTrends/useEditableSubmarketRecentLeases';
import { FormControlLabel } from '../pdf/UI';
import { getDocumentTitle } from '../pdf/getDocumentTitle';
import { ExportPdfStep, PdfPageProps } from '../pdf/types';
import { ExportPdfData } from '../pdf/useExportPdfData';
import { CheckboxControl } from './CheckboxControl';

type Props = {
	data: ExportPdfData;
};

export const ExportPdfStepTwo = ({ data }: Props) => {
	const appConfig = useAppConfig();

	const { sections, toggleSection, leaseOptionsState, setStep } =
		useExportPdfContext();

	const {
		page,
		setPage,
		editableOverviewFields,
		setEditableOverviewFields,
		editableLeaseSnapshotFields,
	} = useExportPdfStateContext();

	const pdfPageProps: PdfPageProps = {
		user: data.user,
		page,
		property: data.property,
		apn: data.apn,
	};

	const { loans, summary, keyMetrics, mufaFloorPlans, submarketRecentLeases } =
		data;

	const {
		editableCompsetColumns,
		setEditableCompsetColumns,
		editableCompsetRows,
		setEditableCompsetRows,
	} = useEditableCompsetState({ data });

	const {
		editableLeasesInCompsetColumns,
		setEditableLeasesInCompsetColumns,
		editableLeasesInCompsetRows,
		setEditableLeasesInCompsetRows,
		leasesInCompsetSelection,
	} = useEditableLeasesInCompsetState({ data });

	const loan = loans?.treppLoans?.[0];

	const {
		editablePublicLoansRow,
		setEditablePublicLoansRow,
		editablePublicLoansColumns,
		setEditablePublicLoansColumns,
	} = useEditablePublicLoans({ property: pdfPageProps.property });

	const { editableMultiFamilyRow } = useEditableMultifamily({
		floorPlans: mufaFloorPlans?.floorPlans,
	});

	const { isStreetViewEnabled, isLoading: isLoadingStreetView } =
		useStreetViewEnabled({
			lat: data.property.geoPoint.lat,
			lon: data.property.geoPoint.lon,
			radius: STREET_VIEW_RADIUS,
		});

	const { trendsToShow } = useSubmarketTrendsContext();

	const [imageUrls, setImageUrls] = useState<ImageUrls>({
		commercial: '',
		mufa1: '',
		mufa2: '',
		marketStats: '',
	});

	const {
		editableLeasesColumnsBySpaceType,
		setEditableLeasesColumnsBySpaceType,
		editableLeasesRowsBySpaceType,
		setEditableLeasesRowsBySpaceType,
		leasesSelectionBySpaceType,
		setLeasesSelectionBySpaceType,
		selectedLeaseIds,
		selectedLeasesBySpaceType,
	} = useEditableLeasesState({ data });

	const {
		editableSales,
		setEditableSales,
		editableSalesColumns,
		setEditableSalesColumns,
		salesSelection,
	} = useEditableSalesState({ data });

	const { editableCompstakEstimates, setEditableCompstakEstimates } =
		useEditableCompstakEstimates();

	const editableSubmarketLeases = useEditableSubmarketRecentLeases({
		property: pdfPageProps.property,
		comps: submarketRecentLeases,
	});

	const title = getDocumentTitle(data.property);

	return (
		<StepTwoDialogOverlay>
			<Dialog.Content
				onInteractOutside={onInteractOutsideModal}
				onPointerDownOutside={onPointerDownOutsideModal}
			>
				<Toolbar>
					<div>
						<CheckboxesLabel>
							Select sections to include in the report:
						</CheckboxesLabel>
						<CheckboxesContainer>
							{Object.keys(sections).map((name) => {
								if (
									name === 'leasesInCompset' ||
									name === 'leaseSnapshot' ||
									name === 'marketEstimates' ||
									name === 'marketStats'
								) {
									return null;
								}
								return (
									<CheckboxControl
										key={name}
										label={SECTIONS_MAP[name as SectionKey]}
										checked={!!sections[name as SectionKey]}
										onCheckedChange={(value) => {
											toggleSection(name as SectionKey, value);
										}}
										horizontal
									/>
								);
							})}
						</CheckboxesContainer>
					</div>
					<RightContainer>
						<TextButton
							onClick={() => {
								setStep(ExportPdfStep.ONE);
							}}
						>
							Edit Export Settings
						</TextButton>
						<ExportPdfButton
							data={data}
							recentLeasesSelection={
								sections.submarketTrends
									? editableSubmarketLeases.recentLeasesSelection.selection
									: []
							}
							leasesInCompsetSelection={
								sections.compset && sections.leasesInCompset
									? leasesInCompsetSelection.selection
									: []
							}
							leasesSelection={sections.leases ? selectedLeaseIds : []}
							salesSelection={sections.sales ? salesSelection.selection : []}
							onPrepared={({ imageUrls }) => {
								setImageUrls(imageUrls);
							}}
							document={
								<GoogleMapConfigProvider googleApiKey={appConfig.google.apiKey}>
									<MapboxConfigProvider
										accessToken={appConfig.mapbox.accessToken}
									>
										<ThemeProvider theme={defaultTheme}>
											<Document title={title}>
												{sections.cover && (
													<CoverPage
														{...pdfPageProps}
														isStreetViewEnabled={isStreetViewEnabled}
													/>
												)}
												{sections.overview && (
													<OverviewPage
														{...pdfPageProps}
														editableOverviewFields={editableOverviewFields}
														isMufa={data.isMufa}
														isStreetViewEnabled={isStreetViewEnabled}
														editableLeaseSnapshotFields={
															editableLeaseSnapshotFields
														}
														editableCompstakEstimates={
															editableCompstakEstimates
														}
														sections={sections}
													/>
												)}
												{sections.marketStats && (
													<MarketStatsPage
														{...pdfPageProps}
														imageUrl={imageUrls.marketStats}
														marketStatsFilters={data.marketStatsFilters}
													/>
												)}
												{sections.compset && (
													<CompsetPage
														{...pdfPageProps}
														rows={data.compsetRows}
														columns={editableCompsetColumns}
														compsetType={data.compsetType}
														editableCompsetRows={editableCompsetRows}
														editableLeasesInCompsetColumns={
															editableLeasesInCompsetColumns
														}
														editableLeasesInCompsetRows={leasesInCompsetSelection.getSelectedRows()}
														showLeasesInCompset={!!sections.leasesInCompset}
														propertyLeaseCount={data.leases.length}
													/>
												)}
												{sections.submarketTrends && (
													<SubmarketTrendsPage
														trendChartsURL={imageUrls}
														trendsToShow={trendsToShow}
														editableSubmarketRecentLeasesRow={editableSubmarketLeases.recentLeasesSelection.getSelectedRows()}
														columns={
															editableSubmarketLeases.editableSubmarketRecenetLeasesColumns
														}
														{...pdfPageProps}
													/>
												)}
												{sections.multifamily && (
													<MultifamilyPage
														{...pdfPageProps}
														summary={summary}
														keyMetrics={keyMetrics}
														floorPlans={editableMultiFamilyRow}
													/>
												)}
												{sections.leases && (
													<LeasesPage
														{...pdfPageProps}
														leases={data.leases}
														editableLeasesColumnsBySpaceType={
															editableLeasesColumnsBySpaceType
														}
														editableLeasesRowsBySpaceType={
															selectedLeasesBySpaceType
														}
														leaseStatus={leaseOptionsState.leaseStatus}
													/>
												)}
												{(sections.sales || sections.loans) && (
													<PdfPage
														logo={page.logo}
														preparedFor={page.preparedFor}
														title={page.titles.sales}
														user={data.user}
													>
														{sections.sales && (
															<SalesPage
																editableSales={salesSelection.getSelectedRows()}
																editableSalesColumns={editableSalesColumns}
															/>
														)}
														{sections.loans && (
															<LoansPage
																loan={loan}
																editablePublicLoansRow={editablePublicLoansRow}
																columns={editablePublicLoansColumns}
															/>
														)}
													</PdfPage>
												)}
											</Document>
										</ThemeProvider>
									</MapboxConfigProvider>
								</GoogleMapConfigProvider>
							}
						/>
						<ModalCloseButton style={{ position: 'unset' }}>
							<ModalCloseIcon />
						</ModalCloseButton>
					</RightContainer>
				</Toolbar>
				<EditContent>
					{sections.cover && (
						<EditCoverPage
							page={page}
							setPage={setPage}
							property={data.property}
							isStreetViewEnabled={isStreetViewEnabled}
							isLoadingStreetView={isLoadingStreetView}
						/>
					)}
					{sections.overview && (
						<EditOverviewPage
							property={data.property}
							setEditableOverviewFields={setEditableOverviewFields}
							editableOverviewFields={editableOverviewFields}
							isStreetViewEnabled={isStreetViewEnabled}
							isLoadingStreetView={isLoadingStreetView}
							editableCompstakEstimates={editableCompstakEstimates}
							setEditableCompstakEstimates={setEditableCompstakEstimates}
						/>
					)}
					{sections.marketStats && (
						<EditMarketStatsPage property={data.property} />
					)}
					{sections.compset && (
						<EditCompsetPage
							rows={data.compsetRows}
							editableCompsetRows={editableCompsetRows}
							setEditableCompsetRows={setEditableCompsetRows}
							columns={editableCompsetColumns}
							setEditableCompsetColumns={setEditableCompsetColumns}
							editableLeasesInCompset={editableLeasesInCompsetRows}
							setEditableLeasesInCompset={setEditableLeasesInCompsetRows}
							editableLeasesInCompsetColumns={editableLeasesInCompsetColumns}
							setEditableLeasesInCompsetColumns={
								setEditableLeasesInCompsetColumns
							}
							data={data}
							leasesInCompsetSelection={leasesInCompsetSelection}
							showLeasesInCompset={!!sections.leasesInCompset}
						/>
					)}
					{sections.submarketTrends && (
						<EditSubmarketTrends data={data} {...editableSubmarketLeases} />
					)}
					{sections.multifamily && <EditMultifamilyPage />}
					{sections.leases &&
						Object.keys(editableLeasesRowsBySpaceType).length > 0 && (
							<EditLeasesPage
								leases={data.leases}
								editableLeasesRowsBySpaceType={editableLeasesRowsBySpaceType}
								editableLeasesColumnsBySpaceType={
									editableLeasesColumnsBySpaceType
								}
								setEditableLeasesRowsBySpaceType={
									setEditableLeasesRowsBySpaceType
								}
								setEditableLeasesColumnsBySpaceType={
									setEditableLeasesColumnsBySpaceType
								}
								leaseStatus={leaseOptionsState.leaseStatus}
								data={data}
								leasesSelectionBySpaceType={leasesSelectionBySpaceType}
								setLeasesSelectionBySpaceType={setLeasesSelectionBySpaceType}
							/>
						)}
					{(sections.sales || sections.loans) && (
						<PropertyEditPage pageKey="sales">
							{sections.sales && (
								<EditSalesPage
									editableSales={editableSales}
									setEditableSales={setEditableSales}
									editableSalesColumns={editableSalesColumns}
									setEditableSalesColumns={setEditableSalesColumns}
									data={data}
									salesSelection={salesSelection}
								/>
							)}
							{sections.loans && (
								<EditLoansPage
									property={pdfPageProps.property}
									editablePublicLoansRow={editablePublicLoansRow}
									setEditablePublicLoansRow={setEditablePublicLoansRow}
									editablePublicLoansColumns={editablePublicLoansColumns}
									setEditablePublicLoansColumns={setEditablePublicLoansColumns}
								/>
							)}
						</PropertyEditPage>
					)}
				</EditContent>
			</Dialog.Content>
		</StepTwoDialogOverlay>
	);
};

export type ImageUrls = Record<
	'commercial' | 'mufa1' | 'mufa2' | 'marketStats',
	string
>;

const StepTwoDialogOverlay = styled(ModalOverlay)`
	text-align: left;
	align-items: unset;
`;

const Toolbar = styled.div`
	position: sticky;
	top: 0;
	z-index: 2;
	background-color: ${(p) => p.theme.colors.white.white};
	padding: 1rem;
	display: flex;
	align-items: center;
	gap: 1rem;
	margin-bottom: 0.5rem;
	${FormControlLabel} {
		font-size: 0.75rem;
	}
	${(p) => p.theme.breakpoints.up('D_1536')} {
		${FormControlLabel} {
			font-size: 0.875rem;
		}
	}
`;

const CheckboxesLabel = styled.div`
	color: ${(p) => p.theme.colors.gray.gray700};
	font-size: 0.875rem;
	font-weight: 325;
	line-height: 1;
	margin-bottom: 0.5rem;
`;

const CheckboxesContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;
	${(p) => p.theme.breakpoints.up('D_1536')} {
		gap: 1rem;
	}
`;

const RightContainer = styled.div`
	display: flex;
	align-items: flex-end;
	gap: 0.5rem;
	margin-left: auto;
	${(p) => p.theme.breakpoints.up('D_1536')} {
		gap: 1rem;
	}
`;

const TextButton = styled.button`
	all: unset;
	color: ${(p) => p.theme.colors.blue.blue400};
	font-size: 0.75rem;
	font-weight: 300;
	line-height: 1.5;
	padding: 0.25rem;
	cursor: pointer;
	${(p) => p.theme.breakpoints.up('D_1536')} {
		font-size: 0.875rem;
	}
`;
