import {
	CenterOnBounds,
	MapInteractivity,
	MapStyle,
	SubmarketPolygonLayer,
} from '@compstak/maps';
import { AlertCircle, Tooltip } from '@compstak/ui-kit';
import { SubmarketPolygon } from 'api/submarketPolygons/useSubmarketPolygons';
import { memo, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { MufaSubmarketPolygon } from 'api';
import { MapWrapper } from 'maps/components/MapWrapper/MapWrapper';

export type PolygonDataSource = 'CompStak' | 'RealPage';

export type SubmarketProps = {
	/** Submarket name */
	submarket: string;

	submarketPolygon: SubmarketPolygon | MufaSubmarketPolygon;
	/** Source of polygon data */
	polygonDataSource: PolygonDataSource;
};

/**
 * Info with submarket map on hover
 */
export const Submarket = memo<SubmarketProps>(
	({ submarket, submarketPolygon, polygonDataSource }) => {
		const tooltipComponent = useMemo(
			() => (
				<>
					<SubmarketTooltipName data-qa-id={submarketTooltipNameTestId}>
						<TooltipTitle>
							<div>{polygonDataSource} Submarket:</div>
							<TooltipTitleSubmarketName>{submarket}</TooltipTitleSubmarketName>
						</TooltipTitle>
					</SubmarketTooltipName>
					<MapContainer>
						<MapWrapper
							interactivity={MapInteractivity.STATIC}
							mapStyle={MapStyle.MONOCHROME}
							mapDomain="propertyPage"
						>
							<CenterOnBounds geoJSON={submarketPolygon} />
							<SubmarketPolygonLayer data={submarketPolygon} stroked={false} />
						</MapWrapper>
					</MapContainer>
				</>
			),
			[submarket, submarketPolygon, polygonDataSource]
		);

		return (
			<SubmarketRoot>
				<DataSourceName>{polygonDataSource} submarket:</DataSourceName>
				<SubmarketName>{submarket}</SubmarketName>
				<Tooltip
					tooltipComponent={tooltipComponent}
					placement="bottom-end"
					tooltipStyle={tooltipStyle}
					tooltipRootStyle={tooltipRootStyle}
					offsetAway={12}
				>
					<AlertCircle />
				</Tooltip>
			</SubmarketRoot>
		);
	}
);
Submarket.displayName = 'Submarket';
export const submarketTooltipNameTestId = 'submarketTooltipNameTestId';

const tooltipStyle = { padding: '2px' };
const tooltipRootStyle = { display: 'flex', alignItems: 'center' };
const MapContainer = styled.div`
	width: 280px;
	height: 176px;
`;

const SubmarketTooltipName = styled.div`
	padding: 5px;
`;

const SubmarketRoot = styled.div(
	({ theme }) => css`
		font-family: ${theme.typography.fontFamily.gotham};
		white-space: nowrap;
		display: flex;
		align-items: center;
		gap: 4px;
	`
);

const DataSourceName = styled.div(
	({ theme }) => css`
		color: ${theme.colors.gray.n100};
		font-size: ${theme.typography.smallFontSize};
	`
);

const SubmarketName = styled.div(
	({ theme }) => css`
		color: ${theme.colors.gray.gray600};
		font-size: ${theme.typography.ordinaryFontSize};
		font-weight: 500;
	`
);

const TooltipTitle = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;
`;

const TooltipTitleSubmarketName = styled.div`
	width: 130px;
	overflow: hidden;
	text-overflow: ellipsis;
`;
