import { useState } from 'react';
import { nullValueText } from '@compstak/common';
import { useIsMufaProperty } from 'api';
import { MufaPropertyComp, PropertyComp } from 'types';
import { keyInfoAttributes, mufaLabels } from 'Pages/PropertyPageV3/MainInfo';
import { getPropertyAttributesKeys } from 'Pages/PropertyPageV3/MainInfo/PropertyAttributes';
import { formatDate, formatPercent } from 'format';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

export type PropertyWithYearInfo = MufaPropertyComp & { yearInfo: string };

type Props = {
	property: PropertyWithYearInfo;
};

export const useEditableOverview = ({ property }: Props) => {
	const { data: isMufa } = useIsMufaProperty({ propertyId: property.id });
	const propertyAttributes = getPropertyAttributesKeys(
		isMufa,
		property.buildingPropertyType
	);
	const { occupancyPercentage, occupancyDate } = property;
	const asOfDate = occupancyDate
		? ` as of ${formatDate(new Date(occupancyDate))}`
		: '';

	const { AdditionOfOccupancy } = useFeatureFlags();
	const visibleKeyInfoAttributes =
		occupancyPercentage !== undefined && AdditionOfOccupancy
			? keyInfoAttributes
			: keyInfoAttributes.filter((key) => key !== 'occupancyPercentage');

	const keyInfoAttributesMap = {
		...visibleKeyInfoAttributes.reduce(
			(acc, key) => ({
				...acc,
				[key]: !property[key]
					? nullValueText
					: // using mufaLabels because it includes commercial labels as well (formatting is the same)
						mufaLabels[key].formatter?.(property[key]) ?? property[key],
			}),
			{} as OverviewAttributes
		),
		...(occupancyPercentage !== undefined && {
			occupancyPercentage: `${formatPercent(occupancyPercentage / 100)}${asOfDate}`,
		}),
	};

	const [editableOverviewFields, setEditableOverviewFields] =
		useState<EditableOverviewFields>({
			keyInfoAttributes: keyInfoAttributesMap,
			propertyAttributes: {
				...propertyAttributes.reduce(
					(acc, key) => ({
						...acc,
						[key]: !property[key]
							? nullValueText
							: mufaLabels[key].formatter?.(property[key]) ?? property[key],
					}),
					{} as OverviewAttributes
				),
			},
			visibleFields: {
				...[...visibleKeyInfoAttributes, ...propertyAttributes].reduce(
					(acc, key) => ({
						...acc,
						[key]: true,
					}),
					{} as OverviewVisibleFields
				),
			},
		});

	return {
		editableOverviewFields,
		setEditableOverviewFields,
	};
};

export type EditableOverviewFields = {
	keyInfoAttributes: OverviewAttributes;
	propertyAttributes: OverviewAttributes;
	visibleFields: OverviewVisibleFields;
};

export type OverviewKeysToDisplay =
	| keyof PropertyComp
	| keyof MufaPropertyComp
	| 'yearInfo';

export type OverviewVisibleFields = Record<OverviewKeysToDisplay, boolean>;
export type OverviewAttributes = Record<OverviewKeysToDisplay, any>;
