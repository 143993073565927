import { PortfolioSidebarItem } from 'PortfolioAnalytics/PortfolioSidebar/PortfolioSidebarItem';
import { PortfolioShortWithAggregations } from 'PortfolioAnalytics/PortfolioSidebar/usePortfoliosWithAggregations';
import styled from 'styled-components';
import { BENCHMARKING_PORTFOLIOS_LIMIT } from '../constants';
import { Checkbox } from 'Components';
import { Button } from '@compstak/ui-kit';
import { NoPortfolioMessage } from 'PortfolioAnalytics/styles/PortfolioUI';
import { usePortfoliosSelection } from 'PortfolioAnalytics/hooks/usePortfoliosSelection';
import { useMemo } from 'react';

type Props = {
	portfolios: PortfolioShortWithAggregations[];
};

export const BenchmarkingPortfoliosList = ({ portfolios }: Props) => {
	const {
		selectedPortfolioIds,
		selectPortfolio,
		resetSelectedPortfolios,
		isUpdatingPortfolioSelection,
	} = usePortfoliosSelection();

	const selectedPortfolios = useMemo(() => {
		return selectedPortfolioIds
			.map((selectedPortfolioId) =>
				portfolios.find(({ id }) => id === selectedPortfolioId)
			)
			.filter(Boolean) as PortfolioShortWithAggregations[];
	}, [portfolios, selectedPortfolioIds]);

	const nonSelectedPortfolios = useMemo(() => {
		return portfolios.filter(({ id }) => !selectedPortfolioIds.includes(id));
	}, [portfolios, selectedPortfolioIds]);

	const isDisabled =
		selectedPortfolios.length >= BENCHMARKING_PORTFOLIOS_LIMIT ||
		isUpdatingPortfolioSelection;

	return (
		<List>
			<Category>
				Selected Portfolios ({selectedPortfolioIds.length}/
				{BENCHMARKING_PORTFOLIOS_LIMIT})
				<ResetButton variant="ghost" onClick={resetSelectedPortfolios}>
					Reset
				</ResetButton>
			</Category>
			{selectedPortfolioIds.length === 0 ? (
				<NoPortfolioMessage>No portfolio selected</NoPortfolioMessage>
			) : (
				selectedPortfolios.length === 0 && (
					<NoPortfolioMessage>No Portfolios Found</NoPortfolioMessage>
				)
			)}
			{selectedPortfolios.map((p) => (
				<PortfolioSidebarItem
					key={p.id}
					portfolio={p}
					withAlerts={false}
					onClick={() =>
						selectPortfolio({ portfolioId: p.id, selected: false })
					}
				>
					<Checkbox checked />
				</PortfolioSidebarItem>
			))}
			<Category>Other Portfolios</Category>
			{nonSelectedPortfolios.length === 0 ? (
				<NoPortfolioMessage>No Portfolios Found</NoPortfolioMessage>
			) : (
				nonSelectedPortfolios.map((p) => (
					<PortfolioSidebarItem
						key={p.id}
						portfolio={p}
						withAlerts={false}
						onClick={() =>
							!isDisabled &&
							selectPortfolio({ portfolioId: p.id, selected: true })
						}
						isDisabled={isDisabled}
					>
						<Checkbox disabled={isDisabled} />
					</PortfolioSidebarItem>
				))
			)}
		</List>
	);
};

const List = styled.ul`
	width: 100%;
	overflow-y: auto;
	height: 100%;
`;

const Category = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 0.875em;
	font-weight: 400;
	color: ${({ theme }) => theme.colors.blue.blue500};
	padding: 0.5rem 0 0.5rem 1rem;
`;

const ResetButton = styled(Button)`
	color: ${({ theme }) => theme.colors.neutral.n50};
	font-size: 1em;
`;
