import { Flex } from '@compstak/ui-kit';
import { LinkishButton } from 'Components';
import { withErrorBoundaryDefault } from 'Components/ErrorBoundary';
import { ReferenceData } from 'api';
import { MQB } from 'constants/mediaQueryBreakpoints';
import { ComponentProps } from 'react';
import styled from 'styled-components';
import formConstants from 'util/formConstants';
import { SelectOrInputText } from './SelectOrInputText';
import { INPUT_HEIGHT, inputCss } from './Ui';

export type StrFormOption = { label: string; value: string };

export const STATE_SELECT_OPTIONS = formConstants.usStates.map((state) => ({
	label: state.abbreviation,
	value: state.abbreviation,
}));

const MONTH_OPTIONS = formConstants.months.map((month) => ({
	label: month.number,
	value: month.number,
}));

const DAY_OPTIONS = Array.from({ length: 31 }, (_, k) => ({
	label: String(k + 1),
	value: String(k + 1),
}));

export const WORK_TYPE_OPTIONS = (() => {
	const workTypes = [...formConstants.workTypes].sort();
	workTypes.splice(workTypes.indexOf('TI'), 1);
	workTypes.splice(0, 0, 'TI');
	return workTypes.map((type) => ({
		label: type,
		value: type,
	}));
})();

export const PROPERTY_TYPE_OPTIONS = formConstants.propertyTypes.map(
	(propertyType) => ({
		label: propertyType,
		value: propertyType,
	})
);

export const INTEREST_TYPE_OPTIONS = formConstants.interestTypes.map(
	(interestType) => ({
		label: interestType,
		value: interestType,
	})
);

export function getSelectOptionsFromRefrenceData(
	referenceData: ReferenceData | null
) {
	const leaseTypeOptions =
		referenceData?.leaseTypes.map((type) => ({
			label: type.name,
			value: type.name,
		})) ?? [];
	const transactionTypeOptions =
		referenceData?.transactionTypes.map((type) => ({
			label: type.name,
			value: type.name,
		})) ?? [];
	const spaceTypeOptions =
		referenceData?.spaceTypes
			.filter((type) => type.id > 0) // exclude unknown space type
			.map((type) => ({ label: type.name, value: type.name })) ?? [];
	return {
		leaseTypeOptions,
		transactionTypeOptions,
		spaceTypeOptions,
	};
}

export function ManualUploadFormBody(props: ComponentProps<'form'>) {
	return (
		<FormWrapperSC>
			<FormSC
				onSubmit={(ev) => ev.preventDefault()}
				autoComplete="off"
				// autoComplete is off because when people are filling out the form,
				// sometimes by accident autocomplete adds values from old comp submissions to the form fields
				{...props}
			/>
		</FormWrapperSC>
	);
}

const FormWrapperSC = styled.div`
	overflow: auto;
	width: 100%;
`;
FormWrapperSC.displayName = 'FormWrapperSC';

const FormSC = styled.form`
	margin: 8px auto;
	padding: 32px 40px 72px;
	font-weight: 400;
	width: 95%;
	min-width: 320px;
	max-width: 960px;
	box-sizing: border-box;
	display: grid;
	gap: 16px;
`;
FormSC.displayName = 'FormSC';

export const Row12 = styled.div`
	@media (width <= ${MQB.M_800}px) {
		display: block;
	}
	display: grid;
	grid-gap: 8px;
	grid-template-columns: repeat(12, 1fr);
`;
Row12.displayName = 'Row12';

export type AsteriskCount = 0 | 1 | 2;

type InputProps = {
	value: string;
	label: string;
	name: string;
	width: number;
	unit: string;
	postUnit: string;
	asteriskCount: AsteriskCount;
	avoidAutofillByName?: boolean;
};

export function LabeledInput({
	label,
	value,
	name,
	width,
	onChange,
	unit,
	postUnit,
	asteriskCount,
	avoidAutofillByName,
}: InputProps & {
	onChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
}) {
	const paddingRight = unit ? `${16 + unit.length * 7}px` : undefined;
	const nameToAvoidAutofill = avoidAutofillByName ? undefined : name;
	return (
		<RowElementSC {...{ width }}>
			<LabelWithAsterisks {...{ label, unit, postUnit, asteriskCount, name }} />
			<InputAndUnitWrapperSC>
				<UploadFormInputSC
					id={nameToAvoidAutofill}
					name={nameToAvoidAutofill}
					autoComplete={avoidAutofillByName ? 'do-not-autofill' : 'off'}
					{...{
						value,
						paddingRight,
						onChange,
					}}
				/>
				{!!unit && <InputUnitSC>{unit}</InputUnitSC>}
			</InputAndUnitWrapperSC>
		</RowElementSC>
	);
}

export const LabeledDateInput = withErrorBoundaryDefault(
	function LabeledDateInputUnsafe({
		label,
		value,
		name,
		width,
		onChange,
		unit,
		postUnit,
		asteriskCount,
		yearOptions,
	}: InputProps & {
		onChange: (value: string) => void;
		yearOptions: Array<StrFormOption>;
	}) {
		const [month, day, year] = (value + '//').split('/');
		return (
			<>
				<RowElementSC {...{ width }}>
					<LabelWithAsterisks
						{...{
							label,
							unit,
							postUnit,
							asteriskCount,
							name,
						}}
					/>
					<Flex>
						<SelectOrInputText
							name={name + '-month'}
							placeholder="Month"
							onChange={(v) => onChange(`${v}/${day}/${year}`)}
							onSearchBlur={(v) => {
								const formattedValue = v.length === 1 ? `0${v}` : v;
								const matchedOption = MONTH_OPTIONS.find(
									(option) => option.value === formattedValue
								);

								if (!matchedOption) {
									return;
								}

								onChange(`${matchedOption.value}/${day}/${year}`);
							}}
							options={MONTH_OPTIONS}
							value={month}
						/>
						<SelectOrInputText
							name={name + '-day'}
							placeholder="Day"
							onChange={(v) => onChange(`${month}/${v}/${year}`)}
							options={DAY_OPTIONS}
							value={day}
						/>
						<SelectOrInputText
							name={name + '-year'}
							placeholder="Year"
							onChange={(v) => onChange(`${month}/${day}/${v}`)}
							options={yearOptions}
							value={year}
						/>
					</Flex>
				</RowElementSC>
			</>
		);
	}
);

export function LabeledSelect({
	name,
	width,
	options,
	label,
	onChange,
	value,
	unit,
	postUnit,
	asteriskCount,
	avoidAutofillByName,
}: InputProps & {
	options: Array<StrFormOption>;
	onChange: (value: string) => void;
}) {
	return (
		<RowElementSC {...{ width }}>
			<LabelWithAsterisks
				{...{
					label,
					unit,
					postUnit,
					asteriskCount,
					name,
				}}
			/>
			<InputAndUnitWrapperSC>
				<SelectOrInputText
					{...{
						value,
						name,
						onChange,
						options,
						avoidAutofillByName,
					}}
				/>
				{!!unit && <InputUnitSC>{unit}</InputUnitSC>}
			</InputAndUnitWrapperSC>
		</RowElementSC>
	);
}

const YES_NO_OPTIONS = Object.freeze([
	{ label: 'Yes', value: 'yes' },
	{ label: 'No', value: 'no' },
]);

export function LabeledRadio({
	name,
	label,
	onChange,
	width,
	value,
	hideLabel,
}: {
	name: string;
	label: string;
	onChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
	width: number;
	value: string;
	hideLabel?: boolean;
}) {
	return (
		<RowElementSC padding="0 40px" {...{ width }}>
			{!hideLabel && <LabelSC htmlFor={name}>{label}</LabelSC>}
			<RadioButtons {...{ name, value, onChange }} />
		</RowElementSC>
	);
}

export function RadioButtons({
	name,
	value,
	options = YES_NO_OPTIONS,
	onChange,
}: {
	name: string;
	value: string;
	options?: ReadonlyArray<StrFormOption>;
	onChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
}) {
	return (
		<RadioContainerSC>
			{options.map((option, index) => {
				const optName = name + '-' + index;
				return (
					<Flex alignItems="baseline" key={optName}>
						<input
							type="radio"
							name={name}
							id={optName}
							onChange={onChange}
							value={option.value}
							checked={value === option.value}
							data-qa-id={name + '-radio-input-' + index}
						/>
						<label htmlFor={optName}>{option.label}</label>
					</Flex>
				);
			})}
		</RadioContainerSC>
	);
}

function LabelWithAsterisks({
	label,
	unit,
	postUnit,
	asteriskCount,
	name,
}: {
	label: string;
	asteriskCount: AsteriskCount;
	unit?: string;
	postUnit?: string;
	name: string;
}) {
	const postUnitDisplay: string =
		postUnit && postUnit !== unit ? `(${postUnit})` : '';
	return (
		<LabelSC htmlFor={name}>
			{label} <RequiredRedAsterisk count={asteriskCount} /> {postUnitDisplay}
		</LabelSC>
	);
}

export function RequiredFieldsAsteriskLegend({
	twoAsteriskText,
}: {
	twoAsteriskText: string;
}) {
	return (
		<div>
			<div>
				<RequiredRedAsterisk /> Required field
			</div>
			<div>
				<RequiredRedAsterisk count={2} /> {twoAsteriskText}
			</div>
		</div>
	);
}

export const RowElementSC = styled.div<{
	width: number;
	padding?: string;
}>`
	${(props) => (props.width ? `grid-column: span ${props.width};` : '')}
	${(props) => (props.padding ? `padding: ${props.padding};` : '')}
`;
RowElementSC.displayName = 'RowElementSC';

const InputAndUnitWrapperSC = styled.div`
	position: relative;
	display: inline-block;
	width: 100%;
`;

const InputUnitSC = styled.span`
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	pointer-events: none; /* This ensures the unit text is not selectable or clickable */
	right: 10px; /* Adjust this value as needed */
	color: ${({ theme }) => theme.colors.gray.gray500};
`;

const LabelSC = styled.label`
	color: ${({ theme }) => theme.colors.gray.gray700};
	display: block;
	font-family: Gotham;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
`;
LabelSC.displayName = 'LabelSC';

export const UploadFormInputSC = styled.input<{ paddingRight?: string }>`
	${inputCss}
	${(props) =>
		props.paddingRight ? `padding-right: ${props.paddingRight};` : ''}
	&[type='text'] {
		/* This css specificity hack is done because there are global styles for type="text" */
		${inputCss}
	}
`;
UploadFormInputSC.displayName = 'UploadFormInputSC';

const RadioContainerSC = styled.div`
	height: ${INPUT_HEIGHT}px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 16px;

	input,
	label {
		cursor: pointer;
	}

	label {
		padding-left: 16px;
	}
`;

export const HorizontalLine = styled.hr`
	width: 98%;
	margin: 20px auto;
	border: 1px solid ${({ theme }) => theme.colors.neutral.n50};
`;

export function RequiredRedAsterisk({ count = 1 }: { count?: AsteriskCount }) {
	if (!count || count < 1) {
		return <></>;
	}
	return <RequiredRedAsteriskSC>{'*'.repeat(count)}</RequiredRedAsteriskSC>;
}

export const RequiredRedAsteriskSC = styled.span`
	color: ${({ theme }) => theme.colors.red.red500};
`;

export function ShowAdditionalFieldsButton({
	onClick,
}: {
	onClick: () => void;
}) {
	return (
		<LinkishExpandButtonSC onClick={onClick}>
			Add More Fields to Earn More Credits
		</LinkishExpandButtonSC>
	);
}
const LinkishExpandButtonSC = styled(LinkishButton)`
	color: ${({ theme }) => theme.colors.blue.blue400};
	text-transform: uppercase;
	text-decoration: none;
	font-size: 14px;
	line-height: 25px;
	letter-spacing: 1.21px;
	margin: 20px auto 0;
	border-bottom: 1px solid ${({ theme }) => theme.colors.blue.blue400};
`;
