import { ExchangePanel, LoadingBox, StatBox, StatsContainer } from './UI';
import styled from 'styled-components';
import { useExchangeDashboardAverages } from './hooks/averages/useExchangeDashboardAverages';
import { useExchangeDashboardState } from 'reducers/exchangeDashboard';

export const AveragesPanel = () => {
	const { fullPageState } = useExchangeDashboardState();
	const { averages, isFetching, timeline, setTimeline } =
		useExchangeDashboardAverages({
			enabled: fullPageState === null,
		});

	return (
		<ExchangePanel
			id="averages"
			title="Averages"
			tooltip="Averages encompass leases executed in the selected time range (the most recent 6, 12, or 24 months)."
			cta={
				<Tabs>
					<Tab
						active={timeline === 6}
						onClick={() => setTimeline(6)}
						data-qa-id="6-months-timeline-tab"
					>
						6 Months
					</Tab>
					<Tab
						active={timeline === 12}
						onClick={() => setTimeline(12)}
						data-qa-id="12-months-timelime-tab"
					>
						{' '}
						12 Months
					</Tab>
					<Tab
						active={timeline === 24}
						onClick={() => setTimeline(24)}
						data-qa-id="24-months-timeline-tab"
					>
						24 Months
					</Tab>
				</Tabs>
			}
		>
			<div>
				<StatsContainer>
					{isFetching && (
						<>
							<LoadingAverage />
							<LoadingAverage />
							<LoadingAverage />
							<LoadingAverage />
							<LoadingAverage />
						</>
					)}
					{!isFetching && (
						<>
							<StatBox
								property="Starting Rent (PSF)"
								value={averages?.startingRent?.value ?? 'N/A'}
							/>
							<StatBox
								property="Net Effective Rent"
								value={averages?.effectiveRent?.value ?? 'N/A'}
							/>
							<StatBox
								property="TI Value"
								value={averages?.ti?.value ?? 'N/A'}
							/>
							<StatBox
								property="Free Rent (mo)"
								value={averages?.freeMonths?.value ?? 'N/A'}
							/>
							<StatBox
								property="Term (yy/mo)"
								value={averages?.leaseTerm?.value ?? 'N/A'}
							/>
						</>
					)}
				</StatsContainer>
			</div>
		</ExchangePanel>
	);
};

const LoadingAverage = styled(LoadingBox)`
	box-sizing: border-box;
	flex-grow: 1;
	flex: 1;
	margin: 1% 1% 0;
	min-height: 90px;
	padding: 0.75rem;
`;

const Tab = styled.div<{ active: boolean }>`
	background-color: ${(props) => (props.active ? '#228fff' : 'transparent')};
	border-radius: 30px;
	border: 1px solid #4a78f6;
	padding: 6px 12px;
	color: ${(props) => (props.active ? '#fff' : '#303441')};
	font-family:
		Gotham,
		Roboto Mono;
	font-size: 10px;
	font-weight: normal;
	justify-content: center;
	text-align: center;
	width: auto;

	&:hover {
		background: #eaf2fd;
		color: #2d77e6;
	}

	&:active {
		background-color: #228fff;
		color: #fff;
	}
`;

const Tabs = styled.div`
	background: #ffffff;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	cursor: pointer;
	min-width: 280px;
	max-width: 280px;
	margin: 0 0 1rem;
	overflow: hidden;
`;
