import { Button, Spinner } from '@compstak/ui-kit';
import { useAllPortfoliosQuery } from 'api';
import styled from 'styled-components';
import { DarkSidebar } from 'ui/UI';
import { usePortfoliosWithAggregations } from './usePortfoliosWithAggregations';
import { useSortedPortfolios } from './useSortedPortfolios';
import { useModal } from 'providers/ModalProvider';
import { PortfolioModal } from '../modals/PortfolioModal';
import IconPlusVeryLight from 'ui/svg_icons/plus_very_light.svg';
import IconBenchmarking from 'ui/svg_icons/benchmarking.svg';

import { PortfolioSidebarItem } from './PortfolioSidebarItem';
import { useListSearchInput } from 'Components/ListSearchInput/ListSearchInput';
import { trimString } from 'util/trimString';
import { routes } from 'router';
import { PortfolioSidebarV2SortControl } from './PortfolioSidebarV2SortControl';
import { useNavigate } from 'react-router';
import { NoPortfolioMessage } from 'PortfolioAnalytics/styles/PortfolioUI';
import { usePortfoliosSelection } from 'PortfolioAnalytics/hooks/usePortfoliosSelection';
import { usePortfolioSortControl } from 'PortfolioAnalytics/hooks/usePortfolioSortControl';

type Props = {
	portfolioId?: number;
};

export const PortfolioSidebarV2 = ({ portfolioId }: Props) => {
	const { setSortDirection, setSortField, sortDirection, sortField } =
		usePortfolioSortControl();

	const { data: portfolios, isFetching: isFetchingPortfolios } =
		useAllPortfoliosQuery();
	const { selectedPortfolioIds, isFetching: isFetchingSelectedPortfolios } =
		usePortfoliosSelection();

	const { element: searchInput, searchTerm } = useListSearchInput({
		inputPlaceholder: 'Search for portfolio',
		inputDataQaId: 'portfolio-search-input',
	});

	const portfoliosWithAggregates = usePortfoliosWithAggregations(portfolios);

	const filteredPortfoliosWithAggregations = searchTerm
		? portfoliosWithAggregates.filter(({ name }) =>
				trimString(name).toLowerCase().includes(trimString(searchTerm))
			)
		: portfoliosWithAggregates;

	const sortedPortfolios = useSortedPortfolios({
		portfoliosWithAggregates: filteredPortfoliosWithAggregations,
		sortField,
		sortDirection,
	});

	const { openModal } = useModal();
	const navigate = useNavigate();

	const onNewPortfolioClick = () => {
		openModal({
			modalContent: (
				<PortfolioModal action="create" title="Create a New Portfolio" />
			),
		});
	};

	return (
		<DarkSidebar sideBarState="open">
			<HeaderContainer>
				<Header>
					<HeaderTitle>Portfolios</HeaderTitle>
					<NewPortfolioButton
						variant="primary"
						onClick={onNewPortfolioClick}
						disabled={isFetchingPortfolios}
						icon={<IconPlusVeryLight />}
					>
						New portfolio
					</NewPortfolioButton>
				</Header>
				<ComparePortfoliosButton
					variant="attentive"
					size="l"
					disabled={isFetchingPortfolios || isFetchingSelectedPortfolios}
					isLoading={isFetchingSelectedPortfolios}
					icon={<IconBenchmarking />}
					href={routes.portfoliosBenchmarking.path}
				>
					Compare Portfolios ({selectedPortfolioIds.length})
				</ComparePortfoliosButton>
			</HeaderContainer>
			{!isFetchingPortfolios && (
				<>
					{searchInput}
					<PortfolioSidebarV2SortControl
						sortedPortfolios={sortedPortfolios}
						sortField={sortField}
						setSortField={setSortField}
						sortDirection={sortDirection}
						setSortDirection={setSortDirection}
					/>
				</>
			)}
			{isFetchingPortfolios ? (
				<SpinnerContainer>
					<Spinner />
				</SpinnerContainer>
			) : (
				<List>
					{sortedPortfolios.length === 0 ? (
						<NoPortfolioMessage>No Portfolios Found</NoPortfolioMessage>
					) : (
						sortedPortfolios.map((p) => {
							const isSelected = portfolioId === p.id;

							const onClick = () => {
								navigate(
									routes.portfolioByIdView.toHref({
										portfolioId: p.id,
										viewType: 'dashboard',
									})
								);
							};

							return (
								<PortfolioSidebarItem
									key={p.id}
									isSelected={isSelected}
									portfolio={p}
									onClick={onClick}
								/>
							);
						})
					)}
				</List>
			)}
		</DarkSidebar>
	);
};

const List = styled.ul`
	width: 100%;
	overflow-y: auto;
	height: 100%;
`;

const SpinnerContainer = styled.div`
	display: flex;
	justify-content: center;
`;

const HeaderContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 1rem;
	gap: 1rem;
`;

const Header = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const HeaderTitle = styled.h2`
	font-size: 1.25em;
	color: ${({ theme }) => theme.colors.white.white};
	font-weight: 400;
`;

const NewPortfolioButton = styled(Button)``;

const ComparePortfoliosButton = styled(Button)`
	width: 100%;
`;
