import Between from 'Components/Filters/Base/Between';
import { FilterBase } from 'Components/Filters/Base/Filter/FilterBase';
import { FilterDescription } from 'Components/Filters/Base/Filter/FilterDescription';

export const OccupancyFilter = () => {
	return (
		<FilterBase attribute="occupancyPercentage">
			{(props) => (
				<div>
					<Between {...props} />
					<FilterDescription>
						Only the most recent CMBS occupancy value will be available
					</FilterDescription>
				</div>
			)}
		</FilterBase>
	);
};
