import { Button, MQB, TooltipV2 } from '@compstak/ui-kit';
import { CreateSavedSearchModal } from 'Components/SavedSearches/CreateSavedSearchModal';
import { getSetFilterKeysExceptMarkets } from 'models/filters/util/filterHelpers';
import { useModal } from 'providers/ModalProvider';
import { useMediaQuery } from 'react-responsive';
import { useFilters } from 'reducers/filtersReducer';
import IconBookmark from 'ui/svg_icons/bookmark.svg';
import { useFindSavedSearchToEdit } from 'hooks/useFindSavedSearchToEdit';
import { EditSavedSearchModal } from 'Components/SavedSearches/EditSavedSearchModal';
import pluralizeCompType from 'util/pluralizeCompType';
import styled from 'styled-components';
import { CompType } from 'types';

type Props = {
	compType: CompType;
};

export const SaveSearchButton = ({ compType }: Props) => {
	const [filters] = useFilters();
	const hasSetFilters = getSetFilterKeysExceptMarkets(filters).length > 0;

	const isDisabled = !hasSetFilters;

	const isLargeScreen = useMediaQuery({ minWidth: MQB.D_1536 });

	const { openModal } = useModal();

	const savedSearch = useFindSavedSearchToEdit();

	if (!compType) return null;

	const isButtonWide = compType !== 'property' || isLargeScreen;

	return (
		<TooltipV2 content={isButtonWide ? '' : 'Save Search'}>
			<ButtonStyled
				variant="primary3"
				size="l"
				disabled={isDisabled}
				onClick={() => {
					openModal({
						modalContent: savedSearch ? (
							<EditSavedSearchModal savedSearch={savedSearch} />
						) : (
							<CreateSavedSearchModal
								savedSearch={{
									filters,
									searchType: pluralizeCompType(compType, true),
								}}
							/>
						),
					});
				}}
				icon={<IconBookmark />}
			>
				{isButtonWide && 'Save Search'}
			</ButtonStyled>
		</TooltipV2>
	);
};

const ButtonStyled = styled(Button)`
	min-width: min-content;
`;
