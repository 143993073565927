import React, { memo, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
	capitalizeFirstLetter,
	ErrorUI,
	getDownloadFileName,
	HeightContainer,
	HelpCircle,
	numberUtils,
	Pending,
	RowFlexContainer,
	SaveDataMenu,
	Select,
	TickFormatter,
	Tooltip,
	VerticalBarChart,
	WidgetContainer,
	WidgetTitle,
	CustomLegend,
	ChartData,
} from '@compstak/ui-kit';

import { RentPeriod } from '../../../Pages/PropertyPageV2_1/api';

import {
	barsData,
	chartYAxisLabel,
	headersMapping,
	spaceTypeItems,
	tooltipContent,
} from './auxiliaryData';

import {
	calcYTicks,
	calcDomainFromTicks,
} from '../../../Pages/PropertyPageV2_1/utils';

import { adaptData } from './adaptData';
import { ChartTypes } from '../../../Pages/PropertyPageV2_1/type';
import { useDistributionData } from 'api';
import { getRentPeriod } from 'Pages/PropertyPageV2_1/helpers';
import {
	CommercialChartsNames,
	CommonChartProps,
	FiltersNames,
	distributionMonthsListItems,
} from 'types/propertyTrendCharts';
import {
	CommercialFilterChangeFn,
	CommercialTrendsFiltersState,
} from '../useCommercialTrendsState';

export type SubmarketRentDistributionProps = {
	address: string;
	marketId: number;
	rentDistribution: ReturnType<typeof useDistributionData>;
	filters: CommercialTrendsFiltersState;
	handleFilterChange: CommercialFilterChangeFn;
} & CommonChartProps;

export const SubmarketRentDistribution = memo<SubmarketRentDistributionProps>(
	({
		address,
		marketId,
		showAnimation = true,
		showSaveDataMenu = true,
		shouldShowTooltip = true,
		filters,
		handleFilterChange,
		rentDistribution,
	}) => {
		const [ref, setRef] = useState<HTMLDivElement | null>(null);

		const { data, isFetching, isError, error } = rentDistribution;

		const rentPeriod = getRentPeriod(marketId);
		const chartId = `${widgetTitle}${address}`;
		const legendTitle = `Starting Rent, ${capitalizeFirstLetter(
			rentPeriod
		)}, ($/SqFt)`;

		const chartData = useMemo(
			() => adaptData(data, rentPeriod),
			[data, rentPeriod]
		);

		const yTicks = useMemo(
			() => calcYTicks(ChartTypes['BAR_CHART'], chartData),
			[chartData]
		);

		const handleSpaceTypeChange = useCallback(
			// @ts-expect-error TS7006: Parameter 'args' implicitly ha...
			(args) => {
				handleFilterChange(
					CommercialChartsNames.SubmarketRentDistribution,
					FiltersNames.SPACE_TYPE,
					args.selectedItem.value
				);
			},
			[handleFilterChange]
		);

		const handleMonthsChange = useCallback(
			// @ts-expect-error TS7006: Parameter 'args' implicitly ha...
			(args) => {
				handleFilterChange(
					CommercialChartsNames.SubmarketRentDistribution,
					FiltersNames.MONTHS,
					args.selectedItem.value
				);
			},
			[handleFilterChange]
		);

		return (
			<WidgetContainer>
				<HeaderContainer>
					<RowFlexContainer>
						<Title ref={setRef}>{widgetTitle}</Title>
						{shouldShowTooltip && (
							<Tooltip
								tooltipComponent={tooltipContent}
								placement="bottom"
								tooltipRootStyle={tooltipRootStyle}
								refToObservePositionChange={ref}
							>
								<HelpCircle />
							</Tooltip>
						)}
						<Select
							items={spaceTypeItems}
							value={
								filters[CommercialChartsNames.SubmarketRentDistribution][
									FiltersNames.SPACE_TYPE
								]
							}
							onChange={handleSpaceTypeChange}
							data-qa-id="select"
						/>
						<Select
							items={distributionMonthsListItems}
							value={
								filters[CommercialChartsNames.SubmarketRentDistribution][
									FiltersNames.MONTHS
								]
							}
							onChange={handleMonthsChange}
							data-qa-id="select"
						/>
					</RowFlexContainer>
					{showSaveDataMenu && (
						<SaveDataMenu
							downloadFileName={getDownloadFileName(address, widgetTitle)}
							elementId={chartId}
							dataToCopy={chartData}
							headersMapping={headersMapping}
						/>
					)}
				</HeaderContainer>
				<HeightContainer margin={HEIGHT_CONTAINER_MARGIN}>
					{isFetching ? (
						<Pending times={4} />
					) : (
						<VerticalBarChart
							bars={barsData}
							data={chartData}
							yAxisLabel={chartYAxisLabel}
							yTickFormatter={yTickFormatter}
							xTickFormatter={rentPeriodToXTickFormatter[rentPeriod || '']}
							tooltipFormatter={tooltipFormatter}
							xInterval={calcXInterval(chartData)}
							yTicks={yTicks}
							yDomain={calcDomainFromTicks(yTicks)}
							chartId={chartId}
							barSize={10}
							yTooltipPosition={-15}
							isAnimationActive={showAnimation}
						/>
					)}
					{isError && <ErrorUI error={error} />}
				</HeightContainer>
				<CustomLegend legendTitle={legendTitle} />
			</WidgetContainer>
		);
	}
);

SubmarketRentDistribution.displayName = 'SubmarketRentDistribution';

export const widgetTitle = 'Submarket Rent Distribution';

export const HeaderContainer = styled(RowFlexContainer)`
	margin-bottom: 28px;
`;

const HEIGHT_CONTAINER_MARGIN = { bottom: 24 };

export const Title = styled(WidgetTitle)`
	margin-right: 4px;
`;

const tooltipRootStyle = {
	marginRight: '16px',
};

const yTickFormatter: TickFormatter = (value) => {
	return numberUtils.formatNumber(value, 'oneDecimal', 'financial');
};

/**
	FIXME: (@Vladimir)
	This is a quick solution to display XX.XX values for monthly rent data
 */
const xTickFormatterAnnual: TickFormatter = (value) => {
	return numberUtils.formatCurrency(value, 'currencyInteger', 'financial');
};

const xTickFormatterMonthly: TickFormatter = (value) => {
	return numberUtils.formatCurrency(value, 'currencyOneDecimal', 'financial');
};

const tooltipFormatter: TickFormatter = (value) =>
	numberUtils.formatNumber(value, 'integer');

const rentPeriodToXTickFormatter = {
	[RentPeriod.ANNUAL]: xTickFormatterAnnual,
	[RentPeriod.MONTHLY]: xTickFormatterMonthly,
};

export const calcXInterval = (items: Array<ChartData>): number => {
	if (items.length <= TICKS_NUMBER_MAX) return 0;

	return Math.ceil(
		(items.length - TICKS_NUMBER_MAX) / INTERVALS_NUMBER_BETWEEN_TICKS
	);
};

export const TICKS_NUMBER_MAX = 6;
export const INTERVALS_NUMBER_BETWEEN_TICKS = TICKS_NUMBER_MAX - 1;
