import styled from 'styled-components';

export const Row = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	min-height: 400px;
`;

export const HR = styled.hr`
	color: red;
	width: 100%;
`;

export const LeftColumn = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	width: 400px;
	height: 100%;

	margin-right: 24px;
`;

export const MobilityScoreButton = styled.button`
	all: unset;
	text-decoration: underline;
	cursor: pointer;
`;

export const MoreInfo = styled.div`
	padding: 1.2rem 1.5rem;
	background-color: ${(p) => p.theme.colors.gray.gray200};
	margin-top: 1rem;
	line-height: 1.5;
`;

export const MoreInfoHeading = styled.h6`
	margin-bottom: 0.4rem;
	font-size: 14px;
`;

export const MoreInfoColumn = styled.div`
	width: 50%;
`;

export const MapContainer = styled.div`
	height: 254px;
	width: 100%;
`;
