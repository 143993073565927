import { Image, Text, View } from '@react-pdf/renderer';
import { PdfPage } from 'pdf/PdfPage';
import { PropertyAddress } from '../OverviewPage/PropertyAddress';
import { pdfStyle } from '../style';
import { PdfPageProps } from '../types';
import { MarketStatsFilters } from 'Pages/PropertyPageV3/MarketStats/MarketStatsFiltersProvider';
import { distributionMonthsListItems } from 'types/propertyTrendCharts';

type Props = PdfPageProps & {
	imageUrl: string;
	marketStatsFilters: MarketStatsFilters;
};

export const MarketStatsPage = ({
	page,
	user,
	apn,
	property,
	imageUrl,
	marketStatsFilters,
}: Props) => {
	const distributionMonths = distributionMonthsListItems.find(
		(m) => m.value === marketStatsFilters.duration
	);
	return (
		<PdfPage
			logo={page.logo}
			preparedFor={page.preparedFor}
			title={page.titles.overview}
			user={user}
		>
			<View fixed>
				<PropertyAddress property={property} apn={apn} />
			</View>
			<View style={pdfStyle.pageTitle}>
				<Text>
					Market Stats - {marketStatsFilters.spaceType}
					{distributionMonths ? ` - ${distributionMonths.title}` : ''}
				</Text>
			</View>
			<Image src={imageUrl} style={pdfStyle.charts} />
		</PdfPage>
	);
};
