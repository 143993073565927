import React from 'react';
import IconError from 'ui/svg_icons/error.svg';
import styles from './MissingValue.less';
import styled from 'styled-components';

export default function MissingValueComponent({
	tooltip,
	displayName,
}: {
	tooltip?: boolean;
	displayName?: string;
}) {
	if (tooltip) {
		return (
			<span
				className={styles.missingValue}
				data-tooltip={`No ${displayName}`}
				data-tooltipdelay={100}
			>
				<ErrorIconStyled width={23} height={23} />
			</span>
		);
	} else {
		return (
			<span className={styles.missingValue}>
				<ErrorIconStyled width={23} height={23} /> No {displayName}
			</span>
		);
	}
}

const ErrorIconStyled = styled(IconError)`
	fill: hsl(226, 15%, 62%);
`;
