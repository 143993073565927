/**
 * Value undefined means we don't have the data.
 */
export function compFieldDataIsMissing(data: unknown): data is undefined {
	return data === undefined;
}

/**
 * Value null means data is locked.
 */
export function compFieldDataIsLocked(data: unknown): data is null {
	return data === null;
}
