import { MapInteractivity, MapStyle } from '@compstak/maps';
import {
	TenantIndustryLayer,
	TenantIndustryLegend,
	useIsTopTenTenantIndustries,
	useTenantIndustryState,
} from 'maps';
import { useFilters } from 'reducers/filtersReducer';
import styled from 'styled-components';
import { useFilteredTenantIndustries } from '../../TenantIndustryMap';
import { useDashboardTenantIndustryAnalyticsData } from '../../useDashboardTenantIndustryAnalyticsData';
import { PanelDescription, PDFPanel } from './UI';
import { MapWrapper } from 'maps/components/MapWrapper/MapWrapper';

export const TenantIndustryMap = () => {
	const [filters] = useFilters('exchange-dashboard');
	const [state] = useTenantIndustryState();

	const { data } = useDashboardTenantIndustryAnalyticsData();

	const tenantIndustries = useFilteredTenantIndustries();

	const isTopTen = useIsTopTenTenantIndustries({ tenantIndustries, filters });

	return (
		<PDFPanel id="tenant-industry" title="Tenant Industry Map">
			<MapContainer>
				<MapWrapper
					initialViewState={{
						latitude: filters.market.lat,
						longitude: filters.market.lon,
						zoom: filters.market.zoom,
					}}
					interactivity={MapInteractivity.STATIC}
					mapStyle={MapStyle.MONOCHROME}
					mapDomain="marketDashboard"
				>
					<TenantIndustryLayer data={data} marketName={filters.market.name} />
				</MapWrapper>
			</MapContainer>
			<TenantIndustryLegend
				tenantIndustries={tenantIndustries}
				isTopTen={false}
				listCss={{
					display: 'grid',
					gridTemplateColumns: '50% 50%',
					columnGap: '0.5rem',
					padding: '0.5rem',
				}}
			/>
			<PanelDescription style={{ margin: 0 }}>
				{isTopTen ? 'Top ten industries' : 'Selected industries'} based on total{' '}
				{TEXT_MAP[state.metric]} in selected market and submarket(s). Radius
				indicates total {TEXT_MAP[state.metric]} of prevalent industry type of a
				property.
			</PanelDescription>
		</PDFPanel>
	);
};

const MapContainer = styled.div`
	height: 332px;
	width: 100%;
	position: relative;
`;

const TEXT_MAP = {
	sqft: 'square feet',
	leaseCount: 'lease count',
};
