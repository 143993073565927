import { useModal } from 'providers/ModalProvider';
import styled from 'styled-components';
import { BlueBox } from '../UI';
import { EffectiveRentMarketStats } from './EffectiveRentMarketStats';
import { FreeMonthsMarketStats } from './FreeMonthsMarketStats';
import { MarketStatsBoxHeader } from './MarketStatsBoxHeader';
import { MarketStatsOurCalculationModal } from './MarketStatsOurCalculationModal';
import { StartingRentMarketStats } from './StartingRentMarketStats';
import { TenantImprovementMarketStats } from './TenantImprovementMarketStats';
import { TransactionSizeMarketStats } from './TransactionSizeMarketStats';
import { usePropertyPageDataContext } from '../PropertyPageDataProvider';
import { MarketStatsFilters } from './MarketStatsFilters';
import IconCalculate from 'ui/svg_icons/calculate.svg';
import { TooltipV2 } from '@compstak/ui-kit';

export const MarketStats = () => {
	const { openModal } = useModal();

	const { isMarketStatsCollapsed, setIsMarketStatsCollapsed } =
		usePropertyPageDataContext();

	return (
		<BlueBox isCollapsed={isMarketStatsCollapsed}>
			<MarketStatsBoxHeader
				setIsMarketStatsCollapsed={setIsMarketStatsCollapsed}
				isMarketStatsCollapsed={isMarketStatsCollapsed}
			/>
			{!isMarketStatsCollapsed && (
				<>
					<TopContainer>
						<MarketStatsFilters />
						<TooltipV2 content="Calculation Overview">
							<CalculationButton
								onClick={() =>
									openModal({
										modalContent: <MarketStatsOurCalculationModal />,
										modalProps: {
											contentProps: { view: 'medium' },
										},
									})
								}
							>
								<IconCalculate width="1.375rem" height="1.375rem" />
							</CalculationButton>
						</TooltipV2>
					</TopContainer>
					<Container>
						<StartingRentMarketStats />
						<EffectiveRentMarketStats />
						<TransactionSizeMarketStats />
						<TenantImprovementMarketStats />
						<FreeMonthsMarketStats />
					</Container>
				</>
			)}
		</BlueBox>
	);
};

const CalculationButton = styled.button`
	all: unset;
	color: ${(p) => p.theme.colors.blue.blue400};
	text-decoration: underline;
	cursor: pointer;
`;

const TopContainer = styled.div`
	padding-bottom: 1.25rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 0.5rem;
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
`;
