import CrossIcon from 'ui/svg_icons/cross.svg';
import GearIcon from 'ui/svg_icons/gear.svg';
import { MapButton } from './MapButton';
import { ButtonProps } from '@compstak/ui-kit';

type Props = ButtonProps & {
	isMenuOpen: boolean;
};

export const MapAnalyticsSettingsButton = ({ isMenuOpen, ...props }: Props) => {
	const text = isMenuOpen ? 'Close' : 'Settings';
	const icon = isMenuOpen ? (
		<CrossIcon width={8} height={8} />
	) : (
		<GearIcon width={17} height={17} viewBox="0 0 25 25" />
	);

	return (
		<MapButton
			variant="secondary2"
			data-qa-id="analytics-settings-btn"
			icon={icon}
			{...props}
		>
			{text}
		</MapButton>
	);
};
