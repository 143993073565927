import { getSetFilterKeys } from './getSetFilters';
import dayjs from 'dayjs';
import { createFilters, CreateFiltersArg } from 'models/filters';
import { ServerFilterItem } from 'types/serverFilters';
import {
	FiltersTypesKeys,
	FiltersObject,
	FilterDateInterval,
	FilterNumberInterval,
	FilterStringInterval,
	NumberIntervalFiltersKeys,
} from '../types';
import { setFilters } from './setFilters';
import {
	isArrayFilter,
	isBooleanStringFilter,
	isDateIntervalFilterValue,
	isFilterKey,
	isMarketFilter,
	isMarketsFilter,
	isMultiPolygonFilter,
	isNumberIntervalFilterValue,
	isPolygonFilter,
	isRadiusFilter,
	isStringIntervalFilterValue,
	isSubmarketsFilter,
	isAPNFilter,
	isCompIdFilter,
	isDateTimestampFilterKey,
} from './filterHelpers';
import { DATE_FORMATS } from 'constants/dateFormats';
import { processCompatibleServerFilterItem } from './filtersCompatibilityUtils';
import {
	isServerMarketFilter,
	isServerMarketsFilter,
	isServerHiddenFilter,
	isServerBooleanFilter,
	isServerRadiusFilter,
	isServerPolygonFilter,
	isServerDateIntervalFilter,
	isServerNumberIntervalFilter,
	isServerStringIntervalFilter,
	isServerSubmarketsFilter,
	isServerMultiPolygonFilter,
	isServerAPNFilter,
} from './serverFiltersHelpers';
import {
	SUBMARKETS_ID_TO_NAME,
	SUBMARKETS_NAME_TO_IDS,
} from 'constants/submarkets';
import { getFiltersMarkets } from './getFiltersMarkets';
import { getServerCacheFilter } from './serverFiltersCache';
import {
	isDateIntervalFilterKey,
	isNumberIntervalFilterKey,
	isStringIntervalFilterKey,
} from './isIntervalFilterKey';
import { MarketsState } from 'Pages/Login/reducers';

// This one is needed for enabling compatible filters to be still processable and mapped to current ones
export const COMPATIBLE_FILTERS = {
	tenantName: {
		filterProperty: 'tenantNames',
		serializedProperty: 'tenantNames',
		valueMapping: (oldValue: string) => [oldValue],
		serializedValueMapping: (oldValue: string) => [oldValue],
		queryParamMapping: (oldValue: string) =>
			encodeURIComponent(JSON.stringify([oldValue])),
		isListFilterKey: false,
	},
	landlordName: {
		filterProperty: 'landlordNames',
		serializedProperty: 'landlordNames',
		valueMapping: (oldValue: string) => [oldValue],
		serializedValueMapping: (oldValue: string) => [oldValue],
		queryParamMapping: (oldValue: string) =>
			encodeURIComponent(JSON.stringify([oldValue])),
		isListFilterKey: false,
	},
	loanOriginator: {
		filterProperty: 'loanOriginators',
		serializedProperty: 'loanOriginators',
		valueMapping: (oldValue: string) => [oldValue],
		serializedValueMapping: (oldValue: string) => [oldValue],
		queryParamMapping: (oldValue: string) =>
			encodeURIComponent(JSON.stringify([oldValue])),
		isListFilterKey: false,
	},
	submarket: {
		filterProperty: 'submarkets',
		serializedProperty: 'submarketId',
		serializedValueMapping: (oldValue: string[], filters: FiltersObject) => {
			const filtersMarketIds = getFiltersMarkets(filters).map(({ id }) => id);
			// TODO: AP-12875 extract submarkets from submarkets state, not hardcoded submarkets
			const submarketIds = oldValue.flatMap((name) => {
				const submarket = SUBMARKETS_NAME_TO_IDS[name];
				if (submarket) {
					return submarket.ids.filter((_, index) =>
						filtersMarketIds.some((id) => id === submarket.marketIds[index])
					);
				}
				return [];
			});

			return [...new Set(submarketIds)];
		},
		valueMapping: (oldValue: string[]) => {
			// TODO: AP-12875 extract submarkets from submarkets state, not hardcoded submarkets
			const submarkets = oldValue.flatMap((name) => {
				const submarket = SUBMARKETS_NAME_TO_IDS[name];
				if (submarket) {
					return submarket.ids.map((id) => ({ id, name }));
				}
				return [];
			});

			return submarkets;
		},
		queryParamMapping: (oldValue: string) => {
			// TODO: AP-12875 extract submarkets from submarkets state, not hardcoded submarkets
			const value = JSON.parse(oldValue) as string[];
			const submarketIds = value.flatMap((name) => {
				const submarket = SUBMARKETS_NAME_TO_IDS[name];
				if (submarket) {
					return submarket.ids;
				}
				return [];
			});

			return `[${[...new Set(submarketIds)]}]`;
		},
		isListFilterKey: true,
	},
	buyer: {
		filterProperty: 'buyers',
		serializedProperty: 'buyers',
		valueMapping: (oldValue: string) => [oldValue],
		serializedValueMapping: (oldValue: string) => [oldValue],
		queryParamMapping: (oldValue: string) =>
			encodeURIComponent(JSON.stringify([oldValue])),
		isListFilterKey: false,
	},
	seller: {
		filterProperty: 'sellers',
		serializedProperty: 'sellers',
		valueMapping: undefined,
		serializedValueMapping: undefined,
		queryParamMapping: undefined,
		isListFilterKey: true,
	},
} as const;

/**
 * When we leave boolean fields as boolean, then we get bugs: AP-5092 & AP-5094.
 * This is because typed-immutable treats `value: false` same as `value: null`.
 * This means that we don't send api requests and don't have filters UI working.
 * If we convert them to strings:
 * 		1. filterToServerJSON -- We send boolean to api.
 * 		2. filtersFromServerJson -- We load boolean -> string for filters to work.
 */

export function filterToServerJSON<K extends FiltersTypesKeys>(
	value: FiltersObject[K],
	key: K
): ServerFilterItem {
	if (!isFilterKey(key)) {
		throw new Error(`Unknown filter (key: ${key}, value: ${value})`);
	}

	if (key === 'hidden') {
		switch (value) {
			case null:
				return {
					property: key,
					value: false,
				};
			case 'hidden':
				return {
					property: key,
					value: true,
				};
			case 'both':
				return {
					property: key,
					value: null,
				};
		}
	}

	if (isCompIdFilter(value)) {
		return {
			property: 'id',
			value: value.value ?? [],
			exclude: value.exclude ?? true,
		};
	}

	if (isBooleanStringFilter(key)) {
		switch (value) {
			case 'false':
				return {
					property: key,
					value: false,
				};
			case 'true':
				return {
					property: key,
					value: true,
				};
			case null:
				return {
					property: key,
					value: null,
				};
		}
	}

	if (isMarketFilter(value)) {
		return {
			property: 'marketName',
			value: value.name,
		};
	}

	if (isMarketsFilter(value)) {
		return {
			property: 'marketNames',
			value: value.map((x) => x.name),
		};
	}

	if (isSubmarketsFilter(value)) {
		return {
			property: 'submarketId',
			value: value.map(({ id }) => id),
		};
	}

	if (isRadiusFilter(value)) {
		return {
			property: 'area',
			value: {
				distance: value.distance,
				point: value.center,
				shape: 'circle',
				buildingAddressAndCity: value.buildingAddressAndCity,
			},
		};
	}

	if (isPolygonFilter(value)) {
		return {
			property: 'area',
			value: {
				points: value,
				shape: 'polygon',
			},
		};
	}

	if (isMultiPolygonFilter(value)) {
		return {
			property: 'area',
			value: {
				points: value,
				shape: 'multiPolygon',
			},
		};
	}

	if (isAPNFilter(value)) {
		const area = value.area ?? getServerCacheFilter('apn')?.area;
		if (!area) {
			return {
				property: 'fips',
				value: [value.fips],
			};
		}

		return {
			property: 'apn',
			value: {
				points: area,
				shape: 'multiPolygon',
				fips: value.fips,
				apn: value.apn,
			},
		};
	}

	if (isNumberIntervalFilterKey(key) && isNumberIntervalFilterValue(value)) {
		return {
			property: key,
			value: {
				...(value.min != null && { from: value.min }),
				...(value.max != null && { to: value.max }),
				...(value.allowFallback != null && {
					allowFallback: value.allowFallback,
				}),
			},
		} as ServerFilterItem<NumberIntervalFiltersKeys>;
	}

	if (isStringIntervalFilterKey(key) && isStringIntervalFilterValue(value)) {
		return {
			property: key,
			value: {
				...(value.min != null && { from: value.min }),
				...(value.max != null && { to: value.max }),
				...(value.allowFallback != null && {
					allowFallback: value.allowFallback,
				}),
			},
		};
	}

	if (isDateIntervalFilterKey(key) && isDateIntervalFilterValue(value)) {
		const min = value.min
			? isDateTimestampFilterKey(key)
				? dayjs.utc(value.min).format(DATE_FORMATS['YYYY-MM-DDTHH:mm:ss[Z]'])
				: dayjs(value.min).format(DATE_FORMATS['MM/DD/YYYY'])
			: null;
		const max = value.max
			? isDateTimestampFilterKey(key)
				? dayjs.utc(value.max).format(DATE_FORMATS['YYYY-MM-DDTHH:mm:ss[Z]'])
				: dayjs(value.max).format(DATE_FORMATS['MM/DD/YYYY'])
			: null;
		const allowFallback = value.allowFallback;

		return {
			property: key,
			value: {
				...(min != null && {
					from: min,
				}),
				...(max != null && {
					to: max,
				}),
				...(allowFallback != null && {
					allowFallback: allowFallback,
				}),
			},
		};
	}

	if (isArrayFilter(value)) {
		return {
			property: key,
			value,
		} as ServerFilterItem;
	}

	return {
		property: key,
		value,
	} as ServerFilterItem;
}

export function filtersToServerJSON(
	filters: Partial<FiltersObject>,
	excludeHidden = false
) {
	let setFilters = getSetFilterKeys(filters);
	if ('hidden' in filters) {
		if (excludeHidden || filters.hidden === 'both') {
			setFilters = setFilters.filter((k) => k !== 'hidden');
		} else if (filters.hidden === null) {
			setFilters.push('hidden');
		}
	}

	return setFilters.map((key) => {
		return filterToServerJSON(filters[key], key);
	});
}

export function filtersFromServerJSON<T extends CreateFiltersArg>(
	compType: T,
	markets: MarketsState,
	serverJSON: ServerFilterItem[],
	extraOptions: { ignoreErrors?: boolean; warnOnErrors?: boolean } = {}
) {
	console.assert(
		Array.isArray(serverJSON),
		'serverJSON is expected to be an array'
	);

	let filters = createFilters(compType);

	function setServerItemToFilters(
		acc: typeof filters,
		serverItem: ServerFilterItem
	) {
		serverItem = processCompatibleServerFilterItem(serverItem, acc);

		if (isServerMarketFilter(serverItem) && serverItem.value) {
			if (!markets) {
				return acc;
			}
			return setFilters(acc, 'market', markets[serverItem.value]);
		}

		if (isServerMarketsFilter(serverItem)) {
			if (!markets || !Array.isArray(serverItem.value)) {
				return acc;
			}
			return setFilters(
				acc,
				'markets',
				serverItem.value.map((serverMarket) => markets[serverMarket])
			);
		}

		if (isServerSubmarketsFilter(serverItem)) {
			if (!Array.isArray(serverItem.value)) {
				return acc;
			}

			// TODO: AP-12875 extract submarkets from submarkets state
			return setFilters(
				acc,
				'submarkets',
				serverItem.value
					.map((id) => ({ id, name: SUBMARKETS_ID_TO_NAME[id]?.name }))
					.filter(({ name }) => !!name)
			);
		}

		if (isServerRadiusFilter(serverItem) && serverItem.value) {
			const lat = serverItem.value.point.lat;
			const lng = serverItem.value.point.lng;
			const distance = serverItem.value.distance;
			const buildingAddressAndCity = serverItem.value.buildingAddressAndCity;
			const radius = {
				center: { lat, lng },
				distance,
				buildingAddressAndCity,
			};
			return setFilters(acc, 'radius', radius);
		}

		if (isServerPolygonFilter(serverItem) && serverItem.value) {
			return setFilters(
				acc,
				'polygon',
				serverItem.value.points ?? serverItem.value
			);
		}

		if (isServerAPNFilter(serverItem) && serverItem.value) {
			return setFilters(acc, 'apn', {
				fips: serverItem.value.fips,
				apn: serverItem.value.apn,
				area: serverItem.value.points,
			});
		}

		if (isServerMultiPolygonFilter(serverItem) && serverItem.value) {
			return setFilters(
				acc,
				'multiPolygon',
				serverItem.value.points ?? serverItem.value
			);
		}

		if (isServerDateIntervalFilter(serverItem) && serverItem.value) {
			const dateIntervalFilter = acc[serverItem.property] as FilterDateInterval;
			const min = serverItem.value.from
				? isDateTimestampFilterKey(serverItem.property)
					? dayjs
							.utc(
								serverItem.value.from,
								DATE_FORMATS['YYYY-MM-DDTHH:mm:ss[Z]']
							)
							.toDate()
					: dayjs(serverItem.value.from, DATE_FORMATS['MM/DD/YYYY']).toDate()
				: null;
			const max = serverItem.value.to
				? isDateTimestampFilterKey(serverItem.property)
					? dayjs
							.utc(serverItem.value.to, DATE_FORMATS['YYYY-MM-DDTHH:mm:ss[Z]'])
							.toDate()
					: dayjs(serverItem.value.to, DATE_FORMATS['MM/DD/YYYY']).toDate()
				: null;
			const allowFallback = serverItem.value.allowFallback;

			return setFilters(acc, serverItem.property, {
				...dateIntervalFilter,
				...(min != null && {
					min,
				}),
				...(max != null && {
					max,
				}),
				...(allowFallback != null && { allowFallback }),
			});
		}

		if (
			serverItem.value &&
			(isServerNumberIntervalFilter(serverItem) ||
				isServerStringIntervalFilter(serverItem))
		) {
			const intervalFilter = acc[serverItem.property] as
				| FilterNumberInterval
				| FilterStringInterval;
			const min = serverItem.value.from;
			const max = serverItem.value.to;
			const allowFallback = serverItem.value.allowFallback;

			// @ts-expect-error TS2345: Argument of type '{ comparison...
			return setFilters(acc, serverItem.property, {
				...intervalFilter,
				...(min != null && { min }),
				...(max != null && { max }),
				...(allowFallback != null && { allowFallback }),
			});
		}

		if (isServerHiddenFilter(serverItem)) {
			switch (serverItem.value) {
				case null:
					return setFilters(acc, serverItem.property, 'both');

				case true:
					return setFilters(acc, serverItem.property, 'hidden');

				case false:
					return setFilters(acc, serverItem.property, null);
			}
		}

		if (isServerBooleanFilter(serverItem)) {
			return setFilters(
				acc,
				serverItem.property,
				// @ts-expect-error TS2345: Argument of type 'string | nul...
				serverItem.value?.toString() ?? null
			);
		}

		// @ts-expect-error TS2345: Argument of type '"transaction...
		return setFilters(filters, serverItem.property, serverItem.value);
	}

	for (const serverItem of serverJSON) {
		try {
			filters = setServerItemToFilters(filters, serverItem);
		} catch (err) {
			if (extraOptions.ignoreErrors) {
				if (extraOptions.warnOnErrors ?? true) {
					console.warn(err);
				}
			} else {
				throw err;
			}
		}
	}

	return filters;
}
