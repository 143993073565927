import { KeyInfoItem } from 'Components/KeyInfoItem';
import styled from 'styled-components';
import { MufaPropertyComp, PropertyComp } from 'types';
import { Labels, commercialLabels, mufaLabels } from './labels';
import { COMP_FIELD_TOOLTIPS } from 'constants/tooltips';
import { nullValueText } from '@compstak/common';
import { formatDate, formatPercent } from 'format';
import { ReactNode } from 'react';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

type KeyInfoItemsProps = {
	property: PropertyComp | MufaPropertyComp;
	isMufa: boolean;
};
export const KeyInfoItems = ({ property, isMufa }: KeyInfoItemsProps) => {
	const labels = isMufa ? mufaLabels : commercialLabels;

	const { AdditionOfOccupancy } = useFeatureFlags();
	return (
		<KeyInfoContainer>
			{keyInfoAttributes.map((key) => {
				if (key === 'occupancyPercentage' && !AdditionOfOccupancy) return null;

				const customAttributeCtx = attributesMap[key];

				const customAttribute = customAttributeCtx
					? customAttributeCtx({ property, labels })
					: undefined;

				if (customAttribute === null) {
					return null;
				}

				const title = customAttribute
					? customAttribute.title
					: labels[key].name;

				const plainPropertyValue =
					key in property ? property[key as keyof PropertyComp] : '';

				const formattedPropertyValue =
					plainPropertyValue &&
					(labels[key]?.formatter?.(plainPropertyValue) ?? plainPropertyValue);

				const value = customAttribute
					? customAttribute.value
					: formattedPropertyValue ?? '';

				const tooltip =
					key === 'buildingClass'
						? COMP_FIELD_TOOLTIPS.buildingClass
						: undefined;

				return (
					<KeyInfoItem
						key={key}
						tooltip={tooltip}
						title={title}
						value={value}
					/>
				);
			})}
		</KeyInfoContainer>
	);
};

type AttributesMap = Partial<
	Record<
		KeyInfoAttributes,
		(props: { property: KeyInfoItemsProps['property']; labels: Labels }) => {
			title: string;
			value: ReactNode;
		} | null
	>
>;

const attributesMap: AttributesMap = {
	yearInfo: ({ property, labels }) => {
		const { buildingYear, buildingYearRenovated } = property;
		const yearBuilt = buildingYear ?? nullValueText;
		const yearRenovated = buildingYearRenovated ?? nullValueText;

		return {
			title: labels.yearInfo.name,
			value: `${yearBuilt} / ${yearRenovated}`,
		};
	},
	occupancyPercentage: ({ property, labels }) => {
		const { occupancyPercentage, occupancyDate } = property;

		if (occupancyPercentage === undefined) {
			return null;
		}

		return {
			title: labels.occupancyPercentage.name,
			value: (
				<>
					<OccupancyValue>
						{formatPercent(occupancyPercentage / 100)}
					</OccupancyValue>{' '}
					{occupancyDate && (
						<OccupanceDate>
							(as of {formatDate(new Date(occupancyDate))})
						</OccupanceDate>
					)}
				</>
			),
		};
	},
};

export const keyInfoAttributes: KeyInfoAttributes[] = [
	'buildingPropertyType',
	'buildingClass',
	'landlordName',
	'buildingPropertySubtype',
	'buildingSize',
	'yearInfo',
	'occupancyPercentage',
];

export type KeyInfoAttributes =
	| keyof Pick<
			PropertyComp,
			| 'buildingPropertyType'
			| 'buildingClass'
			| 'landlordName'
			| 'buildingPropertySubtype'
			| 'buildingSize'
			| 'buildingYear'
			| 'buildingYearRenovated'
			| 'occupancyPercentage'
	  >
	| 'yearInfo';

export const KeyInfoContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	gap: 16px;
	& > div:nth-child(3) {
		grid-column: span 2;
	}
`;

const OccupancyValue = styled.span`
	line-height: 1.3;
`;

const OccupanceDate = styled.span`
	font-size: 0.75rem;
	color: ${({ theme }) => theme.colors.gray.gray300};
`;
