// inject needs to be first
import './inject';
import dayjs from 'dayjs';
import DayJSIsSameOrAfterPlugin from 'dayjs/plugin/isSameOrAfter';
import utc from 'dayjs/plugin/utc';
import { createRoot } from 'react-dom/client';
import { APP_CONTAINER_ID } from 'ui/UI';
import AppEntry from './AppEntry';

dayjs.extend(DayJSIsSameOrAfterPlugin);
dayjs.extend(utc);

const container = document.getElementById(APP_CONTAINER_ID) as HTMLElement;
const root = createRoot(container);

root.render(<AppEntry />);
