import Details from 'Components/Lease/Details';
import { useGetPreferredPreviewComp } from 'services/userflowOnboardingServices';
import { Spinner } from '@compstak/ui-kit';
import { routes, useNavigate } from 'router';
import { Modal } from 'Components/Modals/common/UI';
import { CompNotFound } from 'Components/CompNotFound/CompNotFound';

// @ts-expect-error TS7006: Parameter 'props' implicitly h...
export default function LeaseDetails(props) {
	return <Details {...props} />;
}

export function LeaseDetailsExample() {
	const { data: compId, isLoading, error } = useGetPreferredPreviewComp();
	const navigate = useNavigate();

	if (isLoading || error) {
		return (
			<Modal
				onClose={() =>
					navigate(routes.searchLeasesHome.toHref(), { replace: true })
				}
			>
				{isLoading ? (
					<Spinner isCentered size="l" />
				) : (
					<CompNotFound
						onClick={() =>
							navigate(routes.searchLeasesHome.toHref(), { replace: true })
						}
					/>
				)}
			</Modal>
		);
	}

	navigate(
		{
			pathname: routes.leaseById.toHref({ id: Number(compId) }),
		},
		{ replace: true }
	);
	return null;
}
