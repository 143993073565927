import {
	useChartPropertiesTopQuery,
	PROPERTY_TYPE_NAME_TO_ID,
	PropertyTypeId,
} from 'api';
import { formatPercent } from 'format';
import { PortfolioPieChart } from 'PortfolioAnalytics/Charts/PortfolioPieChart';
import { ChartStateWrapper } from '../ChartStateWrapper';
import { FiltersState } from 'PortfolioAnalytics/PortfolioFiltersProvider';
import { ChartBox, ChartOption } from 'PortfolioAnalytics/Charts/ChartSelect';
import { useNavigate } from 'react-router';
import { routes } from 'router';

type PropertyTypeByPropertySqftChartProps = {
	portfolioId: number;
	filters: FiltersState;
};

type PropertyTypeByPropertySqftChartV2ContentProps =
	PropertyTypeByPropertySqftChartProps & {
		selectedChart: ChartOption<'property'>;
	};

const PROPERTY_TYPE_BY_PROPERTY_SQFT_CHART_NAME = 'Property Type';

export const PropertyTypeByPropertySqftChartV2 = (
	props: PropertyTypeByPropertySqftChartProps
) => {
	return (
		<ChartBox
			chartName={PROPERTY_TYPE_BY_PROPERTY_SQFT_CHART_NAME}
			chartDataType="property"
		>
			{(selectedChart) => (
				<PropertyTypeByPropertySqftChartV2Content
					{...props}
					selectedChart={selectedChart}
				/>
			)}
		</ChartBox>
	);
};

const PropertyTypeByPropertySqftChartV2Content = ({
	portfolioId,
	filters,
	selectedChart,
}: PropertyTypeByPropertySqftChartV2ContentProps) => {
	const { data, isFetching, isError } = useChartPropertiesTopQuery(
		portfolioId,
		{
			filters,
			chartMetric:
				selectedChart.type === 'totalBuildingSize'
					? 'buildingSize'
					: selectedChart.type,
			chartGrouping: 'buildingPropertyType',
			chartLimit: 10,
		}
	);

	const total = data?.total;
	const chartData =
		!total || total === 0 || !data?.values
			? []
			: data?.values
					.map((item) => ({
						x: item.label,
						y: item.value,
						label: formatPercent(item.value / total, 1),
						propertyTypeId:
							PROPERTY_TYPE_NAME_TO_ID[
								item.label as keyof typeof PROPERTY_TYPE_NAME_TO_ID
							],
					}))
					.toSorted((a, b) => b.y - a.y);

	const noData = chartData.length === 0;

	const navigate = useNavigate();

	return (
		<ChartStateWrapper
			isError={isError}
			isFetching={isFetching}
			noData={noData}
			showNoDataMessage={false}
		>
			<PortfolioPieChart
				data={chartData}
				events={[
					{
						target: 'data',
						eventHandlers: {
							onClick: (_, clickedProps) => {
								const propertyTypeId = clickedProps.datum
									.propertyTypeId as PropertyTypeId;
								navigate(
									routes.portfolioByIdView.toHref(
										{ portfolioId: portfolioId, viewType: 'list' },
										{ propertyTypeIds: [propertyTypeId] }
									)
								);
							},
						},
					},
				]}
				dataType="property"
			/>
		</ChartStateWrapper>
	);
};
