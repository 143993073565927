import { GoogleMapSwitcher, GoogleMapSwitcherProps } from '@compstak/maps';
import { FeatureFlags } from 'api/featureFlags/types';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { MapNotAvailable } from '../MapNotAvailable/MapNotAvailable';
import styled from 'styled-components';
import { MAP_NOT_AVAILABLE_MESSAGE } from './constants';

type GoogleMapSwitcherWrapperProps = GoogleMapSwitcherProps & {
	mapDomain: FeatureFlags['disableMapsFF'][number];
};

export const GoogleMapSwitcherWrapper = ({
	mapDomain,
	...props
}: GoogleMapSwitcherWrapperProps) => {
	const { disableMapsFF } = useFeatureFlags();
	const isDisabled = disableMapsFF.includes(mapDomain);

	if (isDisabled) {
		return (
			<MapNotAvailableStyled
				message={MAP_NOT_AVAILABLE_MESSAGE}
				width={props.width}
				height={props.height}
			/>
		);
	}

	return <GoogleMapSwitcher {...props} />;
};

const MapNotAvailableStyled = styled(MapNotAvailable)<{
	width?: number;
	height?: number;
}>`
	${({ width }) => width && `width: ${width}px`};
	${({ height }) => height && `height: ${height}px`};
`;
