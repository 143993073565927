import { memo } from 'react';
import { Portfolio, PROPERTY_TYPE_ID_TO_NAME, PropertyTypeId } from 'api';
import { formatPercent } from 'format';
import { groupBy, mapValues } from 'lodash';
import { ChartBox, ChartTypeLease, getTotalValuesLease } from '../ChartSelect';
import { PortfolioPieChart } from '../PortfolioPieChart';
import { useNavigate } from 'react-router';
import { routes } from 'router';
import { ChartStateWrapper } from '../ChartStateWrapper';

interface PropertyTypeChartProps {
	portfolio: Portfolio;
}

const PROPERTY_TYPE_CHART_NAME = 'Property Type';

export const PropertyTypeChart = memo(
	({ portfolio }: PropertyTypeChartProps) => {
		const groupsByPropertyType = groupBy(
			portfolio.propertyTypesByMarkets,
			(p) => p.buildingPropertyTypeId
		);

		const propertyTypeIds = Object.keys(groupsByPropertyType).map((key) =>
			Number(key)
		) as PropertyTypeId[];

		const totalValuesPerPropertyType = mapValues(
			groupsByPropertyType,
			getTotalValuesLease
		);
		const totalValuesForPortfolio = getTotalValuesLease(
			Object.values(totalValuesPerPropertyType)
		);

		const propertyTypeBySqft = propertyTypeIds.map((propertyTypeId) => {
			return getChartPoint(
				propertyTypeId,
				totalValuesPerPropertyType[propertyTypeId].totalSqFt,
				totalValuesForPortfolio.totalSqFt
			);
		});

		const propertyTypeByLeaseAmount = propertyTypeIds.map((propertyTypeId) => {
			return getChartPoint(
				propertyTypeId,
				totalValuesPerPropertyType[propertyTypeId].activeLeaseAmount,
				totalValuesForPortfolio.activeLeaseAmount
			);
		});

		const propertyTypeByLeaseCount = propertyTypeIds.map((propertyTypeId) => {
			return getChartPoint(
				propertyTypeId,
				totalValuesPerPropertyType[propertyTypeId].activeLeaseCount,
				totalValuesForPortfolio.activeLeaseCount
			);
		});

		const getData = (chartType: ChartTypeLease) => {
			switch (chartType) {
				case 'sqft':
					return propertyTypeBySqft;
				case 'leaseAmount':
					return propertyTypeByLeaseAmount;
				case 'leaseCount':
					return propertyTypeByLeaseCount;
				default:
					return [];
			}
		};

		const navigate = useNavigate();

		return (
			// Property Type Chart V1 uses the "lease" options for the select options
			<ChartBox<'lease'> chartName={PROPERTY_TYPE_CHART_NAME}>
				{(selectedChart) => {
					const data = getData(selectedChart.type);

					return (
						<ChartStateWrapper
							noData={data.length === 0 || !data.some((d) => d.y > 0)}
						>
							<PortfolioPieChart
								data={data}
								events={[
									{
										target: 'data',
										eventHandlers: {
											onClick: (_, clickedProps) => {
												const propertyTypeId = clickedProps.datum
													.propertyTypeId as PropertyTypeId;
												navigate(
													routes.portfolioByIdView.toHref(
														{ portfolioId: portfolio.id, viewType: 'list' },
														{ propertyTypeIds: [propertyTypeId] }
													)
												);
											},
										},
									},
								]}
								dataType="property"
							/>
						</ChartStateWrapper>
					);
				}}
			</ChartBox>
		);
	}
);

PropertyTypeChart.displayName = 'PropertyTypeChart';

const getChartPoint = (
	propertyTypeId: PropertyTypeId,
	value: number,
	totalValue: number
) => {
	return {
		x: PROPERTY_TYPE_ID_TO_NAME[propertyTypeId],
		y: value,
		label:
			totalValue !== 0
				? formatPercent(value / totalValue, 1)
				: formatPercent(0, 1),
		propertyTypeId,
	};
};
