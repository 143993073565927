import { LeaseInsights, useHasLeaseInsights } from 'Components/LeaseInsights';
import { FilterChangeEffect } from 'Layouts/SearchLayout';
import { useEffect, useMemo, useState } from 'react';
import { useFilters } from 'reducers/filtersReducer';
import styled from 'styled-components';
import { LeasesSearchTable } from './LeasesSearchTable';
import { useCreateAdditionalFilters } from './utils/useCreateAdditionalFilters';

const TableComponent = () => {
	const [filters, setFilters, resetFilters] = useFilters('exchange-dashboard');

	const additionalFilters = useCreateAdditionalFilters(filters);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const backupFilters = useMemo(() => filters, []);

	useEffect(() => {
		if (!additionalFilters) {
			return;
		}

		setFilters(additionalFilters);

		return () => {
			resetFilters(backupFilters);
		};
	}, [additionalFilters, backupFilters, setFilters, resetFilters]);

	const hasInsights = useHasLeaseInsights({ filters });

	const [areInsightsOpen, setAreInsightsOpen] = useState(false);

	return (
		<Container>
			<FilterChangeEffect compType={'lease'} filters={filters} />
			<SearchTableLayout
				style={{
					gridTemplateRows: hasInsights
						? areInsightsOpen
							? '35px 270px auto'
							: `35px auto`
						: undefined,
				}}
			>
				{hasInsights && (
					<LeaseInsights
						isOpen={areInsightsOpen}
						toggleIsOpen={() => setAreInsightsOpen((s) => !s)}
						isDashboard
					/>
				)}
				<LeasesSearchTable
					settingsMenuSide={areInsightsOpen ? 'right' : 'bottom'}
				/>
			</SearchTableLayout>
		</Container>
	);
};

export default TableComponent;

const Container = styled.div`
	height: calc(100% - 90px);
	position: relative;
	flex: 1;
`;

const SearchTableLayout = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	display: grid;
	background-color: #fff;
`;
