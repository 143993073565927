import { API } from '@compstak/common';
import {
	useMutation,
	UseMutationOptions,
	useQueryClient,
} from '@tanstack/react-query';
import { SavedSearch } from './useSavedSearchesQuery';
import { QUERY_KEYS } from 'api/constants';

export const useViewSavedSearchMutation = (
	options?: UseMutationOptions<void, unknown, UpdateSavedSearchParams>
) => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (params: UpdateSavedSearchParams) => {
			await API.put(`/api/savedSearches/${params.id}/actions/viewed`);
		},
		onSuccess: (_data, variables) => {
			queryClient.setQueryData<SavedSearch[]>(
				[QUERY_KEYS.SAVED_SEARCHES],
				(oldSearches) => {
					if (!oldSearches) return oldSearches;
					return oldSearches.map((savedSearch) => {
						if (savedSearch.id === variables.id) {
							return {
								...savedSearch,
								dateSeen: new Date().toISOString(),
							};
						}
						return savedSearch;
					});
				}
			);
		},
		...options,
	});
};

export type UpdateSavedSearchParams = Pick<SavedSearch, 'id'>;
