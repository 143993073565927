import { MQB } from '@compstak/ui-kit';
import styled from 'styled-components';

const widthDefault = 160;

export const Wrapper = styled.div<{ width?: number }>`
	min-width: ${(props) => props.width || widthDefault}px;
	position: relative;
`;

export const Selector = styled.div`
	align-items: center;
	background: transparent;
	border-radius: 3px;
	border: 1px solid #d5d8df;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	padding: 4px 13px 4px 4px;
	transition: border-color 300ms;
	color: rgba(0, 0, 0, 0.87);

	&:hover {
		border-color: #5e6579;
	}
`;

export const SelectorContentWrapper = styled.div`
	width: 100%;
	font-size: 0.875rem;

	@media (max-width: ${MQB.D_1280}) {
		font-size: 0.75rem;
	}
`;

export const SelectorTitle = styled.span`
	font-size: 0.715em;
	color: ${(p) => p.theme.colors.gray.gray700};
`;

export const SelectorLabel = styled.div`
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	color: ${(p) => p.theme.colors.gray.gray700};
`;

export const SelectorIconWrapper = styled.div<{ isOpen: boolean }>`
	transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'none')};
`;

export const ListWrapper = styled.div`
	background-color: #fff;
	border-radius: 3px;
	box-shadow:
		0px 4px 12px rgb(50 50 71 / 8%),
		0px 16px 24px rgb(50 50 71 / 8%);
	max-height: 200px;
	overflow-x: hidden;
	overflow-y: auto;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 100;
`;

export const ListItem = styled.div`
	padding: 10px 20px;
	cursor: pointer;
	display: flex;
	align-items: center;
	transition: background-color 120ms;

	&:hover {
		background-color: #f1f2f5;
	}
`;

export const ListItemText = styled.span<{ active: boolean }>`
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	${(props) => props.active && `font-weight: bold`};
`;
