import {
	ModalButton,
	ModalButtons,
	ModalParagraph,
	ModalTitle,
} from 'Components/Modals/common/UI';
import { usePortfoliosSelection } from 'PortfolioAnalytics/hooks/usePortfoliosSelection';
import { useModal } from 'providers/ModalProvider';

export const RemovePortfolioModal = ({
	portfolioName,
	portfolioId,
}: {
	portfolioName: string;
	portfolioId: number;
}) => {
	const { closeModal } = useModal();
	const { selectPortfolio, isUpdatingPortfolioSelection } =
		usePortfoliosSelection();

	return (
		<>
			<ModalTitle>Remove portfolio from Comparison?</ModalTitle>
			<ModalParagraph>
				Click <b>CONFIRM</b> to remove <b>{portfolioName}</b> from the
				Comparison view. The portfolio can be reselected from the left panel if
				needed.
			</ModalParagraph>
			<ModalButtons>
				<ModalButton onClick={closeModal} variant="secondary2">
					Cancel
				</ModalButton>
				<ModalButton
					disabled={isUpdatingPortfolioSelection}
					isLoading={isUpdatingPortfolioSelection}
					onClick={() => {
						selectPortfolio(
							{ portfolioId: portfolioId, selected: false },
							{ onSuccess: () => closeModal() }
						);
					}}
				>
					Confirm
				</ModalButton>
			</ModalButtons>
		</>
	);
};
