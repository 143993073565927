import { Comp, CompType } from 'types/comp';
import salePointService from 'services/salePoints';
import leasePointService from 'services/leasePoints';
import propertyPointService from 'services/propertyPoints';
import mufaPropertyPointsService from 'services/mufaPropertyPoints';
import { FiltersObject } from 'models/filters/types';
import { PINS_LIMIT } from 'Pages/Search/Map/actions';
import { AppDispatch, AppGetState } from 'store';
import { ReduxPromiseAction } from 'types/redux-promise-middleware';
import { isMultifamily } from 'util/isMultifamily';
import { filtersToServerJSON } from 'models/filters/util/serverJson';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';

export const SELECT_COMPS = 'SELECT_COMPS' as const;
export const DESELECT_COMPS = 'DESELECT_COMPS' as const;
export const DESELECT_ALL = 'DESELECT_ALL' as const;
export const SELECT_ALL_COMPS = 'SELECT_ALL_COMPS' as const;

export const selectAllComps =
	(filters: FiltersObject, compType: CompType) =>
	(dispatch: AppDispatch, getState: AppGetState) => {
		const store = getState();

		if (compType === 'property') {
			const permissions = store.permissions;

			const useMultifamily = isMultifamily({
				markets: getFiltersMarkets(filters),
				permissions,
			});
			return dispatch(
				useMultifamily
					? selectMufaPropertiesInternal(filters)
					: selectPropertiesInternal(filters)
			);
		} else if (compType === 'sale') {
			return dispatch(selectSalesInternal(filters));
		}
		return dispatch(selectLeasesInternal(filters));
	};

export function deselectAll() {
	return {
		type: DESELECT_ALL,
	};
}

export function selectComps(comps: Comp[]) {
	return {
		type: SELECT_COMPS,
		payload: comps,
	};
}

export function deselectComps(compIds: number[]) {
	return {
		type: DESELECT_COMPS,
		payload: compIds,
	};
}

const selectLeasesInternal = (filters: FiltersObject) => {
	const filter = filtersToServerJSON(filters);

	return {
		type: SELECT_ALL_COMPS,
		payload: {
			promise: leasePointService
				.load({
					filter,
					properties: ['id', 'own', 'cost', 'marketId', 'propertyId'],
					limit: PINS_LIMIT,
					sort: filters.sortField,
					order: filters.sortDirection,
				})
				.then((response) => response.comps),
		},
	};
};

const selectSalesInternal = (filters: FiltersObject) => {
	const filter = filtersToServerJSON(filters);

	return {
		type: SELECT_ALL_COMPS,
		payload: {
			promise: salePointService
				.load({
					filter,
					properties: ['id', 'own', 'cost', 'marketId', 'portfolio.propertyId'],
					limit: PINS_LIMIT,
					sort: filters.sortField,
					order: filters.sortDirection,
				})
				.then((response) => response.comps),
		},
	};
};

const selectPropertiesInternal = (filters: FiltersObject) => {
	const filter = filtersToServerJSON(filters);

	return {
		type: SELECT_ALL_COMPS,
		payload: {
			promise: propertyPointService
				.load({
					filter,
					properties: ['id', 'marketId', 'buildingAddressAndCity'],
					limit: PINS_LIMIT,
					sort: filters.sortField,
					order: filters.sortDirection,
				})
				.then((response) => response.properties),
		},
	};
};

const selectMufaPropertiesInternal = (filters: FiltersObject) => {
	const filter = filtersToServerJSON(filters);

	return {
		type: SELECT_ALL_COMPS,
		payload: {
			promise: mufaPropertyPointsService
				.load({
					filter,
					properties: ['id', 'marketId', 'buildingAddressAndCity'],
					limit: PINS_LIMIT,
					sort: filters.sortField,
					order: filters.sortDirection,
				})
				.then((response) => response.properties),
		},
	};
};

export type SelectionAction =
	| ReduxPromiseAction<ReturnType<typeof selectLeasesInternal>>
	| ReduxPromiseAction<ReturnType<typeof selectSalesInternal>>
	| ReduxPromiseAction<ReturnType<typeof selectPropertiesInternal>>
	| ReduxPromiseAction<ReturnType<typeof selectMufaPropertiesInternal>>
	| ReturnType<typeof deselectAll>
	| ReturnType<typeof selectComps>
	| ReturnType<typeof deselectComps>;
