import { InfiniteData } from '@tanstack/react-query';
import { createLoadingRows } from 'Components';
import { useMemo } from 'react';

type Props<Row, Data> = {
	data: InfiniteData<Data> | undefined;
	isFetching: boolean;
	getRows: (page: Data) => Row[];
	pageSize?: number;
};

/** Merges rows from all pages (infinite query) & adds "loading rows" appropriately. */
export const useInfiniteTableRows = <Row, Data>({
	data,
	isFetching,
	getRows,
	pageSize = 50,
}: Props<Row, Data>): Row[] => {
	return useMemo(() => {
		if (!data) return createLoadingRows(pageSize);
		const dataRows = data.pages.reduce<Row[]>((acc, page) => {
			acc.push(...getRows(page));
			return acc;
		}, []);
		if (isFetching) {
			return dataRows.concat(createLoadingRows(pageSize));
		}

		return dataRows;
	}, [data, isFetching, getRows, pageSize]);
};
