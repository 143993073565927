import {
	ModalButton,
	ModalButtons,
	ModalParagraph,
	ModalTitle,
} from 'Components/Modals/common/UI';

export const CompNotFound = ({
	title,
	message,
	onClick,
}: {
	title?: string;
	message?: string;
	onClick: () => void;
}) => {
	return (
		<>
			<ModalTitle>{title ?? 'Unable to view comp'}</ModalTitle>
			<ModalParagraph>
				{message ??
					'This comp either doesn’t exist or you don’t have access to it.'}
			</ModalParagraph>
			<ModalButtons>
				<ModalButton onClick={onClick}>New Search</ModalButton>
			</ModalButtons>
		</>
	);
};
