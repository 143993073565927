import { Flex, Label } from '@compstak/ui-kit';
import { PropertyLease, SpaceTypeName } from 'api';
import { ChangeEvent } from 'react';
import styled from 'styled-components';

type Props = {
	disableSpaceType?: boolean;
	leasesGroupedBySpaceType: Partial<Record<SpaceTypeName, PropertyLease[]>>;
	leasesByStatus: PropertyLease[];
	checkedSpaceType: 'All Leases' | SpaceTypeName;
	setCheckedSpaceType: React.Dispatch<
		React.SetStateAction<'All Leases' | SpaceTypeName>
	>;
	checkedStatus: 'All Leases' | 'Only Active Leases';
	setCheckedStatus: React.Dispatch<
		React.SetStateAction<'All Leases' | 'Only Active Leases'>
	>;
};

export const RadioButtonGroup = ({
	disableSpaceType = false,
	leasesGroupedBySpaceType,
	leasesByStatus,
	checkedSpaceType,
	setCheckedSpaceType,
	checkedStatus,
	setCheckedStatus,
}: Props) => {
	const handleSpaceTypeRadioChange = (
		e: ChangeEvent<HTMLInputElement>
	): void => {
		setCheckedSpaceType(e.target.value as 'All Leases' | SpaceTypeName);
	};

	const handleStatusRadioChange = (e: ChangeEvent<HTMLInputElement>) => {
		setCheckedStatus(e.target.value as 'All Leases' | 'Only Active Leases');
	};

	return (
		<OptionsContainer>
			<Flex justifyContent="flex-start" gap="32px">
				<Flex justifyContent="flex-start" gap="16px">
					<strong>Status:</strong>
					<Flex gap="8px">
						<input
							type="radio"
							name="status"
							checked={checkedStatus === 'All Leases'}
							onChange={handleStatusRadioChange}
							value={'All Leases'}
							data-qa-id="status-all-radio"
						/>
						<Label>All Leases</Label>
					</Flex>
					<Flex gap="8px">
						<input
							type="radio"
							name="status"
							checked={checkedStatus === 'Only Active Leases'}
							onChange={handleStatusRadioChange}
							value={'Only Active Leases'}
							data-qa-id="status-active-radio"
						/>
						<Label>Only Active Leases</Label>
					</Flex>
				</Flex>

				<Flex justifyContent="flex-start" gap="16px">
					<strong>Space Type:</strong>
					<Flex gap="8px">
						<input
							type="radio"
							name="spaceType"
							checked={checkedSpaceType === 'All Leases'}
							onChange={handleSpaceTypeRadioChange}
							value={'All Leases'}
							data-qa-id="spaceType-all-radio"
						/>
						<Label>All Leases ({leasesByStatus.length})</Label>
					</Flex>
					{Object.entries(leasesGroupedBySpaceType).map(
						([spaceType, items]) => {
							return (
								<Flex gap="8px" alignItems="center" key={spaceType}>
									<input
										name="spaceType"
										type="radio"
										checked={checkedSpaceType === spaceType}
										onChange={handleSpaceTypeRadioChange}
										value={spaceType}
										//Disable click on button if comp with specific spaceType is not unlocked
										disabled={
											disableSpaceType &&
											items?.filter((comp) => comp.own).length === 0
										}
									/>
									<Label>
										{spaceType} (
										{
											leasesByStatus.filter(
												(lease) =>
													lease.spaceType === spaceType ||
													(!lease.spaceType && spaceType === 'Other')
											).length
										}
										)
									</Label>
								</Flex>
							);
						}
					)}
				</Flex>
			</Flex>
		</OptionsContainer>
	);
};

RadioButtonGroup.displayName = 'RadioButtonGroup';

const OptionsContainer = styled.div`
	margin: 18px 0;
`;
