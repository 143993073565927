import { z } from 'zod';

const promotionValidatorBase = z.object({
	id: z.number(),
	title: z.string(),
	shortDescription: z.string(),
	description: z.string(),
	state: z.string(),
	creditReward: z.object({ multiplier: z.number() }).nullish(),
	giftCardReward: z
		.object({
			tiers: z
				.array(
					z.object({
						compThreshold: z.number(),
						rewardDollars: z.number(),
					})
				)
				.nullable(),
		})
		.nullish(),
	marketingStartDate: z.string(),
	marketingEndDate: z.string(),
	marketsDisplayText: z.string().optional(),
	propertySubtypes: z.array(z.string()).optional(),
	compType: z.enum(['Leases', 'Sales']),
	// Spec from swagger:
	// https://user-service.dev.cs-int-592.com/docs/swagger/#/promotion/get_admin_promotions__promotionId_
	markets: z.array(z.object({ marketId: z.number(), displayName: z.string() })),
	tagIds: z.array(z.number()).optional(),
	submarkets: z
		.array(
			z.object({
				marketId: z.number(),
				submarketId: z.number(),
				name: z.string(),
			})
		)
		.optional(),
	submarketsDisplayText: z.string().optional(),
	rewardOnlyMasterCreator: z.boolean().optional(),
});

const promotionValidatorLeases = promotionValidatorBase.extend({
	compType: z.literal('Leases'),
	spaceTypes: z.array(z.string()),
	propertyTypes: z.array(z.string()).optional(),
	marketingExecutionStartDate: z.string(),
	executionDateLookback: z.string().optional(),
	// TODO Remove optional after releasing the feature AppsPromotionIterations3.5
	premiumOnly: z.boolean().optional(),
});

const promotionValidatorSales = promotionValidatorBase.extend({
	compType: z.literal('Sales'),
	marketingSaleStartDate: z.string(),
	saleDateLookback: z.string().optional(),
	propertyTypes: z.array(z.string()),
	premiumOnly: z.boolean(),
	salePrice: z.number(),
});

export const promotionValidator = z.discriminatedUnion('compType', [
	promotionValidatorLeases,
	promotionValidatorSales,
]);

export enum PromotionState {
	DRAFT = 'Draft',
	PENDING = 'Pending',
	ACTIVE = 'Active',
	COMPLETED = 'Completed',
	TERMINATED = 'Terminated',
	DELETED = 'Deleted',
}

type PromotionSales = Omit<z.infer<typeof promotionValidatorSales>, 'state'>;
type PromotionLeases = Omit<z.infer<typeof promotionValidatorLeases>, 'state'>;

export type Promotion = (PromotionSales | PromotionLeases) & {
	state: PromotionState;
};
