import { ChartBox } from 'PortfolioAnalytics/Charts/ChartSelect';
import { getTenantIndustryChartData } from '../../../Charts/TenantIndustryExposure/TenantIndustryExposureChart';
import { colors } from '@compstak/ui-kit';
import { Portfolio } from 'api/types/portfolio';
import { UseQueryResult } from '@tanstack/react-query';
import { ChartLegendV1 } from '../ChartLegend';
import { TenantIndustryTooltip } from './TenantIndustryTooltip';

import {
	VictoryAxis,
	VictoryBar,
	VictoryChart,
	VictoryGroup,
	VictoryLabelProps,
	VictoryStack,
	VictoryVoronoiContainer,
	VictoryTooltip,
} from 'victory';

import {
	BENCHMARKING_COLORS,
	BENCHMARKING_SELECT_PORTFOLIOS_MESSAGE,
} from '../../constants';
import { VICTORY_CONTAINER_STYLE_800 } from 'PortfolioAnalytics/Charts/chartsConstants';
import { usePortfoliosByIdsQueries } from 'api/portfolio/portfolioById/usePortfoliosByIdsQueries';
import { useMemo } from 'react';

import { ChartOption } from 'PortfolioAnalytics/Charts/ChartSelect';

import { usePortfolioFilters } from 'PortfolioAnalytics/PortfolioFiltersProvider';
import { usePortfoliosSelection } from 'PortfolioAnalytics/hooks/usePortfoliosSelection';
import { ChartStateWrapper } from 'PortfolioAnalytics/Charts/ChartStateWrapper';

const CHART_NAME = 'Tenant Industry Distribution';
const CUTOFF_BAR_LENGTH_TO_DISPLAY_VALUE = 11;
const CUTOFF_BAR_LENGTH_TO_DISPLAY_NAME = 36;
const CUTOFF_NAME_LENGTH = 12;

export const BenchmarkingTenantIndustryChart = () => {
	return (
		<ChartBox chartName={CHART_NAME}>
			{(selectedChart) => (
				<BenchmarkingTenantIndustryChartContent selectedChart={selectedChart} />
			)}
		</ChartBox>
	);
};

const getGradientColor = (baseColor: string, index: number) => {
	switch (index) {
		case 0:
			return baseColor;
		case 1:
			return `${baseColor}cc`;
		case 2:
			return `${baseColor}99`;
		case 3:
			return `${baseColor}66`;
		case 4:
			return `${baseColor}33`;
		default:
			return baseColor;
	}
};

const industryNameStyle = {
	fill: colors.white.white,
	textShadow:
		'0px 3px 5px rgba(24, 25, 26, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31)',
	fontSize: '0.7em',
	fontWeight: 300,
	fontFamily: 'Gotham, sans-serif',
};

const valueStyle = {
	fill: colors.white.white,
	fontSize: '0.7em',
	fontWeight: 500,
	fontFamily: 'Gotham, sans-serif',
};

const CustomLabel: React.FC<VictoryLabelProps> = (props) => {
	const { x, y, datum } = props;
	const name = datum?.name || '';
	const value = datum?.y || 0;
	const industryNameXOffset = value === 100 ? -48 : -40;
	const shortenedName =
		value > CUTOFF_BAR_LENGTH_TO_DISPLAY_NAME
			? `${name.slice(0, CUTOFF_NAME_LENGTH)}${name.length > CUTOFF_NAME_LENGTH ? '...' : ''}`
			: '';
	const valueString =
		value > CUTOFF_BAR_LENGTH_TO_DISPLAY_VALUE ? `${value.toFixed(1)}%` : '';

	return (
		<g>
			<text
				x={x}
				y={y}
				dy={5}
				dx={industryNameXOffset}
				textAnchor="end"
				style={industryNameStyle}
			>
				{shortenedName}
			</text>
			<text x={x} y={y} dy={5} dx={-3} textAnchor="end" style={valueStyle}>
				{valueString}
			</text>
		</g>
	);
};

const BenchmarkingTenantIndustryChartContent = ({
	selectedChart,
}: {
	selectedChart: ChartOption<'lease'>;
}) => {
	const totalValues: Record<number, number> = {};
	const { filters } = usePortfolioFilters();
	const { selectedPortfolioIds } = usePortfoliosSelection();
	const portfoliosByIdParams = selectedPortfolioIds.map((portfolioId) => ({
		id: portfolioId,
		filters,
	}));
	const portfoliosResponse = usePortfoliosByIdsQueries(portfoliosByIdParams);
	const isFetching = portfoliosResponse.some(
		(portfolioData) => portfolioData.isFetching
	);
	const isError = portfoliosResponse.some(
		(portfolioData) => portfolioData.isError
	);

	const portfoliosData: Portfolio[] = portfoliosResponse
		.filter(
			(
				portfolio
			): portfolio is UseQueryResult<Portfolio> & { data: Portfolio } =>
				portfolio?.data !== null && portfolio?.data !== undefined
		)
		.map((portfolio) => portfolio.data);

	const chartData = portfoliosData.map((portfolioTenantIndustries) =>
		getTenantIndustryChartData(portfolioTenantIndustries)
	);

	const selectedData = useMemo(
		() =>
			chartData
				.filter(
					(portfolioData) => portfolioData && portfolioData[selectedChart.type]
				)
				.map((portfolioData) =>
					portfolioData[selectedChart.type]
						.filter((industry) => industry && industry.x !== 'Other industries')
						.map((industry) => ({
							...industry,
							portfolioId: portfolioData.portfolioId,
						}))
				),
		[chartData, selectedChart.type]
	);

	const selectedDataWithColor = useMemo(
		() =>
			selectedData
				.map((portfolio, portfolioIndex) => {
					const baseColor = BENCHMARKING_COLORS[portfolioIndex];
					let totalValue = 0;
					return portfolio.map((industry, industryIndex) => {
						totalValue += industry.y;
						totalValues[industry.portfolioId] = totalValue;
						const industryColor = getGradientColor(baseColor, industryIndex);
						return { ...industry, color: industryColor };
					});
				})
				.filter((portfolio) => portfolio && portfolio.length > 0),
		[selectedData]
	);

	const chartHeight = selectedDataWithColor.length * 30 + 80;

	const allPortfoliosEmpty = useMemo(
		() => selectedPortfolioIds.length > 0 && selectedDataWithColor.length === 0,
		[selectedPortfolioIds, selectedDataWithColor]
	);

	const noData =
		!isFetching && (selectedPortfolioIds.length === 0 || allPortfoliosEmpty);

	return (
		<ChartStateWrapper
			isError={isError}
			isFetching={isFetching}
			noData={noData}
			showNoDataMessage={!allPortfoliosEmpty}
			noDataMessage={BENCHMARKING_SELECT_PORTFOLIOS_MESSAGE}
		>
			<VictoryChart
				domainPadding={5}
				height={chartHeight}
				containerComponent={
					<VictoryVoronoiContainer
						id="benchmarking-tenant-industry-chart-voronoi-container"
						constrainToVisibleArea={false}
						style={{ height: '100%', ...VICTORY_CONTAINER_STYLE_800 }}
						voronoiDimension="x"
						labels={() => ' '}
						labelComponent={
							<VictoryTooltip
								flyoutComponent={
									<TenantIndustryTooltip width={220} height={20} />
								}
							/>
						}
					/>
				}
			>
				<VictoryAxis
					style={{
						tickLabels: { fontSize: 12 },
						axis: { stroke: 'transparent' },
					}}
					tickFormat={() => ''}
				/>
				<VictoryGroup horizontal>
					{selectedDataWithColor
						.slice()
						.reverse()
						.map((portfolio, index) => (
							<VictoryStack key={index}>
								{portfolio.map((industry, industryIndex) => {
									return (
										<VictoryBar
											barWidth={30}
											data={[
												{
													x: `${index + 1}`,
													y:
														totalValues[industry.portfolioId] > 0
															? (100 * industry.y) /
																totalValues[industry.portfolioId]
															: 0,
													name: industry.x,
												},
											]}
											key={industryIndex}
											labels={() => ''}
											labelComponent={<CustomLabel />}
											style={{
												data: { fill: `${industry.color}` },
											}}
										/>
									);
								})}
							</VictoryStack>
						))}
				</VictoryGroup>
			</VictoryChart>
			<ChartLegendV1
				portfolios={portfoliosData}
				colors={BENCHMARKING_COLORS}
				metric="tenantIndustries"
			/>
		</ChartStateWrapper>
	);
};
