import { PortfolioLeasesFilters } from './PortfolioLeasesFiltersProvider';
import dayjs from 'dayjs';
import { FiltersObject } from 'models/filters/types';

type Props = {
	leaseFilters: PortfolioLeasesFilters;
	portfolioFilters: FiltersObject;
};

export const getFiltersFromPortfolioLeasesFilters = ({
	leaseFilters: { activeLeasesOnly, includeSubleases, filterBy },
	portfolioFilters,
}: Props) => {
	const filters: FiltersObject = {
		...portfolioFilters,
		partial: 'false',
		sortField: 'executionQuarter',
		sortDirection: 'desc',
		...(includeSubleases === false && { sublease: 'false' }),
	};

	if (activeLeasesOnly) {
		filters.expirationDate = {
			...portfolioFilters.expirationDate,
			min: new Date(),
			max: portfolioFilters.expirationDate?.max ?? null,
			allowFallback: portfolioFilters.expirationDate?.allowFallback ?? false,
		};
	}

	if (filterBy) {
		const criterion = filterBy.criterion;
		const duration = filterBy.duration.value;
		const unit = filterBy.duration.unit;
		switch (criterion) {
			case 'newOnCompstak': {
				filters.dateCreated = {
					min: dayjs().subtract(duration, unit).toDate(),
					max: null,
				};
				break;
			}
			case 'recentlyUpdated': {
				filters.lastAttributeUpdate = {
					min: dayjs().subtract(duration, unit).toDate(),
					max: null,
				};
				break;
			}
		}
	}

	return filters;
};
