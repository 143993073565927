import {
	formatMonths,
	formatNumber,
	formatObfuscatedSizeRange,
	formatRent,
} from 'format';
import { PdfColumn } from 'pdf/PdfTable';
import { LeaseComp } from 'types';
import { getIsMonthlyMarket, renderSafeValue } from 'utils';

export const useLeasesPdfColumns = (): PdfColumn<LeaseComp>[] => {
	return [
		{
			id: 'tenantName',
			header: 'Tenant',
			body: ({ row }) => row.tenantName,
		},
		{
			id: 'buildingAddress',
			header: 'Address',
			body: ({ row }) => row.buildingAddress,
		},
		{
			id: 'city',
			header: 'City',
			body: ({ row }) => row.city,
		},
		{
			id: 'submarket',
			header: 'Submarket',
			body: ({ row }) => row.submarket,
		},
		{
			id: 'transactionSize',
			header: 'SqFt',
			body: ({ row }) => {
				return renderSafeValue({
					value: row.transactionSize,
					format: (v) =>
						row.own
							? formatNumber(v, { shorten: false })
							: formatObfuscatedSizeRange(v),
				});
			},
		},
		{
			id: 'effectiveRent',
			header: 'Net Effective Rent',
			body: ({ row }) => {
				const isMonthly = getIsMonthlyMarket(row.marketId);
				return renderSafeValue({
					value: row.effectiveRent,
					format: (v) =>
						`${formatRent(v, isMonthly)} ${isMonthly ? '(Monthly)' : '(Annual)'}`,
				});
			},
		},
		{
			id: 'leaseTerm',
			header: 'Term',
			body: ({ row }) =>
				renderSafeValue({ value: row.leaseTerm, format: formatMonths }),
		},
	];
};
