import {
	AngleArrow,
	Button,
	MQB,
	Spinner,
	TooltipV2,
	basicDropdownListItemStyles,
} from '@compstak/ui-kit';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { defaultDropdownContentCss } from 'Components';
import { useMarketsHaveFeature, usePropertyAverages } from 'api';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { routes } from 'router';
import styled from 'styled-components';
import CompTypeIcon from 'ui/svg_icons/compTypeIcon.svg';
import abbreviateNumber from 'ui/util/abbreviateNumber';
import { filtersToQueryString } from '../../../models/filters/util';
import { CompType, PropertyComp } from '../../../types';
import { FiltersObject } from 'models/filters/types';

const MAX_SELECTED_COMPS_LIMIT = 1000;

type Props = {
	properties: PropertyComp[];
	filters: FiltersObject;
};

export const GoToCompTypeSection = ({ properties, filters }: Props) => {
	const selectionIds = useMemo(() => {
		return properties.map((c) => c.id);
	}, [properties]);

	const marketIds = useMemo(() => {
		return getFiltersMarkets(filters).map((m) => m.id);
	}, [filters]);

	const { data: averages, isFetching: isFetchingAverages } =
		usePropertyAverages({
			ids: selectionIds,
			marketIds,
		});
	const isLargeScreen = useMediaQuery({ minWidth: MQB.D_1536 });

	const leaseCount = properties.length > 0 ? averages?.leases ?? 0 : 0;
	const saleCount = properties.length > 0 ? averages?.sales ?? 0 : 0;

	const addresses = properties.map(
		(property) => property.buildingAddressAndCity
	);

	const filterString = filtersToQueryString({
		address: addresses,
		sortField: 'address',
		sortDirection: 'asc',
	});

	const isZeroSelected = properties.length === 0;
	const leasesOverLimitSelected = leaseCount > MAX_SELECTED_COMPS_LIMIT;
	const salesOverLimitSelected = saleCount > MAX_SELECTED_COMPS_LIMIT;
	const leaseDisabled =
		isZeroSelected ||
		leasesOverLimitSelected ||
		isFetchingAverages ||
		leaseCount === 0;
	const saleDisabled =
		isZeroSelected ||
		salesOverLimitSelected ||
		isFetchingAverages ||
		saleCount === 0;

	const saleLabel = getLabel('Sales', isFetchingAverages, saleCount);
	const leaseLabel = getLabel('Leases', isFetchingAverages, leaseCount);

	const leaseTooltip = getTooltip('lease', leaseDisabled);
	const saleTooltip = getTooltip('sale', saleDisabled);

	const { data: hasSalesAccess } = useMarketsHaveFeature({
		feature: 'salesAccess',
		method: 'some',
	});

	const { data: hasLeaseAccess } = useMarketsHaveFeature({
		feature: 'leaseAccess',
		method: 'some',
	});

	const [isMenuOpen, setIsMenuOpen] = useState(false);

	return isLargeScreen ? (
		<>
			{hasLeaseAccess && (
				<TooltipV2 content={leaseTooltip}>
					<ButtonStyled
						variant="primary3"
						size="l"
						href={`${routes.searchLeasesByView.toHref({ view: 'list' })}?${filterString}`}
						disabled={leaseDisabled}
					>
						{leaseLabel}
					</ButtonStyled>
				</TooltipV2>
			)}
			{hasSalesAccess && (
				<TooltipV2 content={saleTooltip}>
					<ButtonStyled
						variant="primary3"
						size="l"
						href={`${routes.searchSalesByView.toHref({ view: 'list' })}?${filterString}`}
						disabled={saleDisabled}
					>
						{saleLabel}
					</ButtonStyled>
				</TooltipV2>
			)}
		</>
	) : (
		<div>
			<DropdownMenu.Root open={isMenuOpen} onOpenChange={setIsMenuOpen}>
				<DropdownMenu.Trigger asChild>
					<ButtonStyled variant="primary3" size="l">
						<CompTypeIcon />
						<StyledAngleArrow rotate={isMenuOpen ? 180 : 0} />
					</ButtonStyled>
				</DropdownMenu.Trigger>
				<DropdownMenu.Portal>
					<DropdownContent>
						{hasSalesAccess && (
							<DropdownMenu.Item asChild>
								<StyledDropdownItemLink
									to={`${routes.searchLeasesByView.toHref({ view: 'list' })}?${filterString}`}
									disabled={leaseDisabled}
									data-tooltip={leaseTooltip}
									data-tooltip-position={
										leasesOverLimitSelected || salesOverLimitSelected
											? 'below'
											: 'above'
									}
									onClick={(e) => {
										if (leaseDisabled) {
											e.preventDefault();
										}
									}}
								>
									{leaseLabel}
								</StyledDropdownItemLink>
							</DropdownMenu.Item>
						)}
						{hasLeaseAccess && (
							<DropdownMenu.Item asChild>
								<StyledDropdownItemLink
									to={`${routes.searchSalesByView.toHref({ view: 'list' })}?${filterString}`}
									disabled={saleDisabled}
									data-tooltip={saleTooltip}
									data-tooltip-position={
										leasesOverLimitSelected || salesOverLimitSelected
											? 'below'
											: 'above'
									}
									onClick={(e) => {
										if (saleDisabled) {
											e.preventDefault();
										}
									}}
								>
									{saleLabel}
								</StyledDropdownItemLink>
							</DropdownMenu.Item>
						)}
					</DropdownContent>
				</DropdownMenu.Portal>
			</DropdownMenu.Root>
		</div>
	);

	function getTooltip(compType: CompType, disabled: boolean) {
		if (isFetchingAverages || !disabled) {
			return undefined;
		}

		if (
			(compType === 'lease' && leasesOverLimitSelected) ||
			(compType === 'sale' && salesOverLimitSelected)
		) {
			return `You can see a max of ${MAX_SELECTED_COMPS_LIMIT} comps from this set of properties. Please refine your filters or change your selected properties to view these comps`;
		}

		return `There are no ${compType} comps associated with the selected set of properties`;
	}
};

function getLabel(title: string, isAveragesLoading: boolean, count: number) {
	return (
		<>
			<span>{title}</span>
			{isAveragesLoading ? (
				<Spinner size="s" />
			) : (
				`(${abbreviateNumber(count)})`
			)}
		</>
	);
}

const StyledDropdownItemLink = styled(Link)<{ disabled: boolean }>`
	${basicDropdownListItemStyles};
	padding: 0.75rem 0.5rem;
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
	color: ${({ theme }) => theme.colors.gray.gray0};
	display: flex;
	align-items: center;

	${({ disabled }) => (disabled ? 'opacity: 0.5;' : '')};

	&:hover {
		color: ${({ theme }) => theme.colors.gray.gray0};
	}
`;

const DropdownContent = styled(DropdownMenu.Content)`
	${defaultDropdownContentCss}
	border: 1px solid ${({ theme }) => theme.colors.neutral.n100};
`;

const ButtonStyled = styled(Button)`
	display: flex;
	gap: 0.25rem;
	min-width: min-content;
`;

const StyledAngleArrow = styled(AngleArrow)`
	path {
		stroke: ${({ theme }) => theme.colors.white.white};
	}
`;
